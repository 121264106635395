import MaterialTableIcons from 'components/Common/MaterialTableIcons';
import { BackgroundColorContext } from 'contexts/BackgroundColorContext';
import { format } from 'date-fns';
import MaterialTable from 'material-table'
import PropTypes from 'prop-types'
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUserData } from 'Redux/features/Auth/AuthSlice';
import { studentSelected } from 'Redux/features/Student/StudentSlice';
import { selectstudent } from 'Redux/features/Student/StudentSlice';

const ReferPointsRecordList = ({ recordList }) => {

  const param = useParams()
  const { color } = useContext(BackgroundColorContext)
  const { tableIcons } = MaterialTableIcons();
  const allStudents = useSelector(selectstudent)
  const userData = useSelector(studentSelected)

  const renderReferRowData = (value) => (
    <div className="title">
      <h1>{value}</h1>
    </div>
  )

  const renderUserData = (label, value) => (
    <div>
      <h1>{label}</h1>
      <h1> {value}</h1>
    </div>

  )

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" }
  ];

  // console.log("allStudents");
  // console.log(allStudents);
  // console.log(recordList);
  let count = 0;
  // const student = allStudents?.length && allStudents.find(e => e._id == param.studentID);
  // const userName = student?.first_name + " " + student?.last_name;
  // const userEmail = student?.email;
  // const referCode = student?.referral_code;
  const totalPoints = recordList?.length && recordList?.reduce((a, b) => { return a += b.points }, 0)

  return (
    <div className='refer_points_record-list'>

      <div className="user_data">
        {renderUserData("User Name:", userData?.first_name + " " + userData?.last_name)}
        {renderUserData("Email:", userData?.email)}
        {renderUserData("ReferCode:", userData?.referral_code)}
      </div>

      <p className='total_points' >Total Points: {totalPoints}</p>

      {!recordList?.length ? <h3>No Record found.</h3> :
        <MaterialTable
          icons={tableIcons}
          title={null}
          key={count++}
          columns={
            [
              // {
              //   title: "#", field: 'index',
              //   render: rowData => renderReferRowData(rowData.index),
              //   filtering: false
              // },
              {
                title: "Used By", field: 'availedBy',
                render: rowData => renderReferRowData(rowData.availedBy),
              },
              {
                title: "Email/Phone", field: 'availedBy_email',
                render: rowData => renderReferRowData(rowData.availedBy_email),
              },
              {
                title: "Platform", field: 'platform',
                render: rowData => renderReferRowData(rowData.platform),
              },
              {
                title: "Use Date", field: 'date_of_use',
                render: rowData => renderReferRowData(rowData.date_of_use),
              },
              {
                title: "Source", field: 'source',
                render: rowData => renderReferRowData(rowData.source),
              },
              {
                title: "Points", field: 'points',
                render: rowData => renderReferRowData(rowData.points),
              }
            ]
          }

          data={
            recordList?.map((e) => {
              return {
                index: ++count,
                _id: e._id,
                user_name: e?.user?.user_name || "-",
                email: e?.user?.email || "-",
                date_of_use: e.date ? format(new Date(e.date), "dd MMM yyyy") : '-',
                source: e?.source || "-",
                points: e?.points ?? "-",
                availedBy: e?.availedBy?.user_name || "-",
                availedBy_email: e?.availedBy?.email || "-",
                platform: e?.availedBy?.platform || "-",
                referral_code: e?.user?.referral_code || "-",
              }
            })}

          options={{
            actionsColumnIndex: -1,
            exportButton: false,
            pageSize: 5,
            pageSizeOptions: [5, 10, 30, 50],
            headerStyle: {
              backgroundColor: colorsList.find(e => e.color === color).value,
              color: '#FFF'
            },
            filtering: true
          }}
        />
      }
    </div>
  )
}

ReferPointsRecordList.propTypes = {
  recordList: PropTypes.array.isRequired,
}

export default ReferPointsRecordList
