import backend from "../api";

export const saveNotificationLogs = async (data, category, userID) =>
  await backend({
    method: "PUT",
    url:
      category === "general"
        ? "/notification/log/general"
        : `/notification/log/individual/${userID}`,
    data: data,
  });

export const saveIndividualNotificationLogs = async (data, userID) =>
  await backend({
    method: "PUT",
    url: `/notification/log/individual/${userID}`,
    data: data,
  });

export const sendGradeNotificationLogs = async (data) =>
  await backend({
    method: "PUT",
    url: "/notification/log/grade",
    data: data,
  });

export const sendSessionNotificationLogs = async (data) =>
  await backend({
    method: "PUT",
    url: "/notification/log/session",
    data: data,
  });
