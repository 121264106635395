import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import MaterialTable from "material-table";
import { format, formatDistance } from "date-fns";
import DateFilters from "components/Common/DateFilters";
import Loader from "components/Common/Loader";
import { getStudentUninstall } from "Utils/reports.Utils";
import { Bar, Line } from "react-chartjs-2";
import { getInstallStats } from "Utils/reports.Utils";
import { toast } from "react-toastify";
import { setDateRange } from "Utils/helper_Utils";
import { getStudentsPlatform } from "Utils/reports.Utils";
import { getReportsByScreenNames } from "Utils/reports.Utils";
import { createDateRange } from "Utils/helper_Utils";
import { differenceBtw2Dates } from "Utils/helper_Utils";
import { useRef } from "react";
import DownloadPDF from "components/Common/DownloadPDF";

const userStickinessGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "unInstallStats",
    },
  },

  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const userStickinessRatioGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "unInstallStats",
    },
  },

  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        if (tooltipItem?.datasetIndex === 0)
          return "DAU / MAU : " + (tooltipItem?.yLabel).toFixed(2) + "%";
        if (tooltipItem?.datasetIndex === 1)
          return "DAU / WAU : " + (tooltipItem?.yLabel).toFixed(2) + "%";
        if (tooltipItem?.datasetIndex === 2)
          return "WAU / MAU : " + (tooltipItem?.yLabel).toFixed(2) + "%";
      },
    },
  },

  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const userStickinessGraph = {};
const userStickinessRatioGraph = {};

function UserStickiness() {
  const [loading, setLoading] = useState(false);
  const [appSessionJourneyList, setAppSessionJourneyList] = useState([]);
  const [topicJourneyList, setTopicJourneyList] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [dateObj, setDateObj] = useState(setDateRange());
  const [dailyActiveUsers, setDailyActiveUsers] = useState(0);
  const [totalInstall, setTotalInstall] = useState(0);
  const [topicThreshold, setTopicThreshold] = useState(0);

  const createUserGraph = (journeyData) => {
    setLoading(true);
    const arrangeData = journeyData.map((e) => {
      e.date_created = e?.date_created.split("T")[0];
      return e;
    });

    const uniqueDates = [
      ...new Set(arrangeData.map((e) => e?.date_created)),
    ].sort();

    const userGraphData = arrangeData.reduce(function (acc, curr) {
      return (
        acc[curr?.date_created]
          ? ++acc[curr?.date_created]
          : (acc[curr?.date_created] = 1),
        acc
      );
    }, {});

    userStickinessGraph.labels = uniqueDates;
    userStickinessGraph.datasets.push({
      label: "Users",
      data: Object.entries(userGraphData)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map((e) => e[1]),
      borderColor: "rgb(74, 68, 252)",
      backgroundColor: "rgb(74, 68, 252)",
      fill: false,
      lineTension: 0,
    });

    setTotalStudents(journeyData?.length);
    setLoading(false);
  };

  const createActiveUserGraph = (dateObj, journeyData) => {
    setLoading(true);
    const dateList = createDateRange(differenceBtw2Dates(dateObj) + 1).sort();

    const list =
      journeyData?.length &&
      journeyData
        .reduce((a, b) => {
          const journeys = b?.journeys?.length ? b.journeys : [];
          return [...a, ...journeys];
        }, [])
        .filter((e) => dateList.includes(e?.date));

    const userPerDay = dateList.map((e) => list.filter((x) => x?.date === e));

    userStickinessGraph?.datasets?.push({
      label: "Daily active users",
      data: userPerDay?.map((x) => x?.length),
      borderColor: "rgb(117, 217, 98)",
      backgroundColor: "rgb(117, 217, 98)",
      fill: false,
      lineTension: 0,
    });

    setLoading(false);
  };

  const createWeeklyActiveUserGraph = (dateObj, journeyData) => {
    setLoading(true);

    const dateList = createDateRange(differenceBtw2Dates(dateObj) + 1).sort();

    const userPerDay = dateList.map((e) => {
      const dates = createDateRange(7, new Date(e));

      const userData = journeyData.map((x) =>
        x?.journeys?.length
          ? x?.journeys?.map((a) => (dates.find((c) => c === a.date) ? 1 : 0))
          : 0
      );

      return userData;
    });

    userStickinessGraph?.datasets?.push({
      label: "Weekly active users",
      data: userPerDay?.map(
        (x) => x?.filter((a) => a.find((a) => a === 1))?.length
      ),
      borderColor: "rgb(235, 52, 52)",
      backgroundColor: "rgb(235, 52, 52)",
      fill: false,
      lineTension: 0,
    });

    setLoading(false);
  };

  const createMonthlyActiveUserGraph = (dateObj, journeyData, threshold) => {
    setLoading(true);

    const dateList = createDateRange(differenceBtw2Dates(dateObj) + 1).sort();

    const userPerDay = dateList.map((e) => {
      const dates = createDateRange(30, new Date(e)).sort();

      const userData = journeyData.map((x) => {
        if (x?.journeys?.length) {
          const topicData = x.journeys
            .map((a) => (dates.find((d) => d === a?.date) ? a : false))
            .filter((s) => s && s?.status === "completed");
          return topicData?.length
            ? [...new Set(topicData?.map((c) => c.uniCode))]?.length
            : 0;
        }

        return 0;
      });

      return userData;
    });

    userStickinessGraph.datasets.push({
      label: `Monthly active users threshold ${threshold}`,
      data: userPerDay?.map(
        (x) => x.filter((e) => e >= Number(threshold))?.length
      ),
      borderColor: "rgb(194, 8, 172)",
      backgroundColor: "rgb(194, 8, 172)",
      fill: false,
      lineTension: 0,
    });

    setLoading(false);

    setTopicThreshold(0);
    setTimeout(() => {
      setTopicThreshold(threshold);
    }, 500);
  };

  const createUserStickinessRatioGraph = () => {
    setLoading(true);

    const dateList = userStickinessGraph.labels;
    const dau = userStickinessGraph.datasets[1]?.data;
    const wau = userStickinessGraph.datasets[2]?.data;
    const mau = userStickinessGraph.datasets[3]?.data;

    const graphData = dateList.map((e, i) => {
      const dau_mau = Number(dau[i]) / Number(mau[i]);
      const dau_wau = Number(dau[i]) / Number(wau[i]);
      const wau_mau = Number(wau[i]) / Number(mau[i]);

      return { dau_mau, dau_wau, wau_mau };
    });

    userStickinessRatioGraph.labels = dateList;
    userStickinessRatioGraph.datasets = [];

    userStickinessRatioGraph.datasets.push({
      label: "DAU / MAU",
      data: graphData.map((e) => e.dau_mau * 100),
      borderColor: "rgb(194, 8, 172)",
      backgroundColor: "rgb(194, 8, 172)",
      fill: false,
      lineTension: 0,
    });
    userStickinessRatioGraph.datasets.push({
      label: "DAU / WAU",
      data: graphData.map((e) => e.dau_wau * 100),
      borderColor: "rgb(117, 217, 98)",
      backgroundColor: "rgb(117, 217, 98)",
      fill: false,
      lineTension: 0,
    });
    userStickinessRatioGraph.datasets.push({
      label: "WAU / MAU",
      data: graphData.map((e) => e.wau_mau * 100),
      borderColor: "rgb(82, 71, 237)",
      backgroundColor: "rgb(82, 71, 237)",
      fill: false,
      lineTension: 0,
    });

    setLoading(false);
  };

  const fetchUserData = async (dateObj) => {
    setLoading(true);
    try {
      const users = await getStudentsPlatform(dateObj);

      userStickinessGraph.datasets = [];
      createUserGraph(users?.data?.data);
      createActiveUserGraph(dateObj, appSessionJourneyList);
      createWeeklyActiveUserGraph(dateObj, appSessionJourneyList);
      createMonthlyActiveUserGraph(dateObj, topicJourneyList, topicThreshold);
      createUserStickinessRatioGraph();
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialData = async (dateObj) => {
    setLoading(true);
    try {
      const [users, appSessionJourney, topicJourney] = await Promise.all([
        getStudentsPlatform(dateObj),
        getReportsByScreenNames("appSession"),
        getReportsByScreenNames("topic_view"),
      ]);

      userStickinessGraph.datasets = [];
      createUserGraph(users?.data?.data);
      createActiveUserGraph(dateObj, appSessionJourney?.data?.data);
      createWeeklyActiveUserGraph(dateObj, appSessionJourney?.data?.data);
      createMonthlyActiveUserGraph(dateObj, topicJourney?.data?.data, 30);
      createUserStickinessRatioGraph();

      setAppSessionJourneyList(appSessionJourney?.data?.data);
      setTopicJourneyList(topicJourney?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
  };

  const submitHandler = (dateObj) => {
    if (!dateObj?.startDate || !dateObj?.endDate) {
      toast.info("Dates Missing");
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    setDateObj(dateObj);
    fetchUserData(dateObj);
  };

  const refreshHandler = () => {
    const date = setDateRange();
    setDateObj(date);
    fetchUserData(date);
  };

  const filterTopicCompletedThreshold = () => {
    if (!topicThreshold) {
      toast.error("Field missing");
      return;
    }

    userStickinessGraph.datasets.pop();
    createMonthlyActiveUserGraph(dateObj, topicJourneyList, topicThreshold);
    createUserStickinessRatioGraph();
  };

  const calculateTotalUsers = (calculateType) => {
    if (calculateType === "dailyActive") {
      console.log("appSessionJourneyList");
      console.log(appSessionJourneyList);

      // if (!appSessionJourneyList?.length) return 0;

      const combineData = appSessionJourneyList.filter(
        (e) => e?.journeys?.length
      );

      return combineData?.length || 0;
    }
    if (calculateType === "weeklyActive") {
    }
    if (calculateType === "monthlyActive") {
    }
  };

  useEffect(() => {
    fetchInitialData(dateObj);
  }, []);
  return (
    <div>
      <div className="user_platforms">
        <DateFilters
          submitHandler={submitHandler}
          refreshHandler={refreshHandler}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            <DownloadPDF downloadFileName="User-Stickiness-Report" />
            <div className="graph">
              <div className="downloadPDF">
                <div className="box">
                  <div className="box_card">
                    <p>Total Days:</p>
                    <p>{userStickinessGraph?.labels?.length || 0}</p>
                  </div>
                  {/* Set completed topic threshold for monthly active users */}
                  <div className="box_card">
                    <div className="inputField">
                      <div className="threshold">
                        <h1s>Topic threshold</h1s>
                        <input
                          type="number"
                          className="from-control"
                          value={topicThreshold}
                          onChange={(e) => setTopicThreshold(e.target.value)}
                          placeholder="Enter topic threshold"
                        />
                      </div>
                      <button
                        className="btn btn-secondary"
                        onClick={filterTopicCompletedThreshold}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="box_card">
                    <p>Total Daily Active user of all time:</p>
                    <p>{calculateTotalUsers("dailyActive")}</p>
                  </div>
                  <div className="box_card">
                    <p>Total User in specified time:</p>
                    <p>
                      {Object.keys(userStickinessGraph)?.length &&
                        userStickinessGraph?.datasets[0]?.data.reduce(
                          (a, b) => (a += b),
                          0
                        )}
                    </p>
                  </div>
                </div>
                <div className="box">
                  <div className="box_card">
                    <p>Total Daily active users in specified time:</p>
                    <p>
                      {Object.keys(userStickinessGraph)?.length &&
                        userStickinessGraph?.datasets[1]?.data.reduce(
                          (a, b) => (a += b),
                          0
                        )}
                    </p>
                  </div>
                  <div className="box_card">
                    <p>Total Weekly Active User in specified time:</p>
                    <p>
                      {Object.keys(userStickinessGraph)?.length &&
                        userStickinessGraph?.datasets[2]?.data.reduce(
                          (a, b) => (a += b),
                          0
                        )}
                    </p>
                  </div>
                </div>
                <div className="box">
                  <div className="box_card">
                    <p>Total Monthly active users in specified time:</p>
                    <p>
                      {Object.keys(userStickinessGraph)?.length &&
                        userStickinessGraph?.datasets[3]?.data.reduce(
                          (a, b) => (a += b),
                          0
                        )}
                    </p>
                  </div>
                </div>

                <h3 className="heading">User Stickiness</h3>
                <Line
                  options={userStickinessGraphOptions}
                  data={userStickinessGraph}
                />
              </div>

              <hr />
              <div className="gap"></div>
              <div className="downloadPDF">
                <h3 className="heading">User Stickiness Bar Graph</h3>
                <Bar
                  options={userStickinessGraphOptions}
                  data={userStickinessGraph}
                />
              </div>
              <hr />
              <div className="gap"></div>
              <div className="downloadPDF">
                <h3 className="heading">User Stickiness Ratio Graph</h3>
                <div className="box">
                  <div className="box_card">
                    <p>% DAU/MAU:</p>
                    <p>
                      {(userStickinessRatioGraph?.datasets?.length &&
                        userStickinessRatioGraph?.datasets[0]?.data.reduce(
                          (a, b) => (a += b),
                          0
                        ) / userStickinessRatioGraph?.labels?.length) * 100}
                    </p>
                  </div>
                  <div className="box_card">
                    <p>% DAU/WAU</p>
                    <p>
                      {(userStickinessRatioGraph?.datasets?.length &&
                        userStickinessRatioGraph?.datasets[1]?.data.reduce(
                          (a, b) => (a += b),
                          0
                        ) / userStickinessRatioGraph?.labels?.length) * 100}
                    </p>
                  </div>
                  <div className="box_card">
                    <p>% WAU/MAU</p>
                    <p>
                      {(userStickinessRatioGraph?.datasets?.length &&
                        userStickinessRatioGraph?.datasets[2]?.data.reduce(
                          (a, b) => (a += b),
                          0
                        ) / userStickinessRatioGraph?.labels?.length) * 100}
                    </p>
                  </div>
                </div>
                <Line
                  options={userStickinessRatioGraphOptions}
                  data={userStickinessRatioGraph}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

UserStickiness.propTypes = {};

export default UserStickiness;
