import React from "react";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import axios from "../api";
import { useHistory } from "react-router-dom";
import loader from "../assets/img/loader.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { ChangePasswordBackend } from "Utils";
function ChangePassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  let token = sessionStorage.getItem("token");
  let decoded = jwt_decode(token);
  // console.log(decoded);

  const [values, setValues] = useState({ current: "", confirm: "", new: "" });
  const loading = useSelector(selectLoading);
  const handleChange = (props) => (event) => {
    setValues({
      ...values,
      [props]: event.target.value,
    });
  };

  useEffect(() => {
    dispatch(setloading(true));
    setTimeout(() => {
      dispatch(setloading(false));
    }, 1000);
  }, []);
  return (
    <>
      {!loading ? (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <i
                      className="fa fa-arrow-circle-left mx-2"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4> Change Password </h4>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      // console.log(values);
                      if (values.new === values.confirm) {
                        ChangePasswordBackend(
                          values,
                          decoded,
                          setValues,
                          dispatch,
                          history
                        );
                      } else {
                        toast.error(
                          `Password and Confirm Password didn't match`
                        );
                        setValues({ current: "", confirm: "", new: "" });
                      }
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    >
                      <p>Current Password</p>
                      <input
                        type="password"
                        placeholder="Current Password"
                        required
                        minLength="8"
                        value={values.current}
                        // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        // title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required
                        onChange={handleChange("current")}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        marginLeft: "10%",
                        marginRight: "10%",
                        marginTop: "3%",
                      }}
                    >
                      <p>New Password</p>
                      <input
                        type="password"
                        placeholder="New Password"
                        minLength="8"
                        required
                        value={values.new}
                        // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        // title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required
                        onChange={handleChange("new")}
                      />
                    </div>

                    <div
                      className="mb-2"
                      style={{
                        marginLeft: "10%",
                        marginRight: "10%",
                        marginTop: "3%",
                      }}
                    >
                      <p>Confirm Password</p>
                      <input
                        type="password"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        minLength="8"
                        required
                        value={values.confirm}
                        // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        // title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required
                        onChange={handleChange("confirm")}
                      />
                    </div>

                    <div
                      className="w-100 d-flex justify-content-between mt-4"
                      style={{ padding: "2%", paddingBottom: "3%" }}
                    >
                      <button className="btn btn-warning" type="submit">
                        Change
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default ChangePassword;
