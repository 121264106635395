import React, { useContext } from "react";
import PropTypes from "prop-types";
import MaterialTableIcons from "./MaterialTableIcons";
import MaterialTable from "material-table";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

// globally access
const { tableIcons, defaultColumn, defaultRows } = MaterialTableIcons();

const MaterialTableContainer = ({
  globalSearch,
  exportButton,
  defaultPageSize,
  pageSizeRanges,
  columns,
  rows,
}) => {
  const { color } = useContext(BackgroundColorContext);

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  console.log(rows);

  return (
    <div className="materialTable_wrapper">
      <MaterialTable
        icons={tableIcons}
        title={null}
        columns={columns}
        data={rows}
        // actions={}
        options={{
          actionsColumnIndex: -1,
          exportButton: exportButton,
          pageSize: defaultPageSize,
          pageSizeOptions: pageSizeRanges.map((e) => e),
          headerStyle: {
            backgroundColor: colorsList.find((e) => e.color === color).value,
            color: "#FFF",
          },
          filtering: globalSearch,
        }}
      />
    </div>
  );
};

MaterialTableContainer.propTypes = {
  globalSearch: PropTypes.bool,
  exportButton: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  pageSizeRanges: PropTypes.array,
  columns: PropTypes.array,
  rows: PropTypes.array,
};

MaterialTableContainer.defaultProps = {
  globalSearch: false,
  exportButton: false,
  defaultPageSize: 10,
  pageSizeRanges: [5, 10, 30, 50],
  columns: defaultColumn,
  rows: defaultRows,
};

export default MaterialTableContainer;
