import React from "react";
import Modal from "react-awesome-modal";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import { FaRegEdit, FaBan, FaCheckCircle } from "react-icons/fa";
import axios from "../api";
import { setlesson } from "Redux/features/Subject/SubjectSlice";
import { selectlessons } from "Redux/features/Subject/SubjectSlice";
import { selectsubjects } from "Redux/features/Subject/SubjectSlice";
import loader from "../assets/img/loader.svg";
import loaderimg from "../assets/img/loaderimg.svg";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import {
  updateStatusBackend,
  addChapterBackend,
  updateChapterBackend,
} from "Utils/lessons_Utils";
import { selectRegisteredCourseList } from "Redux/features/Admin/AdminSlice";
import { getChapterWithCountQuizzes } from "../Utils/chapterPreview_Utils";
import { getAllSubjects } from "Utils/subjects_Utils";
import { setsubject } from "Redux/features/Subject/SubjectSlice";
import BreadCrumbs from "components/Common/BreadCrumbs";

function Lessons(props) {
  const param = useParams();
  window.localStorage.setItem("subjectID", param?.subjectID || null);
  const history = useHistory();
  const dispatch = useDispatch();
  const lessonsList = useSelector(selectlessons);
  const subjectList = useSelector(selectsubjects);
  const location = useLocation();
  let subjectId = location.pathname.split("/")[3];
  const [chapterName, setchapterName] = useState("");
  const [chapterIcon, setchapterIcon] = useState([]);
  const [addchapIcon, setaddchapIcon] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [modalLesson, setModalLesson] = useState({});
  const [activity, setactivity] = useState("");
  const loading = useSelector(selectLoading);
  const [loadingImage, setloadingImage] = useState(false);
  const [chapterNumber, setChapterNumber] = useState(0);
  const [chaptersQuizzes, setChapterQuizzes] = useState([]);
  const [subject, setSubject] = useState(
    subjectList && subjectList.length > 0
      ? subjectList.filter((sub) => {
          return sub._id === subjectId;
        })
      : null
  );

  useEffect(() => {
    function fetchChapter() {
      axios
        .get(`/chapter?subject=${subjectId}`)
        .then((res) => {
          dispatch(setlesson(res.data.data));
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });

      (async () => {
        try {
          const resp = await getChapterWithCountQuizzes(subjectId);
          let quizzesMarks = [];
          resp?.data?.data.forEach((e) => {
            if (!Boolean(e.chapter_sequence.length)) {
              quizzesMarks.push(0);
            } else {
              const filterTests = e.chapter_sequence.filter((x) =>
                Object.keys(x).includes("test")
              );
              let sum = 0;
              filterTests?.map((a) => (sum += a.test.total_marks));
              quizzesMarks.push(sum);
            }
          });

          setChapterQuizzes(quizzesMarks);
        } catch (error) {
          console.log(error);
        }
      })();
    }
    dispatch(setloading(true));
    fetchChapter();

    if (subjectList !== null) return;

    (async () => {
      try {
        const subjectDetails = await getAllSubjects();
        dispatch(setsubject(subjectDetails.data.data));
      } catch (err) {
        console.log(err?.response?.data?.message || err);
      }
    })();
  }, []);

  const handleAddLesson = () => {
    let lessonToAdd = {
      chapter_name: chapterName,
      chapter_icon: addchapIcon,
      chapter_number: lessonsList.length + 1,
      subject: subjectId,
      grade: subject[0].grade._id,
      isActive: true,
    };

    addChapterBackend(
      lessonToAdd,
      setAddModal,
      setchapterName,
      setaddchapIcon,
      setchapterIcon,
      dispatch
    );
    setAddModal(false);
    //
  };
  const handleEditLesson = () => {
    if (chapterNumber && chapterNumber <= 0) {
      toast.error("Must be positive value");
      setChapterNumber("");
      return;
    }

    const editedLesson = {
      _id: modalLesson._id,
      chapter_name: chapterName,
      chapter_number: chapterNumber ? chapterNumber : 0,
      chapter_icon: addchapIcon,
      subject: subjectId,
      grade: subject[0].grade._id,
      isActive: modalLesson.isActive,
    };

    updateChapterBackend(modalLesson._id, editedLesson, setEditModal, dispatch);
    setEditModal(false);
  };

  async function uploadImage() {
    setloadingImage(true);
    const formData = new FormData();

    formData.append("files", chapterIcon[0]);

    axios
      .post(`/filesupload/file-upload`, formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        setaddchapIcon(res.data.locationArray[0].fileLocation);
        setchapterIcon([]);
        setTimeout(() => {
          setloadingImage(false);
        }, 1000);
      })
      .catch((err) => {
        setloadingImage(false);
        toast.error(err.response.data.message);
      });
  }
  const changeHandler = (event) => {
    chapterIcon.push(event.target.files[0]);
    uploadImage();
  };

  console.log(subject);
  return (
    <>
      {!loading ? (
        <div className="content">
          <BreadCrumbs
            breadCrumbList={[
              { title: subject && subject[0]?.Subject_name || "-", pathName: "/admin/subjects" },
              {
                title: "Chapters",
                pathName: `/admin/subject/${param?.subjectID}`,
              },
            ]}
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <CardTitle
                    tag="h4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <i
                      className="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.push("/admin/subjects");
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Chapters</h4>
                  </CardTitle>
                  <div style={{ display: "flex" }}>
                    <p className="category">
                      {" "}
                      Total {lessonsList.length} Chapters in this Subject
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "auto",
                        alignItems: "flex-end",
                      }}
                    >
                      {/* <Link
                        to={`/admin/subject/chapter/chaptersequence/${subjectId}`}
                      >
                        <button
                          className="btn btn-priamry"
                          style={{
                            border: "0px",
                            fontSize: "14px",
                            width: "220px",
                            marginLeft: "2%",
                          }}
                        >
                          {" "}
                          Change Sequence
                        </button>
                      </Link> */}
                      <button
                        className="btn btn-priamry"
                        onClick={() => {
                          setAddModal(true);
                        }}
                        style={{
                          border: "0px",
                          fontSize: "14px",
                          width: "220px",
                          marginLeft: "2%",
                        }}
                      >
                        {" "}
                        Add Chapter
                      </button>
                      <div className="dropdown">
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          role="button"
                          id="dropdownMenuLinkGrade"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ marginLeft: "2%", width: "224px" }}
                        >
                          {activity === ""
                            ? "Select Active/Inactive"
                            : activity}
                        </a>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLinkGrade"
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            onClick={() => {
                              setactivity("Active");
                              // setcoursePlan("premium");
                            }}
                          >
                            Active
                          </a>
                          <a
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            onClick={() => {
                              setactivity("InActive");
                              // setcoursePlan("freemium");
                            }}
                          >
                            InActive
                          </a>
                          <a
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            onClick={() => {
                              setactivity("");
                              // setcoursePlan("freemium");
                            }}
                          >
                            None
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <table
                        id="dtBasicExample"
                        className="table table-striped table-bordered table-sm"
                        cellspacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th className="th-sm">Chapter Number</th>
                            <th className="th-sm">Name</th>
                            <th className="th-sm">Status</th>
                            <th className="th-sm">Total Topics</th>
                            <th className="th-sm">Total Quizzes</th>
                            <th className="th-sm">Total Points</th>
                            <th className="th-sm">Watch Quiz</th>
                            <th className="th-sm">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lessonsList.map((lesson, index) => {
                            if (activity === "") {
                              return (
                                <tr key={index}>
                                  <td>{lesson?.chapter_number || "N/A"}</td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Link
                                        to={`/admin/subject/chapter/chapterdetails/${lesson._id}`}
                                      >
                                        {lesson.chapter_name}
                                      </Link>
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        {lesson.chapter_icon ? (
                                          <img
                                            src={lesson.chapter_icon}
                                            alt="Logo"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {lesson.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>
                                  <td>
                                    {
                                      lesson?.chapter_sequence.filter((x) =>
                                        Object.keys(x).includes("topic")
                                      ).length
                                    }
                                  </td>
                                  <td>
                                    {
                                      // lesson?.chapter_sequence.length
                                      lesson?.chapter_sequence.filter((x) =>
                                        Object.keys(x).includes("test")
                                      ).length
                                    }
                                  </td>
                                  <td>{chaptersQuizzes[index]}</td>
                                  <td>
                                    <Link
                                      to={`/admin/subject/chapter/quiz/${lesson._id}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Quiz
                                    </Link>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <FaRegEdit
                                        style={{
                                          fontSize: "18px",
                                          color: "#008B8B",
                                          marginRight: "10px",
                                        }}
                                        onClick={() => {
                                          setModalLesson(lesson);
                                          setchapterName(lesson.chapter_name);
                                          setaddchapIcon(lesson.chapter_icon);
                                          setChapterNumber(
                                            lesson?.chapter_number || ""
                                          );
                                          setEditModal(true);
                                        }}
                                      />
                                      {!lesson.isActive ? (
                                        <FaBan
                                          style={{
                                            fontSize: "18px",
                                            color: "red",
                                          }}
                                          onClick={() => {
                                            setModalLesson(lesson);
                                            setStatusModal(true);
                                          }}
                                        />
                                      ) : (
                                        <FaCheckCircle
                                          onClick={() => {
                                            setModalLesson(lesson);
                                            setStatusModal(true);
                                          }}
                                          style={{
                                            fontSize: "18px",
                                            color: "green",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            } else if (
                              activity == "Active" &&
                              lesson.isActive
                            ) {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Link
                                        to={`/admin/subject/chapter/chapterdetails/${lesson._id}`}
                                      >
                                        {lesson.chapter_name}
                                      </Link>
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        {lesson.chapter_icon ? (
                                          <img
                                            src={lesson.chapter_icon}
                                            alt="Logo"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {lesson.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <FaRegEdit
                                        style={{
                                          fontSize: "18px",
                                          color: "#008B8B",
                                          marginRight: "10px",
                                        }}
                                        onClick={() => {
                                          setModalLesson(lesson);
                                          setchapterName(lesson.chapter_name);
                                          setaddchapIcon(lesson.chapter_icon);
                                          setEditModal(true);
                                        }}
                                      />
                                      {!lesson.isActive ? (
                                        <FaBan
                                          style={{
                                            fontSize: "18px",
                                            color: "red",
                                          }}
                                          onClick={() => {
                                            setModalLesson(lesson);
                                            setStatusModal(true);
                                          }}
                                        />
                                      ) : (
                                        <FaCheckCircle
                                          onClick={() => {
                                            setModalLesson(lesson);
                                            setStatusModal(true);
                                          }}
                                          style={{
                                            fontSize: "18px",
                                            color: "green",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/admin/subject/chapter/quiz/${lesson._id}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Quiz
                                    </Link>
                                  </td>
                                </tr>
                              );
                            } else if (
                              activity == "InActive" &&
                              !lesson.isActive
                            ) {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Link
                                        to={`/admin/subject/chapter/chapterdetails/${lesson._id}`}
                                      >
                                        {lesson.chapter_name}
                                      </Link>
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        {lesson.chapter_icon ? (
                                          <img
                                            src={lesson.chapter_icon}
                                            alt="Logo"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {lesson.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <FaRegEdit
                                        style={{
                                          fontSize: "18px",
                                          color: "#008B8B",
                                          marginRight: "10px",
                                        }}
                                        onClick={() => {
                                          setModalLesson(lesson);
                                          setchapterName(lesson.chapter_name);
                                          setaddchapIcon(lesson.chapter_icon);
                                          setEditModal(true);
                                        }}
                                      />
                                      {!lesson.isActive ? (
                                        <FaBan
                                          style={{
                                            fontSize: "18px",
                                            color: "red",
                                          }}
                                          onClick={() => {
                                            setModalLesson(lesson);
                                            setStatusModal(true);
                                          }}
                                        />
                                      ) : (
                                        <FaCheckCircle
                                          onClick={() => {
                                            setModalLesson(lesson);
                                            setStatusModal(true);
                                          }}
                                          style={{
                                            fontSize: "18px",
                                            color: "green",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/admin/subject/chapter/quiz/${lesson._id}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Quiz
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            visible={addModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setchapterName("");
              setaddchapIcon("");
              setchapterIcon([]);
              setAddModal(false);
            }}
          >
            <div className="modal-container">
              <h4
                className="mb-4"
                style={{ color: "#242b3b", marginLeft: "26%" }}
              >
                Create New Chapter
              </h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddLesson();
                }}
              >
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Name</p>
                  <input
                    type="text"
                    required
                    name="ChapterName"
                    value={chapterName}
                    onChange={(e) => {
                      setchapterName(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Chapter Icon</p>
                  <div style={{ display: "flex" }}>
                    <input
                      type="file"
                      required
                      name="chapterIconInput"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler}
                    />
                    {loadingImage ? (
                      <img src={loaderimg} alt="loading..." />
                    ) : null}
                  </div>
                </div>

                <div
                  className="w-100 d-flex justify-content-end mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => {
                      setAddModal(false);
                    }}
                  >
                    Cancel
                  </button> */}
                  <button className="btn btn-warning" type="submit">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            visible={editModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setEditModal(false);
            }}
          >
            <div className="modal-container">
              <h4
                className="mb-4"
                style={{ color: "#242b3b", marginLeft: "35%" }}
              >
                Edit Chapter
              </h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEditLesson();
                  setchapterName("");
                  setaddchapIcon("");
                  setchapterIcon([]);
                }}
              >
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Name</p>
                  <input
                    type="text"
                    required
                    name="ChapterName"
                    value={chapterName}
                    onChange={(e) => {
                      setchapterName(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Chapter Icon</p>
                  <div style={{ display: "flex" }}>
                    <input
                      type="file"
                      name="chapterIconInput"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler}
                    />
                    {loadingImage ? (
                      <img src={loaderimg} alt="loading..." />
                    ) : null}
                  </div>
                </div>

                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Chapter Number</p>
                  <input
                    type="number"
                    name="ChapterNumber"
                    value={chapterNumber}
                    onChange={(e) => {
                      setChapterNumber(e.target.value);
                    }}
                  />
                </div>

                <div
                  className="w-100 d-flex justify-content-end mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => {
                      setEditModal(false);
                    }}
                  >
                    Cancel
                  </button> */}
                  <button className="btn btn-warning" type="submit">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            visible={statusModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setStatusModal(false);
            }}
          >
            <div
              className="modal-container"
              style={{ padding: "3%", textAlign: "center" }}
            >
              <h4 className="mb-4" style={{ color: "#242b3b" }}>
                Change Status
              </h4>

              {modalLesson.isActive ? (
                <p style={{ fontSize: "20px" }}>
                  Are you sure you want to restrict Subject's{" "}
                  {modalLesson.chapter_name}?
                </p>
              ) : (
                <p style={{ fontSize: "20px" }}>
                  Are you sure you want to change this Chapter status to active?
                </p>
              )}
              <div
                className="w-100 d-flex justify-content-between mt-4"
                style={{ padding: "2%", paddingBottom: "3%" }}
              >
                <button
                  className="btn btn-danger"
                  onClick={() => setStatusModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-warning"
                  onClick={() => {
                    updateStatusBackend(
                      modalLesson._id,
                      modalLesson.isActive,
                      setStatusModal,
                      dispatch
                    );

                    setStatusModal(false);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default Lessons;
