import { toast } from "react-toastify";
import { setloading } from "Redux/features/Auth/AuthSlice";
import {
  editteacher,
  editteacherStatus,
  addteacher,
} from "Redux/features/Teacher/TeacherSlice";

import axios from "../api";
import backend from "../api";
export function updateStatusBackend(id, selectedTeacher, dispatch) {
  let data = { isActive: !selectedTeacher };
  dispatch(setloading(true));
  axios
    .put(`/user/status/${id}`, data)
    .then((res) => {
      dispatch(editteacherStatus({ _id: id, isActive: data.isActive }));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Teacher's Status Updated Successfully!`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

export function addTeacherBackend(data, setAddModal, dispatch) {
  setAddModal(false);
  dispatch(setloading(true));
  axios
    .post(`/user/create/teacher`, data)
    .then((res) => {
      dispatch(addteacher(res.data.data));

      setTimeout(() => {
        dispatch(setloading(false));
        toast.success("Teacher Added Successfully!");
      }, 1000);
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch(setloading(false));
        toast.error(
          err.response.data.message.includes("pattern")
            ? `${
                err.response.data.message.split(" ")[0]
              } must contain alphabets`
            : err.response.data.message
        );
      }, 1000);
    });
}

export function updateTeacherBackend(id, data, setEditModal, dispatch) {
  setEditModal(false);
  dispatch(setloading(true));
  axios
    .put(`/user/${id}`, data)
    .then((res) => {
      dispatch(editteacher(res.data.data));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Teacher's Information Updated Successfully`);
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch(setloading(false));
        toast.error(
          err.response.data.message.includes("pattern")
            ? `${
                err.response.data.message.split(" ")[0]
              } must contain alphabets`
            : err.response.data.message
        );
      }, 1000);
    });
}

export const getAllTeachersData = async () =>
  await backend({
    method: "GET",
    url: "/user?role=teacher",
  });

export const getTeachersData = async (teacherID) =>
  await backend({
    method: "GET",
    url: `/user?_id=${teacherID}`,
  });

export const saveTeacher = async (teacherObj) =>
  await backend({
    method: "POST",
    url: "/user/create/teacher",
    data: teacherObj,
  });

export const updateTeacher = async (teacherID, teacherObj) =>
  await backend({
    method: "PUT",
    url: `/user/${teacherID}`,
    data: teacherObj,
  });

export const changeTeacherStatus = async (teacherID, teacherObj) =>
  await backend({
    method: "PUT",
    url: `/user/status/${teacherID}`,
    data: teacherObj,
  });

export const getAssignSubjects = async (teacherID) =>
  await backend({
    method: "GET",
    url: `/teacher/subjects/assign?teacher_id=${teacherID}`,
  });

export const saveAssignSubjects = async (assignSubjectsObj) =>
  await backend({
    method: "POST",
    url: `/teacher/subjects/assign`,
    data: assignSubjectsObj,
  });

export const updateAssignSubjects = async (assignObjID, assignSubjectsObj) =>
  await backend({
    method: "PUT",
    url: `/teacher/subjects/assign/${assignObjID}`,
    data: assignSubjectsObj,
  });

var pop_cus = [
  {
    path: "gradesList",
  },
  {
    path: "subjectsList",
  },
  {
    path: "teacher_id.users",
  },
];
const pop_course = encodeURI(JSON.stringify(pop_cus));

export const getTeachersGradesSubjects = async (teacherID) =>
  await backend({
    method: "GET",
    url: `/teacher/subjects/assign?teacher_id=${teacherID}&populate=${pop_course}`,
  });
