import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getStudentsPlatform } from "Utils/reports.Utils";
import { Bar, Line } from "react-chartjs-2";
import DateFilters from "components/Common/DateFilters";
import { format } from "date-fns";
import { getReportsByScreenNames } from "Utils/reports.Utils";
import { getGradeUnicode } from "Utils/grades_Utils";
import { secondsToHms } from "Utils/helper_Utils";
import { getStudentsJourney } from "Utils/reports.Utils";
import { date } from "Templates/Layout/vendors/jszip/lib/defaults";
import DownloadPDF from "components/Common/DownloadPDF";
import { createDateRange } from "Utils/helper_Utils";
import { differenceBtw2Dates } from "Utils/helper_Utils";

const data = {};
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      width: "20px",
    },
    title: {
      display: true,
      text: "Grades Data",
    },
  },

  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        if (tooltipItem?.datasetIndex === 1)
          return "Daily active users : " + tooltipItem?.yLabel;
        if (tooltipItem?.datasetIndex === 2)
          return "Max time : " + secondsToHms(tooltipItem?.yLabel);
        if (tooltipItem?.datasetIndex === 3)
          return "Min time : " + secondsToHms(tooltipItem?.yLabel);
        return (
          "Average app session time : " + secondsToHms(tooltipItem?.yLabel)
        );
      },
    },
  },

  scales: {
    // yAxes: [
    //   {
    //     scaleLabel: {
    //       display: true,
    //       labelString: "Number of students",
    //     },
    //   },
    // ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const GradeReport = () => {
  const userIDSearch = useRef(null);

  const [appSessionDetails, setAppSessionDetails] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState(false);
  const [isSpecificStudentData, setIsSpecificStudentData] = useState(false);
  const [dateObj, setDateObj] = useState(() => {
    var d = new Date();
    return {
      startDate: format(
        new Date(new Date(d.setDate(d.getDate() - 6)).toISOString()),
        "yyyy-MM-dd"
      ),
      endDate: format(new Date(), "yyyy-MM-dd"),
    };
  });
  const [isMount, setIsMount] = useState(true);

  const afterFetchData = (dateFilter, combine, grades) => {
    setLoading(true);

    const { startDate, endDate } = dateFilter;

    const a = new Date(startDate).getTime();
    const b = new Date(endDate).getTime();

    const datesRanges = createDateRange(
      differenceBtw2Dates(dateFilter),
      endDate
    );

    const list = Object.keys(dateFilter)?.length
      ? combine?.length &&
        combine.filter(
          (e) => datesRanges.includes(e?.date)
          // new Date(e?.date).getTime() >= a && new Date(e?.date).getTime() <= b
        )
      : combine;

    const datesList = [...new Set(list.map((x) => x?.date))].sort();
    data.labels = datesList;

    const sessionPerDate = datesList.map((x) => {
      const filterDates = list.filter((a) => a?.date === x);

      const sum =
        filterDates.reduce((a, b) => (a += b?.durationInMin), 0) /
        filterDates?.length;

      const studentWithMaxTime = Math.max(
        ...filterDates.map((e) => e?.durationInMin)
      );
      const studentData = filterDates.find(
        (x) => x?.durationInMin === studentWithMaxTime
      );

      // debugger;
      const maxTime =
        Math.max(...filterDates.map((e) => e?.durationInMin)) * 60;
      // debugger;
      const minTime =
        Math.min(
          ...filterDates
            .filter((e) => e?.durationInMin > 3)
            .map((e) => e?.durationInMin)
        ) * 60;

      return {
        date: x,
        timeInSec: sum * 60,
        time: sum,
        maxTime,
        minTime,
        students: filterDates?.length,
      };
    });

    data.datasets = [];
    data.datasets.push({
      label: "Average app session time",
      data: sessionPerDate.map((x) => x?.timeInSec),
      data1: sessionPerDate.map((x) => x?.time),
      backgroundColor: "rgb(235, 52, 52)",
      borderColor: "rgb(235, 52, 52)",
      lineTension: 0,
      fill: false,
    });

    data.datasets.push({
      label: "Daily active users",
      data: sessionPerDate.map((x) => x?.students),
      backgroundColor: "rgb(21, 7, 122)",
      borderColor: "rgb(21, 7, 122)",
      lineTension: 0,
      fill: false,
    });

    data.datasets.push({
      label: "Max time Spend",
      data: sessionPerDate.map((x) => {
        // debugger;
        return x?.maxTime;
      }),
      backgroundColor: "rgb(5, 130, 5)",
      borderColor: "rgb(5, 130, 5)",
      lineTension: 0,
      fill: false,
    });

    data.datasets.push({
      label: "Min Time Spend to 3 min",
      data: sessionPerDate.map((x) => x?.minTime),
      backgroundColor: "rgb(194, 8, 172)",
      borderColor: "rgb(194, 8, 172)",
      lineTension: 0,
      fill: false,
    });

    setLoading(false);
  };

  const createSpecificUserGraph = (student, studentJourney) => {
    if (!studentJourney?.length) {
      data = {};
      return;
    }

    const appSessions = studentJourney.filter(
      (e) => e?.screenName === "appSession"
    );

    if (!appSessions?.length) {
      data = {};
      return;
    }

    // debugger;
    const datesList = appSessions.map((x) => x?.date);
    // const time = appSessions.map((e) => e?.durationInMin * 60);
    // debugger;
    
    setIsSpecificStudentData(true);
    data.labels = datesList;
    const lineGraphData = [];
    
    debugger;
    lineGraphData.push({
      label: student?.first_name,
      data: appSessions.map((e) => e?.durationInMin * 60),
      backgroundColor: "rgb(117, 217, 98)",
      borderColor: "rgb(217, 117, 98)",
      lineTension: 0,
      fill: false,
    });

    data.datasets = lineGraphData;
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const grades = await getGradeUnicode();
      const resp = await getReportsByScreenNames("appSession");

      const makeOneArrayOfJourney = resp?.data?.data?.reduce((a, b) => {
        const journeys = b?.journeys.map((e) => {
          e.user = b?.user;
          return e;
        });
        return [...a, ...journeys];
      }, []);

      afterFetchData(dateObj, makeOneArrayOfJourney, grades.data.data);
      setAppSessionDetails(makeOneArrayOfJourney);
      setGrades(grades.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (dateObj) => {
    if (!dateObj?.startDate || !dateObj?.endDate) {
      toast.info("Dates Missing");
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    setIsSpecificStudentData(false);
    setDateObj(dateObj);
    afterFetchData(dateObj, appSessionDetails, grades);
  };

  const refreshHandler = () => {
    setIsSpecificStudentData(false);
    const d = new Date();
    const startDate = new Date(new Date(d.setDate(d.getDate() - 6)))
      .toISOString()
      .split("T")[0];
    const endDate = format(new Date(), "yyyy-MM-dd");

    setDateObj({ startDate, endDate });
    afterFetchData({ startDate, endDate }, appSessionDetails, grades);
  };

  const calculateAverageTime = () => {
    return data?.labels?.length && data?.datasets?.length
      ? secondsToHms(
          isSpecificStudentData
            ? data?.datasets[0]?.data?.reduce((a, b) => (a += b), 0) /
                data?.datasets[0]?.data?.length
            : data?.datasets[0]?.data?.reduce((a, b) => (a += b), 0) /
                data?.datasets[0]?.data?.length
        ) || 0
      : 0;
  };

  const searchHandler = async (e) => {
    e.preventDefault();

    const searchStudent =
      appSessionDetails?.length &&
      appSessionDetails.filter(
        (e) => String(e?.user) === userIDSearch?.current?.value
      );

    if (!searchStudent) {
      toast.error("User found");
      return;
    }

    try {
      const resp = await getStudentsJourney(userIDSearch?.current?.value);
      const { userID, journeys } = resp.data.data;
      createSpecificUserGraph(userID, journeys);
      setStudentData(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMount) fetchInitialData();

    return () => setIsMount(false);
  }, []);

  // ///////////////////////////////////////////////////////////// JSX PART BELOW   // /////////////////////////////////////////////////////////////
  const renderSearchByStudentFilter = () => (
    <div>
      <form className="search-by-id" onSubmit={searchHandler}>
        <input
          type="text"
          className="form-control"
          ref={userIDSearch}
          required
          placeholder="Enter User ID to search"
        />
        <button className="btn btn-secondary" type="submit">
          Search
        </button>
      </form>
    </div>
  );

  return (
    <div className="user_platforms">
      <DateFilters
        submitHandler={submitHandler}
        refreshHandler={refreshHandler}
      />
      {renderSearchByStudentFilter()}
      {loading ? (
        <Loader />
      ) : (
        <>
          <DownloadPDF downloadFileName="AppSession-Report" />
          <div className="graph">
            <div className="downloadPDF">
              <div className="box">
                <div className="box_card">
                  <p>Average app session time specified date:</p>
                  <p>{calculateAverageTime()}</p>
                </div>
                <div className="box_card">
                  <p>Total Days:</p>
                  <p>{data?.labels?.length || 0}</p>
                </div>
              </div>
              <div className="box">
                <div className="box_card">
                  <p>Average daily active users: </p>
                  <p>
                    {Object.keys(data)?.length &&
                      (
                        data?.datasets[1]?.data.reduce((a, b) => (a += b), 0) /
                        data?.datasets[1]?.data?.length
                      ).toFixed(0)}
                  </p>
                </div>
              </div>
              <h3 className="heading">App session time Line Graph</h3>
              <Line options={options} data={data} />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">App session time Bar Graph</h3>
              <Bar options={options} data={data} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GradeReport;
// 6232f1ed29f0981bbec71b8d
