import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import {
  saveTeacherPayment,
  updateTeacherPayment,
} from "Utils/teacherPayment_Utils";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import { useSelector } from "react-redux";

const TeacherPaymentForm = ({
  paymentObj,
  handleSave,
  teacherList,
  isEdit,
}) => {
  const userData = useSelector(selectUserData);
  const [teacherDropdown, setTeacherDropdown] = useState([]);
  const [description, setDescription] = useState("");

  const [teacher, setTeacher] = useState(
    isEdit
      ? { value: paymentObj?.teacherID, label: paymentObj?.teacherName }
      : ""
  );
  const [isRequired, setIsRequired] = useState(false);
  const [amountPaid, setAmountPaid] = useState(paymentObj?.amountPaid ?? "");
  const [recordDate, setRecordDate] = useState(
    paymentObj?.recordDate
      ? format(new Date(paymentObj.recordDate), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd")
  );
  const [status, setStatus] = useState(paymentObj?.paymentStatus ?? "pending");

  const onChangeStateHandler = (setState) => (e) => setState(e.target.value);
  const isRole = (role) => (userData?.role === role ? true : false);

  const savePaymentAdminToTeacher = async () => {
    try {
      const paymentData = {
        teacherID: teacher?.value,
        teacherName: teacher?.label,
        amountPaid,
        amountWithdraw: 0,
        recordDate: new Date(),
        sendBy: "admin",
        paymentStatus: status,
        description,
      };

      let resp = null;
      if (isEdit) {
        paymentData._id = paymentObj?._id;
        resp = await updateTeacherPayment(paymentData);
      } else {
        resp = await saveTeacherPayment(paymentData);
      }

      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      handleSave();
    }
  };

  const savePaymentRequestTeacherToAdmin = async () => {
    try {
      const paymentData = {
        teacherID: userData?._id,
        teacherName: `${userData?.first_name} ${userData?.last_name}`,
        amountPaid,
        amountWithdraw: 0,
        recordDate,
        sendBy: "teacher",
        paymentStatus: "pending",
        isView: true,
        description,
      };

      let resp = null;
      if (isEdit) {
        paymentData._id = paymentObj?._id;
        resp = await updateTeacherPayment(paymentData);
      } else {
        resp = await saveTeacherPayment(paymentData);
      }

      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      handleSave();
    }
  };

  const submitPaymentToTeacher = (e) => {
    e.preventDefault();

    if (!teacher?.value) {
      setIsRequired(true);
      return;
    }
    setIsRequired(false);

    savePaymentAdminToTeacher();
  };

  const sendPaymentRequestToAdmin = (e) => {
    e.preventDefault();
    savePaymentRequestTeacherToAdmin();
  };

  // const filterOption = (option, inputValue) => {
  //   const { label, value } = option;
  //   console.log(inputValue);
  //   // console.log(value);
  //   const otherKey = teacherDropdown.find(
  //     opt => String(opt?.email) === inputValue
  //   );
  //   if (otherKey)
  //     setTeacher({ value: otherKey?._id, label: `${otherKey?.first_name} ${otherKey?.last_name}` })
  //   // return value.includes(inputValue) || otherKey.length > 0;
  //   return otherKey?.length ? otherKey : null;
  // };

  useEffect(() => {
    const dropdown =
      teacherList?.length &&
      teacherList.map((e) => {
        return { value: e?._id, label: `${e?.first_name} ${e?.last_name}` };
      });

    if (userData?.role === "teacher") {
      const findTeacher =
        teacherList?.length &&
        teacherList.find((e) => e?._id === paymentObj?.teacherID);
      setTeacher(findTeacher);
    }

    if (isEdit) {
      setDescription(paymentObj?.description || "");
    }

    setTeacherDropdown(dropdown);
  }, []);

  const renderTeacherDropdown = () => (
    <>
      <p>Select teacher</p>
      <Select
        onChange={(e) => {
          setTeacher(e);
          if (isRequired) setIsRequired(false);
        }}
        value={teacher}
        placeholder="Select Teacher"
        className="react-options"
        options={teacherDropdown}
        isClearable
        filterProp="email"
        matchProp="value"
        // filterOption={filterOption}
      />
      <span style={{ color: "red" }}>{isRequired && "Required"}</span>
    </>
  );

  const renderTeacherForm = () => (
    <form
      onSubmit={
        isRole("teacher") ? sendPaymentRequestToAdmin : submitPaymentToTeacher
      }
    >
      <div className="row my-2">
        <div className="col-md-4">
          {isRole("teacher") ? (
            <>
              <p>Name</p>
              <input
                type="text"
                className="form-control"
                disabled
                value={`${userData?.first_name} ${userData?.last_name}`}
              />
            </>
          ) : (
            renderTeacherDropdown()
          )}
        </div>
        <div className="col-md-4">
          <p>Amount</p>
          <input
            type="number"
            min={1}
            className="form-control"
            value={amountPaid}
            onChange={onChangeStateHandler(setAmountPaid)}
            required
          />
        </div>
        <div className="col-md-4">
          <p>Date</p>
          <input
            type="date"
            className="form-control"
            value={
              recordDate ? format(new Date(recordDate), "yyyy-MM-dd") : null
            }
            onChange={onChangeStateHandler(setRecordDate)}
            required
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-md-12">
          <textarea
            value={description}
            placeholder="Enter Description"
            style={{ width: "100%" }}
            rows="5"
            onChange={onChangeStateHandler(setDescription)}
            required
          ></textarea>
        </div>
      </div>

      <div className="btn-save">
        <button type="submit" className="btn btn-secondary">
          {isEdit
            ? "Update"
            : isRole("teacher")
            ? "Request payment"
            : "Send Payment"}
        </button>
      </div>
    </form>
  );

  console.log(teacher);
  return <div className="teacher_payment-form">{renderTeacherForm()}</div>;
};

TeacherPaymentForm.propTypes = {
  paymentObj: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  teacherList: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default TeacherPaymentForm;
