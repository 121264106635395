import { useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import SubjectList from "./SubjectList";
import Header from "components/Common/Header";
import {
  getAllSubjects,
  getSubject,
  saveSubject,
  updateSubject,
} from "Utils/subjects_Utils";
import { getRegisteredCourseByGrade } from "../../Utils/registered_Course";
import {
  getChapterWithQuizzes,
  getTotalChaptersForSubjects,
} from "../../Utils/chapterPreview_Utils";
import { toast } from "react-toastify";
import SubjectForm from "./SubjectForm";
import loader from "../../assets/img/loader.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectsubjects } from "Redux/features/Subject/SubjectSlice";
import { setsubject } from "Redux/features/Subject/SubjectSlice";
import TotalQuizAndTopicsModal from "./TotalQuizAndTopicsModal";
import { getTeachersSubjects } from "../../Utils/subjects_Utils";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import BreadCrumbs from "components/Common/BreadCrumbs";
import { setIsNotificationModalShow } from "Redux/features/Auth/AuthSlice";
import { setSendNotificationID } from "Redux/features/Auth/AuthSlice";
import { setSendNotificationType } from "Redux/features/Auth/AuthSlice";

const SubjectWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [subjectsList, setSubjectsList] = useState(null);
  const [registeredSubject, setRegisteredSubject] = useState(null);
  const [totalChaptersForSubjects, setTotalChaptersForSubjects] =
    useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [subjectObj, setSubjectObj] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isOpenTotalTopicsModal, setIsOpenTotalTopicsModal] = useState(false);
  const [
    totalChapterQuizzesTestsPointsObj,
    setTotalChapterQuizzesTestsPointsObj,
  ] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const allSubjects = useSelector(selectsubjects);
  const userData = useSelector(selectUserData);

  const onCloseModal = () => {
    if (isEdit) setIsEdit(false);

    if (isDelete) setIsDelete(false);

    if (subjectObj !== null) setSubjectObj(null);

    if (totalChapterQuizzesTestsPointsObj !== null)
      setTotalChapterQuizzesTestsPointsObj(null);

    if (isOpenTotalTopicsModal) setIsOpenTotalTopicsModal(false);

    setIsOpen(false);
  };

  const fetchAllSubjects = async () => {
    setLoading(true);
    try {
      const registeredSubjectDetails = await getRegisteredCourseByGrade();
      const totalChaptersForSubjectsDetails =
        await getTotalChaptersForSubjects();
      setRegisteredSubject(registeredSubjectDetails.data.data);
      setTotalChaptersForSubjects(totalChaptersForSubjectsDetails.data.data);

      if (allSubjects && allSubjects.length > 0) {
        setSubjectsList(allSubjects);
        return;
      }

      const subjectDetails = await getAllSubjects();

      const userRole = userData?.role;
      const userID = userData?._id;
      if (userRole === "teacher") {
        const resp = await getTeachersSubjects(userID);
        const { subjectsList } = resp?.data?.data[0];
        const filterTeacherSubjects = subjectDetails?.data?.data.filter((a) => {
          if (subjectsList?.find((x) => x === a?._id)) return a;
        });
        setSubjectsList(filterTeacherSubjects);
        dispatch(setsubject(filterTeacherSubjects));
      } else {
        setSubjectsList(subjectDetails.data.data);
        dispatch(setsubject(subjectDetails.data.data));
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onOpenModal = () => setIsOpen(true);

  const afterSave = async (isUpdate, subjectData) => {
    const [...rest] = subjectsList;
    let updateSubjectList = null;

    const index = rest.findIndex((e) => e._id === subjectData._id);

    if (isUpdate) {
      updateSubjectList = [
        ...rest.slice(0, index),
        subjectData,
        ...rest.slice(index + 1),
      ];
    } else {
      updateSubjectList = [...rest, subjectData];
    }

    setSubjectsList(updateSubjectList);
    dispatch(setsubject(updateSubjectList));
    onCloseModal();
    setLoading(false);
  };

  const saveSubjectHandler = async (_id, subjectData, isUpdate) => {
    setLoading(true);
    try {
      setIsOpen(false);
      let resp = null;

      if (isUpdate) {
        resp = await updateSubject(_id, subjectData);
        afterSave(true, resp.data.data);
      } else {
        resp = await saveSubject(subjectData);
        afterSave(false, resp.data.data[0]);
      }

      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      setLoading(false);
    }
  };

  const setSubjectValue = async (rowData) => {
    const subjectData = {
      _id: rowData._id,
      grade: rowData.gradeID,
      Subject_name: rowData.subjectName,
      subject_icon: rowData.subject_icon,
      subject_price: rowData.subject_price,
      isActive: rowData.isActive,
      uniCode: rowData?.uniCode,
    };
    setSubjectObj(subjectData);
    onOpenModal();
  };

  const addNewChapter = (subjectID) => {
    history.push(`/admin/subject/${subjectID}`);
  };

  const handleEdit = (rowData) => {
    setIsEdit(true);
    setSubjectValue(rowData);
  };

  const handleDelete = (rowData) => {
    setIsDelete(true);
    setSubjectValue(rowData);
  };

  // const sendNotification = (rowData) => {
  //   dispatch(setIsNotificationModalShow(true));
  //   dispatch(setSendNotificationID(rowData?._id));
  //   dispatch(setSendNotificationType("subject"));
  // };

  const showTotalTopicsAndQuizzes = async (subjectObj) => {
    const [subjectName, subjectID] = subjectObj;

    try {
      const resp = await getChapterWithQuizzes(subjectID);

      let filterChapterSequences = resp.data.data.filter(
        (e) => e.chapter_sequence.length > 0
      );
      let filterChapter_Sequences = filterChapterSequences.map(
        (e) => e.chapter_sequence
      );

      console.log(filterChapter_Sequences);

      const totalTopics = [];
      const totalQuizzes = [];
      const totalPoints = [];
      filterChapter_Sequences.forEach((e) => {
        e.forEach((a) => {
          if (Object.keys(a).includes("test")) {
            totalQuizzes.push(a.test);
            totalPoints.push(a.test.total_marks);
          }

          if (Object.keys(a).includes("topic")) totalTopics.push(a.topic);
        });
      });

      const activeQuizzes = totalQuizzes.filter((e) =>
        Boolean(e.isActive)
      ).length;
      const inActiveQuizzes = totalQuizzes.filter(
        (e) => !Boolean(e.isActive)
      ).length;
      const totalQuiz = activeQuizzes + inActiveQuizzes;

      const activeTopics = totalTopics.filter((e) =>
        Boolean(e.isActive)
      ).length;
      const inActiveTopics = totalTopics.filter(
        (e) => !Boolean(e.isActive)
      ).length;
      const totalTopic = activeTopics + inActiveTopics;

      let sum = 0;
      totalPoints.map((x) => (sum += x));

      const data = {
        activeQuizzes: activeQuizzes,
        inActiveQuizzes: inActiveQuizzes,
        totalQuiz: totalQuiz,
        activeTopics: activeTopics,
        inActiveTopics: inActiveTopics,
        totalTopic: totalTopic,
        sum: sum,
        subjectName: subjectName,
      };

      setTotalChapterQuizzesTestsPointsObj(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsOpenTotalTopicsModal(true);
    }
  };

  useEffect(() => {
    fetchAllSubjects();
  }, []);

  const renderAddSubjectBtn = () => (
    <div className="add-subject-btn">
      <button className="btn btn-secondary" onClick={onOpenModal}>
        Add Subject
      </button>
    </div>
  );

  const userRole = sessionStorage.getItem("role");
  return (
    <div className="subject_wrapper content">
      <BreadCrumbs
        breadCrumbList={[{ title: "Subjects", pathName: "/admin/subjects" }]}
      />
      <Row>
        <Col md={12}>
          <Card>
            <Header title="Subjects" />
            <CardBody>
              {loading ? (
                <div className="loader">
                  <img src={loader} alt="Loading...." />
                </div>
              ) : (
                <>
                  {userRole && userRole === "teacher"
                    ? null
                    : renderAddSubjectBtn()}
                  <SubjectList
                    subjectsList={subjectsList}
                    onHandleEdit={handleEdit}
                    onHandleDelete={handleDelete}
                    // sendNotification={sendNotification}
                    registeredSubject={registeredSubject}
                    totalChaptersForSubjects={totalChaptersForSubjects}
                    showTotalTopicsAndQuizzes={showTotalTopicsAndQuizzes}
                    addNewChapter={addNewChapter}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isOpen && (
        <SubjectForm
          isOpenModal={isOpen}
          isEdit={isEdit}
          subjectObj={subjectObj}
          handleOnClose={onCloseModal}
          isDelete={isDelete}
          saveSubjectHandler={saveSubjectHandler}
        />
      )}
      <TotalQuizAndTopicsModal
        handleOnClose={onCloseModal}
        isOpenModal={isOpenTotalTopicsModal}
        totalChapterQuizzesTestsPointsObj={totalChapterQuizzesTestsPointsObj}
      />
    </div>
  );
};
export default SubjectWrapper;
