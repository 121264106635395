import { toast } from "react-toastify";
import { setloading } from "Redux/features/Auth/AuthSlice";
import {
  addgrade,
  editgrade,
  editgradeStatus,
} from "Redux/features/Grade/GradeSlice";
import backend from "../api";
import axios from "../api";
// Update Backend
export async function updateGradeBackend(id, data, dispatch) {
  dispatch(setloading(true));
  axios
    .put(`/grades/${id}`, data)
    .then((res) => {
      dispatch(editgrade(res.data.data));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success("Grade Updated Successfully!");
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

// Add Backend
export async function addGradeBackend(data, setAddModal, dispatch) {
  setAddModal(false);
  dispatch(setloading(true));
  axios
    .post(`/grades`, data)
    .then((res) => {
      dispatch(addgrade(res.data.data));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success("Grade Added Successfully!");
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}
// Status Backend
export async function updateStatusBackend(
  id,
  status,
  setStatusModal,
  dispatch
) {
  let data = { isActive: !status };
  setStatusModal(false);
  dispatch(setloading(true));
  axios
    .put(`/grades/status/${id}`, data)
    .then((res) => {
      dispatch(editgradeStatus({ _id: id, isActive: data.isActive }));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Grade's Status Updated Successfully!`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

export const getGradeForDropdown = async () =>
  await backend({
    method: "GET",
    url: "/grades/dropdown",
  });

export const getGradeUnicode = async () =>
  await backend({
    method: "GET",
    url: "/grades/uniCode",
  });
