import Header from "components/Common/Header";
import { format } from "date-fns";
import { FaPlayCircle } from "react-icons/fa";

import MaterialTable from "material-table";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import PropTypes from "prop-types";

import { FaRegEdit, FaBan } from "react-icons/fa";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const TopicList = ({
  topicList,
  onHandleEdit,
  onHandleDelete,
  onHandleVideoPlay,
  onHandleViewDescription,
}) => {
  const renderCellValue = (value) => (
    <div className="topic-cell_value">
      <h1>{value}</h1>
    </div>
  );

  const renderTopicStatus = (value) => (
    <div className="topic_status">
      <h1 className={value?.toLowerCase()}>{value}</h1>
    </div>
  );

  const renderTopicName = (rowData) => (
    <span
      className="topic-title"
      onClick={() => {
        onHandleEdit(rowData);
      }}
    >
      {rowData.topic_name}
    </span>
  );

  return (
    <div className="topic">
      <MaterialTable
        icons={tableIcons}
        title="Topics Preview"
        editable
        columns={[
          // { title: '#', field: 'index' },
          {
            title: "Topic title",
            field: "topic_name",
            render: (rowData) => renderTopicName(rowData),
          },
          { title: "Topic image", field: "topic_icon", export: false },
          {
            title: "Paid",
            field: "isPaidTopic",
            render: (rowData) => renderCellValue(rowData?.isPaidTopic),
          },
          {
            title: "Grade",
            field: "grade",
            render: (rowData) => renderCellValue(rowData?.grade),
          },
          {
            title: "Subject Name",
            field: "Subject_name",
            render: (rowData) => renderCellValue(rowData?.Subject_name),
          },
          {
            title: "Chapter Name",
            field: "chapter_name",
            render: (rowData) => renderCellValue(rowData?.chapter_name),
          },
          // { title: 'Grade Plan', field: 'course_plan' },
          { title: "Topic video", field: "video", export: false },
          { title: "Topic description", field: "description", export: false },
          {
            title: "Created At",
            field: "createdAt",
            render: (rowData) => renderCellValue(rowData?.createdAt),
          },
          {
            title: "Updated at",
            field: "updatedAt",
            render: (rowData) => renderCellValue(rowData?.updatedAt),
          },
          {
            title: "Status",
            field: "isDelete",
            render: (rowData) => renderTopicStatus(rowData?.isDelete),
            customFilterAndSearch: (term, rowData) =>
              !rowData?.isDelete
                ?.toLowerCase()
                .localeCompare(term.toLowerCase()),
          },
        ]}
        data={topicList?.map((e, index) => {
          // let count = index;
          return {
            videoObj: e,
            _id: e._id,
            vedio_type: e.vedio_type,
            topic_video: e.topic_video,
            topic_detail: e.topic_detail,
            isPaid: e.isPaid,
            isPaidTopic: e.isPaid ? "Paid" : "Free",
            // index: count++,
            topic_title: e.topic_title,
            topic_name: `${e.topic_title} - (${e.uniCode})`,
            topic_icon: <img className="topic-img" src={e.topic_icon} />,
            Subject_name: `${e?.subject?.Subject_name} - (${e?.subject?.uniCode})`,
            chapter_name: `${e?.chapter?.chapter_name} - (${e?.chapter?.uniCode})`,
            course_plan: e.grade.course_plan,
            // grade: `${e.grade.grade} ${e.grade.course_plan}`,
            grade: e.grade.grade,
            grade: `${e.grade.grade} - (${e.grade.uniCode})`,
            video: (
              <a className="play-video" onClick={() => onHandleVideoPlay(e)}>
                {" "}
                play video {<FaPlayCircle className="play-icon" />}{" "}
              </a>
            ),
            description: (
              <a
                className="play-video"
                onClick={() =>
                  onHandleViewDescription([e.topic_detail, e.topic_title])
                }
              >
                {" "}
                View description{" "}
              </a>
            ),
            createdAt: e.createdAt
              ? format(new Date(e.createdAt), "dd MMM yyyy")
              : "N/A",
            updatedAt: e.updatedAt
              ? format(new Date(e.updatedAt), "dd MMM yyyy")
              : "N/A",
            isActive: e.isActive,
            isDelete: e.isActive ? "Active" : "In-Active",
          };
        })}
        actions={[
          {
            icon: () => <FaRegEdit />,
            tooltip: "Edit Topic",
            onClick: (event, rowData) => onHandleEdit(rowData),
          },
          {
            icon: () => <FaBan />,
            tooltip: "Change Status",
            onClick: (event, rowData) => onHandleDelete(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          // exportButton: true,
          pageSize: 10,
          // pageSizeOptions: [10, 15, 20]
          filtering: true,
        }}
      />
    </div>
  );
};

TopicList.propTypes = {
  topicList: PropTypes.array.isRequired,
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  onHandleVideoPlay: PropTypes.func.isRequired,
  onHandleViewDescription: PropTypes.func.isRequired,
};

export default TopicList;
