import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "components/Common/Header";
import { Card, CardBody, Row, Col } from "reactstrap";
import MaterialTableContainer from "components/Common/MaterialTableContainer";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getNewTopics } from "Utils/topic_quiz_Utils";
import Loader from "components/Common/Loader";

const TopicWrapper = ({}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const createColumns = () => {
    const column = [
      { title: "#", field: "index", filtering: false },
      {
        title: "Name",
        field: "title",
        filtering: true,
      },
      {
        title: "Chapter",
        field: "chapter",
        filtering: true,
      },
      {
        title: "Subject",
        field: "subject",
        filtering: true,
      },
      {
        title: "Grade",
        field: "grade",
        filtering: true,
      },
      {
        title: "Level",
        field: "level",
        filtering: true,
      },
      {
        title: "Status",
        field: "isActive",
        filtering: true,
      },
    ];

    setColumns(column);
  };

  const createRow = (topics) => {
    const list = topics?.length
      ? topics.map((e, i) => ({
          index: i + 1,
          title: e?.title || "",
          grade: e?.grade || "",
          subject: e?.subject || "",
          chapter: e?.chapter || "",
          level: e?.level || "",
          isActive: String(e?.isActive),
        }))
      : [];

    setRows(list);
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resp = await getNewTopics();
      setTopicList(resp.data.data);

      createColumns();
      createRow(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchInitialData(), []);

  return (
    <div className=" topic_wrapper content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Header title="Topic Listing" />

              {loading ? (
                <Loader />
              ) : (
                <>
                  <button
                    className="btn btn-secondary"
                    onClick={() => history.push("/admin/topic/new")}
                  >
                    Add Topic
                  </button>
                  <MaterialTableContainer columns={columns} rows={rows} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

TopicWrapper.propTypes = {};

export default TopicWrapper;
