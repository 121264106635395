import backend from '../api';

export const uploadImage = async (imageObj) => {
  const formData = new FormData();
  formData.append('files', imageObj);

  try {
    const resp = await backend({
      method: 'POST',
      url: '/filesupload/file-upload',
      data: formData,
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });
    
    return resp.data.locationArray[0].fileLocation;
  } catch (error) {
    return error;
  }
};
