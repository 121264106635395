import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Header from "components/Common/Header";
import { Card, CardBody, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import {
  getAdvertisement,
  updateAdvertisement,
} from "../../Utils/advertisement_Utils";
import { format } from "date-fns";
import { uploadImage } from "Utils/image_uploader";
import mike from "../../assets/img/mike.jpg";
import loader from "../../assets/img/loaderimg.svg";
import bigLoader from "../../assets/img/loader.svg";

const Advertisement = () => {
  const [loading, setLoading] = useState(false);
  const [advertisementObj, setAdvertisementObj] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);

  const fetchAdvertisementData = async () => {
    setLoading(true);
    try {
      const resp = await getAdvertisement();
      setAdvertisementObj(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const update_Advertisement = async () => {
    try {
      const data = { isActive: !advertisementObj.isActive };
      const resp = await updateAdvertisement(advertisementObj._id, data);

      toast.success(resp.data.message);
      setAdvertisementObj(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeFileHandler = async (e) => {
    const image = e.target.files[0];

    if (!image) return;

    try {
      setImgLoading(true);
      const data = { advertisementImage: await uploadImage(image) };
      setImgLoading(false);
      const resp = await updateAdvertisement(advertisementObj._id, data);

      toast.success(resp.data.message);
      setAdvertisementObj(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdvertisementData();
  }, []);

  const renderAdvertisementData = (label, value) => (
    <div className="data">
      <p>{label}</p>
      <h1>{value}</h1>
    </div>
  );

  return (
    <div className="advertisement content">
      <Row>
        <Col md={12}>
          <Card>
            <Header title="Advertisement" />
            <CardBody>
              {loading ? (
                <div className="loader">
                  <img src={bigLoader} alt="Loading...." />
                </div>
              ) : (
                <>
                  {/* <div className="advertisement-btn">
                    <button
                      className="btn btn-secondary"
                      onClick={update_Advertisement}
                    >
                      Advertisement{" "}
                      {advertisementObj?.isActive ? "Disable" : "Enable"}
                    </button>
                  </div> */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="advertisement_container">
                        <div className="_card">
                          <div className="image">
                            <img
                              src={advertisementObj?.advertisementImage || mike}
                              alt="advertisement_img"
                            />
                          </div>
                          {renderAdvertisementData(
                            "Status",
                            advertisementObj?.isActive ? "Enabled" : "Disabled"
                          )}
                          {renderAdvertisementData(
                            "created at",
                            advertisementObj?.createdAt
                              ? format(
                                  new Date(advertisementObj.createdAt),
                                  "dd MMM yyyy"
                                )
                              : "-"
                          )}
                          {renderAdvertisementData(
                            "updated at",
                            advertisementObj?.updatedAt
                              ? format(
                                  new Date(advertisementObj.updatedAt),
                                  "dd MMM yyyy"
                                )
                              : "-"
                          )}

                          <div className="update_status">
                            <button
                              className="btn btn-secondary"
                              onClick={update_Advertisement}
                            >
                              {advertisementObj?.isActive
                                ? "Disabled"
                                : "Enabled"}
                            </button>
                          </div>

                          <div className="update_image">
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                const element =
                                  document.getElementById("upload_image");
                                element.click();
                              }}
                            >
                              {imgLoading ? (
                                <>
                                  <img src={loader} alt="loader" />
                                </>
                              ) : (
                                "Update Image"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <input
                    type="file"
                    id="upload_image"
                    className="upload_image"
                    onChange={onChangeFileHandler}
                  />

                  {/* <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Advertisement Allow</th>
                        <th>created at</th>
                        <th>updated at</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          {advertisementObj?.isActive
                            ? "Enable"
                            : "Disable"}{" "}
                        </td>
                        <td>
                          {" "}
                          {advertisementObj?.createdAt
                            ? format(
                                new Date(advertisementObj.createdAt),
                                "dd MMM yyyy"
                              )
                            : "-"}
                        </td>
                        <td>
                          {" "}
                          {advertisementObj?.updatedAt
                            ? format(
                                new Date(advertisementObj.updatedAt),
                                "dd MMM yyyy"
                              )
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Advertisement.propTypes = {};

export default Advertisement;
