import backend from "../api";

export const getUniCodeStats = async () =>
  await backend({
    method: "GET",
    url: "/topics/uniCode/stats",
  });

export const getTestUniCodeStats = async () =>
  await backend({
    method: "GET",
    url: "/test/uniCode/stats",
  });

export const getChapterTopicUniCode = async (chapterID) =>
  await backend({
    method: "GET",
    url: `/topics/chapter/specific/unicode/${chapterID}`,
  });

export const getChapterQuizUniCode = async (chapterID) =>
  await backend({
    method: "GET",
    url: `/test/chapter/specific/unicode/${chapterID}`,
  });

export const updateTopicsUniCodes = async (topicsList) =>
  await backend({
    method: "PUT",
    url: "/topics/unicode/update",
    data: { topicsList },
  });

export const updateQuizzesUniCodes = async (quizzesList) =>
  await backend({
    method: "PUT",
    url: "/test/unicode/update",
    data: { quizzesList },
  });
