import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Header from "components/Common/Header";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import {
  selectTeachersList,
  setTeacherList,
} from "Redux/features/Teacher/TeacherSlice";
import { getAllTeachersData } from "Utils/teacher_Utils";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Common/Loader";
import TeacherPaymentList from "./TeacherPaymentList";
import ModalContainer from "components/Common/ModalContainer";
import TeacherPaymentForm from "./TeacherPaymentForm";
import { getTeacherPayments } from "Utils/teacherPayment_Utils";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import TeacherPaymentStatusForm from "./TeacherPaymentStatusForm";
import BreadCrumbs from "components/Common/BreadCrumbs";

const TeacherPaymentWrapper = ({ teacher_ID, showBreadCrumb }) => {
  const [teacherList, setTeachersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [paymentObj, setPaymentObj] = useState({});
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const param = useParams();
  const { teacherID } = param;
  const allTeachersList = useSelector(selectTeachersList);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const history = useHistory();

  const openModal = () => {
    setIsOpenPaymentModal(true);
  };

  const closeModal = () => {
    setPaymentObj({});
    setIsOpenPaymentModal(false);
    setIsEdit(false);
  };

  const closeStatusModal = () => {
    setPaymentObj({});
    setIsOpenStatusModal(false);
  };

  const changeStatusHandler = (paymentData) => {
    setPaymentObj(paymentData);
    setIsOpenStatusModal(true);
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      if (allTeachersList !== null && allTeachersList?.length) {
        setTeachersList(allTeachersList);
      } else {
        const resp = await getAllTeachersData();
        setTeachersList(resp.data.data);
        dispatch(setTeacherList(resp.data.data));
      }

      let resp = null;
      if (teacherID || teacher_ID)
        resp = await getTeacherPayments(teacherID || teacher_ID);
      else resp = await getTeacherPayments();

      setPaymentList(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closePaymentStatusModal = () => {
    closeStatusModal();
    fetchInitialData();
  };

  const afterSavePayment = () => {
    closeModal();
    fetchInitialData();
  };

  const editPaymentHandler = (paymentData) => {
    setIsEdit(true);
    setPaymentObj(paymentData?.data);
    setIsOpenPaymentModal(true);
  };

  const isRole = (role) => (userData?.role === role ? true : false);

  const displayTeacherName = () => {
    const teacherName =
      allTeachersList?.length &&
      allTeachersList.find((e) => e?._id === teacherID);

    return teacherName
      ? teacherName?.username ||
          `${teacherName?.first_name} ${teacherName?.last_name}`
      : "-";
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const renderPaymentList = () =>
    paymentList?.length ? (
      <TeacherPaymentList
        paymentList={paymentList}
        changeStatusHandler={changeStatusHandler}
        editPaymentHandler={editPaymentHandler}
      />
    ) : (
      <h1>Payment list is empty</h1>
    );

  const renderPaymentFormModal = () => (
    <ModalContainer
      isOpen={isOpenPaymentModal}
      onConfirmClick={closeModal}
      modalWidth="800"
      animateEffect="fadeInUp"
      heading={
        isEdit
          ? "Edit payment"
          : isRole("teacher")
          ? "Request Payment"
          : "Send Payment"
      }
    >
      {isOpenPaymentModal && (
        <TeacherPaymentForm
          paymentObj={paymentObj}
          handleSave={afterSavePayment}
          teacherList={teacherList}
          isEdit={isEdit}
        />
      )}
    </ModalContainer>
  );

  const renderPaymentStatusModal = () => (
    <ModalContainer
      isOpen={isOpenStatusModal}
      onConfirmClick={closeStatusModal}
      modalWidth="600"
      animateEffect="fadeInUp"
      heading={"Change Payment Status"}
    >
      {isOpenStatusModal && (
        <TeacherPaymentStatusForm
          closeModal={closePaymentStatusModal}
          paymentObj={paymentObj}
        />
      )}
    </ModalContainer>
  );

  return (
    <div className="teacher_payment content">
      {showBreadCrumb && (
        <BreadCrumbs
          breadCrumbList={
            teacherID
              ? [
                  { title: displayTeacherName(), pathName: "/admin/teachers" },
                  {
                    title: "Payment Details",
                    pathName: `admin/teacher/payment/${teacherID}`,
                  },
                ]
              : [
                  {
                    title: "Payment",
                    pathName: "admin/teacher/payment",
                  },
                ]
          }
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                {!isRole("teacher") && (
                  <Header
                    title="Payments"
                    location={
                      teacherID ? "/admin/teachers" : "/admin/dashboard"
                    }
                  />
                )}
              </CardHeader>
              <CardBody>
                <div className="add_payment">
                  {" "}
                  <button className="btn btn-secondary" onClick={openModal}>
                    {isRole("teacher") ? "Request Payment" : "Add Payment"}
                  </button>{" "}
                </div>
                {renderPaymentList()}
                {renderPaymentFormModal()}
                {renderPaymentStatusModal()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

TeacherPaymentWrapper.propTypes = {
  teacher_ID: PropTypes.string,
  showBreadCrumb: PropTypes.bool,
};

TeacherPaymentWrapper.defaultProps = {
  teacher_ID: "",
  showBreadCrumb: true,
};

export default TeacherPaymentWrapper;
