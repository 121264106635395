import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaBan, FaCheckCircle, FaHourglassHalf } from 'react-icons/fa';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from '../api';
import { selectstudent } from 'Redux/features/Student/StudentSlice';
import { setstudent } from 'Redux/features/Student/StudentSlice';
import loader from '../assets/img/loader.svg';
import { selectLoading } from 'Redux/features/Auth/AuthSlice';
import { setloading } from 'Redux/features/Auth/AuthSlice';
import { toast } from 'react-toastify';
import { updateStatusBackend, updateMacToDefault } from 'Utils/students_Utils';
import dateFormat from 'dateformat';
const Students = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  useEffect(() => {
    function fetchStudents() {
      dispatch(setloading(true));
      axios
        .get(`/user?role=student`)
        .then((res) => {
          dispatch(setstudent(res.data.data));

          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          window.alert(err.response.data.message);
        });
    }
    fetchStudents();
  }, []);
  const students = useSelector(selectstudent);
  // console.log(students)
  const setStatus = (id) => {
    // console.log(id, typeof id);
    updateStatusBackend(id, setStatusModal, studentStatus, dispatch);
    // props.changeStatus(id, !studentStatus);
    setselectedStudentId('');
  };

  // console.log(studentList, "StudentList");
  const [studentStatus, setstudentStatus] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [selectedStudentId, setselectedStudentId] = useState('');
  const [activity, setactivity] = useState('');
  if (activity == '') {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {' '}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: '36px', cursor: 'pointer' }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{' '}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ marginLeft: '10px' }}>Students</h4>
                        <div className="dropdown" style={{ marginLeft: '45vw' }}>
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ marginLeft: '2%', width: '220px' }}
                          >
                            {activity === ''
                              ? 'Select Active/Inactive'
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('Active');
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('InActive');
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('');
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </CardTitle>
                    <div style={{ display: 'flex' }}>
                      <p className="category">
                        {' '}
                        Total {students.length} Students Registered
                      </p>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {' '}
                    <Row>
                      <Col>
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email/Facebook </th>
                              <th className="th-sm">Platform </th>
                              <th className="th-sm">Referral Code </th>
                              <th className="th-sm">Status</th>
                              <th className="th-sm">Device Change Counts </th>
                              <th className="th-sm">Last Change Request </th>
                              <th className="th-sm">Approve Device Status</th>
                              <th className="th-sm">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {students.map((student, index) => (
                              <tr
                                key={index}
                                style={{ backgroundColor: 'transparent' }}
                              >
                                <td>
                                  <Link to={`students/${student._id}`}>
                                    {student.first_name} {student.last_name}
                                  </Link>
                                </td>
                                <td>
                                  {student.email
                                    ? student.email
                                    : student.facebook_account}
                                </td>
                                <td>
                                  {student.platform
                                    ? student.platform
                                    : student.facebook_account
                                    ? 'Facebook'
                                    : 'Apple'}
                                </td>

                                <td>{student.referral_code}</td>
                                <td id="statusholder">
                                  {student.isActive ? (
                                    <p style={{ color: 'green' }}>Active</p>
                                  ) : (
                                    <p style={{ color: 'red' }}>Inactive</p>
                                  )}
                                </td>
                                <td>
                                  {student.MacReq_count
                                    ? student.MacReq_count
                                    : 'N/A'}
                                </td>
                                <td>
                                  {student.last_req
                                    ? dateFormat(
                                        student.last_req,
                                        'mmmm dS, yyyy'
                                      )
                                    : 'N/A'}
                                </td>
                                <td>
                                  {student.changeMac ? (
                                    <FaHourglassHalf
                                      style={{
                                        fontSize: '18px',
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        updateMacToDefault(
                                          student._id,
                                          dispatch
                                        );
                                      }}
                                    />
                                  ) : (
                                    <FaCheckCircle
                                      style={{
                                        fontSize: '18px',
                                        color: 'green',
                                      }}
                                      // onClick={() => {
                                      //   setstudentStatus(student.isActive);
                                      //   setselectedStudentId(student._id);
                                      //   setStatusModal(true);
                                      // }}
                                    />
                                  )}
                                </td>
                                <td>
                                  <i
                                    className="fa fa-bars"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: '1rem',
                                      color: 'green',
                                      cursor: 'pointer',
                                      width: '100%',
                                      textAlign:"center"
                                    }}
                                    title="View Refer points record"
                                    onClick={() => {
                                      history.push(
                                        `/admin/students/refer-points/record/${student._id}`
                                      );
                                    }}
                                  />
                                </td>
                                {/* <td>
                              <div style={{ display: "flex", marginLeft: "30%" }}>
                                {student.isActive ? (
                                  <FaBan
                                    style={{ fontSize: "18px", color: "red" }}
                                    onClick={() => {
                                      setstudentStatus(student.isActive);
                                      setselectedStudentId(student._id);
                                      setStatusModal(true);
                                    }}
                                  />
                                ) : (
                                  <FaCheckCircle
                                    style={{ fontSize: "18px", color: "green" }}
                                    onClick={() => {
                                      setstudentStatus(student.isActive);
                                      setselectedStudentId(student._id);
                                      setStatusModal(true);
                                    }}
                                  />
                                )}
                              </div>
                              <Modal
                                visible={statusModal}
                                width="500"
                                effect="fadeInUp"
                                onClickAway={() => {
                                  setStatusModal(false);
                                  window.location.reload();
                                }}
                              >
                                <div
                                  className="modal-container"
                                  style={{ padding: "3%", textAlign: "center" }}
                                >
                                  <h4
                                    className="mb-4"
                                    style={{ color: "#242b3b" }}
                                  >
                                    Change Status
                                  </h4>
  
                                  {student.isActive ? (
                                    <p
                                      style={{ fontSize: "20px", color: "black" }}
                                    >
                                      Are you sure you want to restrict this user?
                                    </p>
                                  ) : (
                                    <p
                                      style={{ fontSize: "20px", color: "black" }}
                                    >
                                      Are you sure you want to change this user's
                                      status to active?
                                    </p>
                                  )}
                                  <div
                                    className="w-100 d-flex justify-content-between mt-4"
                                    style={{ padding: "2%", paddingBottom: "3%" }}
                                  >
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setStatusModal(false);
                                        window.location.reload();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-warning"
                                      onClick={() => {
                                        setStatusModal(false);
                                        setStatus(selectedStudentId);
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </Modal>
                            </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else if (activity == 'Active') {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {' '}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: '36px', cursor: 'pointer' }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{' '}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ marginLeft: '10px' }}>Students</h4>
                        <div className="dropdown" style={{ marginLeft: '45vw' }}>
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ marginLeft: '2%', width: '220px' }}
                          >
                            {activity === ''
                              ? 'Select Active/Inactive'
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('Active');
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('InActive');
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('');
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </CardTitle>
                    <div style={{ display: 'flex' }}>
                      <p className="category">
                        {' '}
                        Total {students.length} Students Registered
                      </p>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {' '}
                    <Row>
                      <Col>
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email/Facebook </th>
                              <th className="th-sm">Platform </th>
                              <th className="th-sm">Referral Code </th>
                              <th className="th-sm">Status</th>
                              <th className="th-sm">Device Change Counts </th>
                              <th className="th-sm">Last Change Request </th>
                              <th className="th-sm">Approve Device Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {students.map((student, index) =>
                              student.isActive ? (
                                <tr
                                  key={index}
                                  style={{ backgroundColor: 'transparent' }}
                                >
                                  <td>
                                    <Link to={`students/${student._id}`}>
                                      {student.first_name} {student.last_name}
                                    </Link>
                                  </td>
                                  <td>
                                    {student.email
                                      ? student.email
                                      : student.facebook_account}
                                  </td>
                                  <td>
                                    {student.platform
                                      ? student.platform
                                      : student.facebook_account
                                      ? 'Facebook'
                                      : 'Apple'}
                                  </td>
                                  <td>{student.referral_code}</td>
                                  <td id="statusholder">
                                    {student.isActive ? (
                                      <p style={{ color: 'green' }}>Active</p>
                                    ) : (
                                      <p style={{ color: 'red' }}>Inactive</p>
                                    )}
                                  </td>
                                  <td>
                                    {student.MacReq_count
                                      ? student.MacReq_count
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {student.last_req
                                      ? dateFormat(
                                          student.last_req,
                                          'mmmm dS, yyyy'
                                        )
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {student.changeMac ? (
                                      <FaHourglassHalf
                                        style={{
                                          fontSize: '18px',
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          updateMacToDefault(
                                            student._id,
                                            dispatch
                                          );
                                        }}
                                      />
                                    ) : (
                                      <FaCheckCircle
                                        style={{
                                          fontSize: '18px',
                                          color: 'green',
                                        }}
                                        // onClick={() => {
                                        //   setstudentStatus(student.isActive);
                                        //   setselectedStudentId(student._id);
                                        //   setStatusModal(true);
                                        // }}
                                      />
                                    )}
                                  </td>
                                  {/* <td>
                              <div style={{ display: "flex", marginLeft: "30%" }}>
                                {student.isActive ? (
                                  <FaBan
                                    style={{ fontSize: "18px", color: "red" }}
                                    onClick={() => {
                                      setstudentStatus(student.isActive);
                                      setselectedStudentId(student._id);
                                      setStatusModal(true);
                                    }}
                                  />
                                ) : (
                                  <FaCheckCircle
                                    style={{ fontSize: "18px", color: "green" }}
                                    onClick={() => {
                                      setstudentStatus(student.isActive);
                                      setselectedStudentId(student._id);
                                      setStatusModal(true);
                                    }}
                                  />
                                )}
                              </div>
                              <Modal
                                visible={statusModal}
                                width="500"
                                effect="fadeInUp"
                                onClickAway={() => {
                                  setStatusModal(false);
                                  window.location.reload();
                                }}
                              >
                                <div
                                  className="modal-container"
                                  style={{ padding: "3%", textAlign: "center" }}
                                >
                                  <h4
                                    className="mb-4"
                                    style={{ color: "#242b3b" }}
                                  >
                                    Change Status
                                  </h4>
  
                                  {student.isActive ? (
                                    <p
                                      style={{ fontSize: "20px", color: "black" }}
                                    >
                                      Are you sure you want to restrict this user?
                                    </p>
                                  ) : (
                                    <p
                                      style={{ fontSize: "20px", color: "black" }}
                                    >
                                      Are you sure you want to change this user's
                                      status to active?
                                    </p>
                                  )}
                                  <div
                                    className="w-100 d-flex justify-content-between mt-4"
                                    style={{ padding: "2%", paddingBottom: "3%" }}
                                  >
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setStatusModal(false);
                                        window.location.reload();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-warning"
                                      onClick={() => {
                                        setStatusModal(false);
                                        setStatus(selectedStudentId);
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </Modal>
                            </td> */}
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else if (activity == 'InActive') {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {' '}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: '36px', cursor: 'pointer' }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{' '}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ marginLeft: '10px' }}>Students</h4>
                        <div className="dropdown" style={{ marginLeft: '45vw' }}>
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ marginLeft: '2%', width: '220px' }}
                          >
                            {activity === ''
                              ? 'Select Active/Inactive'
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('Active');
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('InActive');
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: 'pointer' }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity('');
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </CardTitle>
                    <div style={{ display: 'flex' }}>
                      <p className="category">
                        {' '}
                        Total {students.length} Students Registered
                      </p>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {' '}
                    <Row>
                      <Col>
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email/Facebook </th>
                              <th className="th-sm">Platform </th>
                              <th className="th-sm">Referral Code </th>
                              <th className="th-sm">Status</th>
                              <th className="th-sm">Device Change Counts </th>
                              <th className="th-sm">Last Change Request </th>
                              <th className="th-sm">Approve Device Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {students.map((student, index) =>
                              student.isActive === false ? (
                                <tr
                                  key={index}
                                  style={{ backgroundColor: 'transparent' }}
                                >
                                  <td>
                                    <Link to={`students/${student._id}`}>
                                      {student.first_name} {student.last_name}
                                    </Link>
                                  </td>
                                  <td>
                                    {student.email
                                      ? student.email
                                      : student.facebook_account}
                                  </td>
                                  <td>
                                    {student.platform
                                      ? student.platform
                                      : student.facebook_account
                                      ? 'Facebook'
                                      : 'Apple'}
                                  </td>
                                  <td>{student.referral_code}</td>
                                  <td id="statusholder">
                                    {student.isActive ? (
                                      <p style={{ color: 'green' }}>Active</p>
                                    ) : (
                                      <p style={{ color: 'red' }}>Inactive</p>
                                    )}
                                  </td>
                                  <td>
                                    {student.MacReq_count
                                      ? student.MacReq_count
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {student.last_req
                                      ? dateFormat(
                                          student.last_req,
                                          'mmmm dS, yyyy'
                                        )
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {student.changeMac ? (
                                      <FaHourglassHalf
                                        style={{
                                          fontSize: '18px',
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          updateMacToDefault(
                                            student._id,
                                            dispatch
                                          );
                                        }}
                                      />
                                    ) : (
                                      <FaCheckCircle
                                        style={{
                                          fontSize: '18px',
                                          color: 'green',
                                        }}
                                        // onClick={() => {
                                        //   setstudentStatus(student.isActive);
                                        //   setselectedStudentId(student._id);
                                        //   setStatusModal(true);
                                        // }}
                                      />
                                    )}
                                  </td>
                                  {/* <td>
                              <div style={{ display: "flex", marginLeft: "30%" }}>
                                {student.isActive ? (
                                  <FaBan
                                    style={{ fontSize: "18px", color: "red" }}
                                    onClick={() => {
                                      setstudentStatus(student.isActive);
                                      setselectedStudentId(student._id);
                                      setStatusModal(true);
                                    }}
                                  />
                                ) : (
                                  <FaCheckCircle
                                    style={{ fontSize: "18px", color: "green" }}
                                    onClick={() => {
                                      setstudentStatus(student.isActive);
                                      setselectedStudentId(student._id);
                                      setStatusModal(true);
                                    }}
                                  />
                                )}
                              </div>
                              <Modal
                                visible={statusModal}
                                width="500"
                                effect="fadeInUp"
                                onClickAway={() => {
                                  setStatusModal(false);
                                  window.location.reload();
                                }}
                              >
                                <div
                                  className="modal-container"
                                  style={{ padding: "3%", textAlign: "center" }}
                                >
                                  <h4
                                    className="mb-4"
                                    style={{ color: "#242b3b" }}
                                  >
                                    Change Status
                                  </h4>
  
                                  {student.isActive ? (
                                    <p
                                      style={{ fontSize: "20px", color: "black" }}
                                    >
                                      Are you sure you want to restrict this user?
                                    </p>
                                  ) : (
                                    <p
                                      style={{ fontSize: "20px", color: "black" }}
                                    >
                                      Are you sure you want to change this user's
                                      status to active?
                                    </p>
                                  )}
                                  <div
                                    className="w-100 d-flex justify-content-between mt-4"
                                    style={{ padding: "2%", paddingBottom: "3%" }}
                                  >
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setStatusModal(false);
                                        window.location.reload();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-warning"
                                      onClick={() => {
                                        setStatusModal(false);
                                        setStatus(selectedStudentId);
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </Modal>
                            </td> */}
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
};

export default Students;
