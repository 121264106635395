import { toast } from 'react-toastify';
import { setloading } from 'Redux/features/Auth/AuthSlice';
import { editquizesStatus } from 'Redux/features/Subject/SubjectSlice';
import axios from '../api';
import backend from '../api';

export function UpdateStatusQuizBackend(quizID, status, dispatch) {
  dispatch(setloading(true));
  const data = { isActive: !status };
  axios
    .put(`/test/${quizID}`, data)
    .then((res) => {
      dispatch(editquizesStatus({ _id: quizID, isActive: data.isActive }));
      toast.success(`Quiz's Status Updated Successfully!`);
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
    })
    .catch((err) => {
      dispatch(setloading(false));
      toast.error(err.response.data.message);
    });
}

export function DelteQuizBackend(questionID, quizID, dispatch) {
  dispatch(setloading(true));
  axios
    .delete(`/test/question/${quizID}/${questionID}`)
    .then((res) => {
      toast.success('Question Deleted!');
      setTimeout(() => {
        dispatch(setloading(false));
      }, 300);
    })
    .catch((err) => {
      dispatch(setloading(false));
      toast.error(err.response.data.message);
    });
}
export function UpdateQuizBackend(data, quizID, dispatch, history) {
  dispatch(setloading(true));
  console.log('Quiz Edited : ', data);
  axios
    .put(
      `/test/${quizID}`,

      data
    )
    .then((res) => {
      toast.success('Quiz Updated Successfully!');
      setTimeout(() => {
        dispatch(setloading(false));
        history.goBack();
      }, 1000);
    })

    .catch((err) => {
      dispatch(setloading(false));
      toast.error(err.response.data.message);
    });
}

// CreateQuizBackend
export async function createQuizBackend(
  data1,
  data2,
  lessonid,
  dispatch,
  history
) {
  dispatch(setloading(true));
  if (data1 !== null && data2 !== null) {
    axios
      .post('/test', data1)
      .then((request) => {
        // console.log(request);
        // console.log(request.data.data._id);
        axios.put(`/test/${request.data.data._id}`, {
          questions: data2,
        });
        axios
          .post(
            `/chapter/chaptersequence/${lessonid}`,

            {
              chapter_sequence: [
                {
                  sort_number: '4',
                  test: `${request.data.data._id}`,
                },
              ],
            }
          )
          .then((res) => {
            toast.success(`Quiz Added Successfully!`);
            setTimeout(() => {
              dispatch(setloading(false));
              history.push(`/admin/subject/chapter/quiz/${lessonid}`);
            }, 1000);
          });
      })

      .catch((err) => {
        dispatch(setloading(false));
        toast.error(err.response.data.message);
      });

    // history.goBack();
  } else {
    dispatch(setloading(false));
    toast.error('Data is null! please fill the data.');
  }
}

export function createQuizBackendMCQs(
  data1,
  data2,
  lessonid,
  dispatch,
  history
) {
  dispatch(setloading(true));

  if (data1 !== null && data2 !== null) {
    axios
      .post(`/test`, data1)
      .then((request) => {
        axios.put(`/test/${request.data.data._id}`, {
          questions: data2,
        });
        axios
          .post(
            `/chapter/chaptersequence/${lessonid}`,

            {
              chapter_sequence: [
                {
                  sort_number: '4',
                  test: `${request.data.data._id}`,
                },
              ],
            }
          )
          .then((res) => {
            toast.success(`Quiz Added SuccessFully!`);
            setTimeout(() => {
              dispatch(setloading(false));
              history.push(`/admin/subject/chapter/quiz/${lessonid}`);
            }, 1000);
          });
      })
      .catch((err) => {
        dispatch(setloading(false));
        toast.error(err.response.data.message);
      });

    // history.goBack();
  } else {
    toast.error('Data is null! please fill the data.');
  }
}

export const saveQuiz = async (data) =>
  await backend({
    method: 'POST',
    url: '/test',
    data: data,
  });

export const updateQuiz = async (quizID, data) =>
  await backend({
    method: 'PuT',
    url: `/test/${quizID}`,
    data: data,
  });
  
export const deleteQuiz = async (quizID, data) =>
  await backend({
    method: 'PuT',
    url: `/test/${quizID}`,
    data: data,
  });

export const saveQuizForChapter = async (lessonID, chapter_sequence) =>
  await backend({
    method: 'POST',
    url: `/chapter/chaptersequence/${lessonID}`,
    data: { chapter_sequence },
  });

export const getQuiz = async (quizID) =>
  await backend({
    method: 'GET',
    url: `/test?_id=${quizID}`,
  });

export const getAllQuizzes = async (chapterID) =>
  await backend({
    method: 'GET',
    url: `/test/only?chapter=${chapterID}`,
  });
