import { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "../../api";
import loader from "../../assets/img/loader.svg";
import loaderimg from "../../assets/img/loaderimg.svg";
import { toast } from "react-toastify";
import { saveQuiz, updateQuiz } from "Utils/quiz_Utils";
import { saveQuizForChapter } from "Utils/quiz_Utils";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { editquiz } from "Redux/features/Subject/SubjectSlice";
import Header from "components/Common/Header";
import { getLessonsOfChapter } from "Utils/chapterPreview_Utils";
import { getQuiz } from "Utils/quiz_Utils";
import BreadCrumbs from "components/Common/BreadCrumbs";

const CreateQuiz = () => {
  const location = useLocation();

  const param = useParams();
  console.log("param");
  console.log(param);

  let lessonid = param?.lessonID || param.quizID;

  const [data, setData] = useState(null);
  const [question, setquestion] = useState("");
  const [questionpoints, setquestionpoints] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [sortQuestion, setSortQuestion] = useState(null);

  const [totalMarks, setTotalMarks] = useState(0);
  const [quizName, setQuizName] = useState("");
  const [quizPlan, setQuizPlan] = useState("free");
  const [passingMark, setPassingMark] = useState(0);
  const [loadingImage, setloadingImage] = useState(false);
  const [questionRemarks, setQuestionRemarks] = useState("");
  const [quizRemarks, setQuizRemarks] = useState("");
  const [byDefaultPassingMarks, setByDefaultPassingMarks] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [isQuestionComplete, setIsQuestionComplete] = useState(false);
  const [questionImage, setQuestionImage] = useState(null);
  const [questionsList, setQuestionsList] = useState([]);
  const [fixedQuestionsList, setFixedQuestionsList] = useState([]);
  const [questionStatus, setQuestionStatus] = useState(null);
  const [chapterObj, setChapterObj] = useState("");
  const tableHeader = [
    "Q# No.",
    "Question title",
    "No. of options",
    "Correct answer",
    "Correct answer image",
    "Question points",
    "Actions",
  ];

  const history = useHistory();

  const resetOptions = () => {
    const optionsData = [
      {
        text: "",
        image:
          "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/MicrosoftTeams-image%20%284%29-1640072810081.png",
        correct_answer: false,
      },
      {
        text: "",
        image:
          "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/MicrosoftTeams-image%20%283%29-1640072858419.png",
        correct_answer: false,
      },
    ];
    setOptionsList(optionsData);
  };

  const addMoreOption = () => {
    if (optionsList.length == 4) {
      toast.info("Maximum options limit are 4");
      return;
    }
    const optionsData = {
      text: "",
      image: `${
        optionsList.length == 2
          ? "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/MicrosoftTeams-image%20%282%29-1640072888637.png"
          : "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/MicrosoftTeams-image%20%281%29-1640073004299.png"
      }`,
      correct_answer: false,
    };

    const newOptionsList = [...optionsList, optionsData];
    setOptionsList(newOptionsList);
  };

  const decreaseOption = () => {
    if (optionsList.length == 2) {
      toast.info("Minimum options limit are 2");
      return;
    }

    const [obj, ...rest] = optionsList.reverse();
    setOptionsList(rest.reverse());
  };

  const checkQuestionComplete = () => {
    if (!question || !Boolean(questionpoints)) {
      setIsQuestionComplete(false);
      return;
    }

    const isCorrect = optionsList.filter((e) => Boolean(e.correct_answer));
    const isAnswer = optionsList.filter((e) => Boolean(e.text));

    if (isCorrect != 0 && isAnswer.length == optionsList.length)
      setIsQuestionComplete(true);
    else setIsQuestionComplete(false);
  };

  const fetchLessonsForChapter = async () => {
    setLoading(true);
    try {
      const resp = await getLessonsOfChapter(param.lessonID);
      // const topic = "60eed8c278c1d42f3182bc77";
      const dataObj = {
        chapterID: resp.data.data[0]._id,
        subjectID: resp.data.data[0].subject?._id,
        gradeID: resp.data.data[0].grade,
      };
      setData(dataObj);
      setChapterObj(resp.data.data[0]);
      resetOptions();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const fetchQuizzes = async () => {
    setLoading(true);
    try {
      const resp = await getQuiz(param.quizID);
      const details = await getLessonsOfChapter(resp?.data?.data[0]?.chapter);
      setChapterObj(details.data.data[0]);
      const quizObj = resp.data.data[0];
      const questionsDetail = quizObj.questions.filter((e) => e?._id);

      setSortQuestion(questionsDetail.map((a) => a?._id));

      const dataObj = {
        chapterID: quizObj.chapter,
        subjectID: quizObj.subject,
        quizID: quizObj._id,
        quizStatus: quizObj.isActive,
      };
      setIsEdit(true);
      setQuizName(quizObj?.test_title || "");
      setQuizRemarks(quizObj?.quiz_remarks || "");
      setQuizRemarks(quizObj?.quiz_remarks || "");
      setTotalMarks(quizObj?.total_marks);
      setPassingMark(quizObj?.passing_marks);
      setQuizPlan(quizObj.isPaid === true ? "true" : "false");
      setData(dataObj);

      let questionArray = [];

      questionsDetail.forEach((e) => {
        const optionDetail = e?.options?.map((a) => {
          if (
            a?.text.toLowerCase().trim() ===
            e?.correct_answer.toLowerCase().trim()
          )
            return {
              text: a?.text,
              image: a?.image,
              correct_answer: true,
              id: e?._id,
            };

          return {
            text: a?.text,
            image: a?.image,
            correct_answer: false,
            id: e?._id,
          };
        });

        const questionData = {
          _id: e?._id,
          question_type: e?.question_type,
          question_title: e?.question_title,
          question_image: e?.question_image || null,
          options: optionDetail,
          questionpoints: e?.marks,
          marks: e?.marks,
          answer_image: e?.answer_image,
          correct_answer: e?.correct_answer,
          question_remarks: e?.question_remarks || "",
          isActive: e.isActive,
        };
        questionArray.push(questionData);
      });

      setQuestionsList(questionArray);
      setFixedQuestionsList(questionArray);
      resetOptions();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkQuestionComplete();
  }, [optionsList, question, questionpoints]);

  useEffect(() => {
    if (param.quizID) {
      fetchQuizzes();
      return;
    }

    fetchLessonsForChapter();
  }, []);

  const createMcqsQuiz = async () => {
    if (quizName == "") {
      toast.info("Quiz Name not provided");
      return;
    } else if (passingMark > totalMarks) {
      toast.info("Passing Marks must be smaller or equal to total marks");
      return;
    } else if (passingMark == 0) {
      toast.info("Please provide passing marks");
      return;
    }

    const element = document.getElementById("quizButton");
    element.disabled = true;

    const questionList = questionsList.map((e) => {
      return {
        question_type: e.question_type,
        question_title: e.question_title,
        question_image: e.question_image,
        options: e.options.map((e) => {
          return { text: e.text, image: e.image };
        }),
        correct_answer: e.correct_answer,
        answer_image: e.answer_image,
        marks: e.marks,
        question_remarks: e.question_remarks,
        isActive: isEdit ? e.isActive : true,
      };
    });

    if (isEdit) {
      questionList.map((x, i) => (x._id = questionsList[i]._id));
    }

    const quizObj = {
      subject: data.subjectID,
      chapter: data.chapterID,
      test_title: quizName,
      total_marks: totalMarks,
      passing_marks:
        passingMark != 0 ? passingMark : Math.round(totalMarks / passingMark),
      questions: questionList,
      isActive: isEdit ? data.quizStatus : true,
      isPaid: quizPlan === "true" ? true : false,
      quiz_remarks: quizRemarks,
    };

    if (isEdit) {
      quizObj._id = data.quizID;

      const { questions } = quizObj;
      const questionsList = [];
      sortQuestion.forEach((e) => {
        const questionObj = questions.find((a) => a._id === e);
        questionsList.push(questionObj);
      });

      quizObj.questions = questionsList;
    }

    try {
      if (isEdit) {
        const resp = await updateQuiz(param.quizID, quizObj);
        toast.success(resp.data.message);
        history.goBack();
        // history.push(`/admin/subject/chapter/quiz/${data.chapterID}`);
        return;
      }

      const quizResp = await saveQuiz(quizObj);

      const chapter_sequence = {
        sort_number: "4",
        test: quizResp.data.data._id,
      };

      const resp = await saveQuizForChapter(lessonid, chapter_sequence);
      toast.success(resp.data.message);
      history.goBack();
      // history.push(`/admin/subject/chapter/quiz/${lessonid}`);
    } catch (err) {
      toast.error(err?.response?.message || "Something went wrong");
      console.log(err);
    }
  };

  const totalQuestionMarks = () => {
    if (isEdit) {
      let sum = 0;
      const filteredQuestions = questionsList.filter((a) =>
        Boolean(a.isActive)
      );
      filteredQuestions.forEach((a) => (sum += a.questionpoints));
      return sum;
    }

    let sum = 0;
    questionsList.forEach((a) => (sum += a.questionpoints));
    return sum;
  };

  const deleteQuestion = (index) => {
    if (isEdit) {
      const [...rest] = questionsList;
      rest[index].isActive = !rest[index].isActive;

      let sum = 0;
      const filteredQuestions = questionsList.filter((a) =>
        Boolean(a.isActive)
      );
      filteredQuestions.forEach((a) => (sum += a.questionpoints));
      setTotalMarks(sum);

      if (!byDefaultPassingMarks) setPassingMark(Math.round(sum / 2));

      setQuestionsList(rest);
      return;
    }

    const deleteQuest = questionsList.filter((a, i) => i == index);
    const remainingQuest = questionsList.filter((a, i) => i !== index);

    const total = totalMarks - deleteQuest[0].marks;

    setTotalMarks(Number(total));
    if (!byDefaultPassingMarks) setPassingMark(Math.round(total / 2));

    setQuestionsList(remainingQuest);
  };

  const editQuestion = (questObj, index) => {
    const editQuest = questionsList?.length
    ? questionsList.find((a, i) => i === index)
    : [];
    
    const remainingQuestion = fixedQuestionsList.filter((a, i) => i !== index);
    
    const total = totalMarks - editQuest.marks;
    const passMarks = Math.round(total / 2);
    
    if (!byDefaultPassingMarks) setPassingMark(Number(passMarks));
    
    setTotalMarks(Number(total));
    setquestion(editQuest.question_title);
    setOptionsList(editQuest.options);
    setQuestionRemarks(editQuest.question_remarks);
    setQuestionImage(editQuest.question_image);
    setquestionpoints(editQuest.questionpoints);

    if (isEdit) {
      setQuestionStatus(editQuest.isActive);
    }

    setQuestionsList(remainingQuestion);
    const element = document.getElementById("add_mcq");
    element.scrollIntoView(true, {
      behavior: "smooth",
    });
  };

  const populateQuizBank = (e) => {
    e.preventDefault();

    if (isEdit && !optionsList[0].id) {
      toast.error("You cannot add more question in edit mode");
      return;
    }

    let isSame = false;
    optionsList.forEach((e) => {
      if (!isSame) {
        const duplicate = optionsList.filter(
          (x) => x.text.toLowerCase().trim() === e.text.toLowerCase().trim()
        );
        if (duplicate.length > 1) isSame = true;
        return true;
      }
    });

    if (isSame) {
      toast.error("Answers must be different");
      return;
    }

    const quizData = {
      question_type: "MCQ's",
      question_title: question,
      question_image: questionImage,
      options: optionsList,
      questionpoints: questionpoints,
      marks: questionpoints,
      answer_image: optionsList.find((e) => Boolean(e.correct_answer)).image,
      correct_answer: optionsList.find((e) => Boolean(e.correct_answer)).text,
      question_remarks: questionRemarks ? questionRemarks : "",
    };

    if (isEdit) {
      quizData._id = optionsList[0].id;
      quizData.isActive = questionStatus;
    }

    const newList = [...questionsList, quizData];
    setQuestionsList(newList);

    // if (isEdit) setFixedQuestionsList(newList);
    setFixedQuestionsList(newList);

    let sum = 0;
    newList.forEach((a) => (sum += a.questionpoints));
    setTotalMarks(sum);

    if (!byDefaultPassingMarks) setPassingMark(Math.round(sum / 2));

    resetQuestionData();
  };

  const uploadOptionImage = (imgUpload, index) => {
    setloadingImage(true);
    const formData = new FormData();

    formData.append("files", imgUpload);

    axios
      .post(`/filesupload/file-upload`, formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((resp) => {
        setloadingImage(false);

        if (index !== false) {
          const [...list] = optionsList;
          list[index].image = resp.data.locationArray[0].fileLocation;
          setOptionsList(list);
        } else {
          setQuestionImage(resp.data.locationArray[0].fileLocation);
        }
      })
      .catch((err) => {
        setloadingImage(false);
        toast.error(err.response.data.message);
        return null;
      });
  };

  const onChangeOptionImage = (event, index) => {
    if (event.target.files[0]) uploadOptionImage(event.target.files[0], index);
  };

  const onChangeCorrectAnswer = (index) => {
    const [...list] = optionsList;
    list.map((e, i) => {
      if (i === index) list[index].correct_answer = true;
      else list[i].correct_answer = false;
    });

    setOptionsList(list);
  };

  const onChangeOptionAnswer = (e, index) => {
    const [...list] = optionsList;
    list[index].text = e.target.value;

    setOptionsList(list);
  };

  const resetQuestionData = () => {
    setquestion("");
    setquestionpoints(1);
    setQuestionRemarks("");
    setQuestionImage("");
    resetOptions();

    if (isEdit) setQuestionStatus(null);
  };

  const renderQuizTitleForm = () => (
    <div className="create_quiz-title-from">
      <div className="row">
        <div className="col-md-6 mb-2">
          <p>
            Quiz title <span className="important_field">*</span>
          </p>
          <input
            type="text"
            class="form-control"
            placeholder="Enter quiz title"
            value={quizName}
            onChange={(e) => {
              setQuizName(e.target.value);
            }}
          />
        </div>

        <div className="col-md-6 mb-2">
          <p>Quiz Plan</p>
          <select
            value={quizPlan}
            className="form-control"
            onChange={(e) => {
              setQuizPlan(e.target.value);
            }}
          >
            <option value="false">Free</option>
            <option value="true">Paid</option>
          </select>
        </div>

        <div className="col-md-6 mb-2">
          <p>Total marks</p>
          <input
            type="text"
            class="form-control"
            value={totalMarks}
            disabled
            readOnly
          />
        </div>

        <div className="col-md-6 mb-2">
          <p>
            Passing marks <span className="important_field">*</span>
          </p>
          <input
            type="number"
            class="form-control"
            placeholder="Enter passing marks"
            value={passingMark}
            onChange={(e) => {
              if (e.target.value < 0) {
                toast.error("Enter positive value");
                setByDefaultPassingMarks(false);
                // setPassingMark(Math.round(totalMarks / 2));
                return;
              }
              if (e.target.value == 0) {
                setPassingMark(e.target.value);
                // setPassingMark(Math.round(totalMarks / 2));
                setByDefaultPassingMarks(false);
                return;
              }

              setPassingMark(e.target.value);
              setByDefaultPassingMarks(true);
            }}
          />
        </div>
        <div className="col-md-12 mb-2">
          <p>Quiz Remarks</p>
          <input
            type="text"
            class="form-control"
            placeholder="Enter quiz remarks"
            value={quizRemarks}
            onChange={(e) => {
              setQuizRemarks(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );

  const renderQuizQuestionsFormFields = () => (
    <div className="row">
      <div className="col-md-12">
        <p>
          Question <span style={{ fontSize: "1.2rem", color: "red" }}>*</span>{" "}
        </p>
        <input
          type="text"
          class="form-control"
          id="question"
          placeholder="Enter question"
          value={question}
          required
          onChange={(e) => {
            setquestion(e.target.value);
            checkQuestionComplete();
          }}
        />
      </div>
      <div className="col-md-6 mt-2">
        <p>Question Remarks</p>
        <input
          type="text"
          class="form-control"
          placeholder="Enter question remarks"
          value={questionRemarks}
          onChange={(e) => {
            setQuestionRemarks(e.target.value);
          }}
        />
      </div>
      <div className="col-md-6 mt-2">
        <p>Question Image</p>
        <input
          type="file"
          class="form-control"
          accept="image/jpeg, image/png"
          onChange={(e) => {
            uploadOptionImage(e.target.files[0], false);
          }}
        />
        {loadingImage ? <img src={loaderimg} alt="loading..." /> : null}
      </div>
    </div>
  );

  const renderQuizQuestionsOptionFields = () => (
    <div className="row">
      {optionsList.map((x, i) => (
        <div className="col-md-6 form-field">
          <div className="option-pic">
            <img src={x?.image} alt={i} />
            <input
              type="file"
              accept="image/jpeg, image/png"
              onChange={(e) => {
                onChangeOptionImage(e, i);
              }}
            />
          </div>

          <div className="option-fields">
            <input
              type="radio"
              name="options"
              checked={x?.correct_answer}
              onChange={() => {
                onChangeCorrectAnswer(i);
              }}
            />
            <input
              type="text"
              className="form-control"
              value={x?.text}
              placeholder={`Answer ${i + 1}`}
              onChange={(e) => {
                onChangeOptionAnswer(e, i);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const renderQuestionPoints = () => (
    <div className="create_quiz-question-header-point">
      <button
        className="btn btn-secondary"
        onClick={() => {
          if (questionpoints !== 0) {
            setquestionpoints(parseInt(questionpoints - 1));
          }
        }}
      >
        -
      </button>
      <h1>
        Question Points: {questionpoints}{" "}
        <span style={{ fontSize: "1.2rem", color: "red" }}>*</span>{" "}
      </h1>
      <button
        className="btn btn-secondary"
        onClick={() => {
          setquestionpoints(parseInt(questionpoints + 1));
        }}
      >
        +
      </button>
    </div>
  );

  const renderButtons = () => (
    <div className="buttons">
      <button type="button" onClick={resetQuestionData} class="btn btn-priamry">
        Reset
      </button>

      <button
        type="submit"
        class="btn btn-priamry"
        disabled={!Boolean(isQuestionComplete)}
      >
        {isEdit ? "Update" : "Add"} Question
      </button>

      <button type="button" class="btn btn-priamry" onClick={addMoreOption}>
        Add option +
      </button>

      <button type="button" class="btn btn-priamry" onClick={decreaseOption}>
        Minus option -
      </button>
    </div>
  );

  const renderQuizQuestionsForm = () => (
    <div className="create_quiz-question" id="add_mcq">
      <div className="create_quiz-question-header">
        <h1>{isEdit ? "Update" : "Add"} MCQ</h1>
        {renderQuestionPoints()}
      </div>

      <form className="create_quiz-question-form" onSubmit={populateQuizBank}>
        {renderQuizQuestionsFormFields()}
        {renderQuizQuestionsOptionFields()}
        {renderButtons()}
      </form>
    </div>
  );

  const renderLoader = () => (
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <CardTitle className="loader">
              <img src={loader} alt="Loading...." />
            </CardTitle>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );

  const isQuizComplete = isEdit
    ? questionsList.length === sortQuestion.length
    : quizName && Boolean(questionsList.length);
  return (
    <div className="create_quiz content">
      {loading ? (
        renderLoader()
      ) : (
        <>
          <BreadCrumbs
            breadCrumbList={[
              {
                title: chapterObj?.subject?.Subject_name || "",
                pathName: "/admin/subjects",
              },
              {
                title: chapterObj?.chapter_name || "Chapters",
                pathName: `/admin/subject/${chapterObj?.subject?._id}`,
              },
              {
                title: "Add Quiz",
                pathName: `/admin/subject/chapter/${chapterObj?._id}`,
              },
            ]}
          />
          <Row>
            <Col md={12}>
              <Card>
                <Header title="Create MCQ Quiz" />
                <CardBody>{renderQuizTitleForm()}</CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>{renderQuizQuestionsForm()}</CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader tag="h4">
                  <div className="view-question-header">
                    MCQs (In Progress)
                    <p>Total Marks: {totalQuestionMarks()}</p>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="view_question-list">
                    <div className="view_question-list-table">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {tableHeader.map((a, i) => (
                              <>
                                {isEdit && tableHeader.length - 1 == i ? (
                                  <th scope="col">
                                    <h4>Status</h4>
                                  </th>
                                ) : null}
                                <th scope="col">
                                  <h4>{a}</h4>
                                </th>
                              </>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {questionsList.map((x, i) => (
                            <tr>
                              <td>
                                <p>{i + 1}</p>
                              </td>
                              <td>
                                <p title={x.question_title}>
                                  {x.question_title}
                                </p>
                              </td>
                              <td>
                                <p>{x.options.length}</p>
                              </td>
                              <td>
                                <p>{x.correct_answer}</p>
                              </td>
                              <td>
                                <img src={x?.answer_image} />
                              </td>
                              <td>
                                <p>{x.questionpoints}</p>
                              </td>
                              {isEdit && (
                                <td>
                                  <p>{x.isActive ? "Active" : "In active"}</p>
                                </td>
                              )}
                              <td>
                                <FaPlus
                                  className="cancel-btn"
                                  title={
                                    isEdit ? "Change Status" : "Delete Question"
                                  }
                                  onClick={() => {
                                    deleteQuestion(i);
                                  }}
                                />
                                <FaRegEdit
                                  className="edit-btn"
                                  title="Edit Question"
                                  onClick={() => {
                                    editQuestion(x, i);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <button
                    id="quizButton"
                    class="btn btn-priamry"
                    type="submit"
                    disabled={!isQuizComplete}
                    onClick={createMcqsQuiz}
                  >
                    {isEdit ? "Update Quiz" : "Create Quiz"}
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CreateQuiz;
