import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getRegisteredCoursesPurchaseDetails } from "Utils/registered_Course";
import { toast } from "react-toastify";
import ReportsList from "./ReportsList";
import Loader from "components/Common/Loader";
import DateFilters from "components/Common/DateFilters";
import { setDateRange } from "Utils/helper_Utils";
import { Bar, Line } from "react-chartjs-2";
import { differenceBtw2Dates } from "Utils/helper_Utils";
import { createDateRange } from "Utils/helper_Utils";
import { format } from "date-fns";
import DownloadPDF from "components/Common/DownloadPDF";

const purchaseGradesSubjectsGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Subjects Purchase",
    },
  },
};

const purchaseDateRangeGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Subjects Purchase",
    },
  },

  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const purchaseSubjectsGraphData = {};
const purchaseGradesGraphData = {};
const purchaseDateRangeGraphData = {};
const purchaseSubjectsGraphDataForSpecifiedDate = {};
const purchaseGradesGraphDataForSpecifiedDate = {};

const PurchaseReports = () => {
  const [loading, setLoading] = useState(false);
  const [purchaseList, setPurchaseList] = useState(null);
  const [filteredPurchaseList, setFilteredPurchaseList] = useState(null);
  const [dateObj, setDateObj] = useState(setDateRange());

  const calculateTotalRevenue = (filterBy, purchaseDetails) => {
    let list = null;

    if (!purchaseDetails?.length) return 0;

    if (filterBy === "subject")
      list = purchaseDetails.filter((x) => !x.isBundle);
    else if (filterBy === "bundle")
      list = purchaseDetails.filter((x) => x.isBundle);
    else list = [...purchaseDetails];

    return list.reduce((a, b) => (a += b.amountPaid), 0);
  };

  const createSubjectPurchaseGraph = (purchaseData) => {
    setLoading(true);
    const subjectsGroup =
      purchaseData?.length &&
      purchaseData
        .filter((e) => !e?.isBundle)
        .reduce(function (acc, curr) {
          return (
            acc[`${curr?.subject?.Subject_name}-${curr?.subject?._id}`]
              ? ++acc[`${curr?.subject?.Subject_name}-${curr?.subject?._id}`]
              : (acc[
                  `${curr?.subject?.Subject_name}-${curr?.subject?._id}`
                ] = 1),
            acc
          );
        }, {});

    const gradesGroup = Object.entries(
      [
        ...new Set(
          purchaseData?.length &&
            purchaseData.filter((e) => e?.isBundle).map((e) => e?.student?._id)
        ),
      ]
        .map((e) =>
          purchaseData
            .filter((x) => String(x?.student?._id) === String(e))
            .reduce(function (acc, curr) {
              return (
                acc[`${curr?.grade?.grade}`]
                  ? ++acc[`${curr?.grade?.grade}`]
                  : (acc[`${curr?.grade?.grade}`] = 1),
                acc
              );
            }, {})
        )
        .reduce((a, b) => [...a, ...Object.keys(b)], [])
        .reduce(function (acc, curr) {
          return acc[`${curr}`] ? ++acc[`${curr}`] : (acc[`${curr}`] = 1), acc;
        }, {})
    ).sort((a, b) => b[1] - a[1]);

    const sortSubjectsGroup = Object.entries(subjectsGroup).sort(
      (a, b) => b[1] - a[1]
    );

    purchaseSubjectsGraphData.labels = sortSubjectsGroup
      .map((x) => {
        const data = purchaseData.find(
          (e) =>
            !e?.isBundle &&
            String(e?.subject?._id) === String(x[0].split("-")[1])
        );

        if (!data) return false;

        return `${data?.grade?.grade} - ${data?.subject?.Subject_name} | ${data?.grade?.uniCode}-${data?.subject?.uniCode}`;
      })
      .filter((x) => x);

    purchaseGradesGraphData.labels = gradesGroup.map((x) => x[0]);

    purchaseSubjectsGraphData.datasets = [
      {
        label: "No of purchases",
        data: sortSubjectsGroup?.map((x) => x[1]),
        borderColor: "rgb(101, 112, 235)",
        backgroundColor: "rgb(101, 112, 235)",
        fill: false,
        lineTension: 0,
      },
    ];

    purchaseGradesGraphData.datasets = [
      {
        label: "No of purchases",
        data: gradesGroup?.map((x) => x[1]),
        borderColor: "rgb(213, 101, 235)",
        backgroundColor: "rgb(213, 101, 235)",
        fill: false,
        lineTension: 0,
      },
    ];

    setLoading(false);
  };

  const createDateRangePurchaseGraph = (dateObj, purchaseData) => {
    setLoading(true);

    const dateList = createDateRange(differenceBtw2Dates(dateObj) + 1).sort();

    const arrangeDates = purchaseData.map((e) => {
      e.purchaseDate = format(new Date(e?.purchaseDate), "yyyy-MM-dd");
      return e;
    });

    if (!arrangeDates?.length) return;

    const groupBySubject = Object.entries(
      dateList
        .map((e) =>
          arrangeDates.filter((x) => x?.purchaseDate === e && !x?.isBundle)
        )
        .reduce((a, b) => [...a, ...b], [])
        .reduce(function (acc, curr) {
          return (
            acc[`${curr?.subject?.Subject_name}`]
              ? ++acc[`${curr?.subject?.Subject_name}`]
              : (acc[`${curr?.subject?.Subject_name}`] = 1),
            acc
          );
        }, {})
    ).sort((a, b) => b[1] - a[1]);

    const groupByBundle = dateList.map((e) => {
      const dataPerDate = arrangeDates.filter(
        (x) => x?.purchaseDate === e && x?.isBundle
      );

      if (!dataPerDate?.length) return 0;

      const uniqueStudents = [
        ...new Set(dataPerDate.map((x) => x?.student?._id)),
      ];

      const studentPurchaseBundles = uniqueStudents.map(
        (x) =>
          [
            ...new Set(
              dataPerDate
                .filter((a) => String(a.student?._id) === String(x))
                .map((c) => c?.grade?.grade)
            ),
          ]?.length || 0
      );

      if (!studentPurchaseBundles?.length) return 0;

      return studentPurchaseBundles.reduce((a, b) => (a += b), 0);
    });

    const dataForDates = dateList
      .map((e) =>
        arrangeDates.filter((x) => x?.purchaseDate === e && x?.isBundle)
      )
      .reduce((a, b) => [...a, ...b], []);

    const gradesGroup = Object.entries(
      [...new Set(dataForDates.map((x) => x?.student?._id))]
        .map((e) =>
          dataForDates
            .filter((x) => String(x?.student?._id) === String(e))
            .reduce(function (acc, curr) {
              return (
                acc[`${curr?.grade?.grade}`]
                  ? ++acc[`${curr?.grade?.grade}`]
                  : (acc[`${curr?.grade?.grade}`] = 1),
                acc
              );
            }, {})
        )
        .reduce((a, b) => [...a, ...Object.keys(b)], [])
        .reduce(function (acc, curr) {
          return acc[`${curr}`] ? ++acc[`${curr}`] : (acc[`${curr}`] = 1), acc;
        }, {})
    ).sort((a, b) => b[1] - a[1]);

    purchaseDateRangeGraphData.labels = dateList;
    purchaseSubjectsGraphDataForSpecifiedDate.labels = groupBySubject.map(
      (e) => e[0]
    );
    purchaseGradesGraphDataForSpecifiedDate.labels = gradesGroup.map(
      (e) => e[0]
    );

    purchaseSubjectsGraphDataForSpecifiedDate.datasets = [];
    purchaseGradesGraphDataForSpecifiedDate.datasets = [];
    purchaseDateRangeGraphData.datasets = [];

    purchaseDateRangeGraphData.datasets = [
      {
        label: "Total purchases",
        data: dateList.map((e, i) => {
          return (
            groupByBundle[i] +
            arrangeDates.filter((x) => x?.purchaseDate === e && !x?.isBundle)
              ?.length
          );
        }),
        borderColor: "rgb(7, 19, 99)",
        backgroundColor: "rgb(7, 19, 99)",
        fill: false,
        lineTension: 0,
      },
      {
        label: "Total purchases with subject",
        data: dateList.map(
          (e) =>
            arrangeDates.filter((x) => x?.purchaseDate === e && !x?.isBundle)
              ?.length
        ),
        borderColor: "rgb(235, 52, 125)",
        backgroundColor: "rgb(235, 52, 125)",
        fill: false,
        lineTension: 0,
      },
      {
        label: "Total purchases with bundle",
        data: groupByBundle.map((e) => e),
        borderColor: "rgb(235, 131, 52)",
        backgroundColor: "rgb(235, 131, 52)",
        fill: false,
        lineTension: 0,
      },
    ];

    purchaseSubjectsGraphDataForSpecifiedDate.datasets = [
      {
        label: "# purchases",
        data: groupBySubject.map((e) => e[1]),
        borderColor: "rgb(245, 66, 239)",
        backgroundColor: "rgb(245, 66, 239)",
        fill: false,
        lineTension: 0,
      },
    ];

    purchaseGradesGraphDataForSpecifiedDate.datasets = [
      {
        label: "# purchases",
        data: gradesGroup.map((e) => e[1]),
        borderColor: "rgb(237, 153, 26)",
        backgroundColor: "rgb(237, 153, 26)",
        fill: false,
        lineTension: 0,
      },
    ];

    setLoading(false);
    const filterDateData = dateList
      .map((e) => arrangeDates.filter((x) => x?.purchaseDate === e))
      .filter((x) => x?.length)
      .reduce((a, b) => [...a, ...b], []);

    setFilteredPurchaseList(filterDateData);
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resp = await getRegisteredCoursesPurchaseDetails();

      const isUserExist = resp.data.data.filter((x) => x?.student);
      createSubjectPurchaseGraph(isUserExist);
      createDateRangePurchaseGraph(dateObj, isUserExist);
      setPurchaseList(isUserExist);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
    setLoading(false);
  };

  const submitHandler = (dateObj) => {
    if (!dateObj?.startDate || !dateObj?.endDate) {
      toast.info("Dates Missing");
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    setDateObj(dateObj);
    createDateRangePurchaseGraph(dateObj, purchaseList);
  };

  const refreshHandler = () => {
    const date = setDateRange();
    setDateObj(date);
    createDateRangePurchaseGraph(date, purchaseList);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const timeSpanStyle = {
    color: "#506aeb",
    textDecoration: "underline",
  };

  const renderTimeSpan = (title) => (
    <span style={timeSpanStyle}> {title} </span>
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      <DownloadPDF downloadFileName="Purchase-Grade-Subject-Report" />
      <div className="user_platforms">
        <div className="graph">
          <div className="box">
            <div className="box_card">
              <p>Total Revenue: </p>
              <p>{calculateTotalRevenue("", purchaseList)}</p>
            </div>
            <div className="box_card">
              <p>Total Revenue With Subject:</p>
              <p>{calculateTotalRevenue("subject", purchaseList)}</p>
            </div>
            <div className="box_card">
              <p>Total Revenue With Bundle:</p>
              <p>{calculateTotalRevenue("bundle", purchaseList)}</p>
            </div>
          </div>
          <h3 className="heading">
            Purchase Subject Listing {renderTimeSpan("All time")}
          </h3>
          <ReportsList registeredCoursesList={purchaseList} />

          <hr />
          <div className="gap"></div>
          <div className="downloadPDF">
            <h3 className="heading">
              Purchase Grades Listing {renderTimeSpan("All time")}
            </h3>
            <Bar
              options={{
                ...purchaseGradesSubjectsGraphOptions,
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Grades",
                      },
                    },
                  ],
                },
              }}
              data={purchaseGradesGraphData}
            />
          </div>
          <hr />
          <div className="gap"></div>

          <div className="downloadPDF">
            <h3 className="heading">
              Purchase Subject Listing {renderTimeSpan("All time")}
            </h3>
            <Bar
              options={{
                ...purchaseGradesSubjectsGraphOptions,
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Subjects",
                      },
                    },
                  ],
                },
              }}
              data={purchaseSubjectsGraphData}
            />
          </div>

          <div style={{ margin: "2rem 1rem 1rem" }}>
            <DateFilters
              submitHandler={submitHandler}
              refreshHandler={refreshHandler}
            />
          </div>

          <hr />
          <div className="gap"></div>
          <div className="downloadPDF">
            <div className="box">
              <div className="box_card">
                <p>Total Revenue: </p>
                <p>{calculateTotalRevenue("", filteredPurchaseList)}</p>
              </div>
              <div className="box_card">
                <p>Total Revenue With Subject:</p>
                <p>{calculateTotalRevenue("subject", filteredPurchaseList)}</p>
              </div>
              <div className="box_card">
                <p>Total Revenue With Bundle:</p>
                <p>{calculateTotalRevenue("bundle", filteredPurchaseList)}</p>
              </div>
            </div>
            <h3 className="heading">Purchases Per Day</h3>
            <Line
              options={purchaseDateRangeGraphOptions}
              data={purchaseDateRangeGraphData}
            />
          </div>

          <hr />
          <div className="gap"></div>

          <div className="downloadPDF">
            <h3 className="heading">
              Purchase Subject Listing {renderTimeSpan("Specified time")}
            </h3>
            <Bar
              options={{
                ...purchaseGradesSubjectsGraphOptions,
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Subjects",
                      },
                    },
                  ],
                },
              }}
              data={purchaseSubjectsGraphDataForSpecifiedDate}
            />
          </div>

          <hr />
          <div className="gap"></div>

          <div className="downloadPDF">
            <h3 className="heading">
              Purchase Grade Listing {renderTimeSpan("Specified time")}
            </h3>
            <Bar
              options={{
                ...purchaseGradesSubjectsGraphOptions,
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Grades",
                      },
                    },
                  ],
                },
              }}
              data={purchaseGradesGraphDataForSpecifiedDate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

PurchaseReports.propTypes = {};

export default PurchaseReports;
