import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import axios from "../../api";
import { useHistory, useLocation } from "react-router-dom";
import ChapterCard from "components/ChapterCard/ChapterCard";
import { selectRegisteredCourses } from "Redux/features/Student/StudentSlice";
import { setregisteredCourses } from "Redux/features/Student/StudentSlice";
import loader from "../../assets/img/loader.svg";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import StudentDetails from "components/Student/StudentDetails";
import BreadCrumbs from "components/Common/BreadCrumbs";
const SpecificStudent = (props) => {
  const loading = useSelector(selectLoading);
  const history = useHistory();
  const dispatch = useDispatch();
  const Currentstudent = useSelector(selectRegisteredCourses);
  const location = useLocation();
  const studentId = location.pathname.split("/")[3];
  console.log(studentId);
  console.log(Currentstudent);
  var pop_cus = [
    {
      path: "history.chapter",
    },

    {
      path: "history.test",
    },

    {
      path: "student",
    },
    {
      path: "subject ",
    },

    {
      path: "grade",
    },
    {
      path: "history.test.chapter",
    },

    {
      path: "subject.grade",
    },
  ];
  const pop_course = encodeURI(JSON.stringify(pop_cus));

  function fetchRegisteredCourses() {
    dispatch(setloading(true));
    axios
      .get(`/courseregistration?student=${studentId}&populate=${pop_course}`)
      .then((res) => {
        dispatch(setregisteredCourses(res.data.data));
        setTimeout(() => {
          dispatch(setloading(false));
        }, 1000);
      })
      .catch((err) => {
        dispatch(setloading(false));
        toast.error(err);
      });
  }

  useEffect(() => {
    fetchRegisteredCourses();
  }, []);

  // console.log("Current Student ", Currentstudent);
  return (
    <>
      {!loading ? (
        <div className="content">
          <BreadCrumbs
            breadCrumbList={[
              {
                title: Currentstudent?.length
                  ? Currentstudent[0]?.student?.user_name
                    ? Currentstudent[0]?.student?.user_name
                    : `${Currentstudent[0]?.student?.first_name} ${Currentstudent[0]?.student?.first_name}`
                  : "-",
                pathName: "/admin/students",
              },
              {
                title: "Profile Details",
                pathName: `/admin/students/${studentId}`,
              },
            ]}
          />

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <i
                          class="fa fa-arrow-circle-left"
                          style={{ fontSize: "36px", cursor: "pointer" }}
                          onClick={() => {
                            history.goBack();
                          }}
                        ></i>{" "}
                        <h4 style={{ textAlign: "center", marginLeft: "10px" }}>
                          {Currentstudent.length > 0
                            ? Currentstudent[0]?.student?.first_name
                            : "No Course Registered "}{" "}
                          {Currentstudent.length > 0
                            ? Currentstudent[0]?.student?.last_name
                            : null}
                        </h4>
                      </div>
                      {/* <h4 style={{ textDecoration: 'uppercase' }}>
                        {Currentstudent.length > 0
                          ? Currentstudent[0]?.student?.current_course_plan
                            ? Currentstudent[0]?.student?.current_course_plan
                            : 'premium'
                          : null}
                      </h4> */}
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {Currentstudent?.length > 0 && (
                    <StudentDetails registeredCourses={Currentstudent} />
                  )}

                  {Currentstudent.map((sub) => {
                    return (
                      <ChapterCard
                        grade={sub?.grade?.grade}
                        history={sub?.history}
                        registration_date={sub?.registration_date}
                        Subject_name={sub?.subject?.Subject_name}
                        Subject={sub?.subject}
                        isPurchase={sub?.isPurchase}
                        isBundle={sub?.isBundle}
                        coursePlan={sub?.student?.current_course_plan}
                        purchaseDate={
                          sub?.purchaseDate ? sub?.purchaseDate : ""
                        }
                        expiryDate={sub?.expireDate ? sub?.expireDate : ""}
                        amountPaid={sub?.amountPaid}
                        regCourseId={sub?._id}
                        gradePlan={sub?.grade?.course_plan}
                        fetchRegisteredCourses={fetchRegisteredCourses}
                      />
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

// const mapStateToProps = (state, ownProps) => {
//   let studentID = ownProps.match.params.studentID;

//   return {
//     studentID: studentID,
//     student: state.Student.students.find((std) => std._id == studentID),
//     studentHistory: state.Student.registeredCourses,

//   };
// };
// const mapDispatchToProps = (dispatch) => {
//   return {
//     setHistory: (data) => dispatch(actions.setHistory(data)),
//   };
// };

export default SpecificStudent;
