import PropTypes from "prop-types";
import { useContext } from "react";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import MaterialTable from "material-table";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

const UniCodeList = ({ dataList, renderView }) => {
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();
  // const userData = useSelector(selectUserData);

  const renderCellValue = (value) => (
    <div className="name">
      <h1>{value}</h1>
    </div>
  );

  const renderUniCodes = (value) => (
    <div className="uniCode_value">
      <h1>{value}</h1>
    </div>
  );

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  return (
    <div className="uniCode_wrapper-list">
      {dataList?.length ? (
        <MaterialTable
          icons={tableIcons}
          title={null}
          key={Math.floor(Math.random() * 1000000)}
          columns={[
            {
              title: "Grade",
              field: "gradeName",
              render: (rowData) => renderCellValue(rowData?.gradeName),
            },
            {
              title: "UniCode",
              field: "gradeUniCode",
              render: (rowData) => renderUniCodes(rowData?.gradeUniCode),
            },
            {
              title: "Subject",
              field: "subjectName",
              render: (rowData) => renderCellValue(rowData?.subjectName),
            },
            {
              title: "UniCode",
              field: "subjectUniCode",
              render: (rowData) => renderUniCodes(rowData?.subjectUniCode),
            },
            {
              title: "Chapter",
              field: "chapterName",
              render: (rowData) => renderCellValue(rowData?.chapterName),
            },
            {
              title: "UniCode",
              field: "chapterUniCode",
              render: (rowData) => renderUniCodes(rowData?.chapterUniCode),
            },
            {
              title: "Topic",
              field: "topicName",
              render: (rowData) => renderCellValue(rowData?.topicName),
            },
            {
              title: "UniCode",
              field: "topicUniCode",
              render: (rowData) => renderUniCodes(rowData?.topicUniCode),
            },
          ]}
          data={
            dataList?.length > 0 &&
            dataList.map((e) => {
              return {
                // index: ++count,
                gradeName:
                  renderView === "topic"
                    ? e?.grade?.grade
                    : e?.subject?.grade?.grade,
                gradeUniCode:
                  renderView === "topic"
                    ? e?.grade?.uniCode
                    : e?.subject?.grade?.uniCode,
                subjectName: e?.subject?.Subject_name,
                subjectUniCode: e?.subject?.uniCode,
                chapterName: e?.chapter?.chapter_name,
                chapterUniCode: e?.chapter?.uniCode,
                topicName:
                  renderView === "topic" ? e?.topic_title : e?.test_title,
                topicUniCode: e?.uniCode,
              };
            })
          }
          options={{
            // actionsColumnIndex: -1,
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 30, 50, dataList?.length],
            headerStyle: {
              backgroundColor: colorsList.find((e) => e.color === color).value,
              color: "#FFF",
            },
            filtering: true,
            search: false,
          }}
        />
      ) : (
        <h1>Record not Found </h1>
      )}
    </div>
  );
};

UniCodeList.propTypes = {
  dataList: PropTypes.array.isRequired,
  renderView: PropTypes.string.isRequired,
};

export default UniCodeList;
