import backend from "../api";

export const saveTeacherPayment = async (paymentObj) => await backend({
    method: "POST",
    url: "/teacher/payments",
    data: paymentObj
})

export const getTeacherPayments = async (teacherID) => await backend({
    method: "GET",
    url: teacherID ? `/teacher/payments?teacherID=${teacherID}` : "/teacher/payments",
})

export const updateTeacherPayment = async (paymentObj) => await backend({
    method: "PUT",
    url: `/teacher/payments/${paymentObj?._id}`,
    data: paymentObj
})

export const updateTeacherPaymentStatus = async (paymentObj) => await backend({
    method: "PUT",
    url: `/teacher/payments/status/${paymentObj?._id}`,
    data: paymentObj
})

export const changeTeacherPaymentView = async (paymentObj) => await backend({
    method: "PUT",
    url: `/teacher/payments/${paymentObj?._id}`,
    data: paymentObj
})