import React, { useEffect, useState } from "react";
import { FaBan, FaCheckCircle, FaRegEdit } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import axios from "../../api";
import { Link, useHistory, useLocation } from "react-router-dom";
import { selectquizes } from "Redux/features/Subject/SubjectSlice";
import { setQuizes } from "Redux/features/Subject/SubjectSlice";
import { editquizesStatus } from "Redux/features/Subject/SubjectSlice";
import loader from "../../assets/img/loader.svg";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { UpdateStatusQuizBackend } from "Utils/quiz_Utils";
const Quiz = () => {
  const loading = useSelector(selectLoading);
  const history = useHistory();
  const location = useLocation();
  let lessonID = location.pathname.split("/")[5];
  console.log(lessonID);
  const quizList = useSelector(selectquizes);
  console.log(quizList);
  const dispatch = useDispatch();

  useEffect(() => {
    function fetchQuiz() {
      axios
        .get(`/test/question?chapter=${lessonID}`)
        .then((res) => {
          dispatch(setQuizes(res.data.data));
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    dispatch(setloading(true));
    fetchQuiz();
  }, []);
  // console.log(props.quizList);
  const [quizModes, setquizModes] = useState(0);
  const [questionindex, setquestionindex] = useState(0);
  const [quizes, setquizes] = useState("");
  const [quizName, setquizName] = useState(0);
  const [activity, setactivity] = useState("");

  if (quizList.length > 0) {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader style={{ alignItems: "center" }}>
                    <CardTitle
                      tag="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <i
                        class="fa fa-arrow-circle-left"
                        style={{ fontSize: "36px", cursor: "pointer" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{" "}
                      <h4 style={{ marginLeft: "10px" }}>Quiz</h4>
                    </CardTitle>
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                        <button
                          className="btn btn-priamry"
                          onClick={() => setquizModes(1)}
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          <Link
                            to={`/admin/subject/chapter/${lessonID}`}
                            style={{ color: "white", fontWeight: "bold" }}
                          >
                            Add Quiz
                          </Link>
                        </button>
                        {/* <button
                          className="btn btn-priamry"
                          onClick={() => setquizModes(2)}
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          <Link
                            to={`/admin/subject/chapter/truefalse/${lessonID}`}
                            style={{ color: "white", fontWeight: "bold" }}
                          >
                            Add True/False Quiz
                          </Link>
                        </button> */}
                        <div class="dropdown">
                          <a
                            class="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: "220px" }}
                          >
                            {activity === ""
                              ? "Select Active/Inactive"
                              : activity}
                          </a>

                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              class="dropdown-item"
                              onClick={() => {
                                setactivity("Active");
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              class="dropdown-item"
                              onClick={() => {
                                setactivity("InActive");
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              class="dropdown-item"
                              onClick={() => {
                                setactivity("");
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div style={{ width: "90%", margin: "3%" }}>
                      <div style={{ width: "100%" }}>
                        <table
                          id="dtBasicExample"
                          class="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr style={{ textAlign: "center" }}>
                              <th class="th-sm">Name</th>
                              <th class="th-sm">Marks</th>
                              <th class="th-sm">Type</th>
                              <th class="th-sm">Status</th>
                              <th class="th-sm">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quizList.map((quiz, index) => {
                              if (quiz.chapter == lessonID && activity == "") {
                                return (
                                  <tr
                                    key={index}
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <td style={{ display: "flex" }}>
                                      {/* <input
                                    type="text"
                                    value={index}
                                    style={{
                                      padding: "0px 5px",
                                      width: "20px",
                                      marginRight: "10px",
                                    }}
                                    onChange={(e) => {
                                      setquestionindex(e.target.value);
                                    }}
                                  /> */}
                                      {quiz.test_title}
                                    </td>
                                    <td>{quiz.total_marks}</td>
                                    <td>
                                      {quiz.questions.length > 0 &&
                                        quiz.questions[0] !== null
                                        ? quiz.questions[0].question_type
                                        : "-"}
                                    </td>

                                    <td>
                                      {quiz.isActive ? (
                                        <p style={{ color: "green" }}>Active</p>
                                      ) : (
                                        <p style={{ color: "red" }}>Inactive</p>
                                      )}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginLeft: "30%",
                                        }}
                                      >
                                        <Link
                                          // style={{ display: "none" }}
                                          // This one
                                          to={`/admin/subject/chapter/specificquiz/${quiz._id}`}
                                        >
                                          <FaRegEdit
                                            style={{
                                              fontSize: "18px",
                                              color: "#008B8B",
                                              marginRight: "20px",
                                            }}
                                            onClick={() => {
                                              // setquizName(quiz.test_title);
                                              // setquizModes(2);
                                            }}
                                          />
                                        </Link>

                                        {!quiz.isActive ? (
                                          <FaBan
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                            onClick={() => {
                                              // setStatus(quiz._id, !quiz.isActive);
                                              UpdateStatusQuizBackend(
                                                quiz._id,
                                                quiz.isActive,
                                                dispatch
                                              );
                                            }}
                                          />
                                        ) : (
                                          <FaCheckCircle
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                            onClick={() => {
                                              // setStatus(quiz._id, !quiz.isActive);
                                              UpdateStatusQuizBackend(
                                                quiz._id,
                                                quiz.isActive,
                                                dispatch
                                              );
                                            }}
                                          />
                                        )}
                                        <Link
                                          // style={{ display: "none" }}
                                          to={`/admin/subject/chapter/quiz/${lessonID}/${quiz._id}`}
                                        >
                                          <i
                                            style={{
                                              color: "green",
                                              fontSize: "18px",
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                            }}
                                            class="fa fa-bars"
                                            aria-hidden="true"
                                            onClick={() => {
                                              // console.log("panel");
                                              // setquizModes(4);
                                            }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              } else if (
                                quiz.chapter == lessonID &&
                                activity == "Active" &&
                                quiz.isActive
                              ) {
                                return (
                                  <tr
                                    key={index}
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <td style={{ display: "flex" }}>
                                      {/* <input
                                    type="text"
                                    value={index}
                                    style={{
                                      padding: "0px 5px",
                                      width: "20px",
                                      marginRight: "10px",
                                    }}
                                    onChange={(e) => {
                                      setquestionindex(e.target.value);
                                    }}
                                  /> */}
                                      {quiz.test_title}
                                    </td>
                                    <td>{quiz.total_marks}</td>
                                    <td>
                                      {quiz.questions.length > 0 &&
                                        quiz.questions[0] !== null
                                        ? quiz.questions[0].question_type
                                        : "-"}
                                    </td>

                                    <td>
                                      {quiz.isActive ? (
                                        <p style={{ color: "green" }}>Active</p>
                                      ) : (
                                        <p style={{ color: "red" }}>Inactive</p>
                                      )}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginLeft: "30%",
                                        }}
                                      >
                                        <Link
                                          style={{ display: "none" }}
                                          to={`/admin/subject/chapter/specificquiz/${quiz._id}`}
                                        >
                                          <FaRegEdit
                                            style={{
                                              fontSize: "18px",
                                              color: "#008B8B",
                                              marginRight: "10px",
                                            }}
                                            onClick={() => {
                                              // setquizName(quiz.test_title);
                                              // setquizModes(2);
                                            }}
                                          />
                                        </Link>

                                        {!quiz.isActive ? (
                                          <FaBan
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                            onClick={() => {
                                              // setStatus(quiz._id, !quiz.isActive);
                                              UpdateStatusQuizBackend(
                                                quiz._id,
                                                quiz.isActive,
                                                dispatch
                                              );
                                            }}
                                          />
                                        ) : (
                                          <FaCheckCircle
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                            onClick={() => {
                                              // setStatus(quiz._id, !quiz.isActive);
                                              UpdateStatusQuizBackend(
                                                quiz._id,
                                                quiz.isActive,
                                                dispatch
                                              );
                                            }}
                                          />
                                        )}
                                        <Link
                                          to={`/admin/subject/chapter/quiz/${lessonID}/${quiz._id}`}
                                        >
                                          <i
                                            style={{
                                              color: "green",
                                              fontSize: "18px",
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                            }}
                                            class="fa fa-bars"
                                            aria-hidden="true"
                                            onClick={() => {
                                              // console.log("panel");
                                              // setquizModes(4);
                                            }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              } else if (
                                quiz.chapter == lessonID &&
                                activity == "InActive" &&
                                !quiz.isActive
                              ) {
                                return (
                                  <tr
                                    key={index}
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <td style={{ display: "flex" }}>
                                      {/* <input
                                    type="text"
                                    value={index}
                                    style={{
                                      padding: "0px 5px",
                                      width: "20px",
                                      marginRight: "10px",
                                    }}
                                    onChange={(e) => {
                                      setquestionindex(e.target.value);
                                    }}
                                  /> */}
                                      {quiz.test_title}
                                    </td>
                                    <td>{quiz.total_marks}</td>
                                    <td>
                                      {quiz.questions.length > 0 &&
                                        quiz.questions[0] !== null
                                        ? quiz.questions[0].question_type
                                        : "-"}
                                    </td>

                                    <td>
                                      {quiz.isActive ? (
                                        <p style={{ color: "green" }}>Active</p>
                                      ) : (
                                        <p style={{ color: "red" }}>Inactive</p>
                                      )}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginLeft: "30%",
                                        }}
                                      >
                                        <Link
                                          style={{ display: "none" }}
                                          to={`/admin/subject/chapter/specificquiz/${quiz._id}`}
                                        >
                                          <FaRegEdit
                                            style={{
                                              fontSize: "18px",
                                              color: "#008B8B",
                                              marginRight: "10px",
                                            }}
                                            onClick={() => {
                                              // setquizName(quiz.test_title);
                                              // setquizModes(2);
                                            }}
                                          />
                                        </Link>

                                        {!quiz.isActive ? (
                                          <FaBan
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                            onClick={() => {
                                              // setStatus(quiz._id, !quiz.isActive);
                                              UpdateStatusQuizBackend(
                                                quiz._id,
                                                quiz.isActive,
                                                dispatch
                                              );
                                            }}
                                          />
                                        ) : (
                                          <FaCheckCircle
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                            onClick={() => {
                                              // setStatus(quiz._id, !quiz.isActive);
                                              UpdateStatusQuizBackend(
                                                quiz._id,
                                                quiz.isActive,
                                                dispatch
                                              );
                                            }}
                                          />
                                        )}
                                        <Link
                                          to={`/admin/subject/chapter/quiz/${lessonID}/${quiz._id}`}
                                        >
                                          <i
                                            style={{
                                              color: "green",
                                              fontSize: "18px",
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                            }}
                                            class="fa fa-bars"
                                            aria-hidden="true"
                                            onClick={() => {
                                              // console.log("panel");
                                              // setquizModes(4);
                                            }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader
                    tag="h4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                  </CardHeader>
                  <CardTitle>
                    <div >
                      <h4 style={{ marginLeft: "1vw" }}>No Data Found Yet</h4>
                      <div style={{ marginLeft: "66%", display: 'flex', alignItems: 'end' }}>
                        <button
                          className="btn btn-priamry"
                          onClick={() => setquizModes(1)}
                          style={{ border: "0px", fontSize: "14px", display: "flex", justifyContent: "center" }}
                        >
                          {" "} 
                          <Link
                            to={`/admin/subject/chapter/${lessonID}`}
                            style={{ color: "white", fontWeight: "bold", whiteSpace: "nowrap" }}
                          >
                            Add Quiz
                          </Link>
                        </button>
                        {/* <button
                          className="btn btn-priamry"
                          onClick={() => setquizModes(2)}
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          <Link
                            to={`/admin/subject/chapter/truefalse/${lessonID}`}
                            style={{ color: "white", fontWeight: "bold" }}
                          >
                            Add True/False Quiz
                          </Link>
                        </button> */}
                        <div class="dropdown">
                          <a
                            class="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: "220px" }}
                          >
                            {activity === ""
                              ? "Select Active/Inactive"
                              : activity}
                          </a>

                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              class="dropdown-item"
                              onClick={() => {
                                setactivity("Active");
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              class="dropdown-item"
                              onClick={() => {
                                setactivity("InActive");
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              class="dropdown-item"
                              onClick={() => {
                                setactivity("");
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardTitle>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
};

export default Quiz;
