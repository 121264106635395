import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import axios from "../api";
import backend from "../api";
import { Link } from "react-router-dom";
import { settopic } from "Redux/features/Subject/SubjectSlice";
import { selecttopics } from "Redux/features/Subject/SubjectSlice";
import { edittopicStatus } from "Redux/features/Subject/SubjectSlice";
import { edittopic } from "Redux/features/Subject/SubjectSlice";
import { setAllQuizzesList } from "Redux/features/Quiz/QuizSlice";
import loader from "../assets/img/loader.svg";
import { ToastContainer, toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import {
  updateStatusBackend,
  updateTopicBackend,
} from "Utils/chapterPreview_Utils";
import ModalContainer from "../components/Common/ModalContainer";
import { FaPlayCircle } from "react-icons/fa";
import TopicList from "./TopicList";
import Header from "components/Common/Header";
import Quiz from "components/Quiz/Quiz";
import QuizWrapper from "components/Quiz/QuizWrapper";
import ChapterSequence from "components/ChapterSequence/ChapterSequence";
import { selectIsQuizTab } from "Redux/features/Quiz/QuizSlice";
import { setIsQuizTab } from "Redux/features/Quiz/QuizSlice";
import ChapterSequenceWrapper from "components/ChapterSequence/ChapterSequenceWrapper";
import BreadCrumbs from "components/Common/BreadCrumbs";

function ChapterPreview() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  let lessonid = location.pathname.split("/")[5];
  console.log(lessonid);
  let topicsList = useSelector(selecttopics);
  const loading = useSelector(selectLoading);
  const isQuizTab = useSelector(selectIsQuizTab);
  const [loadingImage, setloadingImage] = useState(false);
  const [toggle, settoggle] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [videoObj, setVideoObj] = useState(null);
  const [descriptionList, setDescriptionList] = useState(null);
  const [tabValue, setTabValue] = useState("topics");

  const handleOnClose = () => {
    setIsOpenModal(false);
    setVideoObj(null);
    setDescriptionList(null);
  };

  useEffect(() => {
    var pop_cus = [
      {
        path: "subject",
        select: "Subject_name uniCode",
      },
      {
        path: "grade",
        select: "grade uniCode",
      },
      {
        path: "chapter",
        select: "chapter_name uniCode",
      },
    ];
    // {
    //   path: 'chapter'
    // },
    // {
    //   select: '-topic_detail'
    // }

    const pop_course = encodeURI(JSON.stringify(pop_cus));
    async function fetchTopicList() {
      backend
        .get(`/chapter/topic/?chapter=${lessonid}&populate=${pop_course}`)
        .then((res) => {
          dispatch(settopic(res.data.data));
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    dispatch(setloading(true));
    fetchTopicList();
    dispatch(setAllQuizzesList(null));

    if (!isQuizTab) {
      setTabValue("topics");
      return;
    }

    setTabValue("quiz");
    dispatch(setIsQuizTab(false));
  }, []);
  function toggler(index) {
    if (toggle) {
      document.getElementById(index).style.display = "block";
    } else {
      document.getElementById(index).style.display = "none";
    }
  }
  // console.log(topicList, "topicList");

  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [TopicId, setTopicId] = useState("");
  const [TopicName, setTopicName] = useState("");
  const [Topicdescription, setTopicdescription] = useState("");
  const [youtubeId, setyoutubeId] = useState("bfmFfD2RIcg");
  const [vemioId, setvemioId] = useState("");
  const [topicIcon, settopicIcon] = useState([]);
  const [videoType, setvideoType] = useState("");
  const [TopicVideoId, setTopicVideoId] = useState("");
  const [addtopicIcon, setaddtopicIcon] = useState("");
  const [Topicyoutubeid, setyoutubeid] = useState("");
  const [TopicVimeourl, setTopicVimeourl] = useState("");
  const [activity, setactivity] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [modalTopic, setModalTopic] = useState({});
  const [addValues, setAddValues] = useState({ name: "", type: "lesson" });
  const [image, setimage] = useState("");
  const params = useParams();
  const [mode, setMode] = useState(1);
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };
  const [videoHolder, setvideoHolder] = useState([]);
  const [videoPer, setvideoPer] = useState("");
  const [videoUri, setvideoUri] = useState("");
  const videoHandler = (e) => {
    videoHolder.push(e.target.files[0]);
    // console.log(videoHolder);
    // uploadVideo();
  };
  let video_link = `https://player.vimeo.com/video/${vemioId}?title=0&byline=0&portrait=0`;
  const uploadVideo = () => {
    client.upload(
      videoHolder[0],
      function (uri) {
        let respUri = uri.split("/");
        respUri = respUri[2];
        setvemioId(respUri);
        setTopicVideoId(respUri);
        // console.log(vemioId);
        video_link = `https://player.vimeo.com/video/${TopicVideoId}?title=0&byline=0&portrait=0`;
        // console.log(
        //   "File upload completed. Your Vimeo URI is:",
        //   uri,
        //   uri.split("/")
        // );
      },
      function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setvideoPer(percentage);
        // console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      function (error) {
        // console.log("Failed because: " + error);
      }
    );
    setvideoHolder([]);
  };
  let Vimeo = require("vimeo").Vimeo;

  let client = new Vimeo(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_CLIENT_SECRET,
    process.env.REACT_APP_ACCESS_TOKEN
  );

  //reset State
  const resetState = () => {
    setTopicName("");
    setTopicdescription("");
    settopicIcon([]);
    setvideoType("");
    setTopicVideoId("");
    setaddtopicIcon("");
  };

  const handleEditTopic = () => {
    let topicToEdit = {
      _id: modalTopic._id,
      topic_title: TopicName,
      topic_icon: addtopicIcon,
      topic_detail: Topicdescription,
      vedio_type: videoType,
      topic_video: TopicVideoId,
      awarded_points: modalTopic.awarded_points,
      isActive: modalTopic.isActive,
    };
    // console.log(topicToEdit, "Topic To Edit");
    // props.updateTopic(modalTopic._id, topicToEdit);
    updateTopicBackend(lessonid, modalTopic._id, topicToEdit, dispatch);
    //remove null from backedn and shift to redux
  };

  async function uploadImage() {
    setloadingImage(true);
    const formData = new FormData();

    formData.append("files", topicIcon[0]);

    axios
      .post(`/filesupload/file-upload`, formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        setaddtopicIcon(res.data.locationArray[0].fileLocation);
        setTimeout(() => {
          setloadingImage(false);
        }, 1000);
      })
      .catch((err) => {
        setloadingImage(false);
        toast.error(err.response.data.message);
      });
  }
  const changeHandler = (event) => {
    topicIcon.push(event.target.files[0]);
    uploadImage();
  };

  const onChangeTabs = (value) => setTabValue(value);

  const subjectID = window.localStorage.getItem("subjectID");
  // if (topicsList.length > 0) {
  return (
    <>
      {!loading ? (
        <div className="chapter-preview content">
          <BreadCrumbs
            breadCrumbList={[
              {
                title: topicsList[0]?.subject?.Subject_name || "Subjects",
                pathName: "/admin/subjects",
              },
              {
                title: topicsList[0]?.chapter?.chapter_name || "Chapter",
                pathName: `/admin/subject/${topicsList[0]?.subject?._id}`,
              },
              {
                title: "Chapter Detail",
                pathName: `/admin/subject/chapter/chapterdetails/${lessonid}`,
              },
            ]}
          />
          <Row
            lg={12}
            // style={{
            //   width: "100%",
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "space-between",
            //   flexDirection: "row",
            // }}
          >
            {/* <Col lg={6}>
                <h1>
                  {' '}
                  <i
                    className="fa fa-arrow-circle-left"
                    style={{ fontSize: '36px', cursor: 'pointer' }}
                    onClick={() => {
                      history.goBack();
                    }}
                  ></i>{' '}
                  Chapter Preview
                </h1>
              </Col> */}

            {/* <Col lg={6}>
                <Link
                  to={`/admin/subject/chapter/chapterdetails/${lessonid}/add`}
                >
                  <button
                    className="btn btn-priamry"
                    style={{ width: 'fit-content' }}
                    onClick={() => {
                      resetState();
                      setAddModal(true);
                    }}
                  >
                    Add Topic
                  </button>
                </Link>
                <Link to={`/admin/subject/chapter/${lessonid}/chaptersequence`}>
                  <button
                    className="btn btn-priamry"
                    style={{
                      border: '0px',
                      fontSize: '14px',
                      width: 'fit-content',
                    }}
                  >
                    {' '}
                    Manage Topic/Quiz Sequence
                  </button>
                </Link>
                <div className="dropdown">
                  <a
                    className="btn btn-secondary dropdown-toggle"
                    role="button"
                    id="dropdownMenuLinkGrade"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      // marginLeft: ".5%",
                      width: 'fit-content',
                    }}
                  >
                    {activity === '' ? 'Select Active/Inactive' : activity}
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLinkGrade"
                  >
                    <a
                      style={{ cursor: 'pointer' }}
                      className="dropdown-item"
                      onClick={() => {
                        setactivity('Active');
                        // setcoursePlan("premium");
                      }}
                    >
                      Active
                    </a>
                    <a
                      style={{ cursor: 'pointer' }}
                      className="dropdown-item"
                      onClick={() => {
                        setactivity('InActive');
                        // setcoursePlan("freemium");
                      }}
                    >
                      InActive
                    </a>
                    <a
                      style={{ cursor: 'pointer' }}
                      className="dropdown-item"
                      onClick={() => {
                        setactivity('');
                        // setcoursePlan("freemium");
                      }}
                    >
                      None
                    </a>
                  </div>
                </div>
              </Col> */}
          </Row>

          <Modal
            visible={statusModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setStatusModal(false);
              // window.location.reload();
            }}
          >
            <div
              className="modal-container"
              style={{ padding: "3%", textAlign: "center" }}
            >
              <h4 className="mb-4" style={{ color: "#242b3b" }}>
                Change Status
              </h4>

              {modalTopic.isActive ? (
                <p style={{ fontSize: "20px" }}>
                  Are you sure you want to restrict : {TopicName}?
                </p>
              ) : (
                <p style={{ fontSize: "20px" }}>
                  Are you sure you want to change this Topic's status to active?
                </p>
              )}
              <div
                className="w-100 d-flex justify-content-between mt-4"
                style={{ padding: "2%", paddingBottom: "3%" }}
              >
                <button
                  className="btn btn-danger"
                  onClick={() => setStatusModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-warning"
                  onClick={() => {
                    // console.log(modalTopic);
                    // props.changeTopicStatus(modalTopic._id, !modalTopic.isActive);
                    updateStatusBackend(
                      modalTopic._id,
                      modalTopic.isActive,
                      dispatch
                    );
                    // remove when null removed from backend and connect redux

                    setStatusModal(false);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal>

          <Card>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <Header
                    title="Topics"
                    location={`/admin/subject/${subjectID || null}`}
                  />
                </Col>
                <Col lg={6} className="text-right">
                  <Link
                    to={`/admin/topic/new`}
                  >
                    <button
                      className="btn btn-priamry"
                      style={{ width: "fit-content" }}
                    >
                      Add Topic New
                    </button>
                  </Link>
                  <Link
                    to={`/admin/subject/chapter/chapterdetails/${lessonid}/add`}
                  >
                    <button
                      className="btn btn-priamry"
                      style={{ width: "fit-content" }}
                      onClick={() => {
                        resetState();
                        setAddModal(true);
                      }}
                    >
                      Add Topic
                    </button>
                  </Link>
                  {/* <Link to={`/admin/subject/chapter/${lessonid}/chaptersequence`}>
                <button
                  className="btn btn-priamry"
                  style={{
                    border: '0px',
                    fontSize: '14px',
                    width: 'fit-content',
                  }}
                >
                  {' '}
                  Manage Topic/Quiz Sequence
                </button>
              </Link> */}
                  {/* <div className="dropdown">
                  <a
                    className="btn btn-secondary dropdown-toggle"
                    role="button"
                    id="dropdownMenuLinkGrade"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      // marginLeft: ".5%",
                      width: 'fit-content',
                    }}
                  >
                    {activity === '' ? 'Select Active/Inactive' : activity}
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLinkGrade"
                  >
                    <a
                      style={{ cursor: 'pointer' }}
                      className="dropdown-item"
                      onClick={() => {
                        setactivity('Active');
                        // setcoursePlan("premium");
                      }}
                    >
                      Active
                    </a>
                    <a
                      style={{ cursor: 'pointer' }}
                      className="dropdown-item"
                      onClick={() => {
                        setactivity('InActive');
                        // setcoursePlan("freemium");
                      }}
                    >
                      InActive
                    </a>
                    <a
                      style={{ cursor: 'pointer' }}
                      className="dropdown-item"
                      onClick={() => {
                        setactivity('');
                        // setcoursePlan("freemium");
                      }}
                    >
                      None
                    </a>
                  </div>
                </div> */}
                  <button
                    className="btn btn-priamry"
                    onClick={() => {
                      dispatch(setIsQuizTab(true));
                      history.push(`/admin/subject/chapter/${lessonid}`);
                    }}
                  >
                    Add Quiz
                  </button>
                  <button
                    className="btn btn-priamry"
                    onClick={() => {
                      history.push(`/admin/unicodes/update/${lessonid}`);
                    }}
                  >
                    Update UniCodes
                  </button>
                </Col>
              </Row>

              <div className="chapter-preview-tabs">
                <h1
                  className={tabValue === "topics" && "active"}
                  onClick={() => {
                    onChangeTabs("topics");
                  }}
                >
                  Topics
                </h1>
                <h1
                  className={tabValue === "quiz" && "active"}
                  onClick={() => {
                    onChangeTabs("quiz");
                  }}
                >
                  Quizzes
                </h1>
                <h1
                  className={tabValue === "sequence" && "active"}
                  onClick={() => {
                    onChangeTabs("sequence");
                  }}
                >
                  Manage Sequence
                </h1>
              </div>

              <hr />

              {tabValue === "topics" && (
                <TopicList
                  topicList={topicsList}
                  onHandleEdit={(topic) => {
                    history.push(
                      `/admin/subject/chapter/chapterdetails/${lessonid}/edit/${topic._id}`
                    );
                    setModalTopic(topic);
                    setTopicName(topic.topic_title);
                    setvideoType(topic.vedio_type);
                    setTopicVideoId(topic.topic_video);
                    setTopicdescription(topic.topic_detail);
                    settopicIcon(topic.topic_icon);
                  }}
                  onHandleDelete={(topic) => {
                    setTopicName(topic.topic_title);
                    setModalTopic(topic);
                    setStatusModal(true);
                  }}
                  onHandleVideoPlay={(topic) => {
                    const data = {
                      title: topic.topic_title,
                      videoId: topic.topic_video,
                      videoType: topic.vedio_type,
                    };
                    setVideoObj(data);
                    setIsOpenModal(true);
                  }}
                  onHandleViewDescription={(details) => {
                    setDescriptionList(details);
                    setIsOpenModal(true);
                  }}
                />
              )}

              {tabValue === "quiz" && <QuizWrapper />}
              {tabValue === "sequence" && <ChapterSequenceWrapper />}
              {/* {tabValue === "sequence" && <ChapterSequence />} */}
            </CardBody>
          </Card>

          <ModalContainer
            isOpen={isOpenModal}
            modalWidth="700"
            animateEffect="fadeInUp"
            heading={
              videoObj
                ? `${videoObj?.title} video`
                : `${
                    descriptionList !== null ? descriptionList[1] : ""
                  } description`
            }
            onConfirmClick={handleOnClose}
          >
            {descriptionList !== null && (
              <div className="topic-description">
                <p>
                  {descriptionList.length &&
                    descriptionList[0].map((detail) => {
                      if (detail.topic_type == "text") {
                        return parse(detail.description);
                      } else {
                        return <p>{parse(detail.description)}</p>;
                      }
                    })}
                </p>
              </div>
            )}

            {videoObj !== null && (
              <div className="video-player">
                {videoObj?.videoType === "youtube" ? (
                  <iframe
                    // width="630"
                    height="315"
                    width="100%"
                    src={`https://www.youtube.com/embed/${videoObj?.videoId}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                ) : (
                  // <div style={{ width: "640px", height: "500px" }}>
                  <iframe
                    src={`https://player.vimeo.com/video/${videoObj?.videoId}?color=ffffff&title=0&byline=0&portrait=0&badge=0`}
                    style={{
                      width: "100%",
                      height: "315px",
                    }}
                    frameborder="0"
                    // title="YouTube video player"
                  ></iframe>
                  // </div>
                )}
              </div>
            )}
          </ModalContainer>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
    // );
    // } else {
    // return (
    // <>
    //   {!loading ? (
    //     <div className="content">
    //       <ToastContainer
    //         position="top-right"
    //         autoClose={5000}
    //         hideProgressBar={false}
    //         newestOnTop={false}
    //         closeOnClick
    //         rtl={false}
    //         pauseOnFocusLoss
    //         draggable
    //         pauseOnHover
    //       />
    //       <Row
    //         lg={12}
    //       // style={{
    //       //   width: "100%",
    //       //   display: "flex",
    //       //   alignItems: "center",
    //       //   justifyContent: "space-between",
    //       //   flexDirection: "row",
    //       // }}
    //       >
    //         <Col lg={6}>
    //           <h1>Kindly Populate Topics</h1>
    //         </Col>
    //         <Col lg={6}>
    //           <Link
    //             to={`/admin/subject/chapter/chapterdetails/${lessonid}/add`}
    //           >
    //             <button
    //               className="btn btn-priamry"
    //               onClick={() => {
    //                 resetState();
    //                 setAddModal(true);
    //               }}
    //             >
    //               Add Topic
    //             </button>
    //           </Link>

    //           <button
    //             disabled={topicsList.length > 0 ? false : true}
    //             className="btn btn-priamry"
    //             style={{
    //               border: '0px',
    //               fontSize: '14px',
    //               width: 'fit-content',
    //             }}
    //           >
    //             <Link
    //               to={`/admin/subject/chapter/${lessonid}/chaptersequence`}
    //               style={{ color: 'white', fontWeight: '600' }}
    //             >
    //               Manage Topic/Quiz Sequence
    //             </Link>

    //           </button>
    //           <Link to={`/admin/subject/chapter/${lessonid}`}>
    //             <button
    //               className="btn btn-priamry"
    //             >
    //               Add Quiz
    //             </button>
    //           </Link>
    //         </Col>
    //       </Row>
    //     </div>
    //   ) : (
    //     <div className="content">
    //       <Row>
    //         <Col md="12">
    //           <Card>
    //             <CardHeader>
    //               <CardTitle
    //                 style={{
    //                   display: 'flex',
    //                   alignItems: 'center',
    //                   justifyContent: 'center',
    //                 }}
    //               >
    //                 <img src={loader} alt="Loading...." />
    //               </CardTitle>
    //             </CardHeader>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </div>
    //   )}
    // </>
  );
  // }
}

export default ChapterPreview;
