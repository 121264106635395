import backend from "../api";

export const updateRegisteredCourseExpireDate = async (id, expireDate) =>
  await backend({
    method: "PUT",
    url: `/courseregistration/${id}`,
    data: { expireDate },
  });

var pop_cus = [
  {
    path: "history.chapter",
  },

  {
    path: "history.test",
  },

  {
    path: "student",
  },
  {
    path: "subject ",
  },

  {
    path: "grade",
  },
  {
    path: "history.test.chapter",
  },
  {
    path: "subject.grade",
  },
];
const pop_course = encodeURI(JSON.stringify(pop_cus));

export const getRegisteredCourseList = async () =>
  await backend({
    method: "GET",
    url: `/courseregistration/?isPurchase=true&populate=${pop_course}`,
  });

export const getRegisteredCourseByGrade = async () =>
  await backend({
    method: "GET",
    url: `/courseregistration/only/subject`,
  });

export const getStudentAllRegisteredCourses = async (studentID) =>
  await backend({
    method: "GET",
    url: `/courseregistration?student=${studentID}&populate=${pop_course}`,
  });

export const getRegisteredCoursesPurchaseDetails = async () =>
  await backend({
    method: "GET",
    url: "/courseregistration/details",
  });
