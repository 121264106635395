import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import MaterialTable from "material-table";
import { format, formatDistance } from "date-fns";
import DateFilters from "components/Common/DateFilters";
import Loader from "components/Common/Loader";
import { getStudentUninstall } from "Utils/reports.Utils";
import { Bar, Line } from "react-chartjs-2";
import { getInstallStats } from "Utils/reports.Utils";
import { toast } from "react-toastify";
import { setDateRange } from "Utils/helper_Utils";
import DownloadPDF from "components/Common/DownloadPDF";

const unInstallStatsDateFilterOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "unInstallStats",
    },
  },

  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const unInstallStatsGraphData = {};
const unInstallStatsByDateRangeGraphData = {};

function UnInstallReports() {
  const [loading, setLoading] = useState(false);
  const [totalStudents, setTotalStudents] = useState(0);
  const [dateObj, setDateObj] = useState(setDateRange());
  const [totalUnInstalls, setTotalUnInstalls] = useState(0);
  const [totalInstall, setTotalInstall] = useState(0);

  const createDateRangeUninstallInstallGraph = (journeyData) => {
    setLoading(true);

    const arrangeData = journeyData.map((e) => {
      e.updatedAt = e?.updatedAt.split("T")[0];
      e.createdAt = e?.createdAt.split("T")[0];
      return e;
    });

    const uniqueDates = [
      ...new Set(arrangeData.map((e) => e?.updatedAt)),
    ].sort();

    const installsData = arrangeData?.filter((e) => e?.unInstall);
    const unInstallsData = arrangeData?.filter((e) => !e?.unInstall);

    const installsDataPerData = installsData.reduce(function (acc, curr) {
      return (
        acc[curr?.updatedAt]
          ? ++acc[curr?.updatedAt]
          : (acc[curr?.updatedAt] = 1),
        acc
      );
    }, {});

    const unInstallsDataPerData = unInstallsData.reduce(function (acc, curr) {
      return (
        acc[curr?.updatedAt]
          ? ++acc[curr?.updatedAt]
          : (acc[curr?.updatedAt] = 1),
        acc
      );
    }, {});

    unInstallStatsByDateRangeGraphData.labels = uniqueDates;
    unInstallStatsByDateRangeGraphData.datasets = [
      {
        label: "Uninstall",
        data: Object.entries(unInstallsDataPerData)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map((e) => e[1]),
        borderColor: "rgb(74, 68, 252)",
        fill: false,
        lineTension: 0,
      },
      {
        label: "Install",
        data: Object.entries(installsDataPerData)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map((e) => e[1]),
        borderColor: "rgb(247, 30, 303)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "New Users Install",
        data: uniqueDates.map(
          (e) => arrangeData.filter((x) => String(x?.createdAt) === String(e))?.length
        ),
        borderColor: "rgb(59, 232, 56)",
        lineTension: 0,
        fill: false,
      },
    ];

    setTotalStudents(journeyData?.length);
    setLoading(false);
  };

  const fetchInitialData = async (dateObj) => {
    setLoading(true);
    try {
      const resp = await getInstallStats(dateObj);

      const { filteredData, unInstall, install } = resp.data.data;
      createDateRangeUninstallInstallGraph(filteredData);
      setTotalUnInstalls(unInstall);
      setTotalInstall(install);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (dateObj) => {
    if (!dateObj?.startDate || !dateObj?.endDate) {
      toast.info("Dates Missing");
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    setDateObj(dateObj);
    fetchInitialData(dateObj);
  };

  const refreshHandler = () => {
    const date = setDateRange();
    setDateObj(date);
    fetchInitialData(date);
  };

  useEffect(() => {
    fetchInitialData(dateObj);
  }, []);
  return (
    <div>
      <div className="user_platforms">
        <DateFilters
          submitHandler={submitHandler}
          refreshHandler={refreshHandler}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            <DownloadPDF downloadFileName="Install-UnInstall-Report" />
            <div className="graph">
              <div className="downloadPDF">
                <div className="box">
                  <div className="box_card">
                    <p>Total Students in specified date:</p>
                    <p>{totalStudents}</p>
                  </div>
                  <div className="box_card">
                    <p>Total Days:</p>
                    <p>{unInstallStatsByDateRangeGraphData?.labels?.length}</p>
                  </div>
                </div>
                <div className="box">
                  <div className="box_card">
                    <p>Uninstall in specified date:</p>
                    <p>
                      {unInstallStatsByDateRangeGraphData?.datasets?.length
                        ? unInstallStatsByDateRangeGraphData?.datasets[0]?.data.reduce(
                            (a, b) => (a += b),
                            0
                          )
                        : 0}
                    </p>
                  </div>
                  <div className="box_card">
                    <p>Install in specified date:</p>
                    <p>
                      {unInstallStatsByDateRangeGraphData?.datasets?.length
                        ? unInstallStatsByDateRangeGraphData?.datasets[1].data.reduce(
                            (a, b) => (a += b),
                            0
                          )
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="box">
                  <div className="box_card">
                    <p>Uninstall for all time:</p>
                    <p>{totalUnInstalls}</p>
                  </div>
                  <div className="box_card">
                    <p>Install for all time:</p>
                    <p>{totalInstall}</p>
                  </div>
                </div>

                <h3 className="heading">Install / Uninstall</h3>
                <Line
                  options={unInstallStatsDateFilterOptions}
                  data={unInstallStatsByDateRangeGraphData}
                />
              </div>
              <div className="gap"></div>
              <hr />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

UnInstallReports.propTypes = {};

export default UnInstallReports;
