import { format } from "date-fns";

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  if (s === 0 && m === 0 && h === 0) return 0;

  var hDisplay = h > 0 ? h + "hr, " : "";
  var mDisplay = m > 0 ? m + "min, " : "";
  var sDisplay = s > 0 ? s + "sec" : "";
  return hDisplay + mDisplay + sDisplay;
};

export const differenceBtw2Dates = (dateObj) => {
  const { startDate, endDate } = dateObj;

  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const Difference_In_Time = date2.getTime() - date1.getTime();

  return Difference_In_Time / (1000 * 3600 * 24);
};

export const setDateRange = () => {
  var d = new Date();
  return {
    startDate: format(
      new Date(new Date(d.setDate(d.getDate() - 6)).toISOString()),
      "yyyy-MM-dd"
    ),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };
};

export const createDateRange = (numOfDays, date) =>
  [...Array(numOfDays)].map((e, i) => {
    const d = date ? new Date(date) : new Date();
    return new Date(d.setDate(d.getDate() - i)).toISOString().split("T")[0];
  });

export const randomColorGenerated = () => {
  const a = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const c = Math.floor(Math.random() * 256);

  return `rgba(${a}, ${b}, ${c})`;
};
