import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import EquationEditorHolder from "./EquationEditorHolder";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { addtopic } from "Redux/features/Subject/SubjectSlice";
import loader from "../../assets/img/loader.svg";
import loaderimg from "../../assets/img/loaderimg.svg";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { addTopicBackend } from "Utils/chapterPreview_Utils";
import { Editor } from "@tinymce/tinymce-react";
import BreadCrumbs from "components/Common/BreadCrumbs";
const AddTopic = (props) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ direction: "rtl" }], // this is rtl support
    ],
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  let lessonID = location.pathname.split("/")[5];

  // console.log(lessonID);
  const loading = useSelector(selectLoading);
  const [loadingImage, setloadingImage] = useState(false);
  const [loadingPdf, setloadingPdf] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [TopicVideoId, setTopicVideoId] = useState("");
  const [topicTitle, settopicTitle] = useState("");
  const [addtopicIcon, setaddtopicIcon] = useState("");
  const [topicIcon, settopicIcon] = useState([]);
  const [vedio_type, setvedio_type] = useState("youtube");
  const [ismath, setismath] = useState(false);
  const [description, setdescription] = useState([]);
  const [videoHolder, setvideoHolder] = useState([]);
  const [videoPer, setvideoPer] = useState("");
  const [videoUri, setvideoUri] = useState("");
  const [subject, setsubject] = useState("");
  const [grade, setgrade] = useState("");
  const [value, setValue] = useState("");
  const [isPaid, setIsPaid] = useState("false");
  const [descriptionImagesArray, setdescriptionImagesArray] = useState([]);
  const [textData, settextData] = useState("");
  const [chapterObj, setChapterObj] = useState("");
  const editorfunction = () => {
    let script = document.createElement("script");
    script.src = "https://latex.oncodecogs.com/editor.js";
    script.async = true;
    // script.onload = async () => {
    //   EqEditor.embed("toolbar");
    //   var a = new EqTextArea("equation", "testbox");
    //   EqEditor.add(a, false);

    //   EqnExport = function (text) {
    //     EqEditor.addText(document, "buttonexport", text);
    //   };
    //   EqEditor.ExportButton.add(a, "copybutton", EqnExport, "html");

    //   EqnExport2 = function (text) {
    //     alert(text);
    //   };
    //   EqEditor.ExportButton.add(a, "copybutton2", EqnExport2, "html");
    // };
  };

  var pop_cus = {
    path: "subject",
    select: "+Subject_name",
  };
  const pop_course = encodeURI(JSON.stringify(pop_cus));

  useEffect(() => {
    function getChapter() {
      axios
        .get(`/chapter?_id=${lessonID}&populate=${pop_course}`)
        .then((res) => {
          setsubject(res.data.data[0].subject?._id);
          setChapterObj(res.data.data[0]);
          setgrade(res.data.data[0].grade);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    dispatch(setloading(true));
    getChapter();
  }, [ismath]);

  const videoHandler = (e) => {
    videoHolder.push(e.target.files[0]);
    // console.log(videoHolder);
    // uploadVideo();
  };
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const handleAddTopic = (e) => {
    e.preventDefault();

    if (vedio_type === "vimeo") {
      setTopicVideoId(videoUri.split("/")[2]);
    }

    let topicToAdd = {
      subject: subject,
      grade: grade,
      chapter: lessonID,
      topic_title: topicTitle,
      topic_icon: addtopicIcon
        ? addtopicIcon
        : "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/Additive%20Identity-1632462168291.jpg",
      topic_detail: description?.length
        ? description
        : [{ topic_type: "text", description: "<p>.</p>" }],
      vedio_type: vedio_type,
      topic_video: TopicVideoId,
      awarded_points: 5,
      isPaid: isPaid === "false" ? false : true,
      isActive: true,
      topic_file: pdfFile || "-",
    };

    vedio_type === "vimeo"
      ? (topicToAdd.topic_video = videoUri.split("/")[2])
      : (topicToAdd.topic_video = TopicVideoId);
    // console.log(topicToAdd, "Topic To Add");
    // props.addTopic(topicToAdd);
    if (topicTitle !== "" && topicToAdd.topic_video !== "") {
      addTopicBackend(topicToAdd, dispatch, lessonID, history);
      // history.push(`/admin/subject/chapter/chapterdetails/${lessonID}`);
      //
    } else {
      console.log(
        "Title : ",
        topicTitle,
        "Icon : ",
        addtopicIcon,
        "Video : ",
        TopicVideoId,
        "Topic :",
        topicToAdd
      );
      toast.info("Fill out all information title, video");
    }
    // addTopicBackend({ topics: [topicToAdd] });
  };
  async function uploadImage(fileObj, isPdf) {
    if (isPdf) {
      setloadingPdf(true);
    } else setloadingImage(true);

    const formData = new FormData();

    formData.append("files", fileObj);

    axios
      .post(`/filesupload/file-upload`, formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        if (isPdf) setPdfFile(res.data.locationArray[0].fileLocation);
        else setaddtopicIcon(res.data.locationArray[0].fileLocation);

        setTimeout(() => {
          if (isPdf) setloadingPdf(false);
          else setloadingImage(false);
        }, 1000);
      })
      .catch((err) => {
        if (isPdf) setloadingPdf(false);
        else setloadingImage(false);

        toast.error(err?.response?.data?.message?.message);
      });
  }
  const changeHandler = (event) => {
    // topicIcon.push(event.target.files[0]);

    if (event?.target?.files[0]) {
      const isPdf =
        event?.target?.files[0]?.type === "application/pdf" ? true : false;
      uploadImage(event.target.files[0], isPdf);
    }
  };
  // const changeHandlerDescription = (event) => {
  //   descriptionImagesArray.push(event.target.files);
  //   descriptionImagesArray.forEach((desription) => {
  //     console.log(description);
  //   });
  //   // uploadImage();
  // };

  // Object.defineProperty( videoHolder[0], 'name', {
  //   writable: true,
  //   value: topicTitle
  // });
  const uploadVideo = () => {
    if (!topicTitle) {
      toast.error("Plz enter topic title first");
      return;
    }

    client.upload(
      videoHolder[0],
      { name: topicTitle },
      function (uri) {
        // console.log("File upload completed. Your Vimeo URI is:", uri);
        setvideoUri(uri);
        // if (vedio_type == "vimeo") {
        //   let id = videoUri.split("/");
        //   id = id[2];
        //   console.log(id);
        //   setTopicVideoId(id);
        // }
      },
      function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setvideoPer(percentage);
        // console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      function (error) {
        toast.error(error);
        // console.log("Failed because: " + error);
      }
    );
    setvideoHolder([]);
  };

  let Vimeo = require("vimeo").Vimeo;

  let client = new Vimeo(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_CLIENT_SECRET,
    process.env.REACT_APP_ACCESS_TOKEN
  );

  return (
    <>
      {!loading ? (
        <div className="content">
          <BreadCrumbs
            breadCrumbList={[
              {
                title: chapterObj?.subject?.Subject_name || "Subject",
                pathName: "/admin/subjects",
              },
              {
                title: chapterObj?.chapter_name || "Chapters",
                pathName: `/admin/subject/${chapterObj?.subject?._id}`,
              },
              {
                title: "Add Topic",
                pathName: `/admin/subject/chapter/chapterdetails/${lessonID}/add`,
              },
            ]}
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle
                    tag="h4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Add Topic</h4>
                  </CardTitle>
                  <button className="btn btn-danger" onClick={handleAddTopic}>
                    Add
                  </button>
                </CardHeader>
                <CardBody>
                  <div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <p>
                          Topic title <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Topic Title"
                          aria-label="Topictitle"
                          value={topicTitle}
                          required
                          onChange={(e) => settopicTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <p>
                          Select Plan <span style={{ color: "red" }}>*</span>
                        </p>
                        <select
                          name=""
                          id=""
                          value={isPaid}
                          className="form-control"
                          onChange={(e) => {
                            setIsPaid(e.target.value);
                          }}
                        >
                          <option value="false">Free</option>
                          <option value="true">Paid</option>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <p>Topic icon</p>
                        <div style={{ display: "flex" }}>
                          <input
                            type="file"
                            name="TopicIcon"
                            accept="image/jpeg, image/png"
                            onChange={changeHandler}
                            style={{ color: "red" }}
                          />
                          {loadingImage ? (
                            <img src={loaderimg} alt="loading..." />
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p>
                          Upload pdf{" "}
                          <span
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              paddingLeft: "5px",
                            }}
                          >
                            ( File size must be less than 30mb )
                          </span>{" "}
                        </p>
                        <div>
                          <div style={{ display: "flex" }}>
                            <input
                              type="file"
                              name="TopicPdf"
                              accept="application/pdf"
                              className="form-control"
                              onChange={changeHandler}
                              style={{ color: "red" }}
                            />
                            {loadingPdf ? (
                              <img src={loaderimg} alt="loading..." />
                            ) : null}
                          </div>
                          {pdfFile && (
                            <a
                              href={pdfFile}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Preview File
                            </a>
                          )}
                        </div>
                      </div>
                    </div>

                    <p className="mt-3">
                      Video Type <span style={{ color: "red" }}>*</span>
                    </p>
                    <div class="dropdown">
                      <a
                        class="btn btn-secondary dropdown-toggle"
                        role="button"
                        id="dropdownMenuLinkGrade"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {vedio_type}
                      </a>

                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuLinkGrade"
                      >
                        <a
                          class="dropdown-item"
                          onClick={() => {
                            setvedio_type("youtube");
                          }}
                        >
                          youtube
                        </a>
                        <a
                          class="dropdown-item"
                          onClick={() => {
                            setvedio_type("vimeo");
                          }}
                        >
                          vimeo
                        </a>
                      </div>
                    </div>
                    {vedio_type === "vimeo" ? (
                      <>
                        <input type="file" onChange={videoHandler} />
                        {videoPer !== "" ? (
                          <p>Uploading Progress ... {videoPer}%</p>
                        ) : (
                          <p>
                            Waiting for video to upload{" "}
                            <p style={{ color: "red", display: "initial" }}>
                              (Press Upload Vimeo)
                            </p>
                          </p>
                        )}
                        <button
                          className="btn btn-priamry"
                          onClick={() => {
                            uploadVideo();
                          }}
                        >
                          Upload Vimeo
                        </button>
                      </>
                    ) : (
                      <>
                        <p>Video ID</p>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Video Id"
                            aria-label="video_id"
                            value={TopicVideoId}
                            required
                            onChange={(e) => {
                              setTopicVideoId(e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {/* <input
                  type="file"
                  multiple
                  onChange={changeHandlerDescription}
                /> */}
                    <button
                      className="btn btn-priamry mb-3"
                      onClick={() => {
                        setismath(!ismath);
                        setValue("");
                      }}
                      style={{ marginTop: "15px" }}
                    >
                      {ismath ? "Text" : "Math"}
                    </button>
                    {!ismath ? null : (
                      <code style={{ marginLeft: "5px", fontSize: "15px" }}>
                        Click on top left corner to insert math equations
                      </code>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <div>
                      {ismath ? (
                        <>
                          <Helmet>
                            <script src="https://latex.oncodecogs.com/editor.js"></script>
                          </Helmet>
                          <EquationEditorHolder
                            setdescription={setdescription}
                            description={description}
                          />
                          <div>
                            {" "}
                            <h4>Guide</h4>
                            <p>
                              <code>^</code> → Superscript, <code>_</code> →
                              Subscript, <code>int</code> → Integral,{" "}
                              <code>sum</code> → Summation, <code>prod</code> →
                              Product, <code>sqrt</code> → Square root,{" "}
                              <code>bar</code> → Bar over letter,{" "}
                              <code>alpha, beta, ... omega</code> → Small Greek
                              letter, <code>Alpha, Beta, ... Omega</code> →
                              Capital Greek letter
                            </p>
                          </div>

                          {/* <p>
                        
                        <button className="btn btn-priamry">
                          <a href="javascript:OpenLatexEditor('testbox','latex','')">
                            Launch Equation Editor
                          </a>
                        </button>
                      </p> */}

                          {/* This is the correct editor.js */}

                          {/* <h1>Math Editor</h1>
                      <div
                        id="editor"
                        style={{ padding: "10px", margin: "10px" }}
                      ></div>
                      <img id="equation" style={{ display: "none" }} />
                      <Helmet>
                        <script>
                          EqEditor.embed('editor',''); EqEditor.add(new
                          EqTextArea('equation', 'testbox'),false);
                        </script>
                      </Helmet>
                      <textarea
                        id="testbox"
                        rows="6"
                        cols="60"
                        value={textData}
                        onChange={(e) => {
                          settextData(e.target.value);
                        }}
                      ></textarea> */}
                        </>
                      ) : (
                        // <TextEditor
                        //   setdescription={setdescription}
                        //   description={description}
                        // />
                        <div>
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            value={value}
                            onChange={setValue}
                            style={{
                              height: "500px",
                              width: "800px",
                              direction: "rtl",
                              textAlign: "right",
                            }}
                          />

                          {/* <SunEditor
                        lang="ua"
                        setDefaultStyle="background-color : #32325d; text-align: right;"
                        value="x"
                      /> */}
                        </div>
                      )}
                      {/* <TextEditor /> */}
                      {/* <EquationEditorHolder /> */}
                    </div>
                  </div>
                  {!ismath ? (
                    <button
                      id="concatButton"
                      className="btn btn-priamry mt-lg-md"
                      onClick={() => {
                        setdescription((prevItems) => [
                          ...prevItems,
                          {
                            topic_type: "text",
                            // description: draftToHtml(
                            //   convertToRaw(editorState.getCurrentContent())
                            // ),
                            description: value,
                          },
                        ]);
                        setValue("");
                        // seteditorState(EditorState.createEmpty());
                      }}
                    >
                      Add to Description
                    </button>
                  ) : // For editor.js
                  // <button
                  //   id="concatButton"
                  //   className="btn btn-priamry mt-lg-md"
                  //   onClick={() => {
                  //     setdescription((prevItems) => [
                  //       ...prevItems,
                  //       {
                  //         topic_type: "math",
                  //         // description: draftToHtml(
                  //         //   convertToRaw(editorState.getCurrentContent())
                  //         // ),
                  //         description: textData,
                  //       },
                  //     ]);
                  //     settextData("");
                  //     // seteditorState(EditorState.createEmpty());
                  //   }}
                  // >
                  //   Concat
                  // </button>
                  null}

                  <Card className="mt-3 pl-3 pr-3">
                    <h4>Description</h4>
                    {description.map((des) => {
                      return (
                        <>
                          {des.topic_type === "text" ? (
                            parse(des.description)
                          ) : (
                            <p>{parse(des.description)}</p>
                          )}
                        </>
                      );
                      // <div>{parse(des.desc)}</div>;
                    })}
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AddTopic;
