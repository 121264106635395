import { useContext } from "react";
import PropTypes from "prop-types";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import MaterialTable from "material-table";
import { format } from "date-fns";

function UserJourneyReport({ userJourneyReportList }) {
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();

  const renderCellValue = (value) => (
    <div className="report_value">
      <h1>{value}</h1>
    </div>
  );

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  const setStudentName = (user) =>
    user.user_name
      ? user.user_name
      : user.first_name
      ? `${user.first_name} ${user.last_name}`
      : "N/A";

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + "hr, " : "";
    var mDisplay = m > 0 ? m + "min, " : "";
    var sDisplay = s > 0 ? s + "sec" : "";
    return hDisplay + mDisplay + sDisplay;
  };

  return (
    <div className="reports_list mt-3">
      {userJourneyReportList?.length ? (
        <MaterialTable
          icons={tableIcons}
          title={null}
          //   key={count++}
          columns={[
            {
              title: "Student Name",
              field: "studentName",
              render: (rowData) => renderCellValue(rowData?.studentName),
            },
            {
              title: "Screen Name",
              field: "screenName",
              render: (rowData) => renderCellValue(rowData?.screenName),
            },
            {
              title: "Uni Code",
              field: "uniCode",
              render: (rowData) => renderCellValue(rowData?.uniCode),
            },
            {
              title: "Duration",
              field: "durationInMin",
              render: (rowData) =>
                renderCellValue(secondsToHms(rowData?.durationInMin * 60)),
            },
            {
              title: "Date",
              field: "date",
              render: (rowData) => renderCellValue(rowData?.date),
            },
            {
              title: "Status",
              field: "status",
              render: (rowData) => renderCellValue(rowData?.status),
            },
          ]}
          data={
            userJourneyReportList?.length > 0 &&
            userJourneyReportList.map((e) => e)
          }
          options={{
            actionsColumnIndex: -1,
            exportButton: true,
            pageSize: 20,
            pageSizeOptions: [5, 20, 50, userJourneyReportList?.length],
            headerStyle: {
              backgroundColor: colorsList.find((e) => e.color === color).value,
              color: "#FFF",
            },
            filtering: true,
          }}
        />
      ) : (
        <h6 className="mt-3">Data not found</h6>
      )}
    </div>
  );
}

UserJourneyReport.propTypes = {
  userJourneyReportList: PropTypes.array.isRequired,
};

export default UserJourneyReport;
