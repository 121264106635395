import React, { useEffect, useState } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import { Card, CardHeader, CardTitle, Row, Col, CardBody } from "reactstrap";

import loader from "../assets/img/loader.svg";
import axios from "../api";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { setloading, setAccessToken } from "Redux/features/Auth/AuthSlice";
import { setstudent } from "Redux/features/Student/StudentSlice";
import { updateLastVisit } from "../Utils";
import { generateAccessToken, getTotalPerRole } from "../Utils/dashboard_Utils";

import { format } from "date-fns";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { RiContrastDrop2Fill } from "react-icons/ri";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import TeacherPaymentWrapper from "components/TeacherPayment/TeacherPaymentWrapper";
import GoogleAnalyticsStats from "./GoogleAnalyticsStats";
// import { Bar } from 'react-chartjs-2';

// ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard(props) {
  const dispatch = useDispatch();
  const [bigChartData, setbigChartData] = useState("data1");
  const [totalAdmin, settotalAdmin] = useState(0);
  const [totalTeacher, settotalTeacher] = useState(0);
  const [totalStudentsCount, settotalStudentsCount] = useState(0);
  const [totalStudents, settotalStudents] = useState([]);
  const [screenNames, setScreenNames] = useState([]);
  const [eventCount, setEventCount] = useState([]);
  const [downloadsOneDay, setDownloadsOneDay] = useState(0);
  const [renderAnalytics, setRenderAnalytics] = useState(false);
  // const [screenVisitGraphData, setMonthlyUser] = useState(null);
  //   const [loading, setloading] = useState(false);
  const loading = useSelector(selectLoading);
  const userData = useSelector(selectUserData);

  const findLast24HrsTotalDownloads = (list) => {
    const userDateExist = list?.length && list.filter((e) => e.date_created);
    const ms = 24 * 60 * 60 * 1000;
    const lastDay = new Date(new Date(Date.now() - ms * 1).setHours(0, 0, 0));
    const last24HoursUser = userDateExist.filter(
      (a) => new Date(a.date_created).getTime() >= new Date(lastDay).getTime()
    );
    return last24HoursUser?.length ?? 0;
  };

  const filterDownloadByDate = (start, end) => {
    const userDateExist =
      totalStudents?.length && totalStudents.filter((e) => e.date_created);

    const ms = 24 * 60 * 60 * 1000;
    const startDate = new Date(new Date(start).setHours(0, 0, 0)).getTime();
    const endDate = new Date(new Date(end)).getTime();

    const last24HoursUser = userDateExist.filter((a) => {
      const x = new Date(a.date_created).getTime();
      if (x >= startDate && x <= endDate) return a;
    });
    setDownloadsOneDay(last24HoursUser?.length ?? 0);
  };

  const fetchTotalUsersPerRole = async () => {
    dispatch(setloading(true));
    try {
      const resp = await getTotalPerRole();
      const { totalStudents, totalTeachers, totalAdmins, DateCreatedStudents } =
        resp.data.data;
      settotalTeacher(totalTeachers[0]?.count || 0);
      settotalAdmin(totalAdmins[0]?.count || 0);
      settotalStudentsCount(totalStudents[0]?.count || 0);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      dispatch(setloading(false));
    }
  };

  const fetchInitialData = async () => {
    dispatch(setloading(true));
    try {
      const response = await generateAccessToken();
      const { token } = response.data.data;
      dispatch(setAccessToken(token));

      const resp = await getTotalPerRole();
      const { totalStudents, totalTeachers, totalAdmins, DateCreatedStudents } =
        resp.data.data;
      settotalTeacher(totalTeachers[0]?.count || 0);
      settotalAdmin(totalAdmins[0]?.count || 0);
      settotalStudentsCount(totalStudents[0]?.count || 0);
      settotalStudents(DateCreatedStudents);
      const total = await findLast24HrsTotalDownloads(DateCreatedStudents);
      setDownloadsOneDay(total);

      const userEmail = sessionStorage.getItem("user");
      const role = sessionStorage.getItem("role");
      if (role !== "teacher") return;

      const result = await updateLastVisit({
        email: userEmail,
        last_visit: new Date(),
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      dispatch(setloading(false));
      setRenderAnalytics(true);
    }
  };
  useEffect(() => {
    if (userData?.role === "teacher") {
      fetchTotalUsersPerRole();
      return;
    }

    fetchInitialData();
  }, []);

  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  return (
    <>
      {!loading ? (
        <div className="dashboard content">
          <Row>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Total Admins</h5>
                  <CardTitle tag="h3">
                    <i
                      className="fa fa-user"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "black" }}
                    />{" "}
                    {totalAdmin}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Total Teachers</h5>
                  <CardTitle tag="h3">
                    <i
                      className="fa fa-users"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "pink" }}
                    />{" "}
                    {totalTeacher}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Total Students</h5>
                  <CardTitle tag="h3">
                    <i
                      className="fa fa-graduation-cap"
                      aria-hidden="true"
                      style={{
                        fontSize: "20px",
                        color: "green",
                        marginRight: "15px",
                      }}
                    ></i>
                    {totalStudentsCount}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {userData?.role === "teacher" ? (
                    <TeacherPaymentWrapper
                      teacher_ID={userData?._id}
                      showBreadCrumb={false}
                    />
                  ) : (
                    renderAnalytics && (
                      <>
                        <h1>Google Analytics Stats</h1>

                        <div className="row">
                          <div className="col-md-4">
                            <GoogleAnalyticsStats
                              postData="activeUserIn30Minutes"
                              heading="USERS IN LAST 30 MINUTES"
                            />
                          </div>
                          <div className="col-md-4">
                            <GoogleAnalyticsStats
                              postData="timeInTheApp"
                              heading="TIME IN THE APP"
                            />
                          </div>
                          <div className="col-md-4">
                            <GoogleAnalyticsStats
                              postData="downloads"
                              heading="DOWNLOADS IN 24 HOURS"
                              value={downloadsOneDay}
                              filterDownloads={filterDownloadByDate}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <GoogleAnalyticsStats
                              postData="screenSessionTime"
                              heading="Screens session time"
                            />
                          </div>
                          <div className="col-md-6">
                            <GoogleAnalyticsStats
                              postData="noOfStudents"
                              heading="Number of students"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <GoogleAnalyticsStats
                              postData="studentSource"
                              heading="Students Source"
                            />
                          </div>
                          <div className="col-md-6">
                            <GoogleAnalyticsStats
                              postData="screenVisits"
                              heading="Most Screen Click By Students"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <GoogleAnalyticsStats
                              postData="studentsPerRegion"
                              heading="Students Per Region"
                            />
                          </div>
                        </div>
                      </>
                    )
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default Dashboard;
