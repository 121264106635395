import React from "react";

const Loading = () => (
  <div id="loader__control" className="loader__overlay">
    <div class="loader__design"></div>
    {/* <h4 className="loader-text">Loading ....</h4> */}
  </div>
);

export default Loading;
