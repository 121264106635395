import { useEffect, useState } from "react";
import "../../Templates/Login/css/style.css";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { RiMailOpenFill } from "react-icons/ri";
import { Card, CardHeader, CardTitle, Row, Col } from "reactstrap";
import { forgotPasswordSchema } from "Utils";
import axios from "../../api";
import loader from "../../assets/img/loader.svg";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const history = useHistory();
  const sendForgotPasswordEmail = (data) => {
    dispatch(setloading(true));
    axios
      .put(`/user/teacher/password/forgot`, data)
      .then((req) => {
        toast.success(req.data.data.status);
        setTimeout(() => {
          dispatch(setloading(false));
          history.push("/admin/login");
        }, 1000);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          axios
            .put(`/superadmin/password/forgot`, data)
            .then((req) => {
              toast.success(req.data.data.status);

              setTimeout(() => {
                dispatch(setloading(false));
                history.push(`/admin/login`);
              }, 1000);
            })
            .catch((err) => {
              dispatch(setloading(false));
              // window.alert(`User with Email '${data.email}' Not Found`);
              toast.error(err.response.data.message);
            });
          
        } else {
          toast.error(err.response.data.message);
          setTimeout(() => {
            dispatch(setloading(false));
            history.push(`/admin/forgot-password`);
          }, 1000);
        }
      });
  };
  useEffect(() => {
    dispatch(setloading(true));
    setTimeout(() => {
      dispatch(setloading(false));
    }, 1000);
  }, []);
  return (
    <>
      {!loading ? (
        <div className="main" style={{ background: "#141c24", height: "100vh" }}>
          <div className="signup">
            <div className="container" style={{ width: "80%" }}>
              <div className="signup-content">
                <div className="signup-form">
                  <h2
                    className="form-title"
                    style={{
                      textAlign: "left",
                      marginBottom: "1%",
                      marginTop: "-1%",
                      textDecoration: "underline",
                      textDecorationColor: " #93cf35",
                      color: "#222",
                      fontSize: "48px",
                    }}
                  >
                    {" "}
                    Corrsy.{" "}
                  </h2>
                  <p
                    style={{
                      marginLeft: "2%",
                      marginBottom: "5%",
                      fontSize: "17px",
                      color: "grey",
                    }}
                  >
                    # Learn Something New Everyday
                  </p>
                  <h4
                    style={{
                      marginBottom: "5%",
                      color: "darkgrey",
                      fontSize: "38px",
                    }}
                  >
                    Forgot Password??{" "}
                  </h4>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={(values) => {
                      sendForgotPasswordEmail(values);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="form-group">
                            {/* <label for="name"><i className="zmdi zmdi-account material-icons-name"></i></label> */}
                            <RiMailOpenFill style={{ fontSize: "20px" }} />
                            <Field
                              style={{ fontSize: "19px" }}
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Your Email"
                            />
                            <ErrorMessage name="email">
                              {(error) => (
                                <div
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  {error}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group form-button">
                            <input
                              type="submit"
                              name="signup"
                              id="signup"
                              className="form-submit"
                              value="Send Email"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="signup-image">
                  <div>
                    <img
                      src="https://img.freepik.com/free-vector/website-private-password-security-access-lock-notice-online-document-page-verification-login-code-web-internet-notification-authentication-flat-illustration_212005-149.jpg?size=626&ext=jpg"
                      alt="sing up image"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="content"
          style={{ background: "#141c24", height: "100vh" }}
        >
          <Row>
            <Col md="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
