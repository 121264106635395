import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from '../Redux/features/Auth/AuthSlice';
import adminReducer from '../Redux/features/Admin/AdminSlice';
import teacherReducer from '../Redux/features/Teacher/TeacherSlice';
import gradeReducer from '../Redux/features/Grade/GradeSlice';
import courseReducer from '../Redux/features/Course/CourseSlice';
import studentReducer from '../Redux/features/Student/StudentSlice';
import subjectReducer from '../Redux/features/Subject/SubjectSlice';
import quizReducer from '../Redux/features/Quiz/QuizSlice';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  teacher: teacherReducer,
  grade: gradeReducer,
  course: courseReducer,
  student: studentReducer,
  subject: subjectReducer,
  quiz: quizReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,

  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
