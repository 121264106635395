import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Common/Header';
import Loader from 'components/Common/Loader';
import { format } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
} from 'reactstrap';
import { getStudentAllRegisteredCourses } from 'Utils/registered_Course';
import userAvatar from '../../assets/img/anime3.png';
import { getStudentAllPoints } from 'Utils/students_Utils';

const StudentDetails = ({ registeredCourses }) => {

    const [studentDetails, setStudentDetails] = useState({});
    const [studentPoints, setStudentPoints] = useState({});
    const { studentID } = useParams();

    const getRegisteredCourses = async () => {
        setStudentDetails(registeredCourses);

        try {
            const result = await getStudentAllPoints(studentID);
            setStudentPoints(result.data.data);
        } catch (error) {
            toast.error(error?.response?.data?.message || error);
        }
    }

    const getTotalAmountPaid = () => studentDetails?.length && studentDetails.reduce((a, b) =>
        a += b.amountPaid, 0) + " IQD";

    const getTotalPoints = () => studentPoints?.length ? studentPoints.reduce((a, b) =>
        a += b.points, 0) : 0;


    const getPointSources = () => {
        const sources = studentPoints?.length && studentPoints.map(a => a.source);
        const unique = sources?.length ? [...new Set(sources)].join(", ") : "";
        return unique ? `${unique}` : "-"
    }

    const getStudentChapters = () => {

        const isHistoryExist = studentDetails?.length && studentDetails.map(e => {
            if (e?.history?.length) {
                const completedChapter = e?.history.filter(a => a.status === "complete");
                return completedChapter;
            }

            return null;
        });

        const historyList = isHistoryExist?.length ? isHistoryExist.filter((e) => { if (e?.length > 0) return e }) : null;
        const mergeList = historyList?.length ? historyList.reduce((a, b) => [...a, ...b], []) : null;
        const completedChapters = mergeList?.length ? mergeList.map(e => e?.chapter?.chapter_name) : null;
        return completedChapters?.length || 0;
    }

    useEffect(() => {
        getRegisteredCourses();
    }, [])


    const renderStudentProfileImage = (img) => (
        <div className="profile_img">
            <img src={img || userAvatar} alt="profile_img" />
        </div>
    )

    const renderStudentInfo = (label1, value1, label2, value2) => (
        <div className="info">
            <div className="left-part">
                <p>{label1}:</p>
                <h1>{value1}</h1>
            </div>
            <div className="right-part">
                <p>{label2}:</p>
                <h1>{value2}</h1>
            </div>
        </div>
    )


    const renderStudentDetailsContent = () => (
        < div className="student-content" >
            {renderStudentInfo("Email", studentDetails[0]?.student?.email || "-", "Referral Code",
                studentDetails[0]?.student?.referral_code || "-")}
            {renderStudentInfo("Platform", studentDetails[0]?.student?.platform || "-",
                'Status', studentDetails[0]?.student?.isActive ? "Active" : "In-Active")}
            {renderStudentInfo("Phone No", studentDetails[0]?.student?.phone_number || "-", "City",
                studentDetails[0]?.student?.city || "-")}
            {renderStudentInfo('Status', studentDetails[0]?.student?.isActive ? "Active" : "In-Active",
                'Total Registered Courses', studentDetails?.length || 0)}
            {renderStudentInfo('Amount Paid', getTotalAmountPaid(),
                'School', studentDetails[0]?.student?.school || "-")}
            {renderStudentInfo('Total points', getTotalPoints(),
                'Points sources', getPointSources())}
            {renderStudentInfo('Date Created', studentDetails[0]?.student?.date_created ?
                format(new Date(studentDetails[0]?.student?.date_created), 'PPpp') : "-",
                'Completed Chapters', getStudentChapters())}
        </div >
    )

    const renderStudentDetailsWrapper = () => (
        <div className='student_detail-wrapper' >
            {renderStudentProfileImage(studentDetails[0]?.student?.profile_image || "")}
            {renderStudentDetailsContent()}
        </div>
    )

    return <div className='student_detail content' >
        {renderStudentDetailsWrapper()}
    </div>;
};

StudentDetails.propTypes = {
    registeredCourses: PropTypes.array.isRequired
};

export default React.memo(StudentDetails);
