import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Row, Col } from "reactstrap";
import Loader from "../Common/Loader";
import Header from "../Common/Header";
import { getRegisteredCourseList } from "../../Utils/registered_Course";
import ReportsList from "./ReportsList";
import Select from "react-select";
import { getReportForUninstalls } from "Utils/reports.Utils";
import { format } from "date-fns";
import UserJourneyReport from "./UserJourneyReport";
import { getReportForUserJourney } from "Utils/reports.Utils";
import LiveSessionReports from "./LiveSessionReports";
import { getReportsLiveSessions } from "Utils/reports.Utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import AppSessionReport from "./AppSessionReport";
import TopicsReport from "./TopicsReport";
import { setTopicUniCodes } from "Redux/features/Subject/SubjectSlice";
import { getUniCodeStats } from "Utils/uniCode_Utils";
import { getTestUniCodeStats } from "Utils/uniCode_Utils";
import { setQuizUniCodes } from "Redux/features/Subject/SubjectSlice";
import QuizReports from "./QuizReports";
import UnInstallReports from "./UnInstallReports";
import UserPlatformReport from "./UserPlatformReport";
import GradeReport from "./GradeReport";
import UserStickiness from "./UserStickiness";
import PurchaseReports from "./PurchaseReports";

const options = [
  { value: "platform", label: "Students Platform" },
  { value: "grade", label: "Grades Report" },
  { value: "appSession", label: "User’s average time spent within the App" },
  { value: "purchase", label: "Grade/Subject Purchased" },
  { value: "liveSession", label: "Live sessions" },
  { value: "topicView", label: "Topics and Quiz Journey" },
  { value: "uninstall", label: "Installs or Uninstalls App" },
  { value: "userStickiness", label: "User Stickiness" },
  // { value: "userJourney", label: "User Journey" },
  // { value: "students", label: "Students" },
  // { value: "quizView", label: "Quiz Journey" },
  // { value: "lastVisit", label: "Last Visit of Students" },
];

function ReportsWrapper({}) {
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [reportDetails, setReportDetails] = useState(false);
  const [reportType, setReportType] = useState("");

  const fetchReportForPurchase = async () => {
    setLoading(true);
    try {
      const resp = await getRegisteredCourseList();
      setReportDetails(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReportForUninstalls = async () => {
    setLoading(true);
    try {
      const resp = await getReportForUninstalls();
      // String(startDate),
      // String(endDate)

      setReportDetails(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReportForStudents = async () => {
    setLoading(true);
    try {
      setReportDetails([]);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const setStudentName = (user) =>
    user.user_name
      ? user.user_name
      : user.first_name
      ? `${user.first_name} ${user.last_name}`
      : "N/A";

  const arrangeUserJourneyData = (list) =>
    list?.length > 0 &&
    list
      .map((e) =>
        !e.userID
          ? false
          : e?.journeys?.length > 0 &&
            e?.journeys.map((x) => {
              return {
                studentName: e.userID ? setStudentName(e.userID) : "-",
                screenName: x?.screenName || "-",
                durationInMin: x?.durationInMin.toFixed(2) || 0,
                date: x?.date || "-",
                uniCode: x?.uniCode || "-",
                status: Object.keys(x).includes("status") ? x.status : "-",
              };
            })
      )
      .filter((e) => e?.length > 0)
      .reduce((a, b) => [...a, ...b], []);

  const arrangeLiveSessionData = (list) =>
    list?.length > 0 &&
    list
      .map((e) =>
        !e.userID
          ? false
          : e?.liveSessions?.length > 0 &&
            e?.liveSessions.map((x) => {
              return {
                studentName: e.userID ? setStudentName(e.userID) : "-",
                grade: x?.grade?.grade || "-",
                subject: x?.subject?.Subject_name || "-",
                sessionDate: x?.sessionDate || "-",
                sessionTime: x?.sessionTime || "-",
                sessionTitle: x?.sessionTitle || "-",
                sessionStatus: x?.sessionStatus || "-",
              };
            })
      )
      .filter((e) => e?.length > 0)
      .reduce((a, b) => [...a, ...b], []);

  const fetchReportForUserJourney = async () => {
    setLoading(true);
    try {
      const resp = await getReportForUserJourney();
      const list = await arrangeUserJourneyData(resp.data.data);
      setReportDetails(list);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReportForLiveSession = async () => {
    setLoading(true);
    try {
      const resp = await getReportsLiveSessions();
      const list = await arrangeLiveSessionData(resp.data.data);
      setReportDetails(list);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTopicUniCodes = async () => {
    setLoading(true);
    try {
      const [uniCodesList, testUniCodes] = await Promise.all([
        getUniCodeStats(),
        getTestUniCodeStats(),
      ]);

      const list = uniCodesList.data.data.map((e) => {
        return {
          id: e?._id,
          topicName: e?.topic_title || "-",
          chapterName: e?.chapter?.chapter_name || "-",
          subjectName: e?.subject?.Subject_name || "-",
          gradeName: e?.grade?.grade || "-",
          uniCode: `${e?.grade?.uniCode}-${e?.subject?.uniCode}-${e?.chapter?.uniCode}-${e?.uniCode}`,
        };
      });
      const topicList = testUniCodes.data.data.map((e) => {
        return {
          id: e?._id,
          quizName: e?.test_title || "-",
          chapterName: e?.chapter?.chapter_name || "-",
          subjectName: e?.subject?.Subject_name || "-",
          gradeName: e?.subject?.grade?.grade || "-",
          uniCode: `${e?.subject?.grade?.uniCode}-${e?.subject?.uniCode}-${e?.chapter?.uniCode}-${e?.uniCode}`,
        };
      });
      dispatch(setTopicUniCodes(list));
      dispatch(setQuizUniCodes(topicList));
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onChangeReportTypeHandler = (e) => {
    setReportType(e);

    if (e.value === "students") return;

    fetchDataByReports(e.value);
  };

  const fetchDataByReports = (value) => {
    switch (value) {
      // case "purchase":
      //   fetchReportForPurchase();
      //   break;
      case "uninstalls":
        fetchReportForUninstalls();
        break;
      case "students":
        fetchReportForStudents();
        break;
      case "userJourney":
        fetchReportForUserJourney();
        break;
      case "liveSession":
        fetchReportForLiveSession();
        break;
      // case "appSession":
      //   // fetchReportForAppSession();
      //   fetchReportForUninstalls();
      //   break;

      default:
        setReportDetails([]);
        break;
    }
  };

  const getReports = () => fetchDataByReports(reportType?.value);

  const renderTotalInfo = () => (
    <div className="row total_info">
      <div className="col-md-4">
        <p>Total logins:</p>
        <h1>{reportDetails?.totalDownload || 0}</h1>
      </div>
      <div className="col-md-4">
        <p>Total Uninstalls:</p>
        <h1>{reportDetails?.totalUninstalls || 0}</h1>
      </div>
      <div className="col-md-4">
        <p>No token:</p>
        <h1>{reportDetails?.totalUserWithoutToken || 0}</h1>
      </div>
    </div>
  );

  const renderReports = (report) => {
    switch (report) {
      case "uninstalls":
        return loading ? <Loader /> : reportDetails && renderTotalInfo();
      case "userJourney":
        return loading ? (
          <Loader />
        ) : (
          <UserJourneyReport userJourneyReportList={reportDetails} />
        );

      case "purchase":
        return <PurchaseReports />;

      case "liveSession":
        return loading ? (
          <Loader />
        ) : (
          <LiveSessionReports liveSessionReportList={reportDetails} />
        );

      case "appSession":
        return <AppSessionReport />;

      case "topicView":
        return <TopicsReport />;

      case "quizView":
        return <QuizReports />;

      case "uninstall":
        return <UnInstallReports />;

      case "platform":
        return <UserPlatformReport />;

      case "grade":
        return <GradeReport />;

      case "userStickiness":
        return <UserStickiness />;

      case "students":
        return loading ? <Loader /> : <h1>Report 2</h1>;

      default:
        return <h6 className="mt-4"> No report select</h6>;
    }
  };

  useEffect(() => {
    fetchDataByReports("");
    fetchTopicUniCodes();
  }, []);
  return (
    <div className="reports content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Header title="Reports" location="/admin/dashboard" />
              <p>Select report type</p>
              <Select
                value={reportType}
                onChange={onChangeReportTypeHandler}
                placeholder="Select report type"
                className="react-options"
                options={options}
                isClearable
              />
              {renderReports(reportType?.value)}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ReportsWrapper;
