import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-awesome-modal';

function ModalContainer({
  isOpen,
  modalWidth,
  animateEffect,
  onConfirmClick,
  children,
  heading,
}) {
  return (
    <Modal
      visible={isOpen}
      width={modalWidth}
      effect={animateEffect}
    // onClickAway={onConfirmClick}
    >
      <div className="modal_container">
        <h4 className="heading">{heading}</h4>
        <button className="cross-btn" onClick={onConfirmClick}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      {children}
    </Modal>
  );
}

ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalWidth: PropTypes.string.isRequired,
  animateEffect: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
};

export default ModalContainer;
