import Loader from "components/Common/Loader";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { getLiveSessions } from "Utils/live_session_Utils";
import { FaCheckCircle, FaRegEdit, FaBan } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ModalContainer from "components/Common/ModalContainer";
import { updateLiveSession } from "Utils/live_session_Utils";
import { useSelector } from "react-redux";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import { getTeacherLiveSessions } from "Utils/live_session_Utils";
import session from "../../assets/img/session.jpg";

const LiveSessionsList = () => {
  const history = useHistory();
  const userData = useSelector(selectUserData);

  const [loading, setLoading] = useState(false);
  const [sessionsList, setSessionsList] = useState([]);
  const [sessionsObj, setSessionsObj] = useState({});
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [isOpenSessionModal, setIsOpenSessionModal] = useState(false);

  const fetchInitialData = async () => {
    setLoading(true);

    try {
      const resp =
        userData?.role === "teacher"
          ? await getTeacherLiveSessions(userData?._id)
          : await getLiveSessions();
      setSessionsList(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async () => {
    try {
      const data = sessionsObj;
      data.isActive = !sessionsObj.isActive;
      const resp = await updateLiveSession(data);
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      handleOnClose();
      fetchInitialData();
    }
  };

  const handleOnClose = () => setIsOpenStatusModal(false);
  const handleOpen = () => setIsOpenStatusModal(true);

  const changeSessionStatusModal = () =>
    setIsOpenSessionModal(!isOpenSessionModal);

  const setSessionTimeFormat = (time) => {
    const [hours, minutes] = time?.split(":");
    return format(
      new Date(new Date().setHours(Number(hours), Number(minutes), 0)),
      "pp"
    );
  };

  const updateSessionStatus = async (id, status) => {
    try {
      const data = { _id: id, sessionStatus: status };
      const resp = await updateLiveSession(data);
      toast.success(resp.data.message);
      fetchInitialData();
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      changeSessionStatusModal();
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const renderSessionInfo = (icon, value) => {
    return (
      <div className="date">
        <i className={icon} i />
        <p title={value}>{value}</p>
      </div>
    );
  };

  const renderLiveSessionContent = () => (
    <div className="row">
      {sessionsList?.length ? (
        sessionsList.map((e, i) => (
          <div className="col-md-6">
            <div className="session_card">
              <div className="image">
                <img
                  src={e?.user?.profile_image || session}
                  alt="session_img"
                />
              </div>
              <div className="info">
                <h1>{e?.sessionTitle}</h1>

                <div className="row_data">
                  {renderSessionInfo(
                    "fa fa-user",
                    e?.user?.user_name
                      ? e?.user?.user_name
                      : `${e?.user?.first_name} ${e?.user?.last_name}`
                  )}
                  {renderSessionInfo("fa fa-book", e?.subject?.Subject_name)}
                </div>

                <div className="row_data">
                  {renderSessionInfo(
                    "fas fa-calendar",
                    e?.sessionDate
                      ? format(new Date(e?.sessionDate), "PP")
                      : "-"
                  )}
                  {renderSessionInfo(
                    "fas fa-clock",
                    setSessionTimeFormat(e?.sessionTime) || "-"
                  )}
                </div>

                <div className="actions">
                  {e?.sessionStatus === "scheduled" && (
                    <>
                      {userData?.role !== "teacher" && (
                        <>
                          <FaRegEdit
                            className="edit"
                            onClick={() =>
                              history.push(`/admin/session/form/${e?._id}`)
                            }
                            title="Edit session"
                          />
                          {e?.isActive ? (
                            <FaCheckCircle
                              className="active"
                              title="change status"
                              onClick={() => {
                                setSessionsObj(e);
                                handleOpen();
                              }}
                            />
                          ) : (
                            <FaBan
                              className="in-active"
                              title="change status"
                              onClick={() => {
                                setSessionsObj(e);
                                handleOpen();
                              }}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}

                  {e?.sessionStatus !== "end" && (
                    <button
                      onClick={() => {
                        setSessionsObj(e);
                        changeSessionStatusModal();
                      }}
                    >
                      {e?.sessionStatus === "scheduled"
                        ? "Start Session"
                        : "End Session"}
                    </button>
                  )}
                </div>

                <div className="status">
                  {e?.sessionStatus !== "end" && (
                    <a
                      className="join_now"
                      href={`${
                        e?.sessionLink.includes("http")
                          ? e?.sessionLink
                          : "https://" + e?.sessionLink
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join session
                    </a>
                  )}

                  {e?.sessionStatus === "scheduled" ? (
                    <p className="not_live">Coming soon</p>
                  ) : e?.sessionStatus === "live" ? (
                    <p className="live">Live</p>
                  ) : (
                    <p className="expired">Session ended</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <h3 className="no_data">Live sessions not created yet</h3>
      )}

      <ModalContainer
        isOpen={isOpenStatusModal}
        onConfirmClick={handleOnClose}
        modalWidth="600"
        animateEffect="fadeInUp"
        heading="Change Status"
      >
        {sessionsObj?._id && (
          <div className="session-status">
            <h1>
              {sessionsObj?.isActive
                ? "Are you sure you want to restrict this session?"
                : "Are you sure you want to change this session status to active?"}
            </h1>

            <div className="text-right">
              <button
                id="btn-status"
                className="btn btn-primary"
                onClick={updateStatus}
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </ModalContainer>

      <ModalContainer
        isOpen={isOpenSessionModal}
        onConfirmClick={changeSessionStatusModal}
        modalWidth="600"
        animateEffect="fadeInUp"
        heading="Change Session Status"
      >
        {sessionsObj?._id && (
          <div className="session-status">
            <h1>
              {`Are you sure you want to ${
                sessionsObj?.sessionStatus === "scheduled" ? "start" : "end"
              } this session? ${
                sessionsObj?.sessionStatus === "scheduled"
                  ? "Student will also able to view this session"
                  : ""
              }`}
            </h1>

            <div className="text-right">
              <button
                id="btn-status"
                className="btn btn-primary"
                onClick={() =>
                  updateSessionStatus(
                    sessionsObj?._id,
                    sessionsObj?.sessionStatus === "scheduled" ? "live" : "end"
                  )
                }
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </ModalContainer>
    </div>
  );

  return (
    <div className="live_session-list">
      {loading ? <Loader /> : renderLiveSessionContent()}
    </div>
  );
};

// LiveSessionsList.propTypes = {};

export default LiveSessionsList;
