// import PropTypes from "prop-types";
import Select from "react-select";
import Header from "components/Common/Header";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTeachersList,
  setTeacherList,
} from "Redux/features/Teacher/TeacherSlice";
import { useEffect } from "react";
import { getAllTeachersData } from "Utils/teacher_Utils";
import { getTeachersGradesSubjects } from "Utils/teacher_Utils";
import { setGrade } from "Redux/features/Grade/GradeSlice";
import { getAllChapterForSubject } from "Utils/chapterPreview_Utils";
import { getAllTopicsForChapter } from "Utils/topic_quiz_Utils";
import { format } from "date-fns";
import Loader from "components/Common/Loader";
import { saveLiveSession } from "Utils/live_session_Utils";
import { getLiveSessions } from "Utils/live_session_Utils";
import { updateLiveSession } from "Utils/live_session_Utils";
import { selectUserData } from "Redux/features/Auth/AuthSlice";

const LiveSessionForm = () => {
  const history = useHistory();
  const allTeachersList = useSelector(selectTeachersList);
  const dispatch = useDispatch();
  const param = useParams();
  const userData = useSelector(selectUserData);

  const { liveSessionID } = param;

  const [loading, setLoading] = useState(false);
  const [teachersDropdown, setTeacherDropdown] = useState([]);
  const [teacherObj, setTeacherObj] = useState({});
  const [teacher, setTeacher] = useState({});
  const [grade, setGrade] = useState({});
  const [subject, setSubject] = useState({});
  // const [chapterDropdown, setChapterDropdown] = useState([]);
  // const [chapter, setChapter] = useState({});
  // const [topicDropdown, setTopicDropdown] = useState([]);
  // const [topic, setTopic] = useState({});
  const [sessionObj, setSessionObj] = useState({});
  const [sessionTitle, setSessionTitle] = useState("");
  const [sessionMedium, setSessionMedium] = useState("meeting_link");
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [sessionDate, setSessionDate] = useState("");
  const [sessionTime, setSessionTime] = useState("");
  const [sessionLink, setSessionLink] = useState("");
  const [sessionID, setSessionID] = useState("");
  const [sessionPassword, setSessionPassword] = useState("");
  const [sessionDescription, setSessionDescription] = useState("");

  const arrangeTeacherDropdown = async (data) => {
    if (userData?.role === "teacher") {
      setTeacher({
        value: userData?._id,
        label: userData.user_name
          ? userData.user_name
          : `${userData?.first_name}  ${userData?.last_name}`,
      });

      try {
        const result = await getTeachersGradesSubjects(userData?._id);
        setTeacherObj(result.data.data[0]);
      } catch (error) {
        toast.error(error?.response?.data?.message || error);
      }
    }

    return data?.length
      ? data.map((e) => {
          return {
            value: e?._id,
            label: e.user_name
              ? e.user_name
              : `${e?.first_name}  ${e?.last_name}`,
          };
        })
      : [];
  };

  const resetStatesForTeacher = () => {
    setGrade({});
    setSubject({});
    // setChapter({});
    // setTopic({});
    // setChapterDropdown([]);
    // setTopicDropdown([]);
  };

  // const resetStatesForGrades = () => {
  //   setSubject({});
  //   setChapter({});
  //   setTopic({});
  //   setChapterDropdown([]);
  //   setTopicDropdown([]);
  // };

  // const resetStatesForSubjects = () => {
  //   setChapter({});
  //   setChapterDropdown([]);
  //   setTopic({});
  //   setTopicDropdown([]);
  // };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      if (liveSessionID) {
        const resp = await getLiveSessions(liveSessionID);

        const { data: sessionData } = resp.data;

        setSessionObj(sessionData);

        const result = await getTeachersGradesSubjects(sessionData?.user?._id);
        setTeacherObj(result.data.data[0]);

        setTeacher({
          value: sessionData?.user?._id,
          label: sessionData?.user?.user_name
            ? sessionData?.user?.user_name
            : `${sessionData?.user?.first_name}  ${sessionData?.user?.last_name}`,
        });
        setGrade({
          value: sessionData?.grade?._id,
          label: sessionData?.grade?.grade,
        });

        setSubject({
          value: sessionData?.subject?._id,
          label: sessionData?.subject?.Subject_name,
        });

        setSessionTitle(sessionData?.sessionTitle || "");
        setSessionDate(
          format(new Date(sessionData?.sessionDate), "yyyy-MM-dd")
        );

        setSessionTime(sessionData?.sessionTime || "");
        setSessionMedium(sessionData?.sessionMedium);
        setIsShowPassword(sessionData?.sessionMedium === "meeting_link");
        setSessionID(sessionData?.sessionID || "");
        setSessionLink(sessionData?.sessionLink || "");
        setSessionPassword(sessionData?.sessionPassword || "");
        setSessionDescription(sessionData?.sessionDescription || "");
      }

      if (allTeachersList !== null && allTeachersList?.length > 0) {
        const list = await arrangeTeacherDropdown(allTeachersList);
        setTeacherDropdown(list);
        return;
      }
      
      const resp = await getAllTeachersData();
      
      const list = await arrangeTeacherDropdown(resp.data.data);
      setTeacherDropdown(list);
      dispatch(setTeacherList(resp.data.data));
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchAllTopicForChapter = async (chapterID) => {
  //   try {
  //     const resp = await getAllTopicsForChapter(chapterID);

  //     const list = resp.data.data.length
  //       ? resp.data.data.map((e) => {
  //           return { value: e?._id, label: e?.topic_title };
  //         })
  //       : [];

  //     setTopicDropdown(list);
  //   } catch (error) {
  //     console.log(error?.response?.data?.message || error);
  //   }
  // };

  // const fetchAllChapterForSubject = async (subjectID) => {
  //   try {
  //     const resp = await getAllChapterForSubject(subjectID);

  //     const list = resp.data.data.length
  //       ? resp.data.data.map((e) => {
  //           return { value: e?._id, label: e?.chapter_name };
  //         })
  //       : [];

  //     setChapterDropdown(list);
  //   } catch (error) {
  //     console.log(error?.response?.data?.message || error);
  //   }
  // };

  // const onChangeTopicHandler = (e) => {
  //   setTopic(e);
  // };

  // const onChangeChapterHandler = (e) => {
  //   setChapter(e);

  //   if (!e) {
  //     setTopic({});
  //     setTopicDropdown([]);
  //     return;
  //   }

  //   const { value: chapterID } = e;

  //   if (chapter?.value === chapterID) {
  //     return;
  //   }

  //   setTopic({});
  //   setTopicDropdown([]);

  //   fetchAllTopicForChapter(chapterID);
  // };

  const onChangeGradeHandler = async (e) => {
    setGrade(e);

    if (!e) {
      // resetStatesForGrades();
      setSubject({});
      return;
    }

    const { value: gradeID } = e;

    if (grade?.value === gradeID) {
      return;
    }

    // resetStatesForGrades();
    setSubject({});
  };

  const onChangeSubjectHandler = async (e) => {
    setSubject(e);

    // if (!e) {
    //   resetStatesForSubjects();
    //   return;
    // }

    // const { value: subjectID } = e;

    // if (subject?.value === subjectID) {
    //   return;
    // }

    // resetStatesForSubjects();

    // fetchAllChapterForSubject(subjectID);
  };

  const onChangeTeacherHandler = async (e) => {
    setTeacher(e);

    if (!e) {
      resetStatesForTeacher();
      return;
    }

    const { value: teacherID } = e;

    if (teacher?.value === teacherID) {
      return;
    }

    resetStatesForTeacher();
    try {
      const resp = await getTeachersGradesSubjects(teacherID);
      setTeacherObj(resp.data.data[0]);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
  };

  const _submit = async (e) => {
    e.preventDefault();

    if (!teacher?.value || !grade?.value || !subject?.value) {
      toast.error("Fill the required fields");
      return;
    }

    const liveSessionObj = {
      user: teacher?.value,
      grade: grade?.value,
      subject: subject?.value,
      sessionTitle,
      sessionDate,
      sessionTime,
      sessionMedium,
      sessionLink,
      sessionID,
      sessionPassword,
      sessionDescription,
      sessionStatus: "scheduled",
    };

    setLoading(true);
    try {
      let resp = null;

      if (liveSessionID) {
        liveSessionObj._id = sessionObj?._id;
        resp = await updateLiveSession(liveSessionObj);
      } else {
        resp = await saveLiveSession(liveSessionObj);
      }

      toast.success(resp.data.message);
      setTimeout(() => {
        setLoading(false);
        history.push("/admin/live/session");
      }, 100);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const renderErrorSpan = () => (
    <span style={{ color: "red", fontSize: "1.1rem", fontWeight: "500" }}>
      *
    </span>
  );

  const renderSessionForm = () => (
    <form id="session_form" onSubmit={_submit}>
      <div className="row">
        <div className="col-md-4 mb-3">
          <p>Session title {renderErrorSpan()}</p>
          <input
            value={sessionTitle}
            className="form-control"
            id="session_title"
            onChange={(e) => setSessionTitle(e.target.value)}
            placeholder="Enter session title"
            required
          />
        </div>
        <div className="col-md-4 mb-3">
          <p>Select teacher {renderErrorSpan()}</p>
          <Select
            value={teacher}
            onChange={onChangeTeacherHandler}
            placeholder="Select Teacher"
            className="react-options"
            options={teachersDropdown}
            isClearable
            isDisabled={userData?.role === "teacher"}
          />
        </div>

        <div className="col-md-4 mb-3">
          <p>Select grade {renderErrorSpan()}</p>
          <Select
            value={grade}
            onChange={onChangeGradeHandler}
            placeholder="Select Grade"
            className="react-options"
            options={
              teacherObj?.gradesList?.length &&
              teacherObj?.gradesList.map((e) => {
                return { value: e?._id, label: e?.grade };
              })
            }
            isClearable
          />
        </div>

        <div className="col-md-4 mb-3">
          <p>Select subject {renderErrorSpan()}</p>
          <Select
            value={subject}
            onChange={onChangeSubjectHandler}
            placeholder="Select Subject"
            className="react-options"
            options={
              grade?.value &&
              teacherObj?.subjectsList?.length &&
              teacherObj?.subjectsList
                .map((e) => {
                  if (String(e?.grade) === String(grade?.value))
                    return { value: e?._id, label: e?.Subject_name };
                })
                .filter((a) => Boolean(a))
            }
            isClearable
          />
        </div>

        <div className="col-md-4 mb-3">
          <p>Select Date {renderErrorSpan()}</p>
          <input
            type="date"
            value={
              sessionDate ? format(new Date(sessionDate), "yyyy-MM-dd") : ""
            }
            className="form-control"
            id="session_date"
            min={
              liveSessionID && sessionDate
                ? format(new Date(sessionDate), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM-dd")
            }
            onChange={(e) => {
              setSessionDate(e.target.value);
            }}
            placeholder="Enter session date"
            required
          />
        </div>
        <div className="col-md-4 mb-3">
          <p>Select Session time {renderErrorSpan()}</p>
          <input
            type="time"
            value={sessionTime}
            className="form-control"
            id="session_time"
            onChange={(e) => {
              setSessionTime(e.target.value);
            }}
            placeholder="Enter session time"
            required
          />
        </div>
        {/* <div className="col-md-4 mb-3">
          <p>Select Chapter</p>
          <Select
            onChange={onChangeChapterHandler}
            value={chapter}
            placeholder="Select Chapter"
            className="react-options"
            options={chapterDropdown}
            isClearable
          />
        </div>
        <div className="col-md-4 mb-3">
          <p>Select Topic</p>
          <Select
            onChange={onChangeTopicHandler}
            value={topic}
            placeholder="Select Topic"
            className="react-options"
            options={topicDropdown}
            isClearable
          />
        </div> */}

        {/* <div className="col-md-4 mb-3">
          <p>Session platform</p>
          <select
            id="session_platform"
            className="form-control"
            value={sessionMedium}
            onChange={(e) => {
              setIsShowPassword(
                e.target.value === "meeting_link" ? true : false
              );

              setSessionMedium(e.target.value);

              if (e.target.value === "meeting_link") {
                setSessionPassword("");
                setSessionID("");
              } else {
                setSessionLink("");
              }
            }}
          >
            <option value="meeting_link">Meeting Link</option>
            <option value="meeting_id">Meeting ID</option>
          </select>
        </div> */}

        {isShowPassword ? (
          <div className="col-md-12 mb-3">
            <p>Meeting link {renderErrorSpan()}</p>
            <input
              type="text"
              value={sessionLink}
              className="form-control"
              id="session_link"
              onChange={(e) => {
                setSessionLink(e.target.value);
              }}
              placeholder="https://meet.google.com/xxx-xxxx-xxx"
              required
            />
          </div>
        ) : (
          <>
            <div className="col-md-4 mb-3">
              <p>Meeting ID {renderErrorSpan()}</p>
              <input
                type="text"
                value={sessionID}
                className="form-control"
                id="session_ID"
                onChange={(e) => {
                  setSessionID(e.target.value);
                }}
                placeholder="Enter meeting ID"
                required
              />
            </div>
            <div className="col-md-4 mb-3">
              <p>Meeting password {renderErrorSpan()}</p>
              <input
                type="text"
                value={sessionPassword}
                className="form-control"
                id="session_password"
                onChange={(e) => {
                  setSessionPassword(e.target.value);
                }}
                placeholder="Enter password"
                required
              />
            </div>
          </>
        )}

        <div className="col-md-12 mb-3">
          <p>Session description</p>
          <textarea
            name=""
            id="session_description"
            value={sessionDescription}
            className="w-100"
            // cols="30"
            rows="5"
            onChange={(e) => {
              setSessionDescription(e.target.value);
            }}
            placeholder="Enter session description"
          ></textarea>
        </div>
      </div>

      <div className="text-right">
        <button type="submit" className="btn btn-secondary">
          {liveSessionID ? "Update" : "Create"} session
        </button>
      </div>
    </form>
  );

  console.log(sessionDate);
  return (
    <div className="live-session-form content">
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col md="12">
            <Card>
              <Header title="Create Session" />
              <CardBody>{renderSessionForm()}</CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

// LiveSessionForm.propTypes = {};

export default LiveSessionForm;
