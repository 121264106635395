import React from "react";
import PropTypes from "prop-types";
import { CSVLink, CSVDownload } from "react-csv";

const DownloadCSV = ({ excelHeader, excelData, excelFilename }) => {
  return (
    <div>
      <CSVLink
        className="btn btn-secondary"
        data={excelData}
        headers={excelHeader}
        filename={excelFilename}
      >
        Download CSV
      </CSVLink>
    </div>
  );
};

DownloadCSV.propTypes = {
  excelHeader: PropTypes.object.isRequired,
  excelData: PropTypes.object.isRequired,
  excelFilename: PropTypes.object.isRequired,
};

export default DownloadCSV;
