import backend from "../api";

var createNormalPopulate = [
  {
    path: "topic",
    select: "topic_title grade",
  },
  {
    path: "user",
    select: "user_name first_name last_name profile_image reg_token",
  },
  {
    path: "grade",
    select: "grade",
  },
  {
    path: "subject",
    select: "Subject_name",
  },
  {
    path: "chapter",
    select: "chapter_name",
  },
];

var createDetailedPopulated = [
  {
    path: "topic",
    select: "topic_title grade",
  },
  {
    path: "user",
    select: "user_name first_name last_name profile_image role reg_token",
  },
  {
    path: "grade",
    select: "grade",
  },
  {
    path: "subject",
    select: "Subject_name",
  },
  {
    path: "chapter",
    select: "chapter_name",
  },
  {
    path: "answers.user",
    select: "user_name first_name last_name profile_image role email",
  },
  {
    path: "answers.superAdmin",
    select: "email",
  },
];

const normalPopulated = encodeURI(JSON.stringify(createNormalPopulate));
const detailedPopulated = encodeURI(JSON.stringify(createDetailedPopulated));

export const fetchAllComments = async (commentID) =>
  await backend({
    method: "GET",
    url: commentID
      ? `/comments?_id=${commentID}&populate=${detailedPopulated}`
      : `/comments?populate=${normalPopulated}`,
  });

export const saveCommentAnswer = async (commentID, commentObj) =>
  await backend({
    method: "PUT",
    url: `/comment/answer/${commentID}`,
    data: commentObj,
  });

export const updateCommentAnswerPin = async (commentID, answerID, data) =>
  await backend({
    method: "PUT",
    url: `/comment/pin/answer/${commentID}/${answerID}`,
    data: data,
  });

export const fetchAllCommentsPagination = async (limit, startIndex) =>
  await backend({
    method: "GET",
    url: `comment/pagination?limit=${limit}&startIndex=${startIndex}&populate=${normalPopulated}`,
  });

export const deleteComments = async (commentID, data) =>
  await backend({
    method: "PUT",
    url: `/comment/change/status/${commentID}`,
    data: data,
  });

export const deleteCommentAnswer = async (commentID, answerID, data) =>
  await backend({
    method: "PUT",
    url: `/comment/answer/change/status/${commentID}/${answerID}`,
    data: data,
  });

export const updateCommentAnswer = async (commentID, answerID, data) =>
  await backend({
    method: "PUT",
    url: `/comment/answer/${commentID}/${answerID}`,
    data: data,
  });

export const updateCommentQuestion = async (commentID, data) =>
  await backend({
    method: "PUT",
    url: `/comment/${commentID}`,
    data: data,
  });
