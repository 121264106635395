import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import Header from "components/Common/Header";
import { useParams } from "react-router-dom";
import { getUniCodeStats } from "Utils/uniCode_Utils";
import { getChapterQuizUniCode } from "Utils/uniCode_Utils";
import { getChapterTopicUniCode } from "Utils/uniCode_Utils";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragHandle } from "components/CreateSequence/partials/DragHandle";
import { updateTopicsUniCodes } from "Utils/uniCode_Utils";
import { updateQuizzesUniCodes } from "Utils/uniCode_Utils";
import Loader from "components/Common/Loader";

const UpdateUniCodeWrapper = (props) => {
  const param = useParams();

  const [activeTab, setActiveTab] = useState("topic");
  const [topicsUniCode, setTopicsUniCode] = useState([]);
  const [quizUniCodes, setQuizUniCodes] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const { chapterID } = param;

  const onChangeTab = (e) => setActiveTab(e);

  const setDataForRender = () => {
    if (activeTab === "topic") {
      return topicsUniCode?.length
        ? topicsUniCode.map((x) => {
            x.name = x?.topic_title;
            return x;
          })
        : null;
    } else {
      return quizUniCodes?.length
        ? quizUniCodes.map((x) => {
            x.name = x?.test_title;
            return x;
          })
        : null;
    }
  };

  const updateListHandler = (param) => {
    const srcI = param?.source?.index;
    const desI = param?.destination?.index;

    if (desI === undefined || srcI === desI) return;

    const [...rest] = activeTab === "topic" ? topicsUniCode : quizUniCodes;
    const item = rest[srcI];
    const temp = rest.filter((e, i) => i != srcI);
    const updateList = [...temp.slice(0, desI), item, ...temp.slice(desI)];

    if (activeTab === "topic") setTopicsUniCode(updateList);
    else setQuizUniCodes(updateList);
  };

  const arrangeUniCodes = () => {
    if (activeTab === "topic") {
      const [...rest] = topicsUniCode;
      setTopicsUniCode(
        rest.map((e, i) => {
          e.uniCode = `L${i + 1}`;
          return e;
        })
      );

      return;
    }

    const [...rest] = quizUniCodes;
    setQuizUniCodes(
      rest.map((e, i) => {
        e.uniCode = `Q${i + 1}`;
        return e;
      })
    );
  };

  const updateUniCodes = async () => {
    try {
      setLoading(true);
      let resp = null;
      if (activeTab === "topic") {
        resp = await updateTopicsUniCodes(topicsUniCode);
      } else {
        resp = await updateQuizzesUniCodes(quizUniCodes);
      }

      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const [topicUniCodes, quizUniCodeList] = await Promise.all([
        getChapterTopicUniCode(chapterID),
        getChapterQuizUniCode(chapterID),
      ]);

      const sortTopics = topicUniCodes.data.data.sort((a, b) =>
        a.uniCode.localeCompare(b.uniCode)
      );
      const sortQuizzes = quizUniCodeList.data.data.sort((a, b) =>
        a.uniCode.localeCompare(b.uniCode)
      );
      setTopicsUniCode(sortTopics);
      setTopics(sortTopics);
      setQuizUniCodes(sortQuizzes);
      setQuizzes(sortQuizzes);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <div className="content">
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col md="12">
            <Card>
              <CardTitle>
                <Header title="Uni Codes Update" />
              </CardTitle>
              <CardBody>
                <div className="update-uniCode-wrapper">
                  <nav>
                    <p
                      className={activeTab === "topic" && "active"}
                      onClick={() => onChangeTab("topic")}
                    >
                      Topic UniCodes
                    </p>
                    <p
                      className={activeTab === "quiz" && "active"}
                      onClick={() => onChangeTab("quiz")}
                    >
                      Quiz UniCodes
                    </p>
                  </nav>

                  <div className="actions">
                    <button
                      className="btn btn-secondary"
                      onClick={arrangeUniCodes}
                    >
                      Arrange UniCodes
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={updateUniCodes}
                      // disabled={
                      //   JSON.stringify(topics) !==
                      //   JSON.stringify(topicsUniCode)
                      // }
                      style={{ color: "white" }}
                    >
                      Update Changes
                    </button>
                  </div>

                  <div className="uniCode">
                    <DragDropContext onDragEnd={updateListHandler}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {setDataForRender()?.length ? (
                              setDataForRender().map((item, index) => (
                                <Draggable
                                  key={item?._id}
                                  draggableId={item?._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className="uniCode-list"
                                    >
                                      <div>
                                        <DragHandle
                                          {...provided.dragHandleProps}
                                        />
                                        {item?.name ?? null}
                                      </div>

                                      <p>
                                        <b> {item?.uniCode} </b>
                                      </p>
                                    </div>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <h3>No Data</h3>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default UpdateUniCodeWrapper;
