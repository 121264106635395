import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  grades: [],
};

const GradeSlice = createSlice({
  name: "grade",
  initialState,
  reducers: {
    setGrade: (state, action) => {
      state.grades = action.payload;
    },
    addgrade: (state, action) => {
      state.grades.push(action.payload);
    },
    editgrade: (state, action) => {
      let index = state.grades.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.grades];

      updated[index] = action.payload;
      state.grades = updated;
      console.log("After :", state.grades);
    },
    editgradeStatus: (state, action) => {
      let index = state.grades.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.grades];

      updated[index].isActive = action.payload.isActive;
      state.grades = updated;
    },
  },
});

export const { setGrade, addgrade, editgrade, editgradeStatus } =
  GradeSlice.actions;
export const selectgrades = (state) => state.grade.grades;
export default GradeSlice.reducer;
