import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import axios from "../../api";
import ReferalCard from "./ReferalCard";
import { useHistory } from "react-router-dom";
import { selectreferral } from "Redux/features/Admin/AdminSlice";
import { setRefferal } from "Redux/features/Admin/AdminSlice";
import loader from "../../assets/img/loader.svg";
import { ToastContainer, toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
const Refrel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const referral = useSelector(selectreferral);
  const [point, setpoint] = useState(0);
  const [editModal, seteditModal] = useState(false);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    async function fetchReferral() {
      dispatch(setloading(true));
      axios
        .get(`/referralpoints`)
        .then((res) => {
          dispatch(setRefferal(res.data.data));
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    fetchReferral();
  }, []);

  async function handleAddReq() {
    let data = { points: point };
    await axios.post(`/referralpoints`, data);
  }
  return (
    <>
      {!loading ? (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    tag="h4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Referral Points</h4>{" "}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {referral.map((refr) => {
                    return (
                      <Card className="p-lg-3 ">
                        <ReferalCard className="bg-dark" referal={refr} />
                      </Card>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Refrel;
