import React, { useEffect, useState } from "react";
import { ListContainer, ListItem } from "./styles";
import { DragHandle } from "./partials/DragHandle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import axios from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import loader from "../../assets/img/loader.svg";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { updateBackendChapterSequence } from "Utils/topic_quiz_Utils";
const CreateSequence = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  let lessonID = location.pathname.split("/")[4];
  // const list = List.getList();
  var pop_cus = [
    {
      path: "chapter_sequence.test chapter_sequence.topic",
    },
  ];
  const pop_course = encodeURI(JSON.stringify(pop_cus));
  const [list, setlist] = useState([]);
  const [topicList, settopicList] = useState([]);
  const loading = useSelector(selectLoading);
  const [changes, setchanges] = useState(false);
  useEffect(() => {
    async function fetchSequence() {
      axios
        .get(`/chapter?_id=${lessonID}&populate=${pop_course}`)
        .then((res) => {
          setlist(res.data.data[0].chapter_sequence);
          console.log("UseEfect Data : ", res.data.data[0].chapter_sequence);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });

      // console.log(response);
    }
    dispatch(setloading(true));
    fetchSequence();
  }, []);
  console.log(list, "List");
  const saveList = (list) => {
    localStorage.setItem("theList", list);
    console.log(localStorage.getItem("theList"));
  };

  // function updateBackendChapterSequence() {
  //   dispatch(setloading(true));

  //   axios
  //     .put(`/chapter/chaptersequence/${lessonID}`, {
  //       chapter_sequence: list,
  //     })
  //     .then((res) => {
  //       toast.success("Quiz/Topic Sequence Updated Successfully!");
  //       setTimeout(() => {
  //         console.log("Inside");
  //         dispatch(setloading(false));
  //       }, 500);
  //     })

  //     .catch((err) => {
  //       dispatch(setloading(false));
  //       toast.error(err.response.data.message);
  //     });

  // }

  if (list.length > 0) {
    return (
      <div className="content">
        {changes ? (
          <div class="alert alert-primary" role="alert">
            You made changes in sequence ,please click Update!
          </div>
        ) : null}

        <Row>
          <Col md={12}>
            <Card>
              <CardHeader>
                <CardTitle
                  tag="h4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Topic/Quiz Sequence</h4>
                  </div>
                  <button
                    disabled={!changes}
                    className="btn btn-danger"
                    onClick={() => {
                      updateBackendChapterSequence(
                        dispatch,
                        lessonID,
                        list,
                        history
                      );
                    }}
                  >
                    Update
                  </button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                  Sequence will be same in application as it appears in the list
                  below.
                </p>
                <h6>User Guide : </h6>
                <p>
                  Press and Hold{" "}
                  <i
                    class="fa fa-bars"
                    aria-hidden="true"
                    style={{
                      color: "pink",
                      fontSize: "15px",
                      // marginLeft: "5px",
                    }}
                  ></i>{" "}
                  to drag topic/quiz
                </p>
                <DragDropContext
                  onDragEnd={(param) => {
                    const srcI = param.source.index;
                    const desI = param.destination?.index;
                    if (desI) {
                      list.splice(desI, 0, list.splice(srcI, 1)[0]);
                      // console.log(list);
                      // saveList(list);
                      setlist(list);
                      console.log("Updated", list);
                      setchanges(true);
                      // console.log(localStorage.getItem("theList"));
                    }
                  }}
                >
                  <ListContainer>
                    <h1>Topics and Quiz</h1>
                    <Droppable droppableId="droppable-1">
                      {(provided, _) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {list.map((item, i) => (
                            <Draggable
                              key={item._id}
                              draggableId={"draggable-" + item._id}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <ListItem
                                    className="bg-dark"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      boxShadow: snapshot.isDragging
                                        ? "0 0 .4rem #666"
                                        : "none",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "normal",
                                    }}
                                  >
                                    <DragHandle {...provided.dragHandleProps} />
                                    <span
                                      style={{
                                        color: "white",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {"test" in item ? (
                                        <>
                                          {item.test !== null
                                            ? item.test.test_title
                                            : "Deleted Quiz🛑"}
                                        </>
                                      ) : (
                                        <>
                                          {item.topic !== null
                                            ? item.topic.topic_title
                                            : "Deleted Topic🛑"}
                                        </>
                                      )}
                                    </span>
                                  </ListItem>
                                </>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </ListContainer>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={loader} alt="Loading...." />
                </CardTitle>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
};

export default CreateSequence;
