import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import MaterialTableIcons from 'components/Common/MaterialTableIcons';
import MaterialTable from 'material-table'
import { BackgroundColorContext } from 'contexts/BackgroundColorContext';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import { FaBan, FaCheckCircle, FaHourglassHalf } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { selectUserData } from 'Redux/features/Auth/AuthSlice';
import { useSelector } from 'react-redux';

const TeacherPaymentList = ({ paymentList, changeStatusHandler, editPaymentHandler }) => {

    const { color } = useContext(BackgroundColorContext)
    const { tableIcons } = MaterialTableIcons();
    const userData = useSelector(selectUserData);


    const renderTeacherName = (value) => (
        <div className="teacher_name">
            <h1>{value}</h1>
        </div>
    )

    const renderCellValue = (value) => (
        <div className="payment_value">
            <h1>{value}</h1>
        </div>
    )

    const renderDescription = (value) => (
        <div className="payment_description">
            <h1>{value}</h1>
        </div>
    )

    const renderPaymentStatus = (value) => (
        <div className="payment_status">
            <h1 className={value} >{value}</h1>
        </div>
    )

    const renderActions = (value) => (
        <div className="actions">
            {userData?.role === "teacher" && value?.sendBy === "admin" && value?.paymentStatus === "pending" &&
                <i class="fas fa-reply" title='Change Status' onClick={() => changeStatusHandler(value)} ></i>}
            {userData?.role !== "teacher" && value?.sendBy === "teacher" && value?.paymentStatus === "pending" &&
                <i class="fas fa-reply" title='Change Status' onClick={() => changeStatusHandler(value)}></i>}
            {/* {value?.sendBy === "teacher" && value?.paymentStatus === "pending" && <i class="fas fa-edit" title='Edit'
                onClick={() => { }}></i>} */}
            {userData?.role !== "teacher" && value?.sendBy === "admin" && value?.paymentStatus === "pending" && <i class="fas fa-edit" title='Edit'
                onClick={() => { editPaymentHandler(value) }}></i>}
            {userData?.role === "teacher" && value?.sendBy === "teacher" && value?.paymentStatus === "pending" && <i class="fas fa-edit" title='Edit'
                onClick={() => { editPaymentHandler(value) }}></i>}
        </div>
    )

    const renderAdminRowStyle = (rowData) => rowData?.sendBy === "teacher" && rowData?.isView ? "#ffc594" : ""
    const renderTeacherRowStyle = (rowData) => rowData?.sendBy === "admin" && rowData?.isView ? "#ffc594" : ""

    const colorsList = [
        { color: "blue", value: "#44a3ff" },
        { color: "primary", value: "#ba54f5" },
        { color: "green", value: "#38e3c2" }
    ];

    let count = 0;
    console.log("userData?.role");
    console.log(userData?.role);

    return <div className='teacher_payment-list' >

        {paymentList?.length ?
            <MaterialTable
                icons={tableIcons}
                title={null}
                key={count++}

                onChangePage={(e) => { console.log(e) }}
                //  = {(e) => { console.log(e) }}
                columns={
                    [
                        {
                            title: "Name", field: 'teacherName',
                            render: rowData => renderTeacherName(rowData?.teacherName),
                        },
                        {
                            title: "Amount", field: 'amountPaid',
                            render: rowData => renderCellValue(rowData?.amountPaid),
                        },
                        {
                            title: "Amount Withdraw", field: 'amountWithdraw',
                            render: rowData => renderCellValue(rowData?.amountWithdraw),
                        },
                        {
                            title: "Record Date", field: 'recordDate',
                            render: rowData => renderCellValue(rowData?.recordDate),
                        },
                        // {
                        //     title: "Record Date", field: 'isView',
                        //     render: rowData => renderCellValue(String(rowData?.isView)),
                        // },
                        {
                            title: "Send By", field: 'sendBy',
                            render: rowData => renderCellValue(rowData?.sendBy),
                        },
                        {
                            title: "Status", field: 'paymentStatus',
                            render: rowData => renderPaymentStatus(rowData?.paymentStatus),
                            export: false
                        },
                        {
                            title: "Remarks", field: 'remarks',
                            render: rowData => renderDescription(rowData?.remarks),
                        },
                        {
                            title: "Description", field: 'description',
                            render: rowData => renderDescription(rowData?.description),
                        },
                        {
                            title: "Created At", field: 'createdAt',
                            render: rowData => renderCellValue(rowData?.createdAt),
                        },
                        {
                            title: "Updated At", field: 'updatedAt',
                            render: rowData => renderCellValue(rowData?.updatedAt),
                        },

                        {
                            title: "Actions", field: '_id',
                            render: rowData => renderActions(rowData),
                            filtering: false,
                            export: false
                        },
                    ]
                }

                data={
                    paymentList?.length > 0 && paymentList.map((e) => {
                        return {
                            index: ++count,
                            data: e,
                            _id: e._id,
                            teacherName: e?.teacherName || "-",
                            amountPaid: e?.amountPaid || "-",
                            amountWithdraw: e?.amountWithdraw || "-",
                            recordDate: e.recordDate ? format(new Date(e.recordDate), "dd MMM yyyy") : "-",
                            isActive: e?.isActive ? "Active" : "In-Active",
                            sendBy: e?.sendBy || "-",
                            paymentStatus: e?.paymentStatus || "-",
                            createdAt: e.createdAt ? format(new Date(e.createdAt), "dd MMM yyyy") : "-",
                            updatedAt: e.updatedAt ? format(new Date(e.updatedAt), "dd MMM yyyy") : "-",
                            isView: e?.isView,
                            description: e?.description || "-",
                            remarks: e?.remarks || "-"
                        }
                    })}

                options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    headerStyle: {
                        backgroundColor: colorsList.find(e => e.color === color).value,
                        color: '#FFF'
                    },
                    rowStyle: rowData => ({
                        backgroundColor: userData?.role !== "teacher" ?
                            renderAdminRowStyle(rowData) : renderTeacherRowStyle(rowData)
                    }),
                    filtering: true
                }}
            />
            :
            <h1>Payment list is empty</h1>
        }
    </div>;
};

TeacherPaymentList.propTypes = {
    paymentList: PropTypes.array.isRequired,
    changeStatusHandler: PropTypes.func.isRequired,
    editPaymentHandler: PropTypes.func.isRequired,
};

export default TeacherPaymentList;

/*

1- admin send payment -> teacher
   teacher response back -> admin

*/
