import { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { format } from "date-fns";
import { FaPlayCircle, FaRegEdit, FaBan } from "react-icons/fa";

import MaterialTable from "material-table";
import MaterialTableIcons from "../Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

const SubjectList = ({
  subjectsList,
  onHandleEdit,
  onHandleDelete,
  registeredSubject,
  totalChaptersForSubjects,
  showTotalTopicsAndQuizzes,
  addNewChapter,
  // sendNotification,
}) => {
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();

  const renderSubjectIcon = (subjectImg) => (
    <div className="subject_image">
      {subjectImg ? <img src={subjectImg} /> : null}
    </div>
  );

  const renderCellValue = (value) => (
    <div className="subject_text">
      <h1>{value}</h1>
    </div>
  );

  const renderSubjectStatus = (value) => (
    <div className="subject_status">
      <h1 className={value?.toLowerCase()}>{value}</h1>
    </div>
  );

  const renderTotalChapters = (value) => {
    const chapterObj = totalChaptersForSubjects?.find(
      (x) => x._id === value[1]
    );

    const renderChapterRow = (label, value) => (
      <div className="chapter-row">
        <h1>{label}:</h1>
        <h1>{value}</h1>
      </div>
    );

    return (
      <div className="total_chapters">
        {renderChapterRow("Active", chapterObj?.active || 0)}
        {renderChapterRow("In Active", chapterObj?.inActive || 0)}
        {renderChapterRow("Total", chapterObj?.count || 0)}
        <div
          className="view-more"
          onClick={() => showTotalTopicsAndQuizzes([value[2], value[1]])}
        >
          <h1>View more info</h1>
        </div>
      </div>
    );
  };

  const renderSubjectName = (value) => (
    <h1
      className="subject_name"
      onClick={() => {
        addNewChapter(value[1]);
      }}
    >
      {value[0]}
    </h1>
  );

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  let count = 0;
  const userRole = sessionStorage.getItem("role");
  return (
    <div className="subject_list content">
      <MaterialTable
        icons={tableIcons}
        title={null}
        columns={[
          { title: "#", field: "index", filtering: false },
          {
            title: "Name",
            field: "Subject_name",
            render: (rowData) =>
              renderSubjectName([rowData.Subject_name, rowData._id]),
          },
          {
            title: "Icon",
            field: "subject_icon",
            export: false,
            render: (rowData) => renderSubjectIcon(rowData.subject_icon),
            filtering: false,
          },
          {
            title: "Price",
            field: "Subject_price",
            type: "numeric",
            render: (rowData) => renderCellValue(rowData.Subject_price),
          },
          // {
          //   title: 'Type', field: 'course_plan',
          //   render: rowData => renderCellValue(rowData.course_plan)
          // },
          {
            title: "Grade",
            field: "grade",
            render: (rowData) => renderCellValue(rowData.grade),
          },
          {
            title: "Total Chapters",
            field: "total_chapter",
            type: "numeric",
            render: (rowData) =>
              renderTotalChapters([
                rowData.total_chapter,
                rowData._id,
                rowData.Subject_name,
              ]),
            filtering: false,
          },
          {
            title: "Status",
            field: "isDelete",
            render: (rowData) => renderSubjectStatus(rowData.isDelete),
            customFilterAndSearch: (term, rowData) =>
              !rowData?.isDelete
                ?.toLowerCase()
                .localeCompare(term.toLowerCase()),
          },
          {
            title: "Total Students",
            field: "total_students",
            type: "numeric",
            render: (rowData) =>
              renderCellValue(
                registeredSubject?.find((e) => e._id === rowData._id)?.count ||
                  0
              ),
          },
          {
            title: "Total Income",
            field: "total_income",
            type: "numeric",
            render: (rowData) =>
              renderCellValue(
                registeredSubject?.find((e) => e._id === rowData._id)
                  ?.amountPaid || 0
              ),
            filtering: false,
          },
          {
            title: "Created At",
            field: "createdAt",
            render: (rowData) =>
              renderCellValue(
                rowData?.createdAt
                  ? format(new Date(rowData?.createdAt), "dd MMM yyyy")
                  : "-"
              ),
            filtering: false,
          },
          {
            title: "Updated At",
            field: "updatedAt",
            render: (rowData) =>
              renderCellValue(
                rowData?.updatedAt
                  ? format(new Date(rowData?.updatedAt), "dd MMM yyyy")
                  : "-"
              ),
            filtering: false,
          },
        ]}
        data={subjectsList?.map((subject) => {
          return {
            index: ++count,
            _id: subject._id,
            uniCode: subject?.uniCode,
            Subject_name: `${subject.Subject_name} - (${subject.uniCode})`,
            subjectName: subject.Subject_name,
            subject_icon: subject.subject_icon,
            subject_price: subject.subject_price,
            // Subject_price: subject?.grade?.course_plan === "premium" ? subject.subject_price : "N/A",
            Subject_price: subject?.subject_price,
            isActive: subject.isActive,
            isDelete: subject.isActive ? "Active" : "In-Active",
            course_plan: subject?.grade?.course_plan,
            grade: `${subject?.grade?.grade} - (${subject?.grade?.uniCode})`,
            gradeID: subject?.grade?._id,
            total_chapter:
              totalChaptersForSubjects?.find((x) => x._id === subject._id)
                ?.count || 0,
            createdAt: subject?.createdAt,
            updatedAt: subject?.updatedAt,
            total_topics: 0,
            total_quizzes: 0,
            total_points: 0,
            total_students:
              registeredSubject?.find((e) => e._id === subject._id)?.count || 0,
            total_income:
              registeredSubject?.find((e) => e._id === subject._id)
                ?.amountPaid || 0,
          };
        })}
        actions={[
          {
            icon: () =>
              userRole && userRole === "teacher" ? null : <FaRegEdit />,
            tooltip: "Edit Subject",
            onClick: (event, rowData) => onHandleEdit(rowData),
          },
          {
            icon: (rowData) =>
              userRole && userRole === "teacher" ? null : (
                <FaBan className={rowData.isActive ? "true" : "false"} />
              ),
            tooltip: "Change Status",
            onClick: (event, rowData) => onHandleDelete(rowData),
          },
          // {
          //   icon: (rowData) =>
          //     userRole && userRole === "teacher" ? null : (
          //       <i className="fa fa-bell" />
          //     ),
          //   tooltip: "Send notification",
          //   onClick: (event, rowData) => sendNotification(rowData),
          // },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: false,
          pageSize: 10,
          pageSizeOptions: [5, 10, 30, 50],
          headerStyle: {
            backgroundColor: colorsList.find((e) => e.color === color).value,
            color: "#FFF",
          },
          filtering: true,
        }}
      />
    </div>
  );
};

SubjectList.propTypes = {
  subjectsList: PropTypes.array.isRequired,
  onHandleEdit: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  registeredSubject: PropTypes.array.isRequired,
  totalChaptersForSubjects: PropTypes.array.isRequired,
  showTotalTopicsAndQuizzes: PropTypes.func.isRequired,
  addNewChapter: PropTypes.func.isRequired,
  // sendNotification: PropTypes.func.isRequired,
};

export default SubjectList;
