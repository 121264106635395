import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import Header from "components/Common/Header";
import { toast } from "react-toastify";
import { getTeachersData, updateTeacher } from "Utils/teacher_Utils";
import Loader from "../Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import { uploadImage } from "Utils/image_uploader";
import loaderimg from "../../assets/img/loaderimg.svg";
import { setUserData } from "Redux/features/Auth/AuthSlice";

const TeacherProfile = () => {
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  // const [email, setEmail] = useState(userData?.email || "");
  const [phone, setPhone] = useState(userData?.phone_number || "");
  const [city, setCity] = useState(userData?.city || "");
  const [firstName, setFirstName] = useState(userData?.first_name || "");
  const [lastName, setLastName] = useState(userData?.last_name || "");
  const [qualification, setQualification] = useState(
    userData?.qualification || ""
  );
  const [description, setDescription] = useState(userData?.description || "");
  const [designation, setDesignation] = useState(userData?.designation || "");
  const [profileImage, setProfileImage] = useState(
    userData?.profile_image || ""
  );
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const onChangeStateHandler = (setState) => (e) => setState(e.target.value);

  const param = useParams();
  const { teacherID } = param;

  // const fetchInitialData = async () => {
  //     setLoading(true);
  //     try {
  //         const resp = await getTeachersData(teacherID);
  //         const { email, phone, city, profile_image } = resp.data.data[0];

  //         setEmail(email || "");
  //         setPhone(phone || "");
  //         setCity(city || "");

  //     } catch (error) {
  //         toast.error(error?.response?.data?.message || error)
  //         console.log(error);
  //     } finally {
  //         setLoading(false);
  //     }
  // }

  const _updateTeacherProfile = async () => {
    setLoading(true);
    try {
      const data = {
        _id: userData?._id,
        phone_number: phone || String(0),
        city: city || "-",
        first_name: firstName,
        last_name: lastName,
        profile_image: profileImage,
        email: userData?.email,
        role: userData?.role,
        qualification,
        description,
        designation,
      };
      const resp = await updateTeacher(userData?._id, data);
      dispatch(setUserData(resp.data.data));
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const profileImageUpload = async (e) => {
    const image = e.target.files[0];

    setImgLoading(true);
    try {
      if (!image) {
        return;
      }

      const resp = await uploadImage(image);

      if (resp?.response?.status === 415)
        toast.error(resp?.response?.data?.message);
      else {
        setProfileImage(resp);
        toast.error(resp.response);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setImgLoading(false);
    }
  };

  // useEffect(() => {
  //     // fetchInitialData();
  // }, [])

  const errorSpan = () => <span style={{ color: "red" }}>*</span>;

  const renderTeacherProfileForm = () => (
    <form onSubmit={_updateTeacherProfile}>
      <div className="row">
        <div className="col-md-12 my-2">
          <p>Email</p>
          <input
            type="email"
            value={userData?.email || ""}
            className="form-control"
            disabled
          />
        </div>
        <div className="col-md-6 my-2">
          <p>First name {errorSpan()}</p>
          <input
            type="text"
            value={firstName}
            className="form-control"
            onChange={onChangeStateHandler(setFirstName)}
            required
          />
        </div>
        <div className="col-md-6 my-2">
          <p>Last name {errorSpan()}</p>
          <input
            type="text"
            value={lastName}
            className="form-control"
            onChange={onChangeStateHandler(setLastName)}
            required
          />
        </div>
        <div className="col-md-6 my-2">
          <p>Phone</p>
          <input
            type="text"
            value={phone}
            className="form-control"
            onChange={onChangeStateHandler(setPhone)}
          />
        </div>
        <div className="col-md-6 my-2">
          <p>City</p>
          <input
            type="text"
            value={city}
            className="form-control"
            onChange={onChangeStateHandler(setCity)}
          />
        </div>
        <div className="col-md-6 my-2">
          <p>Qualification</p>
          <input
            type="text"
            value={qualification}
            className="form-control"
            onChange={onChangeStateHandler(setQualification)}
            required
          />
        </div>
        <div className="col-md-6 my-2">
          <p>Description</p>
          <input
            type="text"
            value={description}
            className="form-control"
            onChange={onChangeStateHandler(setDescription)}
            required
          />
        </div>
        <div className="col-md-12 my-2">
          <p>Designation</p>
          <select
            value={designation}
            className="form-control"
            onChange={onChangeStateHandler(setDesignation)}
            required
          >
            <option value="copyWriter">Copy Writer</option>
            <option value="videoCreator">Video Creator</option>
          </select>
        </div>
        <div className="col-md-12 my-2">
          <div className="profile_image">
            <img
              src={imgLoading ? loaderimg : profileImage}
              alt="profile_image"
            />
          </div>
          <p>Profile Image</p>
          <input
            type="file"
            className="form-control"
            style={{ width: "fit-content" }}
            onChange={profileImageUpload}
          />
        </div>
      </div>

      <div className="btn-save text-right mt-3">
        <button type="submit" className="btn btn-secondary">
          Update profile
        </button>
      </div>
    </form>
  );

  const renderTeacherProfileContent = () => (
    <div className="teacher_profile-content">
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              <Header title="Profile" location="/admin/dashboard" />
            </CardHeader>
            <CardBody>{renderTeacherProfileForm()}</CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );

  console.log("userData?.profile_image");
  console.log(userData?.profile_image);
  return (
    <div className="teacher_profile content">
      {loading ? <Loader /> : renderTeacherProfileContent()}
    </div>
  );
};

TeacherProfile.propTypes = {};

export default TeacherProfile;
