import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teachers: [],
  allTeachers: null,
};

const TeacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    setteacher: (state, action) => {
      state.teachers = action.payload;
    },
    addteacher: (state, action) => {
      state.teachers.push(action.payload);
    },
    editteacher: (state, action) => {
      let index = state.teachers.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.teachers];
      updated[index].first_name = action.payload.first_name;
      updated[index].last_name = action.payload.last_name;
      updated[index].email = action.payload.email;
      state.teachers = updated;
    },
    editteacherStatus: (state, action) => {
      let index_ = state.teachers.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.teachers];
      updated[index_].isActive = action.payload.isActive;
      state.teachers = updated;
    },
    setTeacherList: (state, action) => {
      state.allTeachers = action.payload
    },
  },
});

export const { setteacher, addteacher, editteacher, editteacherStatus, setTeacherList } =
  TeacherSlice.actions;
export const selectteachers = (state) => state.teacher.teachers;
export const selectTeachersList = (state) => state.teacher.allTeachers;
export default TeacherSlice.reducer;
