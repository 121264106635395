import backend from "../api";

export const sendNotification = async (dataObj) =>
  await backend({
    method: "PUT",
    url: "/user/send/notification",
    data: dataObj,
  });

export const sendGroupNotification = async (dataObj) =>
  await backend({
    method: "POST",
    url: "/notification/group/send",
    data: dataObj,
  });
