import { useState } from "react";
// import PropTypes from "prop-types";
import Header from "components/Common/Header";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import LiveSessionsList from "./LiveSessionsList";
import { useSelector } from "react-redux";
import { selectUserData } from "Redux/features/Auth/AuthSlice";

const LiveSessionWrapper = () => {
  const history = useHistory();
  const userData = useSelector(selectUserData);

  return (
    <div className="live_session content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              {/* <Header title="Live Sessions" location="/admin/dashboard" /> */}
              <Header title="Live Sessions" />
              {userData?.role !== "teacher" && (
                <div className="text-right">
                  <button
                    className="btn btn-secondary"
                    onClick={() => history.push("/admin/session/form/add")}
                  >
                    Add session
                  </button>
                </div>
              )}
              <LiveSessionsList />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

// LiveSessionWrapper.propTypes = {};

export default LiveSessionWrapper;
