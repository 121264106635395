import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Card, CardBody, Row, Col } from "reactstrap";
import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import ModalContainer from "components/Common/ModalContainer";
import TeachersList from "./TeachersList.jsx";
import {
  getAllTeachersData,
  saveTeacher,
  updateTeacher,
  changeTeacherStatus,
  getAssignSubjects,
  saveAssignSubjects,
  updateAssignSubjects,
} from "../../Utils/teacher_Utils";
import { getGradeForDropdown } from "../../Utils/grades_Utils";
import { getAllSubjects } from "../../Utils/subjects_Utils";
import { uploadImage } from "../../Utils/image_uploader";
import loader from "../../assets/img/loader.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectTeachersList } from "Redux/features/Teacher/TeacherSlice.js";
import { setTeacherList } from "Redux/features/Teacher/TeacherSlice.js";
import BreadCrumbs from "components/Common/BreadCrumbs.jsx";

const TeacherWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [teachersList, setTeachersList] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // for add && update teacher
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false); // for change status
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false); // for add payment
  const [isOpenAssignModal, setIsOpenAssignModal] = useState(false); // for assign subjects
  const [teacherObj, setTeacherObj] = useState(null);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [qualification, setQualification] = useState("");
  const [description, setDescription] = useState("");
  const [designation, setDesignation] = useState("copyWriter");
  const [profile_image, setProfileImage] = useState(
    "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/MicrosoftTeams-image%20%283%29-1640072858419.png"
  );
  const [gradesList, setGradesList] = useState(null);
  const [subjectsList, setSubjectsList] = useState(null);
  const [filteredSubjects, setFilteredSubjects] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState(null);
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [assignSubjectObjID, setAssignSubjectObjID] = useState("");

  const allTeachersList = useSelector(selectTeachersList);
  const dispatch = useDispatch();
  const history = useHistory();

  const resetStates = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setCity("");
    setProfileImage("");
    setTeacherObj(null);
    setSelectedGrades(null);
    setSelectedSubjects(null);
    setAssignSubjectObjID("");
  };

  const handleOpenModal = (openModal, teacherData) => {
    switch (openModal) {
      case "addTeacher":
        setIsOpen(true);
        break;

      case "updateTeacher":
        setTeacherObj(teacherData);
        setIsOpen(true);
        break;

      case "assignSubjects":
        setTeacherObj(teacherData);
        setIsOpenAssignModal(true);
        break;

      case "payTeacher":
        setIsOpenPaymentModal(true);
        break;

      case "changeStatus":
        setTeacherObj(teacherData);
        setIsOpenStatusModal(true);
        break;

      default:
        break;
    }
  };

  const editTeacherHandler = (teacherData) => {
    setTeacherObj(teacherData);
    setFirstName(teacherData?.first_name || "");
    setLastName(teacherData?.last_name || "");
    setEmail(teacherData?.email || "");
    setPhoneNumber(teacherData?.phone_number || "");
    setQualification(teacherData?.qualification || "");
    setDescription(teacherData?.description || "");
    setDesignation(teacherData?.designation || "");
    setProfileImage(
      teacherData?.profile_image ||
        "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/MicrosoftTeams-image%20%283%29-1640072858419.png"
    );
    setCity(teacherData?.city || "");

    setIsOpen(true);
  };

  const handleOnClose = () => {
    resetStates();
    setIsOpen(false);
    setIsOpenStatusModal(false);
    setIsOpenAssignModal(false);
  };

  const changeStatus = (teacherObj) =>
    handleOpenModal("changeStatus", teacherObj);

  const saveTeacherSubjects = async () => {
    let resp = null;
    setLoading(true);
    try {
      const allGrades = selectedGrades?.map((e) => e.value);
      const allSubjects = selectedSubjects?.map((e) => e.value);
      const teacher_id = teacherObj?._id;

      const data = {
        teacher_id,
        gradesList: allGrades,
        subjectsList: allSubjects,
      };

      if (assignSubjectObjID)
        resp = await updateAssignSubjects(assignSubjectObjID, data);
      else resp = await saveAssignSubjects(data);

      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setIsOpenAssignModal(false);
      setAssignSubjectObjID(null);
      setTeacherObj(null);
      setSelectedGrades(null);
      setSelectedSubjects(null);
      setLoading(false);
    }
  };

  const assignSubjectHandler = async (teacherObj) => {
    try {
      const resp = await getAssignSubjects(teacherObj?._id);

      if (!resp?.data?.data?.length) return;

      const [list] = resp?.data?.data;
      setAssignSubjectObjID(list?._id);

      const filterGrades = list?.gradesList?.map((x) => {
        const grade = gradesList?.find((e) => e._id === x);

        if (grade?._id) return { value: grade?._id, label: grade?.grade };
      });

      setSelectedGrades([...filterGrades]);

      const filterSubject = list?.subjectsList?.map((x) => {
        const subject = subjectsList?.find((e) => e._id === x);
        if (subject?._id)
          return {
            value: subject?._id,
            label: subject?.Subject_name + " - " + subject?.grade?.grade,
            gradeID: subject?.grade?._id,
          };
      });

      setSelectedSubjects([...filterSubject]);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      handleOpenModal("assignSubjects", teacherObj);
    }
  };

  const changeHandler = (setState) => (e) => {
    setState(e.target.value);
  };

  const fetchAllTeachersRecord = async (isFetch) => {
    setLoading(true);
    try {
      if (!isFetch && allTeachersList !== null && allTeachersList?.length) {
        setTeachersList(allTeachersList);
      } else {
        const resp = await getAllTeachersData();
        setTeachersList(resp.data.data);
        dispatch(setTeacherList(resp.data.data));
      }

      if (gradesList !== null && selectedSubjects !== null) return;

      const grades = await getGradeForDropdown();
      setGradesList(grades.data.data);

      const allSubjects = await getAllSubjects();
      setSubjectsList(allSubjects.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async () => {
    try {
      const resp = await changeTeacherStatus(teacherObj?._id, {
        isActive: !teacherObj?.isActive,
      });
      setTeachersList(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      handleOnClose();
      fetchAllTeachersRecord(true);
    }
  };

  const openTeacherPaymentScreen = (teacherID) =>
    history.push(`/admin/teacher/payment/${teacherID}`);

  const _submit = async (e) => {
    e.preventDefault();

    try {
      let resp = null;

      if (teacherObj !== null) {
        const data = {
          first_name,
          last_name,
          email,
          role: "teacher",
          phone_number,
          profile_image,
          city,
          designation,
          description,
          qualification,
        };
        resp = await updateTeacher(teacherObj?._id, data);
        setTeacherObj(null);
      } else {
        const data = {
          first_name,
          last_name,
          email,
          isActive: true,
          role: "teacher",
          designation,
          description,
          qualification,
        };

        if (profile_image) data.profile_image = profile_image;
        if (city) data.city = city;
        if (phone_number) data.phone_number = phone_number;
        resp = await saveTeacher(data);
      }

      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      resetStates();

      setIsOpen(false);
      fetchAllTeachersRecord(true);
    }
  };

  const imageUploader = async (e) => {
    const imageUpload = e?.target?.files[0] || null;

    if (!imageUpload) return;

    setIsImageUpload(true);
    try {
      const resp = await uploadImage(imageUpload);

      setProfileImage(resp);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setIsImageUpload(false);
    }
  };

  useEffect(() => {
    if (!selectedGrades?.length) {
      setFilteredSubjects(null);
      setSelectedSubjects(null);
      return;
    }

    if (selectedSubjects?.length) {
      const updateSelectedSubjects = selectedSubjects?.filter((a) => {
        const subjectExist = selectedGrades.find(
          (e) => e?.value === a?.gradeID
        );
        if (subjectExist?.value) return a;
      });

      setSelectedSubjects([...updateSelectedSubjects]);
    }
    const subjects =
      subjectsList?.length &&
      subjectsList.filter((x) => {
        const subjectExist = selectedGrades.find(
          (e) => e?.value === x?.grade?._id
        );
        if (subjectExist?.value) return x;
      });

    setFilteredSubjects([...subjects]);
  }, [selectedGrades]);

  useEffect(() => {
    fetchAllTeachersRecord(false);
  }, []);

  const renderTeacherAssignSubjectsModal = () => (
    <div className="teacher-assign_subject">
      <p>Select Grades</p>
      <Select
        onChange={(e) => setSelectedGrades(e)}
        value={selectedGrades}
        isMulti
        placeholder="Select Grade"
        className="react-options"
        options={
          gradesList?.length > 0 &&
          gradesList.map((e) => {
            return {
              value: e?._id,
              label: e?.grade,
            };
          })
        }
      />

      <p>Select Subjects</p>
      <Select
        onChange={(e) => setSelectedSubjects(e)}
        value={selectedSubjects}
        isMulti
        placeholder="Select Subjects"
        className="react-options"
        options={
          filteredSubjects?.length > 0 &&
          filteredSubjects?.map((e) => {
            return {
              value: e?._id,
              label: e?.Subject_name + " - " + e?.grade?.grade,
              gradeID: e?.grade?._id,
            };
          })
        }
      />

      <div className="save-grade-btn">
        <button
          className="btn btn-secondary"
          disabled={!(selectedGrades?.length && selectedSubjects?.length)}
          onClick={saveTeacherSubjects}
        >
          {" "}
          Save{" "}
        </button>
      </div>
    </div>
  );

  const renderTeacherChangeStatusModal = () => (
    <div className="teacher-status">
      <h1>
        {teacherObj?.isActive
          ? "Are you sure you want to restrict this user?"
          : "Are you sure you want to change this user's status to active?"}
      </h1>

      <div>
        <button
          id="btn-status"
          className="btn btn-primary"
          onClick={updateStatus}
        >
          {" "}
          Confirm{" "}
        </button>
      </div>
    </div>
  );

  const renderTeacherRow = () => (
    <div className="teacher-info">
      <h1 className="count">Total teachers: {teachersList?.length ?? 0}</h1>
      <button
        id="add_teacher"
        className="btn btn-secondary add-teacher-btn"
        onClick={() => handleOpenModal("addTeacher", null)}
      >
        Add teacher
      </button>
    </div>
  );

  const renderTeacherModal = () => (
    <div className="teacher-form">
      <form onSubmit={_submit}>
        <div className="field">
          <p>
            First Name <span style={{ color: "red" }}>*</span>{" "}
          </p>
          <input
            id="first_name"
            type="text"
            className="form-control"
            value={first_name}
            onChange={changeHandler(setFirstName)}
            required
          />
          <p>
            Last Name <span style={{ color: "red" }}>*</span>
          </p>
          <input
            id="last_name"
            type="text"
            className="form-control"
            value={last_name}
            onChange={changeHandler(setLastName)}
            required
          />
          <p>
            Email <span style={{ color: "red" }}>*</span>
          </p>
          <input
            id="email"
            type="email"
            className="form-control"
            value={email}
            onChange={changeHandler(setEmail)}
            required
          />
          <p>Phone number</p>
          <input
            id="phone_number"
            type="number"
            min={0}
            className="form-control"
            value={phone_number}
            onChange={changeHandler(setPhoneNumber)}
          />
          <p>City</p>
          <input
            id="city"
            type="text"
            className="form-control"
            value={city}
            onChange={changeHandler(setCity)}
          />
          <p>
            Qualification <span style={{ color: "red" }}>*</span>{" "}
          </p>
          <input
            id="qualification"
            type="text"
            className="form-control"
            value={qualification}
            onChange={changeHandler(setQualification)}
            required
          />
          <p>
            Description <span style={{ color: "red" }}>*</span>{" "}
          </p>
          <input
            id="description"
            type="text"
            className="form-control"
            value={description}
            onChange={changeHandler(setDescription)}
            required
          />
          <p>Designation</p>
          <select
            id="designation"
            className="form-control"
            value={designation}
            onChange={changeHandler(setDesignation)}
            required
          >
            <option value="copyWriter">Copy Writer</option>
            <option value="videoCreator">Video Creator</option>
          </select>
          <p style={{ margin: "1rem 0 0" }}>Profile Image</p>
          <input
            type="file"
            id="teacher_profile"
            className="form-control"
            onChange={imageUploader}
          />

          <div className="profile_img">
            <img
              src={isImageUpload ? loader : profile_image}
              alt="profile_image"
              srcset=""
            />
          </div>

          <div className="save-btn">
            <button
              id="btn-submit"
              type="submit"
              className="btn btn-secondary"
              disabled={!(first_name && last_name && email)}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div className="teacher content">
      <BreadCrumbs
        breadCrumbList={[{ title: "Teacher", pathName: "/admin/teachers" }]}
      />
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Header title="Teachers" location="/admin/dashboard" />
              {renderTeacherRow()}
              {loading ? (
                <Loader />
              ) : (
                <TeachersList
                  teachersList={teachersList}
                  editTeacherHandler={editTeacherHandler}
                  changeStatus={changeStatus}
                  assignSubjectHandler={assignSubjectHandler}
                  openTeacherPaymentScreen={openTeacherPaymentScreen}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Add or update teacher */}
      <ModalContainer
        isOpen={isOpen}
        onConfirmClick={handleOnClose}
        modalWidth="600"
        animateEffect="fadeInUp"
        heading={teacherObj ? "Update teacher" : "Add New Teacher"}
      >
        {renderTeacherModal()}
      </ModalContainer>

      {/* change status teacher */}
      <ModalContainer
        isOpen={isOpenStatusModal}
        onConfirmClick={handleOnClose}
        modalWidth="600"
        animateEffect="fadeInUp"
        heading="Change Status"
      >
        {renderTeacherChangeStatusModal()}
      </ModalContainer>

      {/* Assign subject to teacher */}
      <ModalContainer
        isOpen={isOpenAssignModal}
        onConfirmClick={handleOnClose}
        modalWidth="600"
        animateEffect="fadeInUp"
        heading={`Assign Subjects to  ${teacherObj?.first_name} ${teacherObj?.last_name}`}
      >
        {renderTeacherAssignSubjectsModal()}
      </ModalContainer>
    </div>
  );
};

export default TeacherWrapper;
