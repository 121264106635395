import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// import UserProfile from "views/UserProfile.js";
import ChangePassword from "views/ChangePassword.js";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import jwt_decode from "jwt-decode";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import ChapterPreview from "views/ChapterPreview";
import Lessons from "views/Lessons";
import SpecificStudent from "../../components/SpecificStudent/SpecificStudent";
import Quiz from "../../components/Quiz/Quiz";
import CreateQuiz from "components/CreateQuiz/CreateQuiz";
import CreateTrueFalseQuiz from "components/CreateTrueFalseQuiz/CreateTrueFalseQuiz";
import EditQuiz from "components/EditQuiz/EditQuiz";
import CreateSequence from "components/CreateSequence/CreateSequence";
import EditTopic from "components/EditTopic/EditTopic";

import QuizPanel from "components/QuizPanel/QuizPanel";
import AddTopic from "components/AddTopic/AddTopic";
import {
  routes,
  routes_admin,
  routes_teacher,
  routes_default,
} from "../../routes";
import ChapterSequence from "components/ChapterSequence/ChapterSequence";
import BundleRate from "components/BundleRate/BundleRate";
import ReferPointsRecordWrapper from "components/ReferPointsRecord/ReferPointsRecordWrapper";
import TeacherProfile from "components/Teacher/TeacherProfile";
import StudentDetails from "components/Student/StudentDetails";
import LiveSessionWrapper from "components/LiveSessions/LiveSessionWrapper";
import LiveSessionForm from "components/LiveSessions/LiveSessionForm";
import TeacherPaymentWrapper from "components/TeacherPayment/TeacherPaymentWrapper";
import CommentsWrapper from "components/Comments/CommentsWrapper";
import CommentAnswers from "components/Comments/CommentAnswers";
import UpdateUniCodeWrapper from "components/Uni Codes/UpdateUniCodeWrapper";
import TopicForm from "components/Topic/TopicForm";

var ps;

function Admin(props) {
  let token = sessionStorage.getItem("token");
  let decoded = jwt_decode(token);
  // console.log(decoded);
  // decoded.role
  const [role, setrole] = useState(decoded.role);

  const location = useLocation();

  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Corrsy";
  };
  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            {role === "admin" ? (
              <Sidebar
                routes={routes_admin}
                // logo={{
                //   // outterLink: "https://www.creative-tim.com/",
                //   text: "Username Here",
                //   // imgSrc: logo,
                // }}
                toggleSidebar={toggleSidebar}
              />
            ) : role === "teacher" ? (
              <Sidebar routes={routes_teacher} toggleSidebar={toggleSidebar} />
            ) : (
              <Sidebar routes={routes} toggleSidebar={toggleSidebar} />
            )}
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />

              <Switch>
                <Route
                  path={`/admin/change-password/`}
                  component={ChangePassword}
                />
                <Route
                  exact
                  path="/admin/students/:studentID"
                  component={SpecificStudent}
                  // component={StudentDetails}
                />
                <Route
                  exact
                  path="/admin/students/refer-points/record/:studentID"
                  component={ReferPointsRecordWrapper}
                />
                <Route
                  exact
                  path="/admin/subject/:subjectID"
                  component={Lessons}
                />
                <Route
                  exact
                  path="/admin/subject/chapter/chapterdetails/:lessonID"
                  component={ChapterPreview}
                />
                <Route
                  exact
                  path="/admin/subject/chapter/chapterdetails/:lessonID/add"
                  component={AddTopic}
                ></Route>
                <Route
                  exact
                  path="/admin/subject/chapter/chapterdetails/:lessonID/edit/:topicID"
                  component={EditTopic}
                ></Route>

                <Route
                  exact
                  path="/admin/subject/chapter/quiz/:lessonID"
                  component={Quiz}
                />

                <Route
                  exact
                  path="/admin/subject/chapter/quiz/:lessonID/:quizID"
                  component={QuizPanel}
                />

                <Route
                  exact
                  path="/admin/subject/chapter/specificquiz/:quizID"
                  component={CreateQuiz}
                />

                <Route
                  exact
                  path="/admin/subject/chapter/:lessonID"
                  component={CreateQuiz}
                />
                <Route
                  exact
                  path="/admin/subject/chapter/truefalse/:lessonID"
                  component={CreateTrueFalseQuiz}
                />
                <Route
                  exact
                  path="/admin/subject/chapter/:lessonID/chaptersequence"
                  component={CreateSequence}
                />

                <Route
                  path={"/admin/subject/chapter/chaptersequence/:subjectId"}
                  component={ChapterSequence}
                />

                <Route path={"/admin/bundle"} component={BundleRate} />

                <Route
                  path={"/admin/teacher/payment/:teacherID"}
                  component={TeacherPaymentWrapper}
                />

                <Route
                  path={"/admin/teacher/profile"}
                  component={TeacherProfile}
                />

                <Route
                  path={"/admin/live/session"}
                  component={LiveSessionWrapper}
                />

                <Route
                  path={"/admin/session/form/add"}
                  component={LiveSessionForm}
                />

                <Route
                  path={"/admin/session/form/:liveSessionID"}
                  component={LiveSessionForm}
                />

                <Route
                  path={"/admin/comment/:commentID"}
                  component={CommentAnswers}
                />

                <Route
                  path={"/admin/unicodes/update/:chapterID"}
                  component={UpdateUniCodeWrapper}
                />
               
                <Route
                  path={"/admin/topic/new"}
                  component={TopicForm}
                />



                {getRoutes(routes)}

                <Redirect from="*" to="/admin/dashboard" />
              </Switch>

              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : (
                  <div style={{ marginTop: "20px" }}>
                    <Footer fluid />
                  </div>
                )
              }
            </div>
          </div>

          <FixedPlugin bgColor={color} handleBgClick={changeColor} />
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
