import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  admins: [],
  referral: [],
  theme: 'dark',
  currentGrade: {},
  currentPlan: '',
  currentSubject: '',
  currentChapter: '',
  bundleTime: null,
  registeredCourseList: null,
  totalChapters: null,
  totalQuizzesAndPoints: null,
  totalTopics: null,
};

const AdminSlice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {
    setcurrentGrade: (state, action) => {
      state.currentGrade = action.payload;
    },
    setcurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    setcurrentSubject: (state, action) => {
      state.currentSubject = action.payload;
    },
    setcurrentChapter: (state, action) => {
      state.currentChapter = action.payload;
    },

    setAdmin: (state, action) => {
      state.admins = action.payload;
    },
    addAdmin: (state, action) => {
      console.log('Before : ', state.admins);
      console.log('Data : ', action.payload);
      state.admins.push(action.payload);
      console.log('After : ', state.admins);
    },
    editAdmin: (state, action) => {
      let index = state.admins.findIndex((u) => u._id === action.payload._id);
      let updated = [...state.admins];

      updated[index].first_name = action.payload.first_name;
      updated[index].last_name = action.payload.last_name;
      updated[index].user_name = action.payload.user_name;
      updated[index].email = action.payload.email;
      state.admins = updated;
    },
    editAdminStatue: (state, action) => {
      let index_ = state.admins.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.admins];
      updated[index_].isActive = action.payload.isActive;
      state.admins = updated;
    },
    setRefferal: (state, action) => {
      state.referral = action.payload;
    },
    addreferral: (state, action) => {
      state.referral.push(action.payload);
    },
    editreferral: (state, action) => {
      console.log('Data : ', action.payload);
      let indexed = state.referral.findIndex(
        (u) => u._id == action.payload._id
      );
      let update = [...state.referral];
      update[indexed] = action.payload;
      console.log('Data : ', action.payload);
      console.log('Before Update', update);
      state.referral = update;
      console.log('After Update', state.referral);
    },
    settheme: (state, action) => {
      state.theme = action.payload;
    },
    setBundleTime: (state, action) => {
      state.bundleTime = action.payload;
    },
    setRegisteredCourseList: (state, action) => {
      state.registeredCourseList = action.payload;
    },
    setTotalChapters: (state, action) => {
      state.totalChapters = action.payload;
    },
    setTotalChapterTopics: (state, action) => {
      state.totalTopics = action.payload;
    },
    setTotalQuizzesAndPoints: (state, action) => {
      state.totalQuizzesAndPoints = action.payload;
    },

  },
});

export const {
  setAdmin,
  setcurrentGrade,
  setcurrentPlan,
  setcurrentSubject,
  setcurrentChapter,
  setRefferal,
  settheme,
  editAdmin,
  editreferral,
  addAdmin,
  addreferral,
  editAdminStatue,
  setBundleTime,
  setRegisteredCourseList,
  setTotalChapters,
  setTotalQuizzesAndPoints,
  setTotalChapterTopics
} = AdminSlice.actions;
export const selectadmins = (state) => state.admin.admins;
export const selectreferral = (state) => state.admin.referral;
export const selectcurrentGrade = (state) => state.admin.currentGrade;
export const selectcurrentPlan = (state) => state.admin.currentPlan;
export const selectcurrentSubject = (state) => state.admin.currentSubject;
export const selectcurrentChapter = (state) => state.admin.currentChapter;
export const selecttheme = (state) => state.admin.theme;
export const selectBundleTime = (state) => state.admin.bundleTime;
export const selectRegisteredCourseList = (state) => state.admin.registeredCourseList;
export const selectTotalChapters = (state) => state.admin.totalChapters;
export const selectTotalQuizzesAndPoints = (state) => state.admin.totalQuizzesAndPoints;
export const selectTotalChapterTopics = (state) => state.admin.totalTopics;
export default AdminSlice.reducer;
