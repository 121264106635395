import React from "react";
import PropTypes from "prop-types";
import html2canvas from "jspdf-html2canvas";

const DownloadPDF = ({ downloadFileName }) => {
  const downloadPDF = async () => {
    const pages = document.getElementsByClassName("downloadPDF");
    await html2canvas(pages, {
      jsPDF: {
        format: "a4",
      },
        watermark({ pdf, pageNumber, totalPageNumber }) {
          pdf.setFontSize(8);
          pdf.text(
            10,
            pdf.internal.pageSize.height - 30,
            `Page: ${pageNumber}/${totalPageNumber}`
          );
        },
      margin: {
        top: 20,
        right: 10,
        bottom: 0,
        left: 10,
      },
      imageType: "image/jpeg",
      output: `${downloadFileName}.pdf`,
    });
  };

  return (
    <div className="text-right">
      <button className="btn btn-secondary" onClick={downloadPDF}>
        Download PDF
      </button>
    </div>
  );
};

DownloadPDF.propTypes = {
  downloadFileName: PropTypes.string.isRequired,
};

export default DownloadPDF;
