import * as Yup from 'yup'

export const loginFormSchema = Yup.object({
    email: Yup.string().email('Email Address is Invalid').required('Required'),
    password: Yup.string().required('Required')
})

export const forgotPasswordSchema = Yup.object({
    email: Yup.string().email('Email Address is Invalid').required('Required'),
}) 

export const changePasswordSchema = Yup.object({
    password: Yup.string().min(8, 'Password must have at least 8 Characters ').required('Required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match').required('Required')
})