import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import { Login, ForgotPassword } from "./views/Login";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAuth,
  selectIsNotificationModalShow,
  setIsNotificationModalShow,
  setloading,
} from "./Redux/features/Auth/AuthSlice";
import { useLocation, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { setsubject } from "Redux/features/Subject/SubjectSlice";
import { setTeacherList } from "Redux/features/Teacher/TeacherSlice";
import { setGradeDropdown } from "Redux/features/Subject/SubjectSlice";
import NotificationModal from "components/Common/NotificationModal";
import Loading from "components/Common/Loading";
// import { analytics } from "Utils/firebase.Utils";
// import { getAnalytics } from "firebase/analytics";

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const auth = useSelector(selectAuth);
  // const isNotificationModalShow = useSelector(selectIsNotificationModalShow);
  const dispatch = useDispatch();

  // const onCloseModal = () => {
  //   dispatch(setIsNotificationModalShow(false));
  // };

  useEffect(() => {
    if (!auth) {
      history.push(`/admin/login`);
    }

    dispatch(setsubject(null));
    dispatch(setTeacherList(null));
    dispatch(setloading(false));
    dispatch(setGradeDropdown(null));
    // dispatch(setIsNotificationModalShow(false));
  }, []);

  // console.log(analytics)
  // console.log(getAnalytics())

  return (
    <div>
      <Loading />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId={"_toast"}
      />

      {/* <NotificationModal
        isOpenModal={isNotificationModalShow}
        onCloseModal={onCloseModal}
      /> */}

      <Switch>
        <Route path="/admin/login">
          <Login />
        </Route>
        <Route path="/admin/forgot-password" component={ForgotPassword} />
        {/* <PrivateRoute
          exact
          path="/admin"
          component={(props) => <AdminLayout {...props} />}
        />
        <PrivateRoute
          exact
          path="/rtl"
          component={(props) => <RTLLayout {...props} />}
        /> */}
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />

        <Redirect from="*" to="/admin/login" />
      </Switch>
    </div>
  );
};

export default App;
