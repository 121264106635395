import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from "reactstrap";
import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import { getGradeForDropdown } from "Utils/grades_Utils";
import { getAllSubjects } from "Utils/subjects_Utils";
import { toast } from "react-toastify";
import Select from "react-select";
import { getChapterForDropdown } from "Utils/chapterPreview_Utils";
import { saveNewTopic } from "Utils/topic_quiz_Utils";
import { useHistory } from "react-router-dom";

const TopicForm = ({ isEdit }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [gradesDropdown, setGradeDropdown] = useState(null);
  const [subjectsDropdown, setSubjectsDropdown] = useState(null);
  const [chaptersDropdown, setChaptersDropdown] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [level, setLevel] = useState("beginner");
  const [title, setTitle] = useState("beginner");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [realLifeScenario, setRealLifeScenario] = useState("");

  const stateChangeHandler = (setState) => (e) => setState(e.target.value);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const [grades, subjects, chapters] = await Promise.all([
        getGradeForDropdown(),
        getAllSubjects(),
        getChapterForDropdown(),
      ]);

      setGradeDropdown(
        grades.data.data?.length
          ? grades.data.data.map((e) => ({ value: e?._id, label: e?.grade }))
          : []
      );

      setSubjectsDropdown(
        subjects?.data?.data?.length ? subjects.data.data : []
      );

      setChaptersDropdown(
        chapters?.data?.data?.length ? chapters.data.data : []
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTopic = () => {};

  const saveTopic = async () => {
    try {
      const topicObj = {
        grade: selectedGrade?.value || "",
        subject: selectedSubject?.value || "",
        chapter: selectedChapter?.value || "",
        title,
        shortDescription,
        longDescription,
        level,
        realLifeScenario,
      };

      const resp = await saveNewTopic(topicObj);
      toast.success(resp.data.message);
      history.goBack();
    } catch (error) {
      toast.success(error?.response?.data?.message || error);
    }
  };

  const _submit = (e) => {
    e.preventDefault();

    if (isEdit) updateTopic();
    else saveTopic();
  };

  useEffect(() => fetchInitialData(), []);

  //  =================================================================== Render Functions ===================================================================

  const renderTopicForm = () => (
    <form onSubmit={_submit}>
      <Row>
        <Col md={6} className="m5">
          <p>Select Grade</p>
          <Select
            onChange={(e) => {
              if (e.value === selectedGrade?.value) return;

              setSelectedGrade(e);
              setSelectedSubject("");
              setSelectedChapter("");
            }}
            value={selectedGrade}
            placeholder="Select Grade"
            className="react-options"
            options={gradesDropdown}
          />
        </Col>
        <Col md={6} className="m5">
          <p>Select Subject</p>
          <Select
            onChange={(e) => {
              if (e.value === selectedSubject?.value) return;

              setSelectedSubject(e);
              setSelectedChapter("");
            }}
            value={selectedSubject}
            placeholder="Select Grade"
            className="react-options"
            options={
              selectedGrade?.value
                ? subjectsDropdown
                    .filter(
                      (e) =>
                        String(e?.grade?._id) === String(selectedGrade?.value)
                    )
                    .map((e) => ({
                      label: `${e?.Subject_name} - ${e?.grade?.grade}`,
                      value: e?._id,
                    }))
                : []
            }
          />
        </Col>
        <Col md={6} className="m5">
          <p>Select Chapter</p>
          <Select
            onChange={(e) => setSelectedChapter(e)}
            value={selectedChapter}
            placeholder="Select Grade"
            className="react-options"
            options={
              selectedSubject?.value
                ? chaptersDropdown
                    .filter((e) => e?.subject?._id === selectedSubject?.value)
                    .map((e) => ({
                      label: `${e?.chapter_name} - ${e?.subject?.Subject_name} - ${e?.grade?.grade}`,
                      value: e?._id,
                    }))
                : []
            }
          />
        </Col>
        <Col md={6} className="m5">
          <p>Select level</p>
          <select
            id="level"
            name="level"
            className="form-control"
            value={level}
            onChange={stateChangeHandler(setLevel)}
          >
            <option value="beginner">Beginner</option>
            <option value="advanced">Advanced</option>
            <option value="proficient">Proficient</option>
          </select>
        </Col>

        <Col md={12}>
          <p className="m5">Topic title</p>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Enter topic title"
            className="form-control"
            onChange={stateChangeHandler(setTitle)}
          />
        </Col>
        <Col md={12}>
          <p className="m5">Short description</p>
          <textarea
            className="w-100"
            id="shortDescription"
            name="shortDescription"
            placeholder="Enter short description"
            className="form-control"
            onChange={stateChangeHandler(setShortDescription)}
          />
        </Col>
        <Col md={12}>
          <p className="m5">Phenomenon </p>
          <textarea
            className="w-100"
            id="longDescription"
            name="longDescription"
            placeholder="Enter long Phenomenon"
            className="form-control"
            onChange={stateChangeHandler(setLongDescription)}
          />
        </Col>
        <Col md={12}>
          <p className="m5">Real life </p>
          <textarea
            className="w-100"
            id="realLifeScenario"
            name="realLifeScenario"
            placeholder="Enter leal life scenario"
            className="form-control"
            onChange={stateChangeHandler(setRealLifeScenario)}
          />
        </Col>
      </Row>

      <div>
        <button type="submit" className="btn btn-secondary">
          {isEdit ? "Update" : "Save"}
        </button>
      </div>
    </form>
  );

  return (
    <div className="topic_form content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Header title="Topic Form" />
              {loading ? <Loader /> : renderTopicForm()}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

TopicForm.propTypes = {
  isEditL: PropTypes.bool.isRequired,
};

export default TopicForm;
