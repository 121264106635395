import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allQuizzes: null,
  isQuizTab: false,
};

const QuizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setAllQuizzesList: (state, action) => {
      state.allQuizzes = action.payload;
    },
    setIsQuizTab: (state, action) => {
      state.isQuizTab = action.payload;
    },
  },
});

export const { setAllQuizzesList, setIsQuizTab } = QuizSlice.actions;

export const selectQuizzesList = (state) => state.quiz.allQuizzes;
export const selectIsQuizTab = (state) => state.quiz.isQuizTab;

export default QuizSlice.reducer;
