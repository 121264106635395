import React from 'react';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-dt/';
import Modal from 'react-awesome-modal';
import { FaRegEdit, FaBan, FaCheckCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../api';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { setGrade } from 'Redux/features/Grade/GradeSlice';
import { selectgrades } from 'Redux/features/Grade/GradeSlice';
import { setsubject } from 'Redux/features/Subject/SubjectSlice';
import { selectsubjects } from 'Redux/features/Subject/SubjectSlice';
import loader from '../assets/img/loader.svg';
import loaderimg from '../assets/img/loaderimg.svg';
import { selectcurrentGrade } from 'Redux/features/Admin/AdminSlice';
import { selectcurrentPlan } from 'Redux/features/Admin/AdminSlice';
import { setcurrentGrade } from 'Redux/features/Admin/AdminSlice';
import {
  setcurrentPlan,
  setRegisteredCourseList,
} from 'Redux/features/Admin/AdminSlice';
import { toast } from 'react-toastify';
import { selectLoading } from 'Redux/features/Auth/AuthSlice';
import { setloading } from 'Redux/features/Auth/AuthSlice';
import {
  updateStatusBackend,
  addSubjectBackend,
  updateSubjectBackend,
} from 'Utils/subjects_Utils';
import { getRegisteredCourseByGrade } from '../Utils/registered_Course';
import { selectRegisteredCourseList } from 'Redux/features/Admin/AdminSlice';
import { getChapterWithQuizzes } from '../Utils/chapterPreview_Utils';
import { setTotalChapters } from 'Redux/features/Admin/AdminSlice';
import { setTotalQuizzesAndPoints } from 'Redux/features/Admin/AdminSlice';
import { selectTotalChapters } from 'Redux/features/Admin/AdminSlice';
import { selectTotalQuizzesAndPoints } from 'Redux/features/Admin/AdminSlice';
const Subjects = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentGrade = useSelector(selectcurrentGrade);
  console.log(currentGrade);
  const currentPlan = useSelector(selectcurrentPlan);
  const loading = useSelector(selectLoading);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [modalSubject, setModalSubject] = useState({});
  const [loadingImage, setloadingImage] = useState(false);
  const [name, setname] = useState('');
  const [gradeName, setgradeName] = useState('');
  const [gradeAE, setgradeAE] = useState('');
  const [coursePlan, setcoursePlan] = useState('');
  const [grade, setgrade] = useState({});
  const [price, setPrice] = useState(0);

  const [SubIcon, setSubIcon] = useState([]);
  const [addsubIcon, setaddsubIcon] = useState('');

  const [maingradeName, setmaingradeName] = useState('');
  const [maincoursePlan, setmaincoursePlan] = useState('');
  const [activity, setactivity] = useState('');
  const [isdataavailable, setisdataavailable] = useState(false);
  // const [registeredCourseList, setRegisteredCourseList] = useState(null);

  const gradeList = useSelector(selectgrades);
  const subjectsList = useSelector(selectsubjects);
  const registeredCourseList = useSelector(selectRegisteredCourseList);
  const totalChapters = useSelector(selectTotalChapters);
  const totalQuizzesAndPoints = useSelector(selectTotalQuizzesAndPoints);

  const fetchRegisteredCourse = async () => {
    dispatch(setloading(true));
    try {
      const resp = await getRegisteredCourseByGrade();
      dispatch(setRegisteredCourseList(resp.data.data));
    } catch (error) {
      console.log(error?.response?.message);
    } finally {
      dispatch(setloading(false));
    }
  };

  useEffect(() => {
    (async () => {
      if (gradeAE) {
        const filterSubjects = subjectsList?.filter((e) => e.grade === gradeAE);

        const grade_subject_chapter = [];
        for (let index = 0; index < filterSubjects.length; index++) {
          const subject = filterSubjects[index];

          const resp = await getChapterWithQuizzes(subject._id);

          if (!resp.data.data.length) {
            grade_subject_chapter.push({
              gradeId: gradeAE,
              subjectId: subject._id,
              chapters: 0,
            });
          } else {
            grade_subject_chapter.push({
              gradeId: gradeAE,
              subjectId: subject._id,
              chapters: resp.data.data,
            });
          }
        }

        const filterChapters = grade_subject_chapter?.map((e) => {
          if (Boolean(e.chapters.length)) return e.chapters.length;

          return 0;
        });

        const filterQuizzesForChapters = grade_subject_chapter?.map((e) => {
          if (!Boolean(e.chapters.length)) return 0;

          const filterQuizzes = e.chapters.filter((a) =>
            Boolean(a.chapter_sequence)
          );

          if (!Boolean(filterQuizzes.length)) return 0;

          let quizzes = [];
          filterQuizzes.forEach((x) => {
            const { chapter_sequence } = x;
            quizzes = [...quizzes, ...chapter_sequence];
          });

          const filterTests = quizzes.filter((x) =>
            Object.keys(x).includes('test')
          );

          const filterTopics = quizzes?.filter((x) =>
            Object.keys(x).includes('topic')
          );

          let sum = 0;
          filterTests?.map((a) => (sum += a.test.total_marks));
          return {
            totalQuizzes: filterTests.length,
            totalPoints: sum,
            totalTopics: filterTopics.length,
          };
        });
        dispatch(setTotalChapters(filterChapters));
        dispatch(setTotalQuizzesAndPoints(filterQuizzesForChapters));
      }
    })();
  }, [gradeAE]);

  useEffect(() => {
    function getGradesSubject() {
      axios
        .get(`/grades`)
        .then((res) => {
          dispatch(setGrade(res.data.data));
          setTimeout(() => {}, 1000);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
      dispatch(setloading(true));
      axios
        .get(`/subject`)
        .then((res) => {
          dispatch(setsubject(res.data.data));
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })

        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    dispatch(setloading(true));
    getGradesSubject();
  }, []);

  async function uploadImage() {
    setloadingImage(true);
    const formData = new FormData();

    formData.append('files', SubIcon[0]);

    axios
      .post(`/filesupload/file-upload`, formData, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {
        setaddsubIcon(res.data.locationArray[0].fileLocation);
        setTimeout(() => {
          setloadingImage(false);
        }, 1000);
      })
      .catch((err) => {
        setloadingImage(false);
        toast.error(err.response.data.message);
        setSubIcon([]);
      });

    // console.log(addsubIcon);
  }
  const changeHandler = (event) => {
    SubIcon.push(event.target.files[0]);
    uploadImage();
  };

  // console.log(gradeList, "gradeList");

  // console.log(subjectsList, "subjectsList");
  const changeStatus = (sub) => {
    setModalSubject(sub);
    setStatusModal(true);
  };

  const handleAddSubject = () => {
    let subToAdd = {
      grade: currentGrade._id,
      Subject_name: name,
      subject_icon: addsubIcon,
      subject_price: price,
      isActive: true,
    };
    // console.log({ Add: subToAdd });

    addSubjectBackend(subToAdd, setAddModal, dispatch, setaddsubIcon);

    //
  };
  const handleEditAdmin = () => {
    let editedSub = {
      _id: modalSubject._id,
      Subject_name: name,
      grade: currentGrade._id,
      subject_icon: addsubIcon,
      course_plan: coursePlan,
      subject_price: price,
      isActive: modalSubject.isActive,
    };
    // console.log(modalSubject._id, editedSub);

    updateSubjectBackend(modalSubject._id, editedSub, setEditModal, dispatch);

    setEditModal(false);
  };

  let indexValue = -1;
  return (
    <>
      {!loading ? (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: '36px', cursor: 'pointer' }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{' '}
                      <h4 style={{ marginLeft: '10px' }}>Subjects</h4>{' '}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div className="dropdown">
                        <p>Select Grade</p>
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {/* {maingradeName} {maincoursePlan} */}
                          {currentGrade.currentgrade
                            ? currentGrade.currentgrade
                            : maingradeName}{' '}
                          {currentPlan ? currentPlan : maincoursePlan}
                          {/* {" "}
                      {maingradeName && maincoursePlan ? (
                        <>
                          {maingradeName} {maincoursePlan}
                        </>
                      ) : (
                        <>
                          {gradeList[0].grade} {gradeList[0].course_plan}
                        </>
                      )} */}
                          {/* {maingradeName} {maincoursePlan} */}
                        </a>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                          style={{ overflowY: 'scroll', maxHeight: '80vh' }}
                        >
                          {gradeList.map((grad) => {
                            return (
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setgrade(grad);
                                  setgradeAE(grad._id);
                                  setmaingradeName(grad.grade);
                                  dispatch(
                                    setcurrentGrade({
                                      _id: grad._id,
                                      currentgrade: grad.grade,
                                    })
                                  );

                                  setmaincoursePlan(grad.course_plan);
                                  dispatch(setcurrentPlan(grad.course_plan));
                                  // subjectsList.forEach((sub) => {
                                  //   if (sub.grade == grade._id) {
                                  //     setisdataavailable(true);
                                  //   } else {
                                  //     setisdataavailable(false);
                                  //   }
                                  // });
                                  // console.log(subjectsList);
                                  fetchRegisteredCourse(grad._id);
                                }}
                              >
                                {grad.grade} {grad.course_plan}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <p className="category">
                          {' '}
                          {/* Total {subjectsList.length} Sbjects Registered */}
                        </p>
                        <div>
                          <button
                            className="btn btn-priamry"
                            onClick={() => {
                              setPrice(0);
                              setAddModal(true);
                            }}
                            style={{
                              border: '0px',
                              fontSize: '14px',
                              width: '220px',
                              marginLeft: '2%',
                            }}
                          >
                            {' '}
                            Add Subject
                          </button>
                          <div className="dropdown">
                            <a
                              className="btn btn-secondary dropdown-toggle"
                              role="button"
                              id="dropdownMenuLinkGrade"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style={{ marginLeft: '2%', width: '220px' }}
                            >
                              {activity === ''
                                ? 'Select Active/Inactive'
                                : activity}
                            </a>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLinkGrade"
                            >
                              <a
                                style={{ cursor: 'pointer' }}
                                className="dropdown-item"
                                onClick={() => {
                                  setactivity('Active');
                                  // setcoursePlan("premium");
                                }}
                              >
                                Active
                              </a>
                              <a
                                style={{ cursor: 'pointer' }}
                                className="dropdown-item"
                                onClick={() => {
                                  setactivity('InActive');
                                  // setcoursePlan("freemium");
                                }}
                              >
                                InActive
                              </a>
                              <a
                                style={{ cursor: 'pointer' }}
                                className="dropdown-item"
                                onClick={() => {
                                  setactivity('');
                                  // setcoursePlan("freemium");
                                }}
                              >
                                None
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <table
                        id="dtBasicExample"
                        className="table table-striped table-bordered table-sm"
                        cellspacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th className="th-sm">Name</th>
                            <th className="th-sm">Type</th>
                            <th className="th-sm">Grade</th>
                            {currentPlan === 'premium' && (
                              <th className="th-sm">Price</th>
                            )}
                            <th className="th-sm">Total Chapters</th>
                            <th className="th-sm">Total Topics</th>
                            <th className="th-sm">Total Quizzes</th>
                            <th className="th-sm">Total Points</th>
                            <th className="th-sm">Status</th>
                            <th className="th-sm">Total Students</th>
                            <th className="th-sm">Total Income</th>
                            {/* <th className="th-sm">Total Quizzes</th> */}
                            <th className="th-sm">Actions</th>
                          </tr>
                        </thead>

                        {subjectsList.map((subject, index) => {
                          if (
                            subject.grade ==
                              (currentGrade._id
                                ? currentGrade._id
                                : grade._id) &&
                            activity == ''
                          ) {
                            // countQuizzes(subject?._id);

                            const filterSubject = registeredCourseList?.find(
                              (e) => e?._id === subject?._id
                            );
                            // const filterIsPurchase = filterSubject?.filter(
                            //   (e) => Boolean(e.isPurchase)
                            // );
                            // let sum = 0;
                            // filterIsPurchase?.forEach(
                            //   (e) => (sum += e.amountPaid)
                            // );
                            indexValue++;
                            // setTotalStudent(filterSubject?.length || 0)
                            // setTotalIncome(sum)
                            // const filterPremium = registeredCourseList?.filter(e=> e?.grade === subject?._id);
                            return (
                              // <table
                              //   id="dtBasicExample1"
                              //   className="table table-striped table-bordered table-sm"
                              //   cellspacing="0"
                              //   width="100%"
                              // >
                              <>
                                <tbody>
                                  <tr
                                    key={index}
                                    style={{ backgroundColor: 'transparent' }}
                                  >
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <p>
                                          <Link
                                            to={`/admin/subject/${subject._id}`}
                                          >
                                            {subject.Subject_name}
                                          </Link>
                                        </p>
                                        <div
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          {subject.subject_icon ? (
                                            <img
                                              src={subject.subject_icon}
                                              alt="Logo"
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <p>
                                        {currentPlan
                                          ? currentPlan
                                          : grade.course_plan}
                                      </p>
                                    </td>
                                    <td>
                                      <p>
                                        {currentGrade.currentgrade
                                          ? currentGrade.currentgrade
                                          : grade.grade}
                                      </p>
                                    </td>
                                    {currentPlan === 'premium' && (
                                      <td>
                                        <p>{subject?.subject_price}</p>
                                      </td>
                                    )}
                                    <td>
                                      <p>{totalChapters[indexValue] ??  "N/A" }</p>
                                    </td>
                                    {Boolean(
                                      totalQuizzesAndPoints[indexValue]
                                    ) ? (
                                      <>
                                        <td>
                                          <p>
                                            {
                                              totalQuizzesAndPoints[indexValue]
                                                ?.totalTopics
                                            }
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            {
                                              totalQuizzesAndPoints[indexValue]
                                                .totalQuizzes
                                            }
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            {
                                              totalQuizzesAndPoints[indexValue]
                                                .totalPoints
                                            }
                                          </p>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>
                                          <p>0</p>
                                        </td>
                                        <td>
                                          <p>0</p>
                                        </td>
                                        <td>
                                          <p>0</p>
                                        </td>
                                      </>
                                    )}

                                    <td>
                                      {subject.isActive ? (
                                        <p style={{ color: 'green' }}>Active</p>
                                      ) : (
                                        <p style={{ color: 'red' }}>Inactive</p>
                                      )}
                                    </td>
                                    <td>
                                      <p>{filterSubject?.count || 0}</p>
                                    </td>
                                    <td>
                                      <p>{filterSubject?.amountPaid}</p>
                                    </td>
                                    {/* <td>
                                      <p>{'totalChapter'}</p>
                                    </td> */}
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          marginLeft: '30%',
                                        }}
                                      >
                                        <FaRegEdit
                                          style={{
                                            fontSize: '18px',
                                            color: '#008B8B',
                                            marginRight: '10px',
                                          }}
                                          onClick={() => {
                                            setname(subject.Subject_name);
                                            setPrice(subject.subject_price);
                                            let index = gradeList.findIndex(
                                              (u) => u._id == subject.grade
                                            );
                                            setgrade(gradeList[index]);
                                            setaddsubIcon(subject.subject_icon);
                                            setgradeAE(gradeList[index]._id);

                                            setgradeName(
                                              gradeList[index].grade
                                            );
                                            setcoursePlan(
                                              gradeList[index].course_plan
                                            );
                                            setmaingradeName(
                                              gradeList[index].grade
                                            );
                                            setmaincoursePlan(
                                              gradeList[index].course_plan
                                            );
                                            dispatch(
                                              setcurrentGrade({
                                                _id: gradeList[index]._id,
                                                currentgrade:
                                                  gradeList[index].grade,
                                              })
                                            );
                                            dispatch(
                                              setcurrentPlan(
                                                gradeList[index].course_plan
                                              )
                                            );
                                            setModalSubject(subject);
                                            setEditModal(true);
                                          }}
                                        />
                                        {!subject.isActive ? (
                                          <FaBan
                                            style={{
                                              fontSize: '18px',
                                              color: 'red',
                                            }}
                                            onClick={() => {
                                              setModalSubject(subject);
                                              changeStatus(subject);
                                            }}
                                          />
                                        ) : (
                                          <FaCheckCircle
                                            onClick={() => {
                                              setModalSubject(subject);
                                              changeStatus(subject);
                                            }}
                                            style={{
                                              fontSize: '18px',
                                              color: 'green',
                                            }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                              // </table>
                            );
                          } else if (
                            subject.grade ==
                              (currentGrade._id
                                ? currentGrade._id
                                : grade._id) &&
                            activity == 'Active' &&
                            subject.isActive
                          ) {
                            return (
                              <>
                                {/* <thead>
                                <tr>
                                  <th className="th-sm">Name</th>
                                  <th className="th-sm">Type</th>
                                  <th className="th-sm">Grade</th>
                                  <th className="th-sm">Status</th>
                                  <th className="th-sm">Actions</th>
                                </tr>
                              </thead> */}
                                <tbody>
                                  <tr
                                    key={index}
                                    style={{ backgroundColor: 'transparent' }}
                                  >
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <Link
                                          to={`/admin/subject/${subject._id}`}
                                        >
                                          {subject.Subject_name}
                                        </Link>
                                        <div
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          {subject.subject_icon ? (
                                            <img
                                              src={subject.subject_icon}
                                              alt="Logo"
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {currentPlan
                                        ? currentPlan
                                        : grade.course_plan}
                                    </td>
                                    <td>
                                      {currentGrade.currentgrade
                                        ? currentGrade.currentgrade
                                        : grade.grade}
                                    </td>
                                    <td>
                                      {subject.isActive ? (
                                        <p style={{ color: 'green' }}>Active</p>
                                      ) : (
                                        <p style={{ color: 'red' }}>Inactive</p>
                                      )}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          marginLeft: '30%',
                                        }}
                                      >
                                        <FaRegEdit
                                          style={{
                                            fontSize: '18px',
                                            color: '#008B8B',
                                            marginRight: '10px',
                                          }}
                                          onClick={() => {
                                            setname(subject.Subject_name);
                                            let index = gradeList.findIndex(
                                              (u) => u._id == subject.grade
                                            );
                                            setgrade(gradeList[index]);
                                            setaddsubIcon(subject.subject_icon);
                                            setgradeAE(gradeList[index]._id);
                                            setgradeName(
                                              gradeList[index].grade
                                            );
                                            setcoursePlan(
                                              gradeList[index].course_plan
                                            );
                                            setmaingradeName(
                                              gradeList[index].grade
                                            );
                                            setmaincoursePlan(
                                              gradeList[index].course_plan
                                            );
                                            dispatch(
                                              setcurrentGrade({
                                                _id: gradeList[index]._id,
                                                currentgrade:
                                                  gradeList[index].grade,
                                              })
                                            );
                                            dispatch(
                                              setcurrentPlan(
                                                gradeList[index].course_plan
                                              )
                                            );
                                            setModalSubject(subject);
                                            setEditModal(true);
                                          }}
                                        />
                                        {!subject.isActive ? (
                                          <FaBan
                                            style={{
                                              fontSize: '18px',
                                              color: 'red',
                                            }}
                                            onClick={() => {
                                              setModalSubject(subject);
                                              changeStatus(subject);
                                            }}
                                          />
                                        ) : (
                                          <FaCheckCircle
                                            onClick={() => {
                                              setModalSubject(subject);
                                              changeStatus(subject);
                                            }}
                                            style={{
                                              fontSize: '18px',
                                              color: 'green',
                                            }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          } else if (
                            subject.grade ==
                              (currentGrade._id
                                ? currentGrade._id
                                : grade._id) &&
                            activity == 'InActive' &&
                            !subject.isActive
                          ) {
                            return (
                              <>
                                {/* <thead>
                                <tr>
                                  <th className="th-sm">Name</th>
                                  <th className="th-sm">Type</th>
                                  <th className="th-sm">Grade</th>
                                  <th className="th-sm">Status</th>
                                  <th className="th-sm">Actions</th>
                                </tr>
                              </thead> */}
                                <tbody>
                                  <tr
                                    key={index}
                                    style={{ backgroundColor: 'transparent' }}
                                  >
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <Link
                                          to={`/admin/subject/${subject._id}`}
                                        >
                                          {subject.Subject_name}
                                        </Link>
                                        <div
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          {subject.subject_icon ? (
                                            <img
                                              src={subject.subject_icon}
                                              alt="Logo"
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {currentPlan
                                        ? currentPlan
                                        : grade.course_plan}
                                    </td>
                                    <td>
                                      {currentGrade.currentgrade
                                        ? currentGrade.currentgrade
                                        : grade.grade}
                                    </td>
                                    <td>
                                      {subject.isActive ? (
                                        <p style={{ color: 'green' }}>Active</p>
                                      ) : (
                                        <p style={{ color: 'red' }}>Inactive</p>
                                      )}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          marginLeft: '30%',
                                        }}
                                      >
                                        <FaRegEdit
                                          style={{
                                            fontSize: '18px',
                                            color: '#008B8B',
                                            marginRight: '10px',
                                          }}
                                          onClick={() => {
                                            setname(subject.Subject_name);
                                            let index = gradeList.findIndex(
                                              (u) => u._id == subject.grade
                                            );
                                            setgrade(gradeList[index]);
                                            setaddsubIcon(subject.subject_icon);
                                            setgradeAE(gradeList[index]._id);
                                            setgradeName(
                                              gradeList[index].grade
                                            );

                                            setcoursePlan(
                                              gradeList[index].course_plan
                                            );
                                            setmaingradeName(
                                              gradeList[index].grade
                                            );
                                            setmaincoursePlan(
                                              gradeList[index].course_plan
                                            );
                                            dispatch(
                                              setcurrentGrade({
                                                _id: gradeList[index]._id,
                                                currentgrade:
                                                  gradeList[index].grade,
                                              })
                                            );
                                            dispatch(
                                              setcurrentPlan(
                                                gradeList[index].course_plan
                                              )
                                            );
                                            setModalSubject(subject);
                                            setEditModal(true);
                                          }}
                                        />
                                        {!subject.isActive ? (
                                          <FaBan
                                            style={{
                                              fontSize: '18px',
                                              color: 'red',
                                            }}
                                            onClick={() => {
                                              setModalSubject(subject);
                                              changeStatus(subject);
                                            }}
                                          />
                                        ) : (
                                          <FaCheckCircle
                                            onClick={() => {
                                              setModalSubject(subject);
                                              changeStatus(subject);
                                            }}
                                            style={{
                                              fontSize: '18px',
                                              color: 'green',
                                            }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          }
                        })}

                        {/* </tbody>*/}
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            visible={statusModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setStatusModal(false);
            }}
          >
            <div
              className="modal-container"
              style={{ padding: '3%', textAlign: 'center' }}
            >
              <h4 className="mb-4" style={{ color: '#242b3b' }}>
                Change Status
              </h4>

              {modalSubject.isActive ? (
                <p style={{ fontSize: '20px' }}>
                  Are you sure you want to restrict Subject{' '}
                  {modalSubject.subject_name}?
                </p>
              ) : (
                <p style={{ fontSize: '20px' }}>
                  Are you sure you want to change this Subject's status to
                  active?
                </p>
              )}
              <div
                className="w-100 d-flex justify-content-between mt-4"
                style={{ padding: '2%', paddingBottom: '3%' }}
              >
                <button
                  className="btn btn-danger"
                  onClick={() => setStatusModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-warning"
                  onClick={() => {
                    updateStatusBackend(
                      modalSubject._id,
                      modalSubject.isActive,
                      setStatusModal,
                      dispatch
                    );

                    setStatusModal(false);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            visible={addModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setAddModal(false);
              setname('');
              setgradeAE('');
              setcoursePlan('');
              setgradeName('');
              setSubIcon([]);
              setaddsubIcon('');
            }}
          >
            <div className="modal-container">
              <h4
                className="mb-4"
                style={{ color: '#242b3b', marginLeft: '26%' }}
              >
                Create New Subject
              </h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddSubject();
                  setname('');
                  setgradeAE('');
                  setcoursePlan('');
                  setgradeName('');
                  setSubIcon([]);
                  setaddsubIcon('');
                }}
              >
                <div
                  className="mb-2"
                  style={{ marginLeft: '10%', marginRight: '10%' }}
                >
                  <p>Name</p>
                  <input
                    type="text"
                    required
                    name="Name"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    marginLeft: '10%',
                    marginRight: '10%',
                  }}
                >
                  <p>Subject Icon</p>
                  <div style={{ display: 'flex' }}>
                    <input
                      type="file"
                      required
                      name="subIcon"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler}
                    />
                    {loadingImage ? (
                      <img src={loaderimg} alt="loading..." />
                    ) : null}
                  </div>
                </div>
                <div
                  className="mb-2"
                  style={{
                    marginLeft: '10%',
                    marginRight: '10%',
                    marginTop: '3%',
                  }}
                >
                  <p>
                    Grade :{' '}
                    <b>
                      {/* {currentGrade.currentgrade
                        ? currentGrade.currentgrade
                        : maingradeName}{" "}
                      {currentPlan ? currentPlan : maincoursePlan} */}
                      {/* {maingradeName} {maincoursePlan} */}
                    </b>
                  </p>
                  <div className="dropdown">
                    <a
                      className="btn btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLinkGrade"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {currentGrade ? currentGrade.currentgrade : gradeName}{' '}
                      {currentPlan ? currentPlan : coursePlan}
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLinkGrade"
                      style={{ overflowY: 'scroll', maxHeight: '80vh' }}
                    >
                      {gradeList.map((grad) => {
                        console.log(gradeList);
                        return (
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setgrade(grad);
                              setgradeName(grad.grade);
                              setmaingradeName(grad.grade);
                              setmaincoursePlan(grad.course_plan);
                              setgradeAE(grad._id);
                              dispatch(
                                setcurrentGrade({
                                  _id: grad._id,
                                  currentgrade: grad.grade,
                                })
                              );
                              setcoursePlan(grad.course_plan);
                              dispatch(setcurrentPlan(grad.course_plan));
                            }}
                          >
                            {grad.grade} {grad.course_plan}
                          </a>
                        );
                      })}
                    </div>
                    {maincoursePlan === 'premium' && (
                      <div
                        className="mb-2"
                        // style={{ marginLeft: '10%', marginRight: '10%' }}
                      >
                        <p>Subject Price</p>
                        <input
                          type="number"
                          required
                          name="price"
                          placeholder="Enter subject price"
                          value={price}
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="w-100 d-flex justify-content-end mt-4"
                  style={{ padding: '2%', paddingBottom: '3%' }}
                >
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => {
                      setAddModal(false);
                      setname("");
                      setgradeAE("");
                      setcoursePlan("");
                      setgradeName("");
                      setSubIcon([]);
                      setaddsubIcon("");
                    }}
                  >
                    Cancel
                  </button> */}
                  <button className="btn btn-warning" type="submit">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            visible={editModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setEditModal(false);
              setname('');
              setgradeAE('');
              setcoursePlan('');
              setgradeName('');
              setSubIcon([]);
              setaddsubIcon('');
            }}
          >
            <div className="modal-container">
              <h4
                className="mb-4"
                style={{ color: '#242b3b', marginLeft: '35%' }}
              >
                Edit Subject
              </h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEditAdmin();
                  setname('');
                  setgradeAE('');
                  setcoursePlan('');
                  setgradeName('');
                  setSubIcon([]);
                  setaddsubIcon('');
                  // ;
                }}
              >
                <div
                  className="mb-2"
                  style={{ marginLeft: '10%', marginRight: '10%' }}
                >
                  <p>Name</p>
                  <input
                    type="text"
                    required
                    name="Name"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{ marginLeft: '10%', marginRight: '10%' }}
                >
                  <p>Subject Icon</p>
                  <div style={{ display: 'flex' }}>
                    <input
                      type="file"
                      name="subIcon"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler}
                    />
                    {loadingImage ? (
                      <img src={loaderimg} alt="loading..." />
                    ) : null}
                  </div>
                </div>
                <div
                  className="mb-2"
                  style={{
                    marginLeft: '10%',
                    marginRight: '10%',
                    marginTop: '3%',
                  }}
                >
                  <p>Grade</p>
                  <div className="dropdown">
                    <a
                      className="btn btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLinkGrade"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {gradeName} {coursePlan}
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLinkGrade"
                      style={{ overflowY: 'scroll', maxHeight: '80vh' }}
                    >
                      {gradeList.map((grad) => {
                        return (
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setgrade(grad);
                              setgradeName(grad.grade);
                              setmaingradeName(grad.grade);
                              setmaincoursePlan(grad.course_plan);
                              setgradeAE(grad._id);
                              dispatch(
                                setcurrentGrade({
                                  _id: grad._id,
                                  currentgrade: grad.grade,
                                })
                              );
                              setcoursePlan(grad.course_plan);
                              dispatch(setcurrentPlan(grad.course_plan));
                            }}
                          >
                            {grad.grade} {grad.course_plan}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {maincoursePlan === 'premium' && (
                  <div
                    className="mb-2"
                    style={{ marginLeft: '10%', marginRight: '10%' }}
                  >
                    <p>Subject Price</p>
                    <input
                      type="number"
                      required
                      name="price"
                      placeholder="Enter subject price"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </div>
                )}

                <div
                  className="w-100 d-flex justify-content-end mt-4"
                  style={{ padding: '2%', paddingBottom: '3%' }}
                >
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => {
                      setEditModal(false);
                      setname("");
                      setgradeAE("");
                      setcoursePlan("");
                      setgradeName("");
                      setSubIcon([]);
                      setaddsubIcon("");
                    }}
                  >
                    Cancel
                  </button> */}
                  <button className="btn btn-warning" type="submit">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Subjects;
