import { useContext } from "react";
import PropTypes from "prop-types";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import MaterialTable from "material-table";
import { format } from "date-fns";

function LiveSessionReports({ liveSessionReportList }) {
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();

  const renderCellValue = (value) => (
    <div className="report_value">
      <h1>{value}</h1>
    </div>
  );

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  return (
    <div className="reports_list mt-3">
      {liveSessionReportList?.length ? (
        <MaterialTable
          icons={tableIcons}
          title={null}
          //   key={count++}
          columns={[
            {
              title: "Student Name",
              field: "studentName",
              render: (rowData) => renderCellValue(rowData?.studentName),
            },
            {
              title: "Title",
              field: "sessionTitle",
              render: (rowData) => renderCellValue(rowData?.sessionTitle),
            },
            {
              title: "Grade",
              field: "grade",
              render: (rowData) => renderCellValue(rowData?.grade),
            },
            {
              title: "Subject",
              field: "subject",
              render: (rowData) => renderCellValue(rowData?.subject),
            },
            {
              title: "Date",
              field: "sessionDate",
              render: (rowData) => renderCellValue(rowData?.sessionDate),
            },
            {
              title: "Time",
              field: "sessionTime",
              render: (rowData) => renderCellValue(rowData?.sessionTime),
            },
            {
              title: "Status",
              field: "sessionStatus",
              render: (rowData) => renderCellValue(rowData?.sessionStatus),
            },
          ]}
          data={
            liveSessionReportList?.length > 0 &&
            liveSessionReportList.map((e) => e)
          }
          options={{
            actionsColumnIndex: -1,
            exportButton: true,
            pageSize: 15,
            pageSizeOptions: [
              5,
              15,
              30,
              liveSessionReportList?.length,
              
            ],
            headerStyle: {
              backgroundColor: colorsList.find((e) => e.color === color).value,
              color: "#FFF",
            },
            filtering: true,
          }}
        />
      ) : (
        <h6 className="mt-3">Data not found</h6>
      )}
    </div>
  );
}

LiveSessionReports.propTypes = {
  liveSessionReportList: PropTypes.array.isRequired,
};

export default LiveSessionReports;
