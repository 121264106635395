import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

const DateFilters = ({ submitHandler, refreshHandler }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const onChangeDateHandler = (setState) => (e) => setState(e.target.value);

  return (
    <div className="row date_filter">
      <div className="col-md-6">
        <p>Start Date</p>
        <input
          className="form-control"
          type="date"
          onChange={onChangeDateHandler(setStartDate)}
          value={startDate}
        />
      </div>
      <div className="col-md-6">
        <p>End Date</p>
        <input
          className="form-control"
          type="date"
          onChange={onChangeDateHandler(setEndDate)}
          value={endDate}
          min={startDate}
        />
      </div>
      <div className="col-md-12">
        <button
          className="btn btn-secondary filter_btn"
          onClick={() => submitHandler({ startDate, endDate })}
        >
          Filter
        </button>
        <button
          className="btn btn-secondary filter_btn"
          onClick={() => {
            setStartDate("");
            setEndDate("");
            // submitHandler("");
          }}
        >
          Clear
        </button>
        <button
          className="btn btn-secondary filter_btn"
          onClick={() => {
            setStartDate("");
            setEndDate("");
            refreshHandler();
          }}
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

DateFilters.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default DateFilters;
