import axios from "../api";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { toast } from "react-toastify";
import {
  addAdmin,
  editAdmin,
  editAdminStatue,
} from "Redux/features/Admin/AdminSlice";

// Add Admin Backend
export function addadminbackend(data, setAddModal, dispatch) {
  setAddModal(false);
  dispatch(setloading(true));
  axios

    .post(`/user/create/admin`, data)
    .then((res) => {
      setAddModal(false);
      dispatch(addAdmin(res.data.data));
      toast.success("Admin Added Successfully!");
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch(setloading(false));
        try {
          toast.error(
            err.response.data.message.includes("pattern")
              ? `${
                  err.response.data.message.split(" ")[0]
                } must contain alphabets`
              : err.response.data.message
          );
        } catch (error) {
          toast.error(err.message);
        }
      }, 1000);
    });
}

// Update Admin Backend
export function updateAdminBackend(data, setEditModal, dispatch) {
  axios
    .put(`/user/${data._id}`, data.editAdmin)
    .then((res) => {
      setEditModal(false);
      dispatch(editAdmin(res.data.data));
      dispatch(setloading(true));

      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success("Admin Updated Successfully!");
    })
    .catch((err) => {
      try {
        toast.error(
          err.response.data.message.includes("pattern")
            ? `${
                err.response.data.message.split(" ")[0]
              } must contain alphabets`
            : err.response.data.message
        );
      } catch (error) {
        toast.error(err.message);
      }
    });
}

// Update Status Backend
export function updateAdminStatus(data, setStatusModal, dispatch) {
  let datatoadd = { isActive: !data.isActive };
  axios
    .put(`/user/status/${data._id}`, datatoadd)
    .then((res) => {
      setStatusModal(false);
      dispatch(
        editAdminStatue({ _id: data._id, isActive: datatoadd.isActive })
      );
      dispatch(setloading(true));

      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);

      toast.success(`Admin's Status Updated Successfully!`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}
