import backend from "../api";

export const generateAccessToken = async () =>
  await backend({
    method: "GET",
    url: "/accessTokens",
    // url: '/accessTokensURL',
  });

export const getTotalPerRole = async () =>
  await backend({
    method: "GET",
    url: "/user/role/count",
  });

export const getGoogleAnalyticsData = (stats, startDate, endDate) => {
  switch (stats) {
    case "screenVisits":
      var data = JSON.stringify({
        dimensions: [
          {
            name: "unifiedScreenName",
          },
        ],
        dateRanges: [
          {
            startDate: startDate,
            endDate: endDate,
          },
        ],
        metrics: [
          {
            name: "eventCount",
          },
        ],
      });
      return data;

    case "noOfStudents":
      var data = JSON.stringify({
        dateRanges: [
          {
            startDate: startDate,
            endDate: endDate,
          },
        ],
        metrics: [
          {
            name: "newUsers",
          },
          {
            name: "activeUsers",
          },
        ],
      });

      return data;

    case "screenSessionTime":
      var data = JSON.stringify({
        dateRanges: [
          {
            startDate: startDate,
            endDate: endDate,
          },
        ],
        dimensions: [
          {
            name: "customEvent:screen_session_time",
          },
        ],
      });

      return data;

    case "studentsPerCountry":
      var data = JSON.stringify({
        dateRanges: [
          {
            startDate: "30daysAgo",
            endDate: "today",
          },
        ],
        dimensions: [
          {
            name: "country",
          },
          {
            name: "city",
          },
        ],
        metrics: [
          {
            name: "newUsers",
          },
        ],
        dimensionFilter: {
          filter: {
            fieldName: "country",
            stringFilter: {
              value: "Iraq",
            },
          },
        },
      });

      return data;

    case "studentsPerRegion":
      var data = JSON.stringify({
        dateRanges: [
          {
            startDate: startDate,
            endDate: endDate,
          },
        ],
        dimensions: [
          {
            name: "country",
          },
          {
            name: "city",
          },
          {
            name: "region",
          },
        ],
        metrics: [
          {
            name: "activeUsers",
          },
        ],
        dimensionFilter: {
          filter: {
            fieldName: "country",
            stringFilter: {
              value: "Iraq",
            },
          },
        },
      });

      return data;

    case "activeUserIn30Minutes":
      var data = JSON.stringify({
        metrics: [
          {
            name: "activeUsers",
          },
        ],
      });

      return data;

    case "timeInTheApp":
      var data = JSON.stringify({
        dateRanges: [
          {
            startDate: "2daysAgo",
            endDate: "today",
          },
        ],
        dimensions: [
          {
            name: "customEvent:session_time",
          },
        ],
      });

      return data;

    case "studentSource":
      var data = JSON.stringify({
        dateRanges: [
          {
            startDate: "30daysAgo",
            endDate: "today",
          },
        ],
        dimensions: [
          {
            name: "firstUserSource",
          },
        ],
        metrics: [
          {
            name: "totalUsers",
          },
        ],
      });

      return data;

    default:
      break;
  }
};

export const arrangeGoogleAnalyticsData = async (postData, data) => {
  let screenNames = [];
  let eventCount = [];

  if (postData === "screenVisits") {
    const screenCount = data?.map((e) => {
      if (
        e?.dimensionValues[0]?.value === "(not set)" ||
        e?.metricValues[0]?.value === "(not set)"
      )
        return null;

      return {
        screens: e?.dimensionValues[0]?.value,
        counts: e?.metricValues[0]?.value,
      };
    });

    const allScreens =
      screenCount?.length &&
      screenCount
        .reduce((a, b) => (a || b?.screens ? [...a, b?.screens] : null), [])
        .filter((e) => e);

    const allVisits =
      screenCount?.length &&
      screenCount
        .reduce((a, b) => (a || b?.counts ? [...a, b?.counts] : null), [])
        .filter((e) => e);

    screenNames = [...allScreens];
    eventCount = [...allVisits];
  } else if (postData === "noOfStudents") {
    const [...rest] = data[0].metricValues;

    const counts = rest?.length && rest.map((e) => e.value);
    screenNames = ["newUsers", "activeUsers"];
    eventCount = [...counts];
  } else if (postData === "screenSessionTime") {
    const arrangeScreenTime = await data
      .map((e) => {
        if (e.dimensionValues[0].value === "(not set)") return null;

        return {
          screen: e.dimensionValues[0].value.split(", ")[0],
          time: e.dimensionValues[0].value.split(", ")[1],
        };
      })
      .filter((e) => Boolean(e));

    const allScreens = await arrangeScreenTime.map((e) => e?.screen);
    const commonScreens = [...new Set(allScreens)];

    const screenWithTime = commonScreens.map((e) => {
      const filterScreen = arrangeScreenTime.filter((x) => x?.screen === e);

      const screenTime = filterScreen.reduce(
        (a, b) => (a += Number(b.time.split(" ")[0])),
        0
      );

      return {
        screen: e,
        time: screenTime / allScreens.filter((d) => d === e).length,
      };
    });
    screenNames = [...screenWithTime.map((e) => e.screen)];
    eventCount = [...screenWithTime.map((e) => e.time)];
  } else if (postData === "studentsPerCountry") {
    screenNames = [...data.map((e) => e.dimensionValues[1].value)];
    eventCount = [...data.map((e) => e.metricValues[0].value)];
  } else if (postData === "studentsPerRegion") {
    screenNames = [...data.map((e) => e.dimensionValues[2].value)];
    eventCount = [...data.map((e) => e.metricValues[0].value)];
  } else if (postData === "activeUserIn30Minutes") {
    screenNames = ["activeUsers"];
    eventCount = [data[0].metricValues[0].value];
  } else if (postData === "timeInTheApp") {
    screenNames = ["TIME IN THE APP"];
    let arrangeCount = [];
    data.forEach((e) => {
      if (e.dimensionValues[0].value !== "(not set)")
        return arrangeCount.push(
          Number(e.dimensionValues[0].value.split(" ")[0])
        );
    });
    const totalTime = arrangeCount.reduce((a, b) => (a += b), 0);
    eventCount = [totalTime / arrangeCount.length];
  } else if (postData === "studentSource") {
    screenNames = [...data.map((e) => e.dimensionValues[0].value)];
    eventCount = [...data.map((e) => e.metricValues[0].value)];
  }

  return { screenNames, eventCount };
};
