import { Row, Col, Card, CardHeader, CardTitle } from "reactstrap";
import loader from "../../assets/img/loader.svg";

const Loader = () => (
  <div className='content'>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <CardTitle className="loader">
              <img src={loader} alt="Loading...." />
            </CardTitle>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  </div>
)

export default Loader;
