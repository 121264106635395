import backend from "../api";

var pop_cus = [
  {
    path: "user",
    select: "+user_name +first_name +last_name +profile_image",
  },
  {
    path: "subject",
    select: "+Subject_name",
  },
];

var pop_cus_1 = [
  {
    path: "user",
  },
  {
    path: "grade",
  },
  {
    path: "subject",
  },
];

var pop_cus_2 = [
  {
    path: "user",
  },
  {
    path: "grade",
  },
  {
    path: "subject",
  },
];

const pop_course = encodeURI(JSON.stringify(pop_cus));
const pop_course_1 = encodeURI(JSON.stringify(pop_cus_1));
const pop_course_2 = encodeURI(JSON.stringify(pop_cus_2));

export const saveLiveSession = async (liveSessionObj) =>
  await backend({
    method: "POST",
    url: "/live/session",
    data: liveSessionObj,
  });

export const getLiveSessions = async (sessionID) =>
  await backend({
    method: "GET",
    url: sessionID
      ? `/live/session?_id=${sessionID}&populate=${pop_course_1}`
      : `/live/session?populate=${pop_course}`,
  });

export const getTeacherLiveSessions = async (teacherID) =>
  await backend({
    method: "GET",
    url: `/live/session?user=${teacherID}&populate=${pop_course}`,
  });

export const updateLiveSession = async (liveSessionObj) =>
  await backend({
    method: "PUT",
    url: `/live/session/${liveSessionObj?._id}`,
    data: liveSessionObj,
  });

export const getLiveSessionsDetails = async () =>
  await backend({
    method: "GET",
    url: `/live/session?populate=${pop_course}`,
  });
