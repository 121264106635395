import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../api";
import loader from "../../assets/img/loader.svg";
import loaderimg from "../../assets/img/loaderimg.svg";
import { ToastContainer, toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { createQuizBackend } from "Utils/quiz_Utils";
const CreateQuiz = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let lessonid = location.pathname.split("/")[5];
  let photo = "";
  const [data, setdata] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [question, setquestion] = useState("");
  const [questionPhotos, setquestionPhotos] = useState([]);
  const [addQuesitonurls, setaddQuesitonurls] = useState("");
  const [questionpoints, setquestionpoints] = useState(0);
  const [answer1, setanswer1] = useState("");
  const [answer1Photos, setanswer1Photos] = useState([]);
  const [addAnswer1urls, setaddAnswer1urls] = useState("");
  const [answer2, setanswer2] = useState("");
  const [answer2Photos, setanswer2Photos] = useState([]);
  const [addAnswer2urls, setaddAnswer2urls] = useState("");
  const [correct, setcorrect] = useState("");
  const [correctPhotos, setcorrectPhotos] = useState(null);
  const loading = useSelector(selectLoading);
  const [loadingImage, setloadingImage] = useState(false);
  const [qID, setqID] = useState(0);
  const [Mcq, setMcq] = useState({});
  const [totalMarks, settotalMarks] = useState(0);
  const [quizName, setquizName] = useState("");
  const [passingMark, setpassingMark] = useState(0);
  const [answerImages, setanswerImages] = useState([]);
  const [quizBankHolder, setquizBankHolder] = useState([]);
  const [raw, setraw] = useState("");
  const history = useHistory();

  // Populate Quiz
  async function populateQuiz(data) {
    await axios.post(`/test/question/${qID}`, {
      questions: data,
    });
  }
  const checkCorrect = () => {
    if (correct === answer1 && addAnswer1urls !== "") {
      photo = addAnswer1urls;
      setcorrectPhotos(addAnswer1urls);
    } else if (correct === answer2 && addAnswer2urls !== "") {
      photo = addAnswer2urls;
      setcorrectPhotos(addAnswer2urls);
    }
  };
  useEffect(() => {
    function fetchQuiz() {
      axios
        .get(`/chapter?_id=${lessonid}`)
        .then((res) => {
          setdata(res.data.data);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    dispatch(setloading(true));
    fetchQuiz();
  }, []);
  // console.log(quiz);
  if (data.length > 0) {
    console.log(data);
    const sub = data[0].subject;
    const chap = data[0]._id;
    const topic = "60eed8c278c1d42f3182bc77";

    const createMcqsQuiz = () => {
      const true_false = {
        isActive: true,
        subject: sub,
        chapter: chap,
        topic: topic,
        test_title: quizName,
        total_marks: totalMarks,
        passing_marks: passingMark,
        // questions: quizBankHolder,
      };
      createQuizBackend(
        true_false,
        quizBankHolder,
        lessonid,
        dispatch,
        history
      );
    };
    const populateQuizBank = () => {
      const quizItem = {
        question_type: "True/False",
        question_title: question,
        questionpoints: questionpoints,
        question_image: addQuesitonurls,
        options: [
          { text: answer1, image: addAnswer1urls },
          { text: answer2, image: addAnswer2urls },
        ],
        marks: questionpoints,
        answer_image: correctPhotos,
        answer_image: photo,
        correct_answer: correct,
      };
      console.log(quizItem, "to be added");
      setquizBankHolder([...quizBankHolder, quizItem]);
      // quizBank.push(quizItem);
      setquestion("");
      setanswer1("");
      setanswer2("");

      setcorrect("");
      setaddQuesitonurls("");
      setaddAnswer1urls("");
      setaddAnswer2urls("");

      setquestionPhotos([]);
      setanswer1Photos([]);
      setanswer2Photos([]);

      // console.log(quizBankHolder);
    };
    // Question Image Handler
    async function uploadImageQuetion() {
      setloadingImage(true);
      const formData = new FormData();

      formData.append("files", questionPhotos[0]);

      axios
        .post(`/filesupload/file-upload`, formData, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then((res) => {
          setaddQuesitonurls(res.data.locationArray[0].fileLocation);
          // console.log("Data", addQuesitonurls);
          setTimeout(() => {
            setloadingImage(false);
          }, 1000);
        })
        .catch((err) => {
          setloadingImage(false);
          toast.error(err.response.data.message);
        });
    }
    const changeHandlerQuestion = (event) => {
      questionPhotos.push(event.target.files[0]);
      uploadImageQuetion();
    };
    // -------------------------------------

    // Answer1 Image Handler
    async function uploadImageAnswer1() {
      setloadingImage(true);
      const formData = new FormData();

      formData.append("files", answer1Photos[0]);

      axios
        .post(`/filesupload/file-upload`, formData, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then((res) => {
          setaddAnswer1urls(res.data.locationArray[0].fileLocation);
          setTimeout(() => {
            setloadingImage(false);
          }, 1000);
        })
        .catch((err) => {
          setloadingImage(false);
          toast.error(err.response.data.message);
        });

      if (correct === answer1) {
        setcorrectPhotos(addAnswer1urls);
      }
      // console.log("Answer1 Photos", addAnswer1urls);
    }
    const changeHandlerAnswer1 = (event) => {
      answer1Photos.push(event.target.files[0]);
      uploadImageAnswer1();
    };
    // -------------------------------------

    // Answer2 Image Handler
    async function uploadImageAnswer2() {
      setloadingImage(true);
      const formData = new FormData();

      formData.append("files", answer2Photos[0]);

      axios
        .post(`/filesupload/file-upload`, formData, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then((res) => {
          setaddAnswer2urls(res.data.locationArray[0].fileLocation);
          setTimeout(() => {
            setloadingImage(false);
          }, 1000);
        })
        .catch((err) => {
          setloadingImage(false);
          toast.error(err.response.data.message);
        });

      if (correct === answer2) {
        setcorrectPhotos(addAnswer2urls);
      }
      // console.log("Answer2 Photos", addAnswer2urls);
    }
    const changeHandlerAnswer2 = (event) => {
      answer2Photos.push(event.target.files[0]);
      uploadImageAnswer2();
    };
    // -------------------------------------
    const resetState = () => {
      var ele = document.getElementsByName("correctanswer");
      for (var i = 0; i < ele.length; i++) ele[i].checked = false;
      const file = document.getElementsByClassName("filehandlers");
      for (var j = 0; j < file.length; j++) file[j].value = "";
      setaddQuesitonurls("");
      setaddAnswer1urls("");
      setaddAnswer2urls("");

      setquestionPhotos([]);
      setanswer1Photos([]);
      setanswer2Photos([]);

      setquestionpoints(0);
      photo = "";
    };
    return (
      <div className="content">
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {" "}
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>
                      Create True/False Quiz
                    </h4>{" "}
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="quizname"
                      required
                      placeholder="Quiz Name"
                      value={quizName}
                      style={{ width: "980px" }}
                      onChange={(e) => {
                        setquizName(e.target.value);
                      }}
                    />
                  </div>
                </CardTitle>
              </CardHeader>

              <CardBody>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ marginBottom: "10px" }}>
                    Total Marks :{" "}
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="totalMark"
                        placeholder="Total Marks"
                        value={totalMarks}
                        style={{ width: "380px" }}
                        readonly="readonly"
                      />
                    </div>
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    Passing Marks :{" "}
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="passingMark"
                        placeholder="Passing Marks"
                        value={passingMark}
                        style={{ width: "380px" }}
                        onChange={(e) => {
                          setpassingMark(e.target.value);
                        }}
                      />
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader
                tag="h4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Add True/False</h4>
                <p style={{ marginBottom: "10px", marginTop: "10px" }}>
                  <button
                    className="btn btn-secondary btn-sm"
                    style={{ margin: "0px 10px" }}
                    onClick={() => {
                      if (questionpoints !== 0) {
                        setquestionpoints(parseInt(questionpoints - 1));
                        settotalMarks(parseInt(totalMarks - 1));
                      }
                    }}
                  >
                    -
                  </button>
                  Question Points: {parseInt(questionpoints)}
                  <button
                    className="btn btn-secondary btn-sm"
                    style={{ margin: "0px 10px" }}
                    onClick={() => {
                      setquestionpoints(parseInt(questionpoints + 1));
                      settotalMarks(parseInt(totalMarks + 1));
                    }}
                  >
                    +
                  </button>
                </p>
              </CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    checkCorrect();

                    if (questionpoints > 0) {
                      setraw(Math.floor(Math.random() * 20));

                      populateQuizBank();
                      resetState();
                    } else {
                      window.alert("Please Provide Question Points > 0");
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="question"
                        placeholder="Question"
                        value={question}
                        style={{ width: "940px", marginLeft: "35px" }}
                        required
                        onChange={(e) => {
                          setquestion(e.target.value);
                        }}
                      />
                    </div>
                    {/* <input
                      type="file"
                      accept="image/jpeg, image/png"
                      
                      className = 'filehandlers'
                      style={{ width: "200px", padding: "6px 0px" }}
                      onChange={changeHandlerQuestion}
                    /> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="answerno1"
                      name="correctanswer"
                      value={answer1}
                      style={{
                        width: "25px",
                        height: "20px",
                        marginBottom: "20px",
                      }}
                      onClick={() => {
                        setcorrect(answer1);
                        setcorrectPhotos(addAnswer1urls);
                        // photo = addAnswer1urls;
                        // console.log(photo, "Added 1");
                        // console.log(correct, "Correct");
                        // console.log(correctPhotos, "Correct Photo");
                      }}
                    />
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="answer1"
                        placeholder="Answer1"
                        value={answer1}
                        style={{ width: "950px" }}
                        required
                        onChange={(e) => {
                          setanswer1(e.target.value);
                        }}
                      />
                    </div>
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      className="filehandlers"
                      style={{ width: "200px", padding: "6px 0px" }}
                      onChange={changeHandlerAnswer1}
                    />

                    {/* <input
                      type="radio"
                      id="answerno1"
                      name="correctanswer"
                      value={answer1}
                      style={{ width: "25px" }}
                      onClick={() => {
                        setcorrect(answer1);
                        setcorrectPhotos(addAnswer1urls);
                        console.log(correct, "Correct");
                        console.log(correctPhotos, "Correct Photo");
                      }}
                    /> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      type="radio"
                      id="answerno2"
                      name="correctanswer"
                      value={answer2}
                      style={{
                        width: "25px",
                        height: "20px",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        setcorrect(answer2);
                        setcorrectPhotos(addAnswer2urls);
                        // photo = addAnswer2urls;
                        // console.log(photo, "Added 2");
                        // console.log(correct, "Correct");
                        // console.log(correctPhotos, "Correct Photo");
                      }}
                    />
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="answer2"
                        placeholder="Answer2"
                        value={answer2}
                        style={{ width: "950px" }}
                        required
                        onChange={(e) => {
                          setanswer2(e.target.value);
                        }}
                      />
                    </div>
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      className="filehandlers"
                      style={{ width: "200px", padding: "6px 0px" }}
                      onChange={changeHandlerAnswer2}
                    />

                    {/* <input
                      type="radio"
                      id="answerno2"
                      name="correctanswer"
                      value={answer2}
                      style={{ width: "25px" }}
                      onClick={() => {
                        setcorrect(answer2);
                        setcorrectPhotos(addAnswer2urls);
                        console.log(correct, "Correct");
                        console.log(correctPhotos, "Correct Photo");
                      }}
                    /> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {loadingImage ? (
                      <img src={loaderimg} alt="loading..." />
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setquestion("");
                        setanswer1("");
                        setanswer2("");

                        setcorrect("");
                        setqID(0);
                        setquizBankHolder([]);
                        setquestionpoints(0);
                        setpassingMark(0);
                        settotalMarks(0);
                        resetState();
                      }}
                      class="btn btn-priamry"
                    >
                      Reset
                    </button>
                    {correct === "" ? (
                      <button
                        type="submit"
                        class="btn btn-priamry"
                        disabled={true}
                      >
                        Add Question
                      </button>
                    ) : (
                      <button
                        type="submit"
                        class="btn btn-priamry"
                        disabled={false}
                      >
                        Add Question
                      </button>
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardHeader tag="h4">True/False (In Progress)</CardHeader>
              <CardBody>
                {quizBankHolder.map((quiz) => (
                  <div>
                    <p>Question : {quiz.question_title}</p>
                    {/* <img
                      style={{ marginTop: "10px" }}
                      src={quiz.question_image}
                      alt="Topic Attachment"
                    /> */}
                    <p>Answers: </p>
                    {quiz.options.map((ans, i) => {
                      return (
                        <div>
                          <p key={i}>{ans.text}</p>
                          <img
                            style={{ marginTop: "10px" }}
                            src={ans.image}
                            alt="Topic Attachment"
                          />
                        </div>
                      );
                    })}

                    <p>Correct Answer : {quiz.correct_answer}</p>
                    <img src={quiz.answer_image} alt="Topic Attachment" />
                    <hr />
                  </div>
                ))}
                <button
                  class="btn btn-priamry"
                  disabled={quizBankHolder.length === 0 ? true : false}
                  onClick={() => {
                    if (quizName == "") {
                      window.alert("Quiz Name not provided");
                    } else if (passingMark > totalMarks) {
                      window.alert("Enter a valid passing mark!");
                    } else if (passingMark == 0) {
                      window.alert("Passing Marks not provided");
                    } else {
                      createMcqsQuiz();
                    }
                  }}
                >
                  Create Quiz
                </button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={loader} alt="Loading...." />
                </CardTitle>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
};

export default CreateQuiz;
