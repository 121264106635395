import React, { useState } from 'react';
import dateFormat from 'dateformat';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import { useEffect } from 'react';
import axios from '../../api';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { updateRegisteredCourseExpireDate } from 'Utils/registered_Course';

const ChapterCard = ({
  history,
  registration_date,
  Subject_name,
  grade,
  Subject,
  isPurchase,
  isBundle,
  coursePlan,
  purchaseDate,
  expiryDate,
  amountPaid,
  regCourseId,
  gradePlan,
  fetchRegisteredCourses
}) => {
  const [selectedChapter, setselectedChapter] = useState('');
  const [chapterList, setchapterList] = useState([]);
  const [topicList, settopicList] = useState([]);
  const [isEditExpiryDate, setIsEditExpiryDate] = useState(false);
  const [expiry_Date, setExpiryDate] = useState(expiryDate); // refactor
  const [isExpire, setIsExpire] = useState(new Date(expiryDate).getTime() < new Date().getTime());

  const updateExpiryDate = async () => {
    if (new Date(purchaseDate).getTime() > new Date(expiry_Date).getTime()) {
      toast.error('Expiry date must be greater than purchase date');
      setExpiryDate(expiryDate);
      return;
    }

    try {
      const resp = await updateRegisteredCourseExpireDate(
        regCourseId,
        expiry_Date
      );
      toast.success(resp.data.message);
      fetchRegisteredCourses();
    } catch (err) {
      console.log(err);
    }
  };

  const renderExpiryDate = () => (
    <div className="edit__date">
      {!isEditExpiryDate && (
        <p style={{ color: `${isExpire ? "red" : ""}` }} >
          Expiry Date : {dateFormat(new Date(expiry_Date), 'mmmm dS, yyyy')}
        </p>
      )}

      {isEditExpiryDate && (
        <input
          type="date"
          className="date_field"
          value={format(new Date(expiry_Date), 'yyyy-MM-dd')}
          placeholder="Set expiry date"
          onChange={(e) => {
            setExpiryDate(e.target.value);
          }}
        />
      )}

      <i
        className={`${isEditExpiryDate ? 'fas fa-save' : 'fas fa-edit'} m_5`}
        title={`${isEditExpiryDate ? 'Save' : 'Edit'}`}
        onClick={() => {
          setIsEditExpiryDate(!isEditExpiryDate);
          isEditExpiryDate && updateExpiryDate();
        }}
      ></i>
      {isEditExpiryDate && (
        <i
          className="fas fa-times m_5"
          title="cancel"
          onClick={() => {
            setIsEditExpiryDate(false);
          }}
        ></i>
      )}
    </div>
  );

  useEffect(() => {
    async function getChapters() {
      axios
        .get(`/chapter?subject=${Subject?._id}`)
        .then((res) => {
          setchapterList(res.data.data);
        })
        .catch((err) => {
          toast.error(err);
        });

      axios
        .get(`/topics`)
        .then((res) => {
          settopicList(res.data.data);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    getChapters();
  }, []);

  return (
    <div className="chapter-card">
      <Card>
        <CardHeader>
          <CardTitle>
            <div className="chapInfo">
              <p>Subject name : {Subject_name}</p>
              <p>
                Registration Date :{' '}
                {dateFormat(registration_date, 'mmmm dS, yyyy')}
              </p>
            </div>

            {gradePlan === 'premium' && (isBundle || isPurchase) && (
              <div className="chapInfo">
                <p>
                  Purchase Date : {dateFormat(purchaseDate, 'mmmm dS, yyyy')}
                </p>
                {renderExpiryDate()}
              </div>
            )}

            <div className="chapInfo">
              <p>Grade : {grade}</p>
              {gradePlan === 'premium' && (
                <>
                  {isBundle && isPurchase && (
                    <p className="status">Bundle Purchased </p>
                  )}
                  {!isBundle && isPurchase && (
                    <p className="status">Subject Purchased </p>
                  )}
                </>
              )}
            </div>

            <div className="chapInfo">
              {gradePlan === 'premium' && <p>amount paid : {amountPaid}</p>}
              <p>grade plan : {gradePlan}</p>
            </div>

            <div class="dropdown">
              <a
                class="btn btn-secondary dropdown-toggle"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedChapter !== '' ? selectedChapter : 'Select Chapter'}
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {chapterList.map((chap) => {
                  return (
                    <a
                      style={{ cursor: 'pointer' }}
                      class="dropdown-item"
                      onClick={() => {
                        setselectedChapter(chap.chapter_name);
                      }}
                    >
                      {chap.chapter_name}
                    </a>
                  );
                })}
              </div>
            </div>
            <div style={{ width: '90%', margin: '3%' }}>
              <div style={{ width: '100%' }} id="tableHolder">
                <table
                  id="infoTable"
                  class="table table-striped table-bordered table-sm"
                  cellspacing="0"
                  width="100%"
                >
                  <thead>
                    {selectedChapter ? (
                      <tr>
                        <th class="th-sm">Chapter Name</th>
                        <th class="th-sm">Name</th>
                        <th class="th-sm">Date</th>
                        <th class="th-sm">Status</th>
                      </tr>
                    ) : (
                      '-'
                    )}
                  </thead>
                  <tbody>
                    {history.map((his) => {
                      if (
                        his.chapter.chapter_name === selectedChapter &&
                        his.length !== 0
                      ) {
                        return (
                          <tr>
                            <td>{his.chapter.chapter_name}</td>
                            {'test' in his ? (
                              <td>{his.test.test_title}</td>
                            ) : (
                              <td>
                                {topicList.map((top) => {
                                  if (top._id === his.topic) {
                                    return top.topic_title;
                                  }
                                })}
                              </td>

                              // his.topic
                            )}
                            {/* <td>{his.test.test_title}</td> */}
                            <td>
                              {dateFormat(his.test_date, 'mmmm dS, yyyy')}
                            </td>
                            <td>
                              {his.status ? (
                                <div>
                                  {his.status === 'complete' ? (
                                    <i
                                      class="fa fa-circle"
                                      aria-hidden="true"
                                      style={{ color: 'green' }}
                                    ></i>
                                  ) : his.status === 'incomplete' ? (
                                    <i
                                      class="fa fa-circle"
                                      aria-hidden="true"
                                      style={{ color: 'red' }}
                                    ></i>
                                  ) : (
                                    <i
                                      class="fa fa-circle"
                                      aria-hidden="true"
                                      style={{ color: 'yellow' }}
                                    ></i>
                                  )}{' '}
                                  {his.total_marks ? (
                                    <>
                                      Marks: {his.obtained_marks} /
                                      {his.total_marks}
                                    </>
                                  ) : null}
                                </div>
                              ) : (
                                'Completed'
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
      </Card>
    </div>
  );
};

export default ChapterCard;
