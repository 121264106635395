import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getStudentsPlatform } from "Utils/reports.Utils";
import { Bar, Line } from "react-chartjs-2";
import DateFilters from "components/Common/DateFilters";
import { format } from "date-fns";
import DownloadPDF from "components/Common/DownloadPDF";
import DownloadCSV from "components/Common/DownloadCSV";

const barGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "left",
      width: "20px",
      display: true,
    },
    title: {
      display: true,
      text: "Corssy",
    },
  },

  scales: {
    barPercentage: 0.6,
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Number of students",
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 0.6,
        scaleLabel: {
          display: true,
          labelString: "Platforms",
        },
      },
    ],
  },
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "Corrsy",
    },
  },
  scales: {
    // yAxes: [
    //   {
    //     scaleLabel: {
    //       display: true,
    //       labelString: "Number of students",
    //     },
    //   },
    // ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const data = {};
const barGraphData = {
  labels: "",
  datasets: [{}],
};

const UserPlatformReport = () => {
  const [studentDetails, setStudentDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateObj, setDateObj] = useState(() => {
    var d = new Date();
    return {
      startDate: format(
        new Date(new Date(d.setDate(d.getDate() - 6)).toISOString()),
        "yyyy-MM-dd"
      ),
      endDate: format(new Date(), "yyyy-MM-dd"),
    };
  });
  // const [loading, setLoading] = useState(false);

  const randomColorGenerated = () => {
    const a = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const c = Math.floor(Math.random() * 256);

    return `rgba(${a}, ${b}, ${c}, 0.5)`;
  };

  const createDataForGraph = (students) => {
    const [...rest] = students;
    const arrangeDates = rest.map((e) => {
      e.date_created = e?.date_created.split("T")[0];
      return e;
    });

    let facebookStd = [];
    let gmailStd = [];
    let phoneStd = [];
    let emailStd = [];
    let appleStd = [];

    arrangeDates.forEach((x) => {
      if ((x?.platform).toLowerCase().includes("facebook")) facebookStd.push(x);
      else if ((x?.platform).toLowerCase().includes("gmail")) gmailStd.push(x);
      else if ((x?.platform).toLowerCase().includes("phone")) phoneStd.push(x);
      else if ((x?.platform).toLowerCase().includes("email")) emailStd.push(x);
      else if ((x?.platform).toLowerCase().includes("apple")) appleStd.push(x);
    });

    const datesList = [
      ...new Set(arrangeDates.map((x) => x?.date_created)),
    ].sort();

    data.labels = datesList;
    let dateForGraph = [];
    [
      {
        platform: "Total",
        list: [
          ...facebookStd,
          ...gmailStd,
          ...emailStd,
          ...phoneStd,
          ...appleStd,
        ],
        color: "rgba(87, 56, 3)",
      },
      {
        platform: "Facebook",
        list: facebookStd,
        color: "rgba(66, 103, 178)",
      },
      { platform: "Gmail", list: gmailStd, color: "rgba(242, 166, 12)" },
      { platform: "Phone", list: phoneStd, color: "rgba(61, 227, 72)" },
      {
        platform: "Email / Password",
        list: emailStd,
        color: "rgba(255, 26, 26)",
      },
      {
        platform: "Apple",
        list: appleStd,
        color: "rgba(0, 0, 0, 0.8)",
      },
    ].forEach((x) => {
      const list = x?.list?.reduce(function (acc, curr) {
        return (
          acc[curr?.date_created]
            ? ++acc[curr?.date_created]
            : (acc[curr?.date_created] = 1),
          acc
        );
      }, {});

      delete list["undefined"];

      const entries = Object.entries(list);

      dateForGraph.push({
        label: x?.platform,
        // data: Object.values(list),
        data: datesList.map((x) => {
          const fn = entries.find((a) => a[0] === x);
          if (fn) return fn[1];
          return 0;
        }),
        backgroundColor: x?.color,
        borderColor: x?.color,
        lineTension: 0,
        fill: false,
      });
    });

    data.datasets = dateForGraph;
    const studentPerPlatform = students.reduce(function (acc, curr) {
      return (
        acc[curr?.platform] ? ++acc[curr?.platform] : (acc[curr?.platform] = 1),
        acc
      );
    }, {});

    const sortable = Object.entries(studentPerPlatform)
      .sort(([, a], [, b]) => b - a)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    barGraphData.labels = Object.keys(sortable);
    barGraphData.datasets[0].data = Object.values(sortable);
    barGraphData.datasets[0].label = "Students";
    barGraphData.datasets[0].backgroundColor = "rgb(127, 37, 184)";
  };
  const fetchInitialData = async (dateObj) => {
    setLoading(true);
    try {
      const resp = await getStudentsPlatform(dateObj);
      createDataForGraph(resp.data.data);
      setStudentDetails(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const differenceBtw2Dates = () => {
    const { startDate, endDate } = dateObj;

    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    const Difference_In_Time = date2.getTime() - date1.getTime();

    return Difference_In_Time / (1000 * 3600 * 24);
  };

  const submitHandler = (dateObj) => {
    if (!dateObj?.startDate || !dateObj?.endDate) {
      toast.info("Dates Missing");
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    setDateObj(dateObj);
    fetchInitialData(dateObj);
  };

  const refreshHandler = () => {
    const d = new Date();
    const startDate = new Date(new Date(d.setDate(d.getDate() - 6)))
      .toISOString()
      .split("T")[0];
    const endDate = format(new Date(), "yyyy-MM-dd");

    setDateObj({ startDate, endDate });

    fetchInitialData({ startDate, endDate });
  };

  const createDatForExcel = (dataType) => {
    if (dataType === "headerData") {
      return [
        { label: "Total Students", key: "totalStudents" },
        { label: "Total Days", key: "totalDays" },
        { label: "Total Facebook Students", key: "facebookStudents" },
        { label: "Total Gmail Students", key: "gmailStudents" },
        { label: "Total Phone Students", key: "phoneStudents" },
        { label: "Total Email/Password Students", key: "emailPhoneStudents" },
        { label: "Total Apple Students", key: "appleStudents" },
      ];
    } else if (dataType === "rowData") {
      const { labels, datasets } = barGraphData;

      const dataValues = datasets?.length && datasets[0].data;

      return [
        {
          totalStudents:
            Object.keys(barGraphData)?.length &&
            barGraphData?.datasets[0]?.data?.reduce((a, b) => (a += b), 0),
          totalDays: differenceBtw2Dates() + 1,
          facebookStudents:
            dataValues?.length &&
            dataValues[
              labels?.length &&
                labels.findIndex((x) => x.toLowerCase() === "facebook")
            ],
          gmailStudents:
            dataValues?.length &&
            dataValues[
              labels?.length &&
                labels.findIndex((x) => x.toLowerCase() === "gmail")
            ],
          phoneStudents:
            dataValues?.length &&
            dataValues[
              labels?.length &&
                labels.findIndex((x) => x.toLowerCase() === "phone")
            ],
          emailPhoneStudents:
            dataValues?.length &&
            dataValues[
              labels?.length &&
                labels.findIndex((x) => x.toLowerCase() === "email/password")
            ],
          appleStudents:
            dataValues?.length &&
            dataValues[
              labels?.length &&
                labels.findIndex((x) => x.toLowerCase() === "apple")
            ],
        },
      ];
    }
  };

  useEffect(() => {
    const d = new Date();
    const startDate = new Date(new Date(d.setDate(d.getDate() - 7)))
      .toISOString()
      .split("T")[0];
    const endDate = format(new Date(), "yyyy-MM-dd");

    fetchInitialData({ startDate, endDate });
  }, []);

  return (
    <>
      <div className="user_platforms">
        <DateFilters
          submitHandler={submitHandler}
          refreshHandler={refreshHandler}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="downloadsBtn">
              <DownloadPDF downloadFileName="install/unInstall-Report" />
              {Object.keys(barGraphData)?.length &&
                Object.keys(data)?.length && (
                  <DownloadCSV
                    excelHeader={createDatForExcel("headerData")}
                    excelData={createDatForExcel("rowData")}
                    excelFilename="Platform Summary"
                  />
                )}
            </div>
            <div className="graph">
              <div className="downloadPDF">
                <div className="box">
                  <div className="box_card">
                    <p>Total Students:</p>
                    <p>
                      {Object.keys(barGraphData)?.length &&
                        barGraphData?.datasets[0]?.data?.reduce(
                          (a, b) => (a += b),
                          0
                        )}
                    </p>
                  </div>
                  <div className="box_card">
                    <p>Total Days:</p>
                    <p>{differenceBtw2Dates() + 1}</p>
                  </div>
                </div>
                <h3 className="heading">Student Joining Platform</h3>
                <Line options={options} data={data} />
              </div>
              <div className="gap"></div>
              <hr />
              <div className="downloadPDF">
                <h3 className="heading">Student Joining Platform</h3>
                <Bar options={options} data={data} />
              </div>
              <div className="gap"></div>
              <hr />
              <div className="downloadPDF">
                <h3 className="heading">
                  Total Accumulated Students Per Platform
                </h3>
                <h3 className="heading" style={{ margin: "0" }}>
                  Least or Most Favourite Platform
                </h3>
                <p className="text-center" style={{ fontSize: "1.2rem" }}>
                  From {dateObj?.startDate} to {dateObj?.endDate}
                </p>
                <Bar options={barGraphOptions} data={barGraphData} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserPlatformReport;
