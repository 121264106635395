import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import { getGradeForDropdown } from "Utils/grades_Utils";
import { getSubjectForDropdown } from "Utils/subjects_Utils";
import { getLiveSessionsDetails } from "Utils/live_session_Utils";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { sendGroupNotification } from "Utils/student_notification_Utils";
import { sendSessionNotificationLogs } from "Utils/notification_logs_Utils";
import { sendGradeNotificationLogs } from "Utils/notification_logs_Utils";

export const NotificationPanelController = () => {
  const userData = useSelector(selectUserData);

  const [sendTo, setSendTo] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [filter, setFilter] = useState("");
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [sessionsList, setSessionsList] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState({});
  const [selectedSubject, setSelectedSubject] = useState({});
  const [selectedSession, setSelectedSession] = useState({});
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState(null);
  const [isSending, setIsSending] = useState(false);

  const onChangeHandler = (setState) => (e) => setState(e.target.value);

  const onSelectChangeHandler = (setState) => (e) => setState(e);

  const onSelectSendToHandler = (e) => {
    setSelectedGrade(null);
    setSelectedSubject(null);
    setSendTo(e.target.value);
  };

  const resetFields = () => {
    setTitle("");
    setBody("");
    setFilter("");
    setSelectedGrade({});
    setSelectedSubject({});
    setSelectedSession({});
    setSendTo("");
  };

  const sendNotificationsToStudent = async () => {
    setIsSending(true);
    try {
      const notifyObj = {
        sendNotificationID: "",
        title,
        body,
        notificationBy: sendTo,
        filterType: filter,
      };

      if (!userData?.role) notifyObj.superAdmin = userData?._id;
      else notifyObj.senderID = userData?._id;

      if (filter) {
        const days = Number(filter.split("days")[0]);

        const dt = new Date();

        notifyObj.filterDate = format(
          new Date(new Date(dt.setDate(dt.getDate() - days))),
          "yyyy-MM-dd"
        );
      }

      await sendGroupNotification(notifyObj);

      toast.success("Notification Send Successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      resetFields();
      setIsSending(false);
    }
  };

  const sendNotificationForLiveSession = async () => {
    setIsSending(true);
    try {
      const session = sessionsList.find(
        (x) => String(x?._id) === String(selectedSession?.value)
      );

      const notifyObj = {
        sessionID: selectedSession?.value,
        title,
        message: body,
        date: new Date(),
        subjectID: session?.subject?._id || "",
        gradeID: session?.grade || "",
        topic: topics.find((e) => String(e?._id) === String(session?.grade))
          ?.topic,
      };

      if (!userData?.role) notifyObj.superAdmin = userData?._id;
      else notifyObj.senderID = userData?._id;

      await sendSessionNotificationLogs([notifyObj]);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      resetFields();
      setIsSending(false);
    }
  };

  const sendNotificationForGrades = async () => {
    setIsSending(true);
    try {
      const notifyObj = {
        gradeID: selectedGrade?.value,
        title,
        message: body,
        date: new Date(),
      };

      if (!userData?.role) notifyObj.superAdmin = userData?._id;
      else notifyObj.senderID = userData?._id;

      notifyObj.topic =
        (topics?.length &&
          topics.find((a) => String(a?._id) === String(notifyObj?.gradeID))
            ?.topic) ||
        "";

      const resp = await sendGradeNotificationLogs([notifyObj]);
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      resetFields();
      setIsSending(false);
    }
  };

  const sendNotifications = async () => {
    setIsSending(true);
    try {
      const notifyObj = {
        sendNotificationID:
          sendTo === "grades" ? selectedGrade?.value : selectedSubject?.value,
        title,
        body,
        notificationBy: sendTo,
        filterType: filter,
      };

      if (!userData?.role) notifyObj.superAdmin = userData?._id;
      else notifyObj.senderID = userData?._id;

      if (filter) {
        const days = Number(filter.split("days")[0]);

        const dt = new Date();

        notifyObj.filterDate = format(
          new Date(new Date(dt.setDate(dt.getDate() - days))),
          "yyyy-MM-dd"
        );
      }

      notifyObj.topic =
        (topics?.length &&
          topics.find(
            (a) => String(a?._id) === String(notifyObj?.sendNotificationID)
          )?.topic) ||
        "";

      const resp = await sendGroupNotification(notifyObj);
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      resetFields();
      setIsSending(false);
    }
  };

  const _submit = async (e) => {
    e.preventDefault();

    if (sendTo === "students") {
      sendNotificationsToStudent();
    } else if (sendTo === "sessions") {
      sendNotificationForLiveSession();
    } else if (sendTo === "grades") {
      sendNotificationForGrades();
    } else {
      sendNotifications();
    }
  };

  const arrangeDropdown = (list) =>
    list?.length ? list.map((e) => ({ value: e?._id, label: e?.name })) : null;

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const [allGrades, allSubjects, allSessions] = await Promise.all([
        getGradeForDropdown(),
        getSubjectForDropdown(),
        getLiveSessionsDetails(),
      ]);

      setGrades(
        await arrangeDropdown(
          allGrades.data.data.map((x) => ({ name: x?.grade, _id: x?._id }))
        )
      );

      setSubjects(
        await arrangeDropdown(
          allSubjects.data.data.map((x) => ({
            name: `${x?.Subject_name} - ${x?.grade?.grade}`,
            _id: x?._id,
          }))
        )
      );

      setTopics(allGrades.data.topics);

      const list = allSessions.data.data.filter(
        (x) => x?.sessionStatus !== "end"
      );

      if (!list?.length) return;

      const sessions = list.map((e) => ({
        value: e?._id,
        label: `${e?.sessionTitle} - ${e?.subject?.Subject_name}`,
      }));

      setSessions(sessions);
      setSessionsList(list);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    sendTo,
    title,
    body,
    filter,
    grades,
    subjects,
    sessionsList,
    sessions,
    selectedGrade,
    selectedSubject,
    selectedSession,
    loading,
    topics,
    isSending,
    setSelectedGrade,
    setSelectedSubject,
    setSelectedSession,
    setTitle,
    setBody,
    onChangeHandler,
    onSelectChangeHandler,
    onSelectSendToHandler,
    resetFields,
    _submit,
    fetchInitialData,
  };
};
