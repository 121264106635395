import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // isAuth: false,
  user: [],
  admin: [],
  login: false,
  loading: false,
  access_token: null,
  userData: null,
  isNotificationModalShow: false,
  sendNotificationID: null,
  sendNotificationType: null,
};

const AuthSlice = createSlice({
  name: `auth`,
  initialState,
  reducers: {
    setauth: (state, action) => {
      // state.user = action.payload;
      state.login = true;
    },
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
    setloading: (state, action) => {
      state.loading = action.payload;
    },
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsNotificationModalShow: (state, action) => {
      state.isNotificationModalShow = action.payload;
    },
    setSendNotificationID: (state, action) => {
      state.sendNotificationID = action.payload;
    },
    setSendNotificationType: (state, action) => {
      state.sendNotificationType = action.payload;
    },
  },
});
export const {
  setauth,
  setAdmin,
  setloading,
  setAccessToken,
  setUserData,
  setIsNotificationModalShow,
  setSendNotificationID,
  setSendNotificationType,
} = AuthSlice.actions;

export const selectAuth = (state) => state.auth.login;
export const selectUser = (state) => state.auth.user;
export const selectAdmin = (state) => state.auth.admin;
export const selectLoading = (state) => state.auth.loading;
export const selectAccessToken = (state) => state.auth.access_token;
export const selectUserData = (state) => state.auth.userData;
export const selectIsNotificationModalShow = (state) =>
  state.auth.isNotificationModalShow;
export const selectSendNotificationID = (state) =>
  state.auth.sendNotificationID;
export const selectSendNotificationType = (state) =>
  state.auth.sendNotificationType;

export default AuthSlice.reducer;
