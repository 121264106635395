import Loader from 'components/Common/Loader'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getChapterSequence } from 'Utils/chapterPreview_Utils'
import { updateChapterSequence } from 'Utils/topic_quiz_Utils'
import Sequence from './Sequence'

const ChapterSequenceWrapper = () => {
  const [chapterDetails, setChapterDetails] = useState(null);
  const [topicsAndQuizzes, setTopicsAndQuizzes] = useState(null);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const updateChapterHandler = async (updated_sequence) => {

    const { ...updateChapterDetails } = chapterDetails[0];
    updateChapterDetails.chapter_sequence = updated_sequence;

    setLoading(true);
    try {
      const resp = await updateChapterSequence(params.lessonID, updateChapterDetails);
      toast.success(resp.data.message);

      setTopicsAndQuizzes(updated_sequence);
    } catch (error) {
      toast.success(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const fetchTopicsAndQuizzes = async () => {
    setLoading(true);
    try {
      const resp = await getChapterSequence(params.lessonID);

      setChapterDetails(resp.data.data)

      const { chapter_sequence } = resp.data.data[0];
      setTopicsAndQuizzes(chapter_sequence);
    } catch (err) {
      toast.error(err?.response?.data?.message || err);
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTopicsAndQuizzes();
  }, [])


  const renderChapterSequenceNote = () => (
    <div className="chapter_sequence-note">
      <div className="important_note">
        <h1>Note</h1>
        <p>Sequence will be same in application as it appears in the list below.</p>
      </div>
      <div className="user_guide">
        <h1>User Guide</h1>
        <p>Press and Hold
          <i
            className="fa fa-bars"
            aria-hidden="true"
          />
          to drag topic/quiz.
        </p>
      </div>
    </div>
  )

  return (
    <div className='chapter_sequence'>
      {loading ? <Loader /> : <>
        {renderChapterSequenceNote()}
        <Sequence chapterTopicsAndQuizzesSequence={topicsAndQuizzes}
          updateChapterSequenceHandler={updateChapterHandler} />

      </>
      }
    </div>
  )
}

export default ChapterSequenceWrapper
