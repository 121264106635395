import { useEffect, useState, useContext } from "react";
import DateFilters from "components/Common/DateFilters";
import Loader from "components/Common/Loader";
import { toast } from "react-toastify";
import { getReportsByScreenNames } from "Utils/reports.Utils";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import MaterialTable from "material-table";
import { format } from "date-fns";
import { getUniCodeStats } from "Utils/uniCode_Utils";
import { useSelector } from "react-redux";
import {
  selectTopicUniCodes,
  selectQuizUniCodes,
} from "Redux/features/Subject/SubjectSlice";
import { getStudentsJourney } from "Utils/reports.Utils";
import { Bar, Line, Chart } from "react-chartjs-2";
import { secondsToHms } from "Utils/helper_Utils";
import ModalContainer from "components/Common/ModalContainer";
import DownloadPDF from "components/Common/DownloadPDF";
import { object } from "yup";

//////////////////////////////////// Graphs Options ////////////////////////////////////////

const graphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      width: "20px",
    },
    title: {
      display: true,
      text: "Grades Data",
    },
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const barGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      width: "20px",
    },
    title: {
      display: true,
      text: "Grades Data",
    },
  },
  scales: {
    // xAxes: [
    //   {
    //     scaleLabel: {
    //       display: true,
    //       labelString: "Topic And Quiz",
    //     },
    //   },
    // ],
  },
};

const multiGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      width: "20px",
    },
    title: {
      display: true,
      text: "Grades Data",
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        if (tooltipItem?.datasetIndex === 0) {
          return "Average time : " + secondsToHms(tooltipItem?.yLabel * 60);
        } else if (tooltipItem?.datasetIndex === 1) {
          return "Average time : " + secondsToHms(tooltipItem?.yLabel * 60);
        } else if (tooltipItem?.datasetIndex === 2) {
          return "Visits : " + tooltipItem?.yLabel;
        }
      },
    },
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Topic UniCodes",
        },
      },
    ],
  },
};

const specificStudentGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      width: "20px",
    },
    title: {
      display: true,
      text: "Grades Data",
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        if (tooltipItem?.datasetIndex === 0) {
          return "Time Spend: " + secondsToHms(tooltipItem?.yLabel * 60);
        } else if (tooltipItem?.datasetIndex === 1) {
          return "Topics Visit : " + tooltipItem?.yLabel;
        } else if (tooltipItem?.datasetIndex === 2) {
          return "Topics Completed : " + tooltipItem?.yLabel;
        } else if (tooltipItem?.datasetIndex === 3) {
          return (
            "Average time on topic screen: " +
            secondsToHms(tooltipItem?.yLabel * 60)
          );
        } else if (tooltipItem?.datasetIndex === 4) {
          return "Quiz Visit : " + tooltipItem?.yLabel;
        } else if (tooltipItem?.datasetIndex === 5) {
          return "Quiz Completed : " + tooltipItem?.yLabel;
        } else if (tooltipItem?.datasetIndex === 6) {
          return (
            "Average time on Quiz screen: " +
            secondsToHms(tooltipItem?.yLabel * 60)
          );
        } else if (tooltipItem?.datasetIndex === 7) {
          return "Visit " + tooltipItem?.yLabel + " times";
        }
      },
    },
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

let topicGraphData = {};
let quizGraphData = {};
let leastAndMostVisitTopicGraphData = {};
let leastAndMostTimeSpendOnTopicGraphData = {};
let leastAndMostCompleteQuizSpecifiedDateGraphData = {};
let leastAndMostCompleteQuizGraphData = {};
let leastAndMostCompleteTopicGraphData = {};
let leastAndMostCompleteTopicDateSpecifiedGraphData = {};
let specificStudentGraph = {};
let searchTopicLeastAndMostGraph = {};
let searchCompletedTopicLeastAndMostGraph = {};
let searchTopicLeastAndMostGraphOfAllTime = {};
let searchCompletedTopicLeastAndMostGraphOfAllTime = {};
let searchQuizLeastAndMostGraph = {};
let searchCompletedQuizLeastAndMostGraph = {};
let searchQuizLeastAndMostGraphOfAllTime = {};
let searchCompletedQuizLeastAndMostGraphOfAllTime = {};
let searchTopicLeastAndMostByTimeSpentSpecifiedTimeGraph = {};
let searchTopicLeastAndMostByTimeSpentAllTimeGraph = {};

const TopicsReport = () => {
  const topicUniCodes = useSelector(selectTopicUniCodes);
  const quizUniCodes = useSelector(selectQuizUniCodes);

  //////////////////////////////////////////// all States ////////////////////////////////////////////////

  const [actualJourneyList, setActualJourneyList] = useState([]);
  const [topicJourneyList, setTopicJourneyList] = useState([]);
  const [quizJourneyList, setQuizJourneyList] = useState([]);
  const [studentJourneyList, setStudentJourneyList] = useState(null);
  const [isSpecificStudentData, setIsSpecificStudentData] = useState(false);
  const [studentID, setStudentID] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [uniCode, setUniCode] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTopicsList, setSelectedTopicsList] = useState([]);
  const [isPointSelected, setIsPointSelected] = useState(false);
  const [dateObj, setDateObj] = useState(() => {
    var d = new Date();
    return {
      startDate: format(
        new Date(new Date(d.setDate(d.getDate() - 6)).toISOString()),
        "yyyy-MM-dd"
      ),
      endDate: format(new Date(), "yyyy-MM-dd"),
    };
  });
  const [searchName, setSearchName] = useState("");
  const { tableIcons } = MaterialTableIcons();

  ////////////////////////////////////////////////////// Handlers ////////////////////////////////////

  const resetGraphsForSearchUniCodeHandler = () => {
    searchTopicLeastAndMostGraph = {};
    searchCompletedTopicLeastAndMostGraph = {};
    searchTopicLeastAndMostGraphOfAllTime = {};
    searchCompletedTopicLeastAndMostGraphOfAllTime = {};
    searchQuizLeastAndMostGraph = {};
    searchCompletedQuizLeastAndMostGraph = {};
    searchQuizLeastAndMostGraphOfAllTime = {};
    searchCompletedQuizLeastAndMostGraphOfAllTime = {};
    searchTopicLeastAndMostByTimeSpentSpecifiedTimeGraph = {};
    searchTopicLeastAndMostByTimeSpentAllTimeGraph = {};
  };

  const makeArrayOfJourney = (list) =>
    list?.length ? list.reduce((a, b) => [...a, ...b?.journeys], []) : [];

  const applyDateFilterOnJourney = (dateFilter, list) => {
    const { startDate, endDate } = dateFilter;

    const a = new Date(startDate).getTime();
    const b = new Date(endDate).getTime();

    return Object.keys(dateFilter)?.length
      ? list?.length &&
          list.filter(
            (e) =>
              new Date(e?.date).getTime() >= a &&
              new Date(e?.date).getTime() <= b
          )
      : [];
  };

  const createGraphData = (journeyData, arrangeGraph, label) => {
    setLoading(true);
    const datesList = [...new Set(journeyData.map((x) => x?.date))].sort();

    arrangeGraph.labels = datesList;

    const dataList = datesList.map((x) => {
      const datePerData = journeyData.filter((a) => a?.date === x);

      const datePerDataCompleted = journeyData.filter(
        (a) => a?.date === x && a?.status === "completed"
      );

      const uniqueTopics = [...new Set(datePerData.map((x) => x?.uniCode))];

      const uniqueCompleted = [
        ...new Set(datePerDataCompleted.map((x) => x?.uniCode)),
      ];

      const averagePerDate =
        datePerData.reduce((a, b) => (a += b?.durationInMin), 0) /
        datePerData?.length;

      return {
        students: datePerData?.length || 0,
        time: averagePerDate,
        topicVisited: uniqueTopics?.length || 0,
        completed: uniqueCompleted?.length || 0,
      };
    });

    // const groupByUniCode = journeyData.reduce(function (acc, curr) {
    //   return (
    //     acc[curr?.uniCode] ? ++acc[curr?.uniCode] : (acc[curr?.uniCode] = 1),
    //     acc
    //   );
    // }, {});

    // let most5Topics = [];
    // let least5Topics = [];

    // if (groupByUniCode) {
    //   const sortable = Object.entries(groupByUniCode).sort((a, b) => b[1] - a[1]);
    //   most5Topics = sortable.slice(0, 5);
    //   least5Topics = sortable.slice(sortable?.length - 5, sortable?.length);
    // }

    const graphList = [];
    graphList.push({
      label: "Daily active users",
      data: dataList.map((a) => a?.students),
      backgroundColor: "rgb(235, 52, 52)",
      borderColor: "rgb(235, 52, 52)",
      lineTension: 0,
      fill: false,
    });

    graphList.push({
      label: "Average time spent on topic screen",
      data: dataList.map((a) => a?.time),
      backgroundColor: "rgb(5, 130, 5)",
      borderColor: "rgb(5, 130, 5)",
      lineTension: 0,
      fill: false,
    });

    graphList.push({
      label: `${label} visit by students`,
      data: dataList.map((a) => a?.topicVisited),
      backgroundColor: "rgb(0, 0, 0)",
      borderColor: "rgb(0, 0, 0)",
      lineTension: 0,
      fill: false,
    });

    graphList.push({
      label: `${label} completed by students`,
      data: dataList.map((a) => a?.completed),
      backgroundColor: "rgb(235, 52, 216)",
      borderColor: "rgb(235, 52, 216)",
      lineTension: 0,
      fill: false,
    });

    arrangeGraph.datasets = [];
    arrangeGraph.datasets = graphList;

    setLoading(false);
  };

  const generateTopicAndQuizGraphData = (list, uniCodeList) =>
    list?.length
      ? list.map((e) => {
          const findItem = uniCodeList.find((a) => String(e[0]) === a?.uniCode);

          return {
            uniCode: findItem?.uniCode,
            count: e[1],
          };
        })
      : [];

  const createLeastAndMostCompletedTopicQuizVisitsGraph = (
    journeyData,
    screenName,
    graphArrayData,
    uniCodeData
  ) => {
    setLoading(true);

    const groupByData = journeyData
      .filter((x) => x?.status === "completed")
      .reduce(function (acc, curr) {
        return (
          acc[curr?.uniCode] ? ++acc[curr?.uniCode] : (acc[curr?.uniCode] = 1),
          acc
        );
      }, {});

    let most5Complete = [];
    let least5Complete = [];

    if (Object.keys(groupByData)?.length > 10) {
      const sortable = Object.entries(groupByData)
        .sort((a, b) => b[1] - a[1])
        .filter((a) => !!Object.keys(a[0]).length);

      most5Complete = sortable.slice(0, 5);
      least5Complete = sortable.slice(sortable?.length - 5, sortable?.length);
    }

    let mostComplete = [];
    let leastComplete = [];

    mostComplete = generateTopicAndQuizGraphData(most5Complete, uniCodeData);
    leastComplete = generateTopicAndQuizGraphData(least5Complete, uniCodeData);

    graphArrayData.labels = [
      ...mostComplete.map((e) => e?.uniCode),
      ...leastComplete.map((e) => e?.uniCode),
    ];

    graphArrayData.datasets = [
      {
        label: `Most visits ${screenName}`,
        data: [
          ...mostComplete.map((a) => a?.count),
          ...[...Array(leastComplete?.length)].map((e) => 0),
        ],
        backgroundColor: "rgb(211, 119, 237)",
        borderColor: "rgb(211, 119, 237)",
      },
      {
        label: `Least visits ${screenName}`,
        data: [
          ...[...Array(mostComplete?.length)].map((e) => 0),
          ...leastComplete.map((a) => a?.count),
        ],
        backgroundColor: "rgb(245, 103, 171)",
        borderColor: "rgb(245, 103, 171)",
      },
    ];

    setLoading(false);
  };

  const createLeastAndMostTopicAndQuizVisitsGraph = (
    journeyData,
    screenName
  ) => {
    setLoading(true);
    const groupByData =
      screenName === "topic"
        ? journeyData.reduce(function (acc, curr) {
            return (
              acc[curr?.uniCode]
                ? ++acc[curr?.uniCode]
                : (acc[curr?.uniCode] = 1),
              acc
            );
          }, {})
        : journeyData
            .filter((x) => x?.status === "completed")
            .reduce(function (acc, curr) {
              return (
                acc[curr?.uniCode]
                  ? ++acc[curr?.uniCode]
                  : (acc[curr?.uniCode] = 1),
                acc
              );
            }, {});

    let most5Topics = [];
    let least5Topics = [];
    let most5Quizzes = [];
    let least5Quizzes = [];

    if (Object.keys(groupByData)?.length > 10) {
      const sortable = Object.entries(groupByData).sort((a, b) => b[1] - a[1]);
      // .filter((a) => !!Object.keys(a[0]).length);

      if (screenName === "topic") {
        most5Topics = sortable.slice(0, 5);
        least5Topics = sortable.slice(sortable?.length - 5, sortable?.length);
      } else if (screenName === "quiz") {
        most5Quizzes = sortable.slice(0, 5);
        least5Quizzes = sortable.slice(sortable?.length - 5, sortable?.length);
      }
    }

    const mostVisitTopics = generateTopicAndQuizGraphData(
      most5Topics,
      topicUniCodes
    );
    const leastVisitTopics = generateTopicAndQuizGraphData(
      least5Topics,
      topicUniCodes
    );
    const mostVisitQuizzes = generateTopicAndQuizGraphData(
      most5Quizzes,
      quizUniCodes
    );
    const leastVisitQuizzes = generateTopicAndQuizGraphData(
      least5Quizzes,
      quizUniCodes
    );

    if (screenName === "topic") {
      leastAndMostVisitTopicGraphData.labels = [
        ...mostVisitTopics.map((e) => e?.uniCode),
        ...leastVisitTopics.map((e) => e?.uniCode),
      ];

      leastAndMostVisitTopicGraphData.datasets = [
        {
          label: "Most visits topics",
          data: [
            ...mostVisitTopics.map((a) => a?.count),
            ...[...Array(leastVisitTopics?.length)].map((e) => 0),
          ],
          backgroundColor: "rgb(211, 119, 237)",
          borderColor: "rgb(211, 119, 237)",
        },
        {
          label: "Least visits topics",
          data: [
            ...[...Array(mostVisitTopics?.length)].map((e) => 0),
            ...leastVisitTopics.map((a) => a?.count),
          ],
          backgroundColor: "rgb(245, 103, 171)",
          borderColor: "rgb(245, 103, 171)",
        },
      ];
    } else if (screenName === "quiz") {
      leastAndMostCompleteQuizSpecifiedDateGraphData.labels = [
        ...mostVisitQuizzes.map((e) => e?.uniCode),
        ...leastVisitQuizzes.map((e) => e?.uniCode),
      ];

      leastAndMostCompleteQuizSpecifiedDateGraphData.datasets = [
        {
          label: "Most completed quizzes",
          data: [
            ...mostVisitQuizzes.map((a) => a?.count),
            ...[...Array(leastVisitQuizzes?.length)].map((e) => 0),
          ],
          backgroundColor: "rgb(222, 135, 13)",
          borderColor: "rgb(222, 135, 13)",
        },
        {
          label: "Least completed quizzes",
          data: [
            ...[...Array(mostVisitQuizzes?.length)].map((e) => 0),
            ...leastVisitQuizzes.map((a) => a?.count),
          ],
          backgroundColor: "rgb(6, 138, 25)",
          borderColor: "rgb(6, 138, 25)",
        },
      ];
    }
    setLoading(false);
  };

  const createGraphForLeastAndMostTimeSpendOnTopicAndQuiz = (journeyData) => {
    const uniqueTopicsByUniCode = [
      ...new Set(journeyData.map((e) => e?.uniCode)),
    ];

    // const topicsIDS = uniqueTopicsByUniCode
    //   .map((e) => {
    //     const topic = topicUniCodes.find(
    //       (a) => String(a?.id) === String(e) || String(a?.uniCode) === String(e)
    //     );

    //     return topic ? { uniCode: topic?.uniCode, id: topic?.id } : null;
    //   })
    //   .filter((x) => x);

    // let topicsIDSList = [];
    // topicsIDS.forEach((e) => {
    //   if (topicsIDSList.find((x) => x.uniCode === e.uniCode)) return;

    //   topicsIDSList.push(e);
    // });

    // const timePerTopic = topicsIDSList
    const timePerTopic = uniqueTopicsByUniCode
      .map((a) => {
        const topics = journeyData.filter((e) => e?.uniCode === a);

        const totalTime = topics?.length
          ? topics.reduce((a, b) => (a += b?.durationInMin), 0) / topics?.length
          : 0;

        return {
          uniCode: a,
          averageTime: totalTime,
          count: topics?.length,
        };
      })
      .sort((a, b) => b?.averageTime - a?.averageTime)
      .filter((s) => s?.averageTime > 3);

    let most5Topics = [];
    let least5Topics = [];

    if (timePerTopic?.length > 10) {
      most5Topics = timePerTopic.slice(0, 5);
      least5Topics = timePerTopic.slice(
        timePerTopic?.length - 5,
        timePerTopic?.length
      );
    }

    leastAndMostTimeSpendOnTopicGraphData.labels = [
      ...most5Topics.map((e) => e.uniCode),
      ...least5Topics.map((e) => e.uniCode),
    ];

    leastAndMostTimeSpendOnTopicGraphData.datasets = [
      {
        label: "Maximum time spent on topic",
        data: [
          ...most5Topics.map((a) => a?.averageTime),
          ...[...Array(least5Topics?.length)].map((e) => 0),
        ],
        backgroundColor: "rgb(6, 138, 25)",
        borderColor: "rgb(6, 138, 25)",
      },
      {
        label: "Minimum time spent at least 3 min",
        data: [
          ...[...Array(most5Topics?.length)].map((e) => 0),
          ...least5Topics.map((a) => a?.averageTime),
        ],
        backgroundColor: "rgb(222, 135, 13)",
        borderColor: "rgb(222, 135, 13)",
      },
      // {
      //   label: "Visits",
      //   data: [
      //     ...most5Topics.map((a) => a?.count),
      //     ...least5Topics.map((a) => a?.count),
      //   ],
      //   backgroundColor: "rgb(222, 135, 13)",
      //   borderColor: "rgb(222, 135, 13)",
      // },
    ];
  };

  const replaceIdsWithUniCode = (journeyData, topicQuizUniCodes) =>
    journeyData?.length
      ? journeyData
          .filter((x) => x?.uniCode)
          .map((e) => {
            const findUniCode = topicQuizUniCodes.find(
              (x) =>
                String(x?.id) === String(e?.uniCode) ||
                String(x?.uniCode) === String(e?.uniCode)
            );

            e.uniCode = findUniCode?.uniCode;
            return e;
          })
          .filter((x) => x)
      : null;

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      // get user journey data from database
      const [topicJourney = [], quizJourney = []] = await Promise.all([
        getReportsByScreenNames("topic_view"),
        getReportsByScreenNames("quiz_view"),
      ]);

      // combine user journeys all together
      const [onlyTopicJourney, onlyQuizJourney] = await Promise.all([
        makeArrayOfJourney(topicJourney?.data?.data),
        makeArrayOfJourney(quizJourney?.data?.data),
      ]);

      // replace ids in journey with proper uniCodes
      const [updateTopicJourney, updateQuizJourney] = await Promise.all([
        replaceIdsWithUniCode(onlyTopicJourney, topicUniCodes),
        replaceIdsWithUniCode(onlyQuizJourney, quizUniCodes),
      ]);

      // filter data according to dates applied
      const [topicData, quizData] = await Promise.all([
        applyDateFilterOnJourney(dateObj, updateTopicJourney),
        applyDateFilterOnJourney(dateObj, updateQuizJourney),
      ]);

      // create Students Topic Journey Graph
      // create Students Quiz Journey Graph
      createGraphData(topicData, topicGraphData, "Topic");
      createGraphData(quizData, quizGraphData, "Quiz");

      // create 5 Least And Most Visits Topics specified time
      createLeastAndMostTopicAndQuizVisitsGraph(topicData, "topic");

      // create 5 Least And Most Completed Quiz Specified Date
      createLeastAndMostTopicAndQuizVisitsGraph(quizData, "quiz");

      createLeastAndMostCompletedTopicQuizVisitsGraph(
        updateTopicJourney,
        "topics",
        leastAndMostCompleteTopicGraphData,
        topicUniCodes
      );
      createLeastAndMostCompletedTopicQuizVisitsGraph(
        topicData,
        "topics",
        leastAndMostCompleteTopicDateSpecifiedGraphData,
        topicUniCodes
      );
      createLeastAndMostCompletedTopicQuizVisitsGraph(
        updateQuizJourney,
        "quizzes",
        leastAndMostCompleteQuizGraphData,
        quizUniCodes
      );

      createGraphForLeastAndMostTimeSpendOnTopicAndQuiz(topicData);

      setTopicJourneyList(updateTopicJourney);
      setQuizJourneyList(updateQuizJourney);
      setActualJourneyList(topicJourney.data.data);
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
  };

  const submitHandler = (dateObj) => {
    if (!dateObj?.startDate || !dateObj?.endDate) {
      toast.info("Dates Missing");
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    setIsSpecificStudentData(false);
    setDateObj(dateObj);
    const topicData = applyDateFilterOnJourney(dateObj, topicJourneyList);
    const quizData = applyDateFilterOnJourney(dateObj, quizJourneyList);

    createGraphData(topicData, topicGraphData, "Topic");
    createGraphData(quizData, quizGraphData, "Quiz");

    createLeastAndMostTopicAndQuizVisitsGraph(topicData, "topic");
    createLeastAndMostTopicAndQuizVisitsGraph(quizData, "quiz");
    createLeastAndMostCompletedTopicQuizVisitsGraph(
      topicData,
      "topics",
      leastAndMostCompleteTopicDateSpecifiedGraphData,
      topicUniCodes
    );
    createGraphForLeastAndMostTimeSpendOnTopicAndQuiz(topicData);
    resetGraphsForSearchUniCodeHandler();

    // afterFetchData(dateObj, appSessionDetails, grades);
  };

  const refreshHandler = () => {
    setLoading(true);
    resetGraphsForSearchUniCodeHandler();
    setIsSpecificStudentData(false);
    const d = new Date();
    const startDate = new Date(new Date(d.setDate(d.getDate() - 6)))
      .toISOString()
      .split("T")[0];
    const endDate = format(new Date(), "yyyy-MM-dd");

    setDateObj({ startDate, endDate });
    setLoading(false);
    // afterFetchData({ startDate, endDate }, appSessionDetails, grades);
  };

  const createSpecificUserJourneyGraph = (userObj, userJourney) => {
    setLoading(true);
    if (!userJourney?.length) {
      toast.error("Journey not exist");
      return;
    }

    const dateList = [...new Set(userJourney.map((a) => a?.date))];

    specificStudentGraph.labels = dateList;
    specificStudentGraph.labels1 = userObj?.first_name
      ? `${userObj?.first_name} ${userObj?.last_name}`
      : userObj?.user_name;
    specificStudentGraph.userID = userObj?._id;

    const graphData = dateList.map((e) => {
      const appSession = userJourney.find(
        (a) => a?.date === e && a?.screenName === "appSession"
      );

      const topicsVisit = userJourney.filter(
        (a) => a?.date === e && a?.screenName === "topic_view"
      );

      const topicTime = topicsVisit?.length
        ? topicsVisit.reduce((a, b) => (a += b?.durationInMin), 0) /
          topicsVisit?.length
        : 0;

      const quizVisits = userJourney.filter(
        (a) => a?.date === e && a?.screenName === "quiz_view"
      );

      const quizTime = quizVisits?.length
        ? quizVisits.reduce((a, b) => (a += b?.durationInMin), 0) /
          quizVisits?.length
        : 0;

      return {
        appSessionTime: appSession?.durationInMin
          ? appSession?.durationInMin
          : 0,
        visitTopic: topicsVisit?.length || 0,
        topicCompleted: topicsVisit?.length
          ? topicsVisit.filter((x) => x.status === "completed")?.length
          : 0,
        topicTime,
        quizVisits: quizVisits?.length || 0,

        quizCompleted:
          userJourney.filter(
            (a) =>
              a?.date === e &&
              a?.screenName === "quiz_view" &&
              a?.status === "completed"
          )?.length || 0,

        quizTime,
      };
    });

    specificStudentGraph.datasets = [
      {
        label: "App Session Time",
        data: graphData.map((e) => e?.appSessionTime),
        backgroundColor: "rgb(117, 217, 98)",
        borderColor: "rgb(117, 217, 98)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "Topic Visits",
        data: graphData.map((e) => e?.visitTopic),
        backgroundColor: "rgb(222, 135, 132)",
        borderColor: "rgb(222, 135, 132)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "Topic Completed",
        data: graphData.map((e) => e?.topicCompleted),
        backgroundColor: "rgb(6, 138, 25)",
        borderColor: "rgb(6, 138, 25)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "Average Time Spent On Topics",
        data: graphData.map((e) => e?.topicTime),
        backgroundColor: "rgb(222, 135, 13)",
        borderColor: "rgb(222, 135, 13)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "Quiz Visit",
        data: graphData.map((e) => e?.quizVisits),
        backgroundColor: "rgb(245, 66, 66)",
        borderColor: "rgb(245, 66, 66)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "Quiz Completed",
        data: graphData.map((e) => e?.quizCompleted),
        backgroundColor: "rgb(162, 29, 240)",
        borderColor: "rgb(162, 29, 240)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "Average Time Spent On Quizzes",
        data: graphData.map((e) => e?.quizTime),
        backgroundColor: "rgb(8, 110, 128)",
        borderColor: "rgb(8, 110, 128)",
        lineTension: 0,
        fill: false,
      },
    ];

    setStudentID("");
    setLoading(false);
  };

  const searchHandler = async (e) => {
    e.preventDefault();

    try {
      const resp = await getStudentsJourney(studentID);
      const { userID, journeys } = resp.data.data;
      createSpecificUserJourneyGraph(userID, journeys);
      setStudentJourneyList({ userID, journeys });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error);
    } finally {
      const element = document.getElementById("scrollingContainer");
      element.scrollIntoView();
    }
  };

  const createSpecificStudentTopicQuizSearchGraph = (
    screenName,
    id,
    uniCode,
    name
  ) => {
    if (studentJourneyList) {
      const { userID, journeys } = studentJourneyList;

      const dateList = specificStudentGraph?.labels;

      const dataList = dateList.map((e) => {
        const isTopicExist = journeys.filter(
          (x) =>
            x?.date === e &&
            x?.screenName === screenName &&
            [id, String(uniCode).toLowerCase()].includes(
              String(x?.uniCode).toLowerCase()
            )
        );

        return {
          count: isTopicExist?.length || 0,
        };
      });

      if (specificStudentGraph?.datasets?.length > 7)
        specificStudentGraph?.datasets.pop();

      specificStudentGraph?.datasets.push({
        label: name,
        data: dataList?.length ? dataList.map((a) => a?.count) : 0,
        backgroundColor: "rgb(0, 0, 0)",
        borderColor: "rgb(0, 0, 0)",
        lineTension: 0,
        fill: false,
      });
    }
  };

  const createGraphForLeastAndMostSearchUniCode = (
    label,
    uniCodesList,
    journeyData,
    arrangeGraph,
    filterUniCodeBy
  ) => {
    if (
      !uniCodesList?.length &&
      !journeyData?.length &&
      !Object.keys(arrangeGraph)?.length
    )
      return;

    const groupByUniCode =
      journeyData?.length &&
      journeyData
        // .filter((a) => a?.uniCode)
        .reduce(function (acc, curr) {
          return (
            acc[curr?.uniCode]
              ? ++acc[curr?.uniCode]
              : (acc[curr?.uniCode] = 1),
            acc
          );
        }, {});

    if (!groupByUniCode) return;

    const arrangeWithUniCodes = Object.entries(groupByUniCode).map((e) => {
      const topic = uniCodesList.find((x) => e[0] === String(x?.uniCode));

      return {
        name: label === "topic" ? topic?.topicName : topic?.quizName,
        id: topic?.id,
        uniCode: topic?.uniCode,
        count: e[1],
      };
    });

    if (!arrangeWithUniCodes?.length) return;

    let size = 0;
    if (filterUniCodeBy === "chapterUnicode") size = 3;
    else if (filterUniCodeBy === "subjectUnicode") size = 2;
    else if (filterUniCodeBy === "gradeUnicode") size = 1;

    const filterDataBySearchUniCode = arrangeWithUniCodes.filter(
      (e) =>
        String(e?.uniCode).split("-").slice(0, size).join("-").toLowerCase() ===
        uniCode?.toLowerCase()
    );

    if (!filterDataBySearchUniCode?.length) return;

    const uniqueUniCode = [
      ...new Set(filterDataBySearchUniCode.map((e) => e?.uniCode)),
    ];

    if (!uniqueUniCode?.length) return;

    const uniqueTopicWithCount = uniqueUniCode.map((e) => {
      const topicExist = filterDataBySearchUniCode?.filter(
        (x) => x?.uniCode === e
      );

      const count = topicExist.reduce((a, b) => (a += b?.count), 0);

      return {
        uniCode: topicExist?.length && topicExist[0]?.uniCode,
        count,
      };
    });

    if (!uniqueTopicWithCount?.length) return;

    const sortUniqueTopics = uniqueTopicWithCount.sort(
      (a, b) => b?.count - a?.count
    );

    const mostTopic = sortUniqueTopics.slice(0, 5);
    const leastTopic = sortUniqueTopics.slice(
      sortUniqueTopics?.length - 5,
      sortUniqueTopics?.length
    );

    arrangeGraph.labels = [
      ...mostTopic.map((e) => e?.uniCode),
      ...leastTopic.map((e) => e?.uniCode),
    ];

    arrangeGraph.datasets = [
      {
        label: "5 Most Visits",
        data: [
          ...mostTopic.map((e) => e?.count),
          ...[...Array(leastTopic?.length)].map((e) => 0),
        ],
        backgroundColor: "rgb(252, 186, 3)",
        borderColor: "rgb(252, 186, 3)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "5 least Visits",
        data: [
          ...[...Array(mostTopic?.length)].map((e) => 0),
          ...leastTopic.map((e) => e?.count),
        ],
        backgroundColor: "rgb(182, 126, 222)",
        borderColor: "rgb(182, 126, 222)",
        lineTension: 0,
        fill: false,
      },
    ];
  };

  const createGraphForLeastAndMostSearchUniCodeByTimeSpent = (
    filterUniCodeBy,
    journeyData,
    arrangeGraph
  ) => {
    if (!topicUniCodes?.length && !journeyData?.length) return;

    // const updateUniCode = journeyData
    //   .filter((x) => x?.uniCode)
    //   .map((e) => {
    //     const findUniCode = topicUniCodes.find(
    //       (x) =>
    //         String(x?.id) === String(e?.uniCode) ||
    //         String(x?.uniCode) === String(e?.uniCode)
    //     );

    //     e.uniCode = findUniCode?.uniCode;
    //     return e;
    //   });

    const uniqueCode = [...new Set(journeyData.map((e) => e.uniCode))].filter(
      (e) => e
    );

    let size = 0;
    if (filterUniCodeBy === "chapterUnicode") size = 3;
    else if (filterUniCodeBy === "subjectUnicode") size = 2;
    else if (filterUniCodeBy === "gradeUnicode") size = 1;

    const filterSearchUniCode = uniqueCode.filter(
      (e) =>
        String(e).split("-").slice(0, size).join("-").toLowerCase() ===
        uniCode?.toLowerCase()
    );

    const sumUpTimeForEachUniCode = filterSearchUniCode
      .map((e) => {
        const sumTime = journeyData.filter(
          (x) => String(x?.uniCode) === String(e)
        );

        const averageTime =
          sumTime.reduce((a, b) => (a += b?.durationInMin), 0) /
          sumTime?.length;

        return {
          uniCode: e,
          time: averageTime,
        };
      })
      .sort((a, b) => b?.time - a?.time);

    const mostTopic = sumUpTimeForEachUniCode.slice(0, 5);
    const leastTopic = sumUpTimeForEachUniCode.slice(
      sumUpTimeForEachUniCode?.length - 5,
      sumUpTimeForEachUniCode?.length
    );

    arrangeGraph.labels = [
      ...mostTopic.map((e) => e?.uniCode),
      ...leastTopic.map((e) => e?.uniCode),
    ];

    arrangeGraph.datasets = [
      {
        label: "5 Most Visits",
        data: [
          ...mostTopic.map((e) => e?.time),
          ...[...Array(leastTopic?.length)].map((e) => 0),
        ],
        backgroundColor: "rgb(252, 186, 3)",
        borderColor: "rgb(252, 186, 3)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "5 least Visits",
        data: [
          ...[...Array(mostTopic?.length)].map((e) => 0),
          ...leastTopic.map((e) => e?.time),
        ],
        backgroundColor: "rgb(182, 126, 222)",
        borderColor: "rgb(182, 126, 222)",
        lineTension: 0,
        fill: false,
      },
    ];
  };

  const createSearchedTopicQuizUniCodeGraph = (
    journeyData,
    id,
    uniCode,
    name
  ) => {
    const dateList = topicGraphData.labels;

    const dataPerDate = dateList.map((e) => {
      const filterDate = journeyData.filter((a) => a?.date === e);

      const topics = filterDate.filter(
        (a) =>
          String(a?.uniCode).toLowerCase() === String(id).toLowerCase() ||
          String(a?.uniCode).toLowerCase() === String(uniCode).toLowerCase()
      );

      return {
        count: topics?.length || 0,
      };
    });

    if (filterBy === "topicUnicode") {
      if (topicGraphData.datasets.length > 3) topicGraphData.datasets.pop();

      topicGraphData.datasets.push({
        label: name,
        data: dataPerDate.map((e) => e?.count),
        backgroundColor: "rgb(66, 75, 245)",
        borderColor: "rgb(66, 75, 245)",
        lineTension: 0,
        fill: false,
      });

      createSpecificStudentTopicQuizSearchGraph(
        "topic_view",
        id,
        uniCode,
        name
      );
    } else if (filterBy === "quizUnicode") {
      if (quizGraphData.datasets.length > 3) quizGraphData.datasets.pop();

      quizGraphData.datasets.push({
        label: name,
        data: dataPerDate.map((e) => e?.count),
        backgroundColor: "rgb(66, 75, 245)",
        borderColor: "rgb(66, 75, 245)",
        lineTension: 0,
        fill: false,
      });

      const element = document.getElementById("quizJourney");
      element.scrollIntoView();

      createSpecificStudentTopicQuizSearchGraph("quiz_view", id, uniCode, name);
      searchQuizLeastAndMostGraph = {};
    }

    resetGraphsForSearchUniCodeHandler();
    setFilterBy("");
    setSearchName(name);
  };

  const createGraphForSearchChapterUnicode = (
    journeyData,
    uniCodeList,
    IDSList,
    labelName,
    screenName
  ) => {
    const dateList = topicGraphData.labels;

    const dataPerDate = dateList.map((e) => {
      const filterData = journeyData.filter((a) => a?.date === e);

      const searchData = filterData.filter(
        (a) =>
          uniCodeList?.find((x) => String(x) === String(a?.uniCode)) ||
          IDSList?.find((x) => String(x) === String(a?.uniCode))
      );

      return {
        count: searchData?.length || 0,
      };
    });

    if (screenName === "topic") {
      if (topicGraphData?.datasets?.length > 3) topicGraphData?.datasets.pop();

      topicGraphData?.datasets.push({
        label: labelName,
        data: dataPerDate?.map((e) => e?.count),
        backgroundColor: "rgb(87, 61, 255)",
        borderColor: "rgb(87, 61, 255)",
        lineTension: 0,
        fill: false,
      });
    } else if (screenName === "quiz") {
      if (quizGraphData?.datasets?.length > 3) quizGraphData?.datasets.pop();

      quizGraphData?.datasets.push({
        label: labelName,
        data: dataPerDate?.map((e) => e?.count),
        backgroundColor: "rgb(87, 61, 255)",
        borderColor: "rgb(87, 61, 255)",
        lineTension: 0,
        fill: false,
      });
    }

    setFilterBy("");
    setSearchName(labelName);
  };

  const searchUniCodeHandler = async (e) => {
    e.preventDefault();

    if (!uniCode) return;

    let isUniCodeExist = null;

    const topicData = applyDateFilterOnJourney(dateObj, topicJourneyList);
    const quizData = applyDateFilterOnJourney(dateObj, quizJourneyList);
    const filterUniCodeBy = filterBy;

    if (filterBy === "topicUnicode") {
      isUniCodeExist = topicUniCodes.find(
        (e) => e?.uniCode.toLowerCase() === uniCode.toLowerCase()
      );

      if (!isUniCodeExist) {
        toast.error("Unicode doesn't exist");
        return;
      }

      const { id, topicName } = isUniCodeExist;

      createSearchedTopicQuizUniCodeGraph(topicData, id, uniCode, topicName);
    } else if (filterBy === "quizUnicode") {
      isUniCodeExist = quizUniCodes.find(
        (e) => e?.uniCode.toLowerCase() === uniCode.toLowerCase()
      );

      if (!isUniCodeExist) {
        toast.error("Unicode doesn't exist");
        return;
      }

      const { id, quizName } = isUniCodeExist;

      createSearchedTopicQuizUniCodeGraph(quizData, id, uniCode, quizName);
    } else if (filterBy === "chapterUnicode") {
      if (uniCode.split("-").length !== 3) {
        toast.error("Invalid UniCode");
        return;
      }

      isUniCodeExist = topicUniCodes.filter(
        (e) =>
          e?.uniCode.split("-").slice(0, 3).join("-").toLowerCase() ===
          uniCode.toLowerCase()
      );

      if (!isUniCodeExist?.length) {
        toast.error("Topic against search unicode doesn't exist");
      }

      if (isUniCodeExist?.length) {
        const uniCodeList = isUniCodeExist?.map((e) => e?.uniCode);
        const IDSList = isUniCodeExist?.map((e) => e?.id);
        const chapterName = isUniCodeExist[0]?.chapterName;
        createGraphForSearchChapterUnicode(
          topicData,
          uniCodeList,
          IDSList,
          chapterName,
          "topic"
        );
      }

      isUniCodeExist = quizUniCodes.filter((e) =>
        e?.uniCode.toLowerCase().includes(uniCode.toLowerCase())
      );

      if (!isUniCodeExist?.length)
        toast.error("Quiz against search unicode doesn't exist");

      if (isUniCodeExist?.length) {
        const uniCodeList = isUniCodeExist?.map((e) => e?.uniCode);
        const IDSList = isUniCodeExist?.map((e) => e?.id);
        const chapterName = isUniCodeExist[0]?.chapterName;
        createGraphForSearchChapterUnicode(
          quizData,
          uniCodeList,
          IDSList,
          chapterName,
          "quiz"
        );
      }
    } else if (filterBy === "subjectUnicode") {
      if (uniCode.split("-").length !== 2) {
        toast.error("Invalid UniCode");
        return;
      }

      isUniCodeExist = topicUniCodes.filter(
        (e) =>
          e?.uniCode.split("-").slice(0, 2).join("-").toLowerCase() ===
          uniCode.toLowerCase()
      );

      if (!isUniCodeExist?.length) {
        toast.error("Topic against search unicode doesn't exist");
      }

      if (isUniCodeExist?.length) {
        const uniCodeList = isUniCodeExist?.map((e) => e?.uniCode);
        const IDSList = isUniCodeExist?.map((e) => e?.id);
        const subjectName = isUniCodeExist[0]?.subjectName;
        createGraphForSearchChapterUnicode(
          topicData,
          uniCodeList,
          IDSList,
          subjectName,
          "topic"
        );
      }

      isUniCodeExist = quizUniCodes.filter(
        (e) =>
          e?.uniCode.split("-").slice(0, 2).join("-").toLowerCase() ===
          uniCode.toLowerCase()
      );

      if (!isUniCodeExist?.length)
        toast.error("Quiz against search unicode doesn't exist");

      if (isUniCodeExist?.length) {
        const uniCodeList = isUniCodeExist?.map((e) => e?.uniCode);
        const IDSList = isUniCodeExist?.map((e) => e?.id);
        const subjectName = isUniCodeExist[0]?.subjectName;
        createGraphForSearchChapterUnicode(
          quizData,
          uniCodeList,
          IDSList,
          subjectName,
          "quiz"
        );
      }
    } else if (filterBy === "gradeUnicode") {
      if (uniCode.includes("-") || uniCode.length <= 1) {
        toast.error("Invalid UniCode");
        return;
      }

      isUniCodeExist = topicUniCodes.filter(
        (e) => e?.uniCode.split("-")[0].toLowerCase() === uniCode.toLowerCase()
      );

      if (!isUniCodeExist?.length) {
        toast.error("Topic against search unicode doesn't exist");
      }

      if (isUniCodeExist?.length) {
        const uniCodeList = isUniCodeExist?.map((e) => e?.uniCode);
        const IDSList = isUniCodeExist?.map((e) => e?.id);
        const gradeName = isUniCodeExist[0]?.gradeName;
        createGraphForSearchChapterUnicode(
          topicData,
          uniCodeList,
          IDSList,
          gradeName,
          "topic"
        );
      }

      isUniCodeExist = quizUniCodes.filter(
        (e) => e?.uniCode.split("-")[0].toLowerCase() === uniCode.toLowerCase()
      );

      if (!isUniCodeExist?.length)
        toast.error("Quiz against search unicode doesn't exist");

      if (isUniCodeExist?.length) {
        const uniCodeList = isUniCodeExist?.map((e) => e?.uniCode);
        const IDSList = isUniCodeExist?.map((e) => e?.id);
        const gradeName = isUniCodeExist[0]?.gradeName;
        createGraphForSearchChapterUnicode(
          quizData,
          uniCodeList,
          IDSList,
          gradeName,
          "quiz"
        );
      }
    }

    if (["quizUnicode", "topicUnicode"].includes(filterBy)) return;

    [
      {
        screenName: "topic",
        uniCodesList: topicUniCodes,
        journeyData: topicData,
        graph: searchTopicLeastAndMostGraph,
      },
      {
        screenName: "quiz",
        uniCodesList: quizUniCodes,
        journeyData: quizData,
        graph: searchQuizLeastAndMostGraph,
      },
      {
        screenName: "topic",
        uniCodesList: topicUniCodes,
        journeyData: topicData.filter((x) => x?.status === "completed"),
        graph: searchCompletedTopicLeastAndMostGraph,
      },
      {
        screenName: "quiz",
        uniCodesList: quizUniCodes,
        journeyData: quizData.filter((x) => x?.status === "completed"),
        graph: searchCompletedQuizLeastAndMostGraph,
      },
      {
        screenName: "topic",
        uniCodesList: topicUniCodes,
        journeyData: topicJourneyList,
        graph: searchTopicLeastAndMostGraphOfAllTime,
      },
      {
        screenName: "quiz",
        uniCodesList: quizUniCodes,
        journeyData: quizJourneyList,
        graph: searchQuizLeastAndMostGraphOfAllTime,
      },
      {
        screenName: "topic",
        uniCodesList: topicUniCodes,
        journeyData: topicJourneyList,
        graph: searchCompletedTopicLeastAndMostGraphOfAllTime,
      },
      {
        screenName: "quiz",
        uniCodesList: quizUniCodes,
        journeyData: quizJourneyList.filter((x) => x?.status === "completed"),
        graph: searchCompletedQuizLeastAndMostGraphOfAllTime,
      },
    ].map((e) =>
      createGraphForLeastAndMostSearchUniCode(
        e?.screenName,
        e?.uniCodesList,
        e?.journeyData,
        e?.graph,
        filterUniCodeBy
      )
    );

    createGraphForLeastAndMostSearchUniCodeByTimeSpent(
      filterUniCodeBy,
      topicData,
      searchTopicLeastAndMostByTimeSpentSpecifiedTimeGraph
    );
    createGraphForLeastAndMostSearchUniCodeByTimeSpent(
      filterUniCodeBy,
      topicJourneyList,
      searchTopicLeastAndMostByTimeSpentAllTimeGraph
    );

    // createGraphForLeastAndMostSearchUniCode(
    //   "topic",
    //   topicUniCodes,
    //   topicData,
    //   searchTopicLeastAndMostGraph
    // );
    // createGraphForLeastAndMostSearchUniCode(
    //   "quiz",
    //   quizUniCodes,
    //   quizData,
    //   searchQuizLeastAndMostGraph
    // );
    // createGraphForLeastAndMostSearchUniCode(
    //   "topic",
    //   topicUniCodes,
    //   topicData.filter((x) => x?.status === "completed"),
    //   searchCompletedTopicLeastAndMostGraph
    // );
    // createGraphForLeastAndMostSearchUniCode(
    //   "quiz",
    //   quizUniCodes,
    //   quizData.filter((x) => x?.status === "completed"),
    //   searchCompletedQuizLeastAndMostGraph
    // );
    // createGraphForLeastAndMostSearchUniCode(
    //   "topic",
    //   topicUniCodes,
    //   topicJourneyList,
    //   searchTopicLeastAndMostGraphOfAllTime
    // );
    // createGraphForLeastAndMostSearchUniCode(
    //   "quiz",
    //   quizUniCodes,
    //   quizJourneyList,
    //   searchQuizLeastAndMostGraphOfAllTime
    // );
    // createGraphForLeastAndMostSearchUniCode(
    //   "topic",
    //   topicUniCodes,
    //   topicJourneyList.filter((x) => x?.status === "completed"),
    //   searchCompletedTopicLeastAndMostGraphOfAllTime
    // );
    // createGraphForLeastAndMostSearchUniCode(
    //   "quiz",
    //   quizUniCodes,
    //   quizJourneyList.filter((x) => x?.status === "completed"),
    //   searchCompletedQuizLeastAndMostGraphOfAllTime
    // );
  };

  const closeHandler = () => {
    setIsPointSelected(false);
    setSelectedTopicsList([]);
  };

  const calculateAverageTime = (screenName) => {
    return topicGraphData?.labels?.length &&
      topicGraphData?.datasets?.length &&
      quizGraphData?.labels?.length &&
      quizGraphData?.datasets?.length
      ? secondsToHms(
          screenName === "quiz"
            ? isSpecificStudentData
              ? (quizGraphData?.datasets[1]?.data?.reduce(
                  (a, b) => (a += b),
                  0
                ) /
                  quizGraphData.datasets[1]?.data?.length) *
                60
              : (quizGraphData.datasets[1]?.data?.reduce(
                  (a, b) => (a += b),
                  0
                ) /
                  quizGraphData.datasets[1]?.data?.length) *
                60
            : isSpecificStudentData
            ? (topicGraphData?.datasets[1]?.data?.reduce(
                (a, b) => (a += b),
                0
              ) /
                topicGraphData.datasets[1]?.data?.length) *
              60
            : (topicGraphData.datasets[1]?.data?.reduce((a, b) => (a += b), 0) /
                topicGraphData.datasets[1]?.data?.length) *
              60
        ) || 0
      : 0;
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  /////////////////////////////////////////////////////////////// JSX PART BELOW   // /////////////////////////////////////////////////////////////

  const renderCellValue = (value) => (
    <div className="value">
      <h1>{value}</h1>
    </div>
  );

  const renderTopicOrQuizTable = () =>
    isPointSelected && (
      <ModalContainer
        isOpen={isPointSelected}
        modalWidth="1000"
        animateEffect="fadeInUp"
        heading={`Topic/Quiz List (${selectedDate})`}
        onConfirmClick={closeHandler}
      >
        <div
          style={{
            height: "600px",
            overflow: "scroll",
            margin: "1rem",
            border: "1px solid black",
            padding: "1rem",
          }}
        >
          {selectedTopicsList?.length ? (
            <MaterialTable
              icons={tableIcons}
              title={null}
              columns={[
                {
                  title: "Unicode",
                  field: "uniCode",
                  render: (rowData) => renderCellValue(rowData?.uniCode),
                },
                {
                  title: "Count",
                  field: "count",
                  render: (rowData) => renderCellValue(rowData?.count),
                },
                {
                  title: "Average Time",
                  field: "averageTime",
                  render: (rowData) => renderCellValue(rowData?.averageTime),
                },
                // {
                //   title: "Status",
                //   field: "status",
                //   render: (rowData) => renderCellValue(rowData?.status),
                // },
                {
                  title: "Topic",
                  field: "topicName",
                  render: (rowData) => renderCellValue(rowData?.topicName),
                },
                {
                  title: "Quiz",
                  field: "quizName",
                  render: (rowData) => renderCellValue(rowData?.quizName),
                },
                {
                  title: "Chapter",
                  field: "chapterName",
                  render: (rowData) => renderCellValue(rowData?.chapterName),
                },
                {
                  title: "Subject",
                  field: "subjectName",
                  render: (rowData) => renderCellValue(rowData?.subjectName),
                },
                {
                  title: "Grade",
                  field: "gradeName",
                  render: (rowData) => renderCellValue(rowData?.gradeName),
                },
              ]}
              data={
                selectedTopicsList?.length > 0 &&
                selectedTopicsList.map((e) => ({
                  id: e?.id,
                  gradeName: e?.gradeName,
                  subjectName: e?.subjectName,
                  chapterName: e?.chapterName,
                  topicName: e?.topicName || "-",
                  quizName: e?.quizName || "-",
                  uniCode: e?.uniCode,
                  count: e?.count,
                  status: e?.status,
                  averageTime: secondsToHms(e?.averageTime * 60),
                }))
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                pageSize: 15,
                pageSizeOptions: [
                  5,
                  15,
                  30,
                  selectedTopicsList?.length > 30 && selectedTopicsList?.length,
                ],
                filtering: true,
                search: true,
              }}
            />
          ) : (
            <h6 className="mt-3">Data not found</h6>
          )}
        </div>
      </ModalContainer>
    );

  const renderStudentSearchFilter = () => (
    <form className="search-by-id" onSubmit={searchHandler}>
      <input
        type="text"
        className="form-control"
        value={studentID}
        required
        placeholder="Enter Student ID to search"
        onChange={(e) => setStudentID(e.target.value)}
      />
      <div className="btn_grp">
        <button className="btn btn-secondary" type="submit">
          Search
        </button>
      </div>
    </form>
  );

  const renderUniCodeSearchFilter = () => (
    <form className="search_uniCode" onSubmit={searchUniCodeHandler}>
      <div className="row">
        <div className="col-md-6">
          <select
            className="form-control"
            value={filterBy}
            required
            onChange={(e) => setFilterBy(e.target.value)}
          >
            <option value="">Select search type</option>
            <option value="topicUnicode">Search by Topic uniCode</option>
            <option value="quizUnicode">Search by Quiz uniCode</option>
            <option value="chapterUnicode">Search by Chapter uniCode</option>
            <option value="subjectUnicode">Search by Subject uniCode</option>
            <option value="gradeUnicode">Search by Grade uniCode</option>
          </select>
        </div>

        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            value={uniCode}
            required
            placeholder="Enter unicode to search"
            onChange={(e) => setUniCode(e.target.value)}
          />
        </div>

        <div className="col-md-12 text-right">
          <button className="btn btn-secondary" type="submit">
            Search
          </button>
        </div>
      </div>
    </form>
  );

  const renderSearchBlock = () => (
    <div className="search_block">
      <div className="row">
        <div className="col-md-4">{renderStudentSearchFilter()}</div>
        <div className="col-md-8"> {renderUniCodeSearchFilter()} </div>
      </div>
    </div>
  );

  return (
    <div className="user_platforms">
      <DateFilters
        submitHandler={submitHandler}
        refreshHandler={refreshHandler}
      />
      {/* <button onClick={searchSpecificDataFromUserJourney}>
        Search This Code
      </button> */}
      {renderSearchBlock()}
      {loading ? (
        <Loader />
      ) : (
        <>
          <DownloadPDF downloadFileName="Topic-Quiz-Journey-Report" />
          <div className="graph">
            <div className="downloadPDF">
              <div className="box">
                <div className="box_card">
                  <p>Average time:</p>
                  <p>{calculateAverageTime()}</p>
                </div>
                <div className="box_card">
                  <p>Total Days:</p>
                  <p>{topicGraphData?.labels?.length || 0}</p>
                </div>
              </div>
              <h3 className="heading">Students Topic Journey</h3>
              <Line
                options={{
                  ...graphOptions,
                  tooltips: {
                    callbacks: {
                      label: function (tooltipItem) {
                        if (tooltipItem?.datasetIndex === 0) {
                          return "Daily Active Users : " + tooltipItem?.yLabel;
                        } else if (tooltipItem?.datasetIndex === 1) {
                          return (
                            "Average time : " +
                            secondsToHms(tooltipItem?.yLabel * 60)
                          );
                        } else if (tooltipItem?.datasetIndex === 2) {
                          return "Topics Visited : " + tooltipItem?.yLabel;
                        } else if (tooltipItem?.datasetIndex === 3) {
                          return "Visit " + tooltipItem?.yLabel + " times";
                        } else if (tooltipItem?.datasetIndex === 4) {
                          return "Visit " + tooltipItem?.yLabel + " times";
                        }
                      },
                    },
                  },
                }}
                data={topicGraphData}
                getElementAtEvent={(elements, event) => {
                  if (!elements?.length) return;
                  const { _datasetIndex, _index, _xScale } = elements[0];

                  if (_datasetIndex !== 2) return;
                  const { ticks } = _xScale;

                  const topics = topicJourneyList.filter(
                    (x) => x.date === ticks[_index]
                  );

                  const uniqueTopics = [
                    ...new Set(topics.map((x) => x?.uniCode)),
                  ];
                  // .filter((e) => e.length > 15);

                  const topicList = uniqueTopics
                    .map((e) => ({
                      ...topicUniCodes.find(
                        (x) =>
                          String(x?.id) === String(e) ||
                          String(x?.uniCode) === String(e)
                      ),
                      count:
                        topics.filter((a) => String(a.uniCode) === String(e))
                          ?.length || 0,
                      averageTime:
                        topics
                          .filter((a) => String(a.uniCode) === String(e))
                          .reduce((a, b) => (a += b?.durationInMin), 0) || 0,
                    }))
                    .filter((a) => a);

                  // const topicGroups = topicList.reduce(function (acc, curr) {
                  //   return (
                  //     acc[curr?.topicName]
                  //       ? ++acc[curr?.topicName]
                  //       : (acc[curr?.topicName] = 1),
                  //     acc
                  //   );
                  // }, {});

                  setIsPointSelected(true);
                  setSelectedTopicsList(topicList || []);
                  setSelectedDate(topics?.length && topics[0].date);
                }}
              />
            </div>
            {/* <hr /> */}
            {/* <div className="gap"></div> */}
            {/* <h3 className="heading">Students Quiz Journey</h3> */}
            {/* <Line options={graphOptions} data={quizGraphData} /> */}
            {/* <hr /> */}
            {/* <div className="gap"></div> */}
            {/* <h3 className="heading">Students Topic Quiz Journey Bar Graph</h3> */}
            {/* <Bar options={graphOptions} data={graphData} /> */}
          </div>
          <hr />
          <div id="quizJourney" className="graph">
            <div className="downloadPDF">
              <div className="box">
                <div className="box_card">
                  <p>Average time:</p>
                  <p>{calculateAverageTime("quiz")}</p>
                </div>
                <div className="box_card">
                  <p>Total Days:</p>
                  <p>{quizGraphData?.labels?.length || 0}</p>
                </div>
              </div>
              <h3 className="heading">Students Quiz Journey</h3>
              <Line
                options={{
                  ...graphOptions,
                  tooltips: {
                    callbacks: {
                      label: function (tooltipItem) {
                        if (tooltipItem?.datasetIndex === 0) {
                          return "Daily Active Users : " + tooltipItem?.yLabel;
                        } else if (tooltipItem?.datasetIndex === 1) {
                          return (
                            "Average time : " +
                            secondsToHms(tooltipItem?.yLabel * 60)
                          );
                        } else if (tooltipItem?.datasetIndex === 2) {
                          return "Quiz Visited : " + tooltipItem?.yLabel;
                        } else if (tooltipItem?.datasetIndex === 3) {
                          return "Visit " + tooltipItem?.yLabel + " times";
                        } else if (tooltipItem?.datasetIndex === 4) {
                          return "Visit " + tooltipItem?.yLabel + " times";
                        }
                      },
                    },
                  },
                }}
                data={quizGraphData}
                getElementAtEvent={(elements, event) => {
                  if (!elements?.length) return;
                  const { _datasetIndex, _index, _xScale } = elements[0];

                  if (_datasetIndex !== 2) return;
                  const { ticks } = _xScale;

                  const quizzes = quizJourneyList.filter(
                    (x) => x.date === ticks[_index]
                  );

                  const uniqueQuizzes = [
                    ...new Set(quizzes.map((x) => x?.uniCode)),
                  ];
                  // .filter((e) => e.length > 15);

                  const quizList = uniqueQuizzes
                    .map((e) => ({
                      ...quizUniCodes.find(
                        (x) =>
                          String(x?.id) === String(e) ||
                          String(x?.uniCode) === String(e)
                      ),
                      count:
                        quizzes.filter((a) => String(a.uniCode) === String(e))
                          ?.length || 0,
                      averageTime:
                        quizzes
                          .filter((a) => String(a.uniCode) === String(e))
                          .reduce((a, b) => (a += b?.durationInMin), 0) || 0,
                    }))
                    .filter((a) => a);

                  setIsPointSelected(true);
                  setSelectedTopicsList(quizList || []);
                  setSelectedDate(quizzes?.length && quizzes[0].date);
                }}
              />
            </div>
            {Object.keys(searchTopicLeastAndMostGraph)?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Visits Topics for {searchName || "-"}{" "}
                    specified time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchTopicLeastAndMostGraph}
                  />
                </div>
              </>
            )}
            {Object.keys(searchQuizLeastAndMostGraph)?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Visits Quiz for {searchName || "-"}{" "}
                    specified time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchQuizLeastAndMostGraph}
                  />
                </div>
              </>
            )}
            {Object.keys(searchTopicLeastAndMostGraphOfAllTime)?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Visits Topic for {searchName || "-"} all
                    time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchTopicLeastAndMostGraphOfAllTime}
                  />
                </div>
              </>
            )}
            {Object.keys(searchQuizLeastAndMostGraphOfAllTime)?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Visits Quiz for {searchName || "-"} all
                    time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchQuizLeastAndMostGraphOfAllTime}
                  />
                </div>
              </>
            )}
            {Object.keys(searchCompletedTopicLeastAndMostGraph)?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Completed Topics for {searchName || "-"}{" "}
                    specified time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchCompletedTopicLeastAndMostGraph}
                  />
                </div>
              </>
            )}
            {Object.keys(searchCompletedTopicLeastAndMostGraphOfAllTime)
              ?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Completed Topics for {searchName || "-"}{" "}
                    all time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchCompletedTopicLeastAndMostGraphOfAllTime}
                  />
                </div>
              </>
            )}
            {Object.keys(searchCompletedQuizLeastAndMostGraph)?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Completed Quiz for {searchName || "-"}{" "}
                    specified time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchCompletedQuizLeastAndMostGraph}
                  />
                </div>
              </>
            )}
            {Object.keys(searchCompletedQuizLeastAndMostGraphOfAllTime)
              ?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Completed Quizzes for {searchName || "-"}{" "}
                    all time
                  </h3>
                  <Bar
                    options={barGraphOptions}
                    data={searchCompletedQuizLeastAndMostGraphOfAllTime}
                  />
                </div>
              </>
            )}
            {Object.keys(searchTopicLeastAndMostByTimeSpentSpecifiedTimeGraph)
              ?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Topic with time spent for{" "}
                    {searchName || "-"} specified time
                  </h3>
                  <Bar
                    options={{
                      ...barGraphOptions,
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem) {
                            return (
                              "Average time : " +
                              secondsToHms(tooltipItem?.yLabel * 60)
                            );
                          },
                        },
                      },
                    }}
                    data={searchTopicLeastAndMostByTimeSpentSpecifiedTimeGraph}
                  />
                </div>
              </>
            )}
            {Object.keys(searchTopicLeastAndMostByTimeSpentAllTimeGraph)
              ?.length && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <h3 className="heading">
                    5 Least And Most Topic with time spent for{" "}
                    {searchName || "-"} all time
                  </h3>
                  <Bar
                    options={{
                      ...barGraphOptions,
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem) {
                            return (
                              "Average time : " +
                              secondsToHms(tooltipItem?.yLabel * 60)
                            );
                          },
                        },
                      },
                    }}
                    data={searchTopicLeastAndMostByTimeSpentAllTimeGraph}
                  />
                </div>
              </>
            )}
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">
                5 Least And Most Visits Topics specified time
              </h3>
              <Bar
                options={barGraphOptions}
                data={leastAndMostVisitTopicGraphData}
              />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">
                5 Least And Most Completed Quiz Specified Date
              </h3>
              <Bar
                options={barGraphOptions}
                data={leastAndMostCompleteQuizSpecifiedDateGraphData}
              />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">
                5 Least And Most Completed Quiz all time
              </h3>
              <Bar
                options={barGraphOptions}
                data={leastAndMostCompleteQuizGraphData}
              />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">
                5 Least And Most Completed Topic Specified Date
              </h3>
              <Bar
                options={barGraphOptions}
                data={leastAndMostCompleteTopicDateSpecifiedGraphData}
              />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">
                5 Least And Most Completed topic all time
              </h3>
              <Bar
                options={barGraphOptions}
                data={leastAndMostCompleteTopicGraphData}
              />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">
                5 Maximum And Minimum Time Spent On Topic specified time
              </h3>
              <Bar
                // type="bar"
                options={multiGraphOptions}
                data={leastAndMostTimeSpendOnTopicGraphData}
              />
            </div>
            <div id="scrollingContainer"></div>
            {specificStudentGraph && (
              <>
                <hr />
                <div className="gap"></div>
                <div className="downloadPDF">
                  <div className="box">
                    <div className="box_card">
                      <p>Average app session:</p>
                      <p>{"-"}</p>
                    </div>
                    <div className="box_card">
                      <p>Average topics visits:</p>
                      <p>
                        {/* {() => {
                          return specificStudentGraph?.datasets?.data?.length &&
                            specificStudentGraph?.datasets?.data[1]
                            ? specificStudentGraph?.datasets?.data[1].reduce(
                                (a, b) => (a += b),
                                0
                              ) /
                                specificStudentGraph?.datasets?.data[1]?.length
                            : 0;
                        }} */}
                        0
                      </p>
                    </div>
                  </div>
                  {/* <div className="box">
                    <div className="box_card">
                      <p>Average quiz visits:</p>
                      <p>
                        {specificStudentGraph?.datasets?.data[4]?.reduce(
                          (a, b) => (a += b),
                          0
                        ) / specificStudentGraph?.datasets?.data[1]?.length}
                      </p>
                    </div>
                    <div className="box_card">
                      <p>Average topics visits:</p>
                      <p>{"-"}</p>
                    </div>
                  </div> */}

                  <h3 className="heading">
                    {specificStudentGraph?.labels1} Whole Journey
                  </h3>

                  <Line
                    options={specificStudentGraphOptions}
                    data={specificStudentGraph}
                    getElementAtEvent={(elements, event) => {
                      if (!elements?.length) return;
                      const { _datasetIndex, _index, _xScale } = elements[0];

                      const { ticks } = _xScale;
                      const { userID, journeys } = studentJourneyList;
                      if (_datasetIndex === 1) {
                        const isTopicJourneyExist = journeys.filter(
                          (e) =>
                            e?.screenName === "topic_view" &&
                            e.date === ticks[_index]
                        );

                        const topicList = isTopicJourneyExist?.length
                          ? isTopicJourneyExist
                          : [];

                        if (!topicList?.length) {
                          toast.error("No topic visit on " + ticks[_index]);
                          return;
                        }

                        const topics = topicList.map((e) => ({
                          ...topicUniCodes.find(
                            (x) =>
                              String(x?.id) === String(e?.uniCode) ||
                              String(x?.uniCode) === String(e?.uniCode)
                          ),
                          count: 1,
                          averageTime: e?.durationInMin,
                          status: e?.status,
                        }));

                        setIsPointSelected(true);
                        setSelectedTopicsList(topics || []);
                      } else if (_datasetIndex === 4) {
                        const isQuizJourneyExist = journeys.filter(
                          (e) =>
                            e?.screenName === "quiz_view" &&
                            e.date === ticks[_index]
                        );

                        const quizList = isQuizJourneyExist?.length
                          ? isQuizJourneyExist
                          : [];

                        if (!quizList?.length) {
                          toast.error("No quiz visit on " + ticks[_index]);
                          return;
                        }

                        const topics = quizList.map((e) => ({
                          ...quizUniCodes.find(
                            (x) =>
                              String(x?.id) === String(e?.uniCode) ||
                              String(x?.uniCode) === String(e?.uniCode)
                          ),
                          count: 1,
                          averageTime: e?.durationInMin,
                          status: e?.status,
                        }));

                        setIsPointSelected(true);
                        setSelectedTopicsList(topics || []);
                      }
                    }}
                  />
                </div>
              </>
            )}
            {/* <Line options={graphOptions} data={quizGraphData} /> */}
            {/* <hr /> */}
            {/* <div className="gap"></div> */}
            {/* <h3 className="heading">Students Topic Quiz Journey Bar Graph</h3> */}
          </div>
        </>
      )}

      {renderTopicOrQuizTable()}
    </div>
  );
};

TopicsReport.propTypes = {};

export default TopicsReport;
// 6232f1ed29f0981bbec71b8d
