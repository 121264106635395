import ModalContainer from 'components/Common/ModalContainer'
import PropTypes from 'prop-types'

const TotalQuizAndTopicsModal = ({ isOpenModal, handleOnClose, totalChapterQuizzesTestsPointsObj }) => {
  const renderRowContent = (text, value) => (
    <div className="total_info">
      <h1 className='label' >{text}</h1>
      <h1>{value}</h1>
    </div>
  )

  const totalInfo = totalChapterQuizzesTestsPointsObj;
  return (
    <ModalContainer isOpen={isOpenModal} onConfirmClick={handleOnClose}
      modalWidth="600" animateEffect="fadeInUp"
      heading="Total topic, quizzes, and points" >

      <div className='total_quiz'>
        <div className="total_quiz-wrapper">
          <h1 className='heading' >{totalInfo?.subjectName || "N/A"}</h1>
          {renderRowContent("Total Active Quizzes", totalInfo?.activeQuizzes || 0)}
          {renderRowContent("Total InActive Quizzes", totalInfo?.inActiveQuizzes || 0)}
          {renderRowContent("Total Quizzes", totalInfo?.totalQuiz || 0)}
          {renderRowContent("Total Points", totalInfo?.sum || 0)}
          {renderRowContent("Total Active Topics", totalInfo?.activeTopics || 0)}
          {renderRowContent("Total InActive Topics", totalInfo?.inActiveTopics || 0)}
          {renderRowContent("Total Topics", totalInfo?.totalTopic || 0)}
        </div>
      </div>

    </ModalContainer>
  )
}

TotalQuizAndTopicsModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  subjectID: PropTypes.string.isRequired,
  totalChapterQuizzesTestsPointsObj: PropTypes.object.isRequired,
}

export default TotalQuizAndTopicsModal
