import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Card, CardBody, Row, Col } from "reactstrap";
import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import StudentList from "components/Student/StudentList";
import {
  getStudentData,
  getStudentDataPagination,
  getTotalActiveStudents,
} from "../../Utils/students_Utils";
import ModalContainer from "components/Common/ModalContainer";
import { sendNotification } from "Utils/student_notification_Utils";
import { setMacToDefault } from "Utils/students_Utils";
import BreadCrumbs from "components/Common/BreadCrumbs";
import { saveNotificationLogs } from "Utils/notification_logs_Utils";
import { useSelector } from "react-redux";
import { selectUserData } from "Redux/features/Auth/AuthSlice";

const StudentWrapper = () => {
  const userData = useSelector(selectUserData);

  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [studentObj, setStudentObj] = useState(null);
  const [notificationObj, setNotificationObj] = useState({
    title: "",
    description: "",
  });
  const [studentCount, setStudentCount] = useState({});

  const openNotificationModal = (studentData) => {
    if (studentData) {
      setStudentObj(studentData);
    } else {
      setIsAll(true);
      setStudentObj(null);
    }

    setIsOpen(true);
  };

  const handleOnClose = () => {
    setIsOpen(false);
    setIsAll(false);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setNotificationObj({ ...notificationObj, [`${name}`]: value });
  };

  const _submit = async (e) => {
    e.preventDefault();

    if (
      notificationObj.title.trim() === "" &&
      notificationObj.description.trim() === ""
    ) {
      toast.error("Empty spaces are not allowed");
      setNotificationObj({ title: "", description: "" });
      const elem = document.getElementById("notificationTitle");
      elem.focus();
      return;
    }

    try {
      let resp = null;
      let notificationLogObj = null;

      if (isAll) {
        const regToken = "";
        resp = await sendNotification({ notificationObj, regToken, isAll });
        notificationLogObj = {
          category: "general",
          notificationLogs: [
            {
              date: new Date(),
              message: notificationObj?.description,
              title: notificationObj?.title,
            },
          ],
        };
      } else {
        // const regToken = studentList?.results?.find(e => e._id === studentObj._id).reg_token;
        const regToken = studentObj?.reg_token || "-";
        resp = await sendNotification({ notificationObj, regToken, isAll });

        notificationLogObj = {
          category: "individual",
          notificationLogs: [
            {
              date: new Date(),
              message: notificationObj?.description,
              title: notificationObj?.title,
            },
          ],
          receiverID: studentObj?._id,
        };
      }

      if (userData?.role)
        notificationLogObj.notificationLogs[0].senderID = userData?._id;
      else notificationLogObj.notificationLogs[0].superAdmin = userData?._id;

      await saveNotificationLogs(
        notificationLogObj,
        notificationLogObj.category,
        studentObj?._id
      );
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setNotificationObj({ title: "", description: "" });

      setIsOpen(false);
      setIsAll(false);
      setStudentObj(null);
    }
  };

  const fetchAllStudentRecord = async (limit, page) => {
    setLoading(true);
    try {
      const resp = await getStudentDataPagination(limit, page);
      const result = await getTotalActiveStudents();
      const rest = [...Array(resp.data.data.totalStudents)].map((e) => {
        return {};
      });
      const data = [...resp.data.data.results, ...rest.splice(10)];
      setStudentList(data);
      const { inActiveCount, activeCount } = result?.data?.data;
      const { count: inActiveStudents } = inActiveCount[0];
      const { count: activeStudents } = activeCount[0];
      const { totalStudents } = resp?.data?.data;
      setStudentCount({ totalStudents, inActiveStudents, activeStudents });
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateMacAddress = async (studentID, MacReq_count) => {
    setLoading(true);
    try {
      const resp = await setMacToDefault(studentID, MacReq_count + 1);
      toast.success(resp.data.message);
      fetchAllStudentRecord(10, 1);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(true);
    }
  };

  useEffect(() => {
    fetchAllStudentRecord(10, 1);
  }, []);

  const renderNotificationModal = () => (
    <div className="student-notification">
      <form onSubmit={_submit} className="notification-form">
        <p>Title</p>
        <input
          id="notificationTitle"
          type="text"
          value={notificationObj?.title}
          className="form-control"
          name="title"
          placeholder="Enter title"
          required
          onChange={changeHandler}
        />
        <p>Description</p>
        <textarea
          name="description"
          cols="30"
          rows="5"
          value={notificationObj?.description}
          className="w-100"
          required
          onChange={changeHandler}
        />
        <button type="submit" className="btn btn-secondary">
          Send
        </button>
      </form>
    </div>
  );
  return (
    <div className="student-wrapper content">
      <BreadCrumbs
        breadCrumbList={[{ title: "Students", pathName: "/admin/students" }]}
      />
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Header title="Students" location="/admin/dashboard" />

              {loading ? (
                <Loader />
              ) : (
                <StudentList
                  studentList={studentList}
                  sendNotification={openNotificationModal}
                  updateMacAddress={updateMacAddress}
                  totalCount={studentCount}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ModalContainer
        isOpen={isOpen}
        onConfirmClick={handleOnClose}
        modalWidth="600"
        animateEffect="fadeInUp"
        heading={
          isAll
            ? "Notification"
            : `Send ${
                studentObj?.first_name + " " + studentObj?.last_name
              } notification`
        }
      >
        {renderNotificationModal()}
      </ModalContainer>
    </div>
  );
};

export default StudentWrapper;
