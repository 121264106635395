import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { getUniCodeStats, getTestUniCodeStats } from "Utils/uniCode_Utils";
import Loader from "components/Common/Loader";
import Header from "components/Common/Header";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import UniCodeList from "./UniCodeList";

export const UniCodeWrapper = (props) => {
  const [topicList, setTopicList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openTab, setOpenTab] = useState("topic");

  const changeTab = (tab) => {
    setOpenTab(tab);
    fetchInitialData(tab);
  };

  const fetchTopicInitialData = async () => {
    setLoading(true);
    try {
      const resp = await getUniCodeStats();
      setTopicList(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchQuizInitialData = async () => {
    setLoading(true);
    try {
      const resp = await getTestUniCodeStats();
      setQuizList(resp.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialData = (tabName) => {
    if (tabName === "topic") fetchTopicInitialData();
    else fetchQuizInitialData();
  };

  const renderLoader = () => <Loader />;

  useEffect(() => {
    fetchInitialData(openTab);
  }, []);

  return (
    <div className="uniCode_wrapper content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Header title="UniCode" location="/admin/teachers" />
            </CardHeader>
            <CardBody>
              <div className="uniCode_wrapper_tabs">
                <div
                  className={` ${openTab === "topic" ? "activeBtn" : ""} topic`}
                  onClick={() => changeTab("topic")}
                >
                  <h1>Topic</h1>
                </div>
                <div
                  className={` ${openTab === "quiz" ? "activeBtn" : ""} quiz`}
                  onClick={() => changeTab("quiz")}
                >
                  <h1>Quiz</h1>
                </div>
              </div>
              {openTab === "topic" ? (
                loading ? (
                  renderLoader()
                ) : (
                  <UniCodeList dataList={topicList} renderView="topic" />
                )
              ) : loading ? (
                renderLoader()
              ) : (
                <UniCodeList dataList={quizList} renderView="quiz" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

UniCodeWrapper.propTypes = {};

export default UniCodeWrapper;
