import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import EquationEditorHolder from "./EquationEditorHolder";
import EquationEditor from "equation-editor-react";
// import TextEditor from "./TextEditor";
import axios from "../../api";
import { useHistory, useLocation } from "react-router-dom";
import { edittopic } from "Redux/features/Subject/SubjectSlice";
import { selecttopics } from "Redux/features/Subject/SubjectSlice";
import { selecttheme } from "Redux/features/Admin/AdminSlice";
import loader from "../../assets/img/loader.svg";
import loadingimg from "../../assets/img/loaderimg.svg";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { updateInternally } from "Utils/chapterPreview_Utils";
const EditTopic = () => {
  const loading = useSelector(selectLoading);
  const [loadingImage, setloadingImage] = useState(false);
  const [loadingPdf, setloadingPdf] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  let topicId = location.pathname.split("/")[7];
  console.log(topicId);
  const topiclist = useSelector(selecttopics);
  let theme = useSelector(selecttheme);
  let topic = topiclist.filter((top) => {
    return top._id === topicId;
  });
  topic = topic[0];
  console.log("Current", topic);
  // console.log(props.theme);
  useEffect(() => {
    dispatch(setloading(true));
    setTimeout(() => {
      dispatch(setloading(false));
    }, 1000);
  }, []);
  // console.log(topic);

  // console.log(data, "Data");

  const [TopicVideoId, setTopicVideoId] = useState(topic.topic_video);
  const [topicTitle, settopicTitle] = useState(topic.topic_title);
  const [addtopicIcon, setaddtopicIcon] = useState(topic.topic_icon);
  const [topicIcon, settopicIcon] = useState([]);
  const [vedio_type, setvedio_type] = useState(topic.vedio_type);
  const [ismath, setismath] = useState(false);
  const [description, setdescription] = useState(topic.topic_detail);
  const [videoHolder, setvideoHolder] = useState([]);
  const [videoPer, setvideoPer] = useState("");
  const [videoUri, setvideoUri] = useState("");
  const [test, settest] = useState("");
  const [currentIndex, setcurrentIndex] = useState(0);
  const [themeData, setthemeData] = useState(theme);
  const [value, setValue] = useState(description[0].description);
  const [equation, setEquation] = useState("");
  const [isPaid, setIsPaid] = useState(String(topic.isPaid));
  const [pdfFile, setPdfFile] = useState(topic?.topic_file);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ direction: "rtl" }], // this is rtl support
    ],
  };
  // const updateInternally = (index) => {
  //   if (description[index].topic_type == "text") {
  //     setdescription(
  //       description.map((des, i) =>
  //         i === index ? { ...des, description: value } : des
  //       )
  //     );
  //     // description[index].description = value;
  //   } else {
  //     setdescription(
  //       description.map((des, i) =>
  //         i === index ? { ...des, description: equation } : des
  //       )
  //     );
  //     // description[index].description = equation;
  //   }
  // };

  async function updateTopicBackend(topic_id, data, topic) {
    dispatch(setloading(true));
    axios
      .put(`/Topics/${topic_id}`, data)
      .then((res) => {
        dispatch(edittopic(res.data.data));
        console.log("Newer One", res.data.data);
        toast.success(`Topic Updated Successfully!`);
        setTimeout(() => {
          dispatch(setloading(false));
          history.push(
            `/admin/subject/chapter/chapterdetails/${topic?.chapter}`
          );
        }, 1000);
      })
      .catch((err) => {
        dispatch(setloading(false));
        try {
          toast.error(err.response.data.message);
        } catch (error) {
          toast.error(err.message);
        }
      });
  }

  async function uploadImage(fileObj, isPdf) {

    if (isPdf)
      setloadingPdf(true);
    else
      setloadingImage(true);

    const formData = new FormData();

    formData.append("files", topicIcon[0]);

    axios
      .post(`/filesupload/file-upload`, formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((res) => {

        if (isPdf)
          setPdfFile(res.data.locationArray[0].fileLocation);
        else
          setaddtopicIcon(res.data.locationArray[0].fileLocation);

        setTimeout(() => {

          if (isPdf)
            setloadingPdf(false);
          else
            setloadingImage(false);

        }, 1000);
      })
      .catch((err) => {

        if (isPdf)
          setloadingPdf(false);
        else
          setloadingImage(false);

        toast.error(err?.response?.data?.message?.message);

      });
  }
  const changeHandler = (event) => {
    topicIcon.push(event.target.files[0]);
    if (event?.target?.files[0]) {
      const isPdf = event?.target?.files[0]?.type === "application/pdf" ? true : false;
      uploadImage(event.target.files[0], isPdf);
    }
  };

  const videoHandler = (e) => {
    videoHolder.push(e.target.files[0]);
    // console.log(videoHolder);
    // uploadVideo();
  };
  const uploadVideo = () => {
    if (!topicTitle) {
      toast.error("Plz enter topic title first");
      return;
    }

    // Object.defineProperty( videoHolder[0], 'name', {
    //   writable: true,
    //   value: topicTitle
    // })

    client.upload(
      videoHolder[0],
      { name: topicTitle },
      function (uri) {
        // console.log("File upload completed. Your Vimeo URI is:", uri);
        setvideoUri(uri);
      },
      function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setvideoPer(percentage);
        // console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      function (error) {
        toast.error(error);
        // console.log("Failed because: " + error);
      }
    );
    setvideoHolder([]);
  };
  const handleEditTopic = () => {
    if (document.getElementById("vimeoinput")) {
      if (document.getElementById("vimeoinput").value !== "") {
        if (vedio_type === "vimeo") {
          setTopicVideoId(videoUri.split("/")[2]);
        }
      }
    }

    let topicToEdit = {
      topic_title: topicTitle,
      topic_icon: addtopicIcon,
      topic_detail: description,
      vedio_type: vedio_type,
      topic_video: TopicVideoId,
      awarded_points: topic.awarded_points,
      isPaid: isPaid === "false" ? false : true,
      isActive: topic.isActive,
      topic_file: pdfFile || "-"
    };

    if (document.getElementById("vimeoinput")) {
      vedio_type === "vimeo" &&
        document.getElementById("vimeoinput").value !== ""
        ? (topicToEdit.topic_video = videoUri.split("/")[2])
        : (topicToEdit.topic_video = TopicVideoId);
    }

    // console.log(topicToEdit, "Topic To Edit");
    // props.updateTopic(topic._id, topicToEdit);
    console.log("Topic to Edit", topicToEdit);
    updateTopicBackend(topic._id, topicToEdit, topic, dispatch, history,);

    //remove null from backedn and shift to redux
    // window.location.reload();
  };
  let Vimeo = require("vimeo").Vimeo;

  let client = new Vimeo(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_CLIENT_SECRET,
    process.env.REACT_APP_ACCESS_TOKEN
  );

  return (
    <>
      {!loading ? (
        <div className="edit-topic content">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <i
                          class="fa fa-arrow-circle-left"
                          style={{ fontSize: "36px", cursor: "pointer" }}
                          onClick={() => {
                            history.goBack();
                          }}
                        ></i>{" "}
                        <h4 style={{ marginLeft: "10px" }}>Edit Topic</h4>
                      </div>

                      <button
                        className="btn btn-danger"
                        onClick={handleEditTopic}
                      >
                        Save
                      </button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Card className="mt-3 pl-3 pr-3">
                    <h4>Video Information</h4>
                    <div style={{ marginTop: "1vw" }}>
                      <div
                        className="row"
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className="col-6">
                          <p>Topic Title <span style={{ color: "red" }} >*</span> </p>
                          <input
                            className="form-control"
                            type="text"
                            value={topicTitle}
                            onChange={(e) => settopicTitle(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <p>Select Plan <span style={{ color: "red" }} >*</span> </p>
                          <select name="" id="" value={isPaid} className="form-control"
                            onChange={(e) => { setIsPaid(e.target.value) }} >
                            <option value="false">Free</option>
                            <option value="true">Paid</option>
                          </select>
                        </div>
                        <div className="col-6 mt-3">
                          <p>Topic Icon</p>
                          <div style={{ display: "flex" }}>
                            <input
                              type="file"
                              name="TopicIcon"
                              accept="image/jpeg, image/png"
                              onChange={changeHandler}
                            />
                            {loadingImage ? (
                              <img src={loadingimg} alt="Loading..." />
                            ) : null}
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <p>Upload pdf <span style={{ color: "red", fontSize: "0.8rem", paddingLeft: "5px" }} >
                            ( File size must be less than 30mb )</span></p>
                          <div>
                            <div style={{ display: "flex" }}>
                              <input
                                type="file"
                                name="TopicPdf"
                                accept="application/pdf"
                                className="form-control"
                                onChange={changeHandler}
                              />
                              {loadingPdf ? (
                                <img src={loadingimg} alt="loading..." />
                              ) : null}
                            </div>
                            {pdfFile && pdfFile != "-" &&
                              <a href={pdfFile} target="_blank" rel="noopener noreferrer">Preview File</a>
                            }
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className="col-12 mt-3">
                          <p>Video Type <span style={{ color: "red" }} >*</span> </p>
                          <div class="dropdown">
                            <a
                              class="btn btn-secondary dropdown-toggle"
                              role="button"
                              id="dropdownMenuLinkGrade"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {vedio_type}
                            </a>

                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLinkGrade"
                            >
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setvedio_type("youtube");
                                }}
                              >
                                youtube
                              </a>
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setvedio_type("vimeo");
                                }}
                              >
                                vimeo
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          {vedio_type === "vimeo" ? (
                            <>
                              <input
                                type="file"
                                id="vimeoinput"
                                onChange={videoHandler}
                              />
                              <button
                                className="btn btn-priamry"
                                onClick={() => {
                                  uploadVideo();
                                }}
                              >
                                Upload Vimeo
                              </button>
                            </>
                          ) : (
                            <>
                              <p>Video ID</p>
                              <input
                                className="form-control"
                                type="text"
                                required
                                name="video_id"
                                value={TopicVideoId}
                                onChange={(e) => {
                                  setTopicVideoId(e.target.value);
                                }}
                              />
                            </>
                          )}
                          <br />
                          {videoPer !== "" ? (
                            <p>Uploading Progress ... {videoPer}%</p>
                          ) : vedio_type === "vimeo" ? (
                            <p>
                              Waiting for video to upload{" "}
                              <p style={{ color: "red" }}>
                                (Press Upload Vimeo)
                              </p>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className="mt-3 pl-3 pr-3">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "3rem"
                        // alignItems: "center",
                      }}
                    >
                      <button
                        className="btn btn-priamry"
                        style={{ alignSelf: "flex-end" }}
                        onClick={() => {
                          setismath(!ismath);
                        }}
                      >
                        {ismath ? "Text" : "Math"}
                      </button>
                      {!ismath ? null : (
                        <code style={{ marginLeft: "5px", fontSize: "15px" }}>
                          Click on top left corner to insert math equations
                        </code>
                      )}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {ismath ? (
                          // <EquationEditorHolder
                          //   setdescription={setdescription}
                          //   description={description}
                          // />
                          <>
                            <div
                              style={{
                                backgroundColor: "white",
                                // width: "60vw",
                                height: "20vw",
                                border: "2px solid black",
                              }}
                            >
                              <EquationEditor
                                value={equation}
                                onChange={setEquation}
                                autoCommands="pi theta sqrt sum prod alpha beta gamma rho"
                                autoOperatorNames="sin cos tan"
                              />
                            </div>
                            <div>
                              {" "}
                              <h4>Guide</h4>
                              <p>
                                <code>^</code> → Superscript, <code>_</code> →
                                Subscript, <code>int</code> → Integral,{" "}
                                <code>sum</code> → Summation, <code>prod</code>{" "}
                                → Product, <code>sqrt</code> → Square root,{" "}
                                <code>bar</code> → Bar over letter,{" "}
                                <code>alpha, beta, ... omega</code> → Small
                                Greek letter,{" "}
                                <code>Alpha, Beta, ... Omega</code> → Capital
                                Greek letter
                              </p>
                            </div>
                            <div className="add-description-btn">
                              <button
                                className="btn btn-priamry "
                                style={{ alignSelf: "flex-end" }}
                                onClick={() => {
                                  setdescription((prevItems) => [
                                    ...prevItems,
                                    {
                                      topic_type: "math",
                                      description: equation,
                                    },
                                  ]);
                                  setEquation("");
                                }}
                              >
                                Add to Description
                              </button>
                              <button
                                className="btn btn-danger "
                                onClick={() => {
                                  updateInternally(
                                    currentIndex,
                                    description,
                                    setdescription,
                                    value,
                                    equation
                                  );
                                }}
                              >
                                Update to Existing Description
                              </button>
                            </div>
                          </>
                        ) : (
                          // <TextEditor
                          //   setdescription={setdescription}
                          //   description={currentlyEditing}
                          // />

                          <div>
                            <ReactQuill
                              lang="en"
                              theme="snow"
                              value={value}
                              modules={modules}
                              onChange={setValue}
                              style={{
                                height: "30vw",
                                // width: "60vw",
                                direction: "rtl",
                                textAlign: "right",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>


                    <div className="add-description-btn">
                      {!ismath ? (
                        <button
                          className="btn btn-priamry"
                          style={{ alignSelf: "flex-end" }}
                          onClick={() => {
                            setdescription((prevItems) => [
                              ...prevItems,
                              {
                                topic_type: "text",
                                // description: draftToHtml(
                                //   convertToRaw(editorState.getCurrentContent())
                                // ),
                                description: value,
                              },
                            ]);
                            setValue("");
                            // seteditorState(EditorState.createEmpty());
                          }}
                        >
                          Add to Description
                        </button>
                      ) : null}
                      {!ismath ? (
                        <button
                          className="btn btn-danger"
                          style={{ alignSelf: "flex-end" }}
                          onClick={() => {
                            updateInternally(
                              currentIndex,
                              description,
                              setdescription,
                              value,
                              equation
                            );
                          }}
                        >
                          Update to Existing Description
                        </button>
                      ) : null}

                    </div>
                  </Card>
                  <Card className="mt-3 pl-3 pr-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h4>Description</h4>
                      <i
                        class="fa fa-retweet"
                        aria-hidden="true"
                        style={{
                          fontSize: "20px",
                          color: "green",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          settest("hi");
                          setEquation("");
                        }}
                      ></i>
                    </div>
                    {description.map((des, index) => {
                      return (
                        <>
                          {des.topic_type == "text" ? (
                            <div className="edit_description">
                              <div className="edit_description-header">
                                <div className="row">
                                  <div className="col-md-6">
                                    <h1> Description {index + 1} </h1>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      className="btn btn-priamry"
                                      onClick={() => {
                                        setValue(des.description);
                                        setcurrentIndex(index);
                                        setismath(false);
                                      }}
                                    >
                                      Edit
                                    </button>

                                  </div>
                                </div>
                              </div>
                              <div className="edit_description-container">
                                <h1>{parse(des.description)}</h1>
                              </div>
                            </div>
                          ) : (
                            <div className="edit_description">
                              <div className="edit_description-header">
                                <div className="row">
                                  <div className="col-md-6">
                                    <h1> Description {index + 1} </h1>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      className="btn btn-priamry"
                                      onClick={() => {
                                        setEquation(des.description);
                                        setcurrentIndex(index);
                                        setismath(true);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="edit_description-container">
                                <h1>{parse(des.description)}</h1>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default EditTopic;
