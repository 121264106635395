import backend from '../api';

export const createBundleDate = async (label, expiryTime) =>
  await backend({
    method: 'POST',
    url: '/bundledate',
    data: { label, expiryTime },
  });

export const getBundleDate = async () =>
  await backend({
    method: 'GET',
    url: '/bundledate',
  });

export const updateBundleDate = async (id, label, expiryTime) =>
  await backend({
    method: 'PUT',
    url: `/bundledate/${id}`,
    data: { label, expiryTime },
  });
