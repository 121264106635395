import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import ModalContainer from "components/Common/ModalContainer";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import { saveCommentAnswer } from "Utils/comments_Utils";
import { fetchAllCommentsPagination } from "Utils/comments_Utils";
import { deleteComments } from "Utils/comments_Utils";
import { fetchAllComments } from "Utils/comments_Utils";
import { getTeachersSubjects } from "../../Utils/subjects_Utils";
import CommentsList from "./CommentsList";
import FilterComments from "./FilterComments";
import loaderImg from "../../assets/img/loaderimg.svg";

function CommentsWrapper() {
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [unChangesComments, setUnChangesComments] = useState([]);
  const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
  const [selectedCommentID, setSelectedCommentID] = useState("");
  const [answer, setAnswer] = useState("");
  const [subjectIDS, setSubjectIDS] = useState([]);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [pageLimit, setPageLimit] = useState(5);
  const [isMoreComments, setIsMoreComments] = useState(false);
  const [totalComments, setTotalComments] = useState(0);

  const fetchTeacherSubjectComments = async () => {
    setLoading(true);
    try {
      let assignSubjectsIDs = null;

      const resp = await fetchAllComments();
      const allComments = resp.data.data;

      if (subjectIDS?.length > 0) {
        assignSubjectsIDs = [...subjectIDS];
      } else {
        const subjectList = await getTeachersSubjects(userData?._id);
        setSubjectIDS(subjectList?.data?.data[0].subjectsList);
        assignSubjectsIDs = subjectList?.data?.data[0].subjectsList;
      }

      const filterComments =
        allComments?.length &&
        allComments.filter(
          (x) =>
            assignSubjectsIDs?.length &&
            assignSubjectsIDs.includes(x.subject?._id)
        );

      setCommentsList(filterComments);
      setUnChangesComments(filterComments);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchComment = async (limit, startIndex) => {
    setLoading(true);
    try {
      const resp = await fetchAllCommentsPagination(limit, startIndex);
      const updateCommentsList = [...unChangesComments, ...resp.data.data.resp];

      setIsMoreComments(resp.data.data.next);
      setCommentsList(updateCommentsList);
      setUnChangesComments(updateCommentsList);

      if (resp.data.data.total !== totalComments)
        setTotalComments(resp.data.data.total);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreComments = async (limit, startIndex) => {
    setIsLoadMore(true);
    try {
      const resp = await fetchAllCommentsPagination(limit, startIndex);
      const updateCommentsList = [...unChangesComments, ...resp.data.data.resp];

      setIsMoreComments(resp.data.data.next);
      setCommentsList(updateCommentsList);
      setUnChangesComments(updateCommentsList);

      if (resp.data.data.total !== totalComments)
        setTotalComments(resp.data.data.total);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setIsLoadMore(false);
    }
  };

  const openAnswerModal = () => {
    const elem = document.getElementById("answerArea");
    elem.focus();
    setIsOpenAnswerModal(true);
  };
  const openFilterModal = () => setIsOpenFilterModal(true);

  const closeAnswerModal = () => setIsOpenAnswerModal(false);
  const closeFilterModal = () => setIsOpenFilterModal(false);

  const replyForCommentHandler = (id) => {
    setSelectedCommentID(id);
    setAnswer("");
    openAnswerModal();
  };

  const submitAnswer = async (e) => {
    e.preventDefault();
    closeAnswerModal();

    // setLoading(true);
    try {
      const data = { answer };

      if (["teacher", "admin"].includes(userData?.role)) {
        data.user = userData?._id;
        data.userName = userData?.user_name
          ? userData?.user_name
          : `${userData?.first_name} ${userData?.last_name}`;
      } else {
        data.superAdmin = userData?._id;
        data.userName = userData?.email.split("@")[0] || "";
      }

      const resp = await saveCommentAnswer(selectedCommentID, data);

      const { answers } = resp.data.data;

      const updatedCommentsList =
        commentsList?.length &&
        commentsList.map((x) => {
          if (String(x?._id) !== String(selectedCommentID)) return x;

          x.answers = answers;
          return x;
        });

      setCommentsList(updatedCommentsList);
      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setAnswer("");
      setSelectedCommentID("");
      // fetchInitialData();
    }
  };

  const afterFilterComments = (searchBy, searchID) => {
    setLoading(true);
    let filteredComments = [];

    switch (searchBy) {
      case "grade":
        filteredComments = unChangesComments.filter(
          (e) => e?.grade?._id === searchID
        );
        break;

      case "subject":
        filteredComments = unChangesComments.filter(
          (e) => e?.subject?._id === searchID
        );
        break;

      case "chapter":
        filteredComments = unChangesComments.filter(
          (e) => e?.chapter?._id === searchID
        );
        break;

      case "topic":
        filteredComments = unChangesComments.filter(
          (e) => e?.topic?._id === searchID
        );
        break;

      default:
        filteredComments = [...unChangesComments];
    }
    setCommentsList(filteredComments);
    setLoading(false);
  };

  const filterCommentsHandler = (filteredData) => {
    closeFilterModal();
    const searchBy = filteredData?.filterBy?.value;
    const searchID = filteredData?.selectedFilter?.value;

    if (!searchID) {
      setCommentsList(unChangesComments);
      return;
    }
    afterFilterComments(searchBy, searchID);
  };

  const loadMoreComments = () => {
    fetchMoreComments(pageLimit, commentsList?.length);
    setIsLoadMore(false);
  };

  const deleteCommentHandler = async (id, isActive) => {
    if (!id) return;

    // setLoading(true);
    try {
      const resp = await deleteComments(id, { isActive: !isActive });

      const indexRemove =
        commentsList?.length &&
        commentsList.findIndex((a) => String(a?._id) === id);

      const indexedRemove =
        unChangesComments?.length &&
        unChangesComments.findIndex((a) => String(a?._id) === id);

      if (
        indexRemove >= 0 &&
        indexRemove <= commentsList.length &&
        commentsList.length
      ) {
        const list = commentsList.map((e) => {
          if (e?._id !== id) return e;

          e.isActive = !isActive;
          return e;
        });
        setCommentsList(list);
      }

      if (
        indexedRemove >= 0 &&
        indexedRemove <= unChangesComments.length &&
        unChangesComments.length
      ) {
        const list = unChangesComments.map((e) => {
          if (e?._id !== id) return e;

          e.isActive = !isActive;
          return e;
        });
        setCommentsList(list);

        setUnChangesComments(list);
      }

      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      // setLoading(false);
    }
  };

  const fetchInitialData = async () =>
    userData?.role === "teacher"
      ? fetchTeacherSubjectComments()
      : fetchComment(pageLimit, unChangesComments?.length);

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <div className="comments content">
      <Row>
        <Col md="12">
          <Card>
            <CardTitle>
              <Header title="Comments" />
            </CardTitle>
            <CardBody>
              <div className="filter">
                <button onClick={openFilterModal}>
                  <i className="fa fa-filter"></i> Filter
                </button>
              </div>
              <CommentsList
                commentsList={commentsList}
                replyForCommentHandler={useCallback(replyForCommentHandler, [])}
                loading={loading}
                deleteCommentHandler={deleteCommentHandler}
              />
              {userData?.role !== "teacher" && (
                <>
                  {isLoadMore ? (
                    <img src={loaderImg} alt="loader" />
                  ) : (
                    <div className="load-more">
                      {isMoreComments ? (
                        <button onClick={loadMoreComments}>Load more</button>
                      ) : (
                        <button>No more comments</button>
                      )}

                      <div className="page_limit">
                        <select
                          id="pageLimit"
                          value={pageLimit}
                          onChange={(e) => setPageLimit(Number(e.target.value))}
                        >
                          <option value="5">5 rows</option>
                          <option value="10">10 rows</option>
                          <option value="20">20 rows</option>
                          <option value="50">50 rows</option>
                        </select>
                        <i className="fa fa-caret-down"></i>
                      </div>
                      {/* {renderTotalComments()} */}

                      <h1>
                        {commentsList?.length + " "} of {totalComments}
                      </h1>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ModalContainer
        isOpen={isOpenAnswerModal}
        onConfirmClick={closeAnswerModal}
        modalWidth="500"
        animateEffect="fadeInUp"
        heading="Answer"
      >
        <form className="answer_form my-3" onSubmit={submitAnswer}>
          <p>Answer</p>
          <textarea
            id="answerArea"
            rows="5"
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Enter Answer"
            value={answer}
            style={{ width: "100%" }}
            required
            dir="auto"
          />

          <div className="text-right mt-2">
            <button type="submit" className="btn btn-secondary">
              Save
            </button>
          </div>
        </form>
      </ModalContainer>

      <FilterComments
        isOpenFilterModal={isOpenFilterModal}
        handleClose={useCallback(closeFilterModal, [])}
        filterCommentsHandler={filterCommentsHandler}
      />
    </div>
  );
}

export default CommentsWrapper;
