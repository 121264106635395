import React from "react";
import PropTypes from "prop-types";

// reactstrap components
import { CardHeader, CardTitle } from "reactstrap";
import { useHistory } from "react-router";

function Header({ title, location }) {
  const history = useHistory();

  return (
    <CardHeader>
      <CardTitle className="bundle-rate__header">
        <i
          class="icon fa fa-arrow-circle-left mx-2"
          onClick={() => {
            if (location) {
              history.push(location);
              return;
            }

            history.goBack();
          }}
        />
        <h4> {title} </h4>
      </CardTitle>
    </CardHeader>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
};

Header.defaultProps = {
  location: "",
};

export default React.memo(Header);
