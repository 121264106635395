import React from 'react';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import loader from '../../assets/img/loader.svg';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBundleDate,
  getBundleDate,
  updateBundleDate,
} from 'Utils/bundle_rate';
import { setBundleTime } from 'Redux/features/Admin/AdminSlice';
import { selectBundleTime } from 'Redux/features/Admin/AdminSlice';
import Header from 'components/Common/Header';
function BundleRate() {
  const history = useHistory();
  const dispatch = useDispatch();
  const bundleTime = useSelector(selectBundleTime);

  const [label, setLabel] = useState('');
  const [expiryTime, setExpiryTime] = useState(0);
  const [expiryDate_id, setExpiryDateID] = useState(null);
  const [loading, setLoading] = useState(false);

  // const loading = useSelector(selectLoading);

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'label':
        setLabel(e.target.value);
        return;
      case 'expiryTime':
        setExpiryTime(e.target.value);
        return;
    }

    // setExpiryDate(e.target.value);
    // console.log(e.target.value);
  };

  const _submit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (!expiryDate_id) {
        const resp = await createBundleDate(label, expiryTime);

        const { _id } = resp.data.data;
        setExpiryDateID(_id);

        dispatch(setBundleTime({ _id, expiryTime, label }));
        toast.success(resp.data.message);
        return;
      }

      const resp = await updateBundleDate(expiryDate_id, label, expiryTime);
      const { _id } = resp.data.data;
      dispatch(setBundleTime({ _id, expiryTime, label }));
      toast.success(resp.data.message);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const renderForm = () => (
    <form className="bundle-rate__form" onSubmit={_submit}>
      <div className="date-select">
        <p>Select expiry time</p>
        <select name="label" value={label} onChange={handleChange} required>
          <option value="">Select expiry</option>
          <option value="years">Years</option>
          <option value="months">Months</option>
          <option value="days">Days</option>
        </select>

        <p>Expiry {label ? label : 'time'}</p>
        <input
          type="Number"
          name="expiryTime"
          placeholder="Select time"
          required
          value={expiryTime}
          onChange={handleChange}
        />

        <button className="btn btn-warning" type="submit">
          Change
        </button>
      </div>
    </form>
  );

  const renderLoader = () => (
    <CardHeader>
      <CardTitle className="loader">
        <img src={loader} alt="Loading...." />
      </CardTitle>
    </CardHeader>
  );

  useEffect(() => {
    (async () => {
      try {
        if (bundleTime !== null) {
          setExpiryTime(bundleTime.expiryTime);
          setLabel(bundleTime.label);
          setExpiryDateID(bundleTime._id);
        } else {
          setLoading(true);
          const resp = await getBundleDate();
          if (!resp.data.data.length) return;

          const { _id, expiryTime, label } = resp.data.data[0];
          setExpiryTime(Number(expiryTime));

          setLabel(label);
          setExpiryDateID(_id);
          dispatch(setBundleTime({ _id, expiryTime, label }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="content bundle-rate">
      <Row>
        <Col md="12">
          <Card>
            {loading ? (
              <>{renderLoader()}</>
            ) : (
              <>
                <Header title="Set Bundle Time" />
                <CardBody>{renderForm()}</CardBody>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BundleRate;
