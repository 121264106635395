import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import ModalContainer from "components/Common/ModalContainer";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import { saveCommentAnswer } from "Utils/comments_Utils";
import { fetchAllComments } from "Utils/comments_Utils";
import { getTeachersSubjects } from "Utils/subjects_Utils";
import userAvatar from "../../assets/img/anime3.png";
import { FaCheckCircle, FaBan } from "react-icons/fa";
import { updateCommentAnswerPin } from "Utils/comments_Utils";
import { deleteCommentAnswer } from "Utils/comments_Utils";
import { updateCommentAnswer } from "Utils/comments_Utils";
import { updateCommentQuestion } from "Utils/comments_Utils";
import { deleteComments } from "Utils/comments_Utils";

const CommentAnswers = () => {
  const [loading, setLoading] = useState(false);
  const [commentDetail, setCommentDetail] = useState({});
  const [subjectIDS, setSubjectIDS] = useState([]);
  const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
  const [selectedCommentID, setSelectedCommentID] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [answer, setAnswer] = useState("");
  const [isEdit, setIsEdit] = useState("");

  const param = useParams();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const { commentID } = param;

  ///////////////////////////////////////////////// LOGIC CODE /////////////////////////////////////////////////

  const sortAnswers = (answers) =>
    answers?.length &&
    answers.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

  const fetchTeacherSubjectComments = async () => {
    setLoading(true);
    try {
      let assignSubjectsIDs = null;

      const resp = await fetchAllComments(commentID);
      const allComments = resp.data.data[0];

      if (subjectIDS?.length > 0) {
        assignSubjectsIDs = [...subjectIDS];
      } else {
        const subjectList = await getTeachersSubjects(userData?._id);
        setSubjectIDS(subjectList?.data?.data[0].subjectsList);
        assignSubjectsIDs = subjectList?.data?.data[0].subjectsList;
      }

      const filterComments =
        assignSubjectsIDs?.length &&
        assignSubjectsIDs.includes(allComments?.subject?._id);

      if (filterComments) setCommentDetail(allComments);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchComment = async () => {
    setLoading(true);
    try {
      const resp = await fetchAllComments(commentID);
      setCommentDetail(resp.data.data[0]);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const openAnswerModal = () => setIsOpenAnswerModal(true);

  const closeAnswerModal = () => {
    setIsEdit("");
    setIsOpenAnswerModal(false);
  };

  const fetchInitialData = async () =>
    userData?.role === "teacher"
      ? fetchTeacherSubjectComments()
      : fetchComment();

  const replyCommentAnswer = (id) => {
    setSelectedCommentID(id);
    setAnswer("");
    openAnswerModal();
  };

  const postCommentAnswer = async () => {
    closeAnswerModal();
    setLoading(true);
    try {
      const data = { answer };

      if (["teacher", "admin"].includes(userData?.role)) {
        data.user = userData?._id;
        data.userName = userData?.user_name
          ? userData?.user_name
          : `${userData?.first_name} ${userData?.last_name}`;
      } else {
        data.superAdmin = userData?._id;
        data.userName = userData?.email.split("@")[0] || "";
      }

      const resp = await saveCommentAnswer(selectedCommentID, data);
      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setAnswer("");
      setSelectedCommentID("");
      fetchInitialData();
      setLoading(false);
    }
  };

  const editCommentAnswer = async () => {
    closeAnswerModal();

    try {
      const resp = await updateCommentAnswer(
        commentDetail?._id,
        selectedAnswer?._id,
        { answer: answer }
      );

      const { answers, ...rest } = commentDetail;
      const list =
        answers?.length &&
        answers.map((x) => {
          if (x?._id !== selectedAnswer?._id) return x;

          x.answer = answer;
          return x;
        });
      rest.answers = list;
      setCommentDetail(rest);

      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setAnswer("");
      setSelectedAnswer("");
      setLoading(false);
    }
  };

  const editCommentQuestion = async () => {
    closeAnswerModal();

    try {
      const resp = await updateCommentQuestion(commentDetail?._id, {
        question: answer,
      });

      const { ...rest } = commentDetail;
      rest.question = answer;
      setCommentDetail(rest);

      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setAnswer("");
      setLoading(false);
    }
  };

  const submitAnswer = async (e) => {
    e.preventDefault();

    if (isEdit === "EDIT_ANSWER") editCommentAnswer();
    else if (isEdit === "EDIT_QUESTION") editCommentQuestion();
    else postCommentAnswer();
  };

  const updateCommentData = async (
    commentID,
    answerID,
    existingAnswerPinTrueID
  ) => {
    try {
      const { answers: commentAnswers, ...rest } = commentDetail;

      if (existingAnswerPinTrueID) {
        const [resp, res] = await Promise.all([
          updateCommentAnswerPin(commentID, answerID, { isPin: true }),
          updateCommentAnswerPin(commentID, existingAnswerPinTrueID, {
            isPin: false,
          }),
        ]);
        toast.success(resp?.data?.message);
      } else {
        const resp = await updateCommentAnswerPin(commentID, answerID, {
          isPin: true,
        });
        toast.success(resp?.data?.message);
      }

      const list =
        commentAnswers?.length &&
        commentAnswers.map((x) => {
          if (String(x?._id) === String(answerID)) x.isPin = true;
          else if (String(x?._id) === String(existingAnswerPinTrueID))
            x.isPin = false;
          return x;
        });

      rest.answers = list;
      setCommentDetail(rest);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
  };

  const pinAnswerHandler = (answerObj) => {
    const existingAnswerPinTrueID =
      commentDetail?.answers?.length &&
      commentDetail.answers.find((x) => x.isPin);

    updateCommentData(
      commentDetail?._id,
      answerObj?._id,
      existingAnswerPinTrueID?._id || ""
    );
  };

  const isShowAction = (e) => {
    if (!userData?.role) {
      return true;
    } else if (userData?.role === "admin") {
      return Object.keys(e).includes("superAdmin") ? false : true;
    } else if (userData?.role === "teacher") {
      return e?.user?.role === "teacher" || e?.user?.role === "student"
        ? true
        : false;
    }
  };

  const deleteHandler = async (e) => {
    try {
      const resp = await deleteCommentAnswer(commentDetail?._id, e?._id, {
        isActive: !e?.isActive,
      });

      const { answers, ...rest } = commentDetail;
      const list =
        answers?.length &&
        answers.map((x) => {
          if (x?._id !== e?._id) return x;

          x.isActive = !e?.isActive;
          return x;
        });
      rest.answers = list;
      setCommentDetail(rest);
      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
  };

  const editAnswerHandler = (e) => {
    setAnswer(e?.answer);
    setIsEdit("EDIT_ANSWER");
    setSelectedAnswer(e);
    openAnswerModal();
  };

  const deleteCommentStatus = async () => {
    try {
      const resp = await deleteComments(commentDetail?._id, {
        isActive: !commentDetail?.isActive,
      });

      const { ...rest } = commentDetail;
      rest.isActive = !commentDetail?.isActive;

      setCommentDetail(rest);
      toast.success(resp?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  ///////////////////////////////////////////////// JSX CODE /////////////////////////////////////////////////
  const renderGradeSubjectTopicRow = (label, value) => (
    <p>
      {label}
      <span>{value}</span>
    </p>
  );

  const renderContent = (commentObj) => (
    <div className="comment-answers_container">
      <div className="details">
        <div className="profileImg">
          <img
            src={commentObj?.user?.profile_image || userAvatar}
            alt="profile"
          />
        </div>

        <div className="info">
          {renderGradeSubjectTopicRow("Name: ", commentObj?.user?.user_name)}
          {renderGradeSubjectTopicRow("Grade: ", commentObj?.grade?.grade)}
          {renderGradeSubjectTopicRow(
            "Subject: ",
            commentObj?.subject?.Subject_name
          )}
          {renderGradeSubjectTopicRow(
            "Chapter: ",
            commentObj?.chapter?.chapter_name
          )}
          {renderGradeSubjectTopicRow(
            "Topic: ",
            commentObj?.topic?.topic_title
          )}
          {renderGradeSubjectTopicRow(
            "Date: ",
            commentObj.createdAt
              ? format(new Date(commentObj.createdAt), "PPpp")
              : "N/A"
          )}
          <div className="action">
            <button
              className="blue"
              onClick={() => {
                setAnswer(commentObj?.question);
                setIsEdit("EDIT_QUESTION");
                openAnswerModal(true);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              className={commentObj?.isActive ? "green" : "red"}
              onClick={() => {
                deleteCommentStatus();
              }}
            >
              <i
                className={commentObj?.isActive ? "fa fa-trash" : "fa fa-ban"}
              ></i>
            </button>
          </div>
        </div>
      </div>

      <p>{commentObj?.user?.role}</p>

      <div dir="auto" className="question-reply">
        <h1>{commentObj?.question}</h1>
        <button onClick={() => replyCommentAnswer(commentObj._id)}>
          Answer
        </button>
      </div>
    </div>
  );

  const renderAnswers = (commentObj) => (
    <div className="comment-answers_list">
      {commentObj?.answers?.length
        ? sortAnswers(commentObj?.answers).map((x, i) => (
            <div className={x?.isPin ? "answer_card bg_light" : "answer_card"}>
              {renderLeftPart(x?.user?.profile_image || userAvatar)}
              {renderRightPart(x)}
            </div>
          ))
        : null}
    </div>
  );

  const renderLeftPart = (image) => (
    <div className="left">
      <div className="user_img">
        <img src={image} alt="avatar" />
      </div>
    </div>
  );

  const renderAction = (e) => (
    <div className="actions">
      {e?.isActive && e?.isPin && (
        <button className="edit_btn only" onClick={() => editAnswerHandler(e)}>
          <i className="fa fa-edit" aria-hidden="true"></i>
        </button>
      )}
      {e?.isActive && !e?.isPin && (
        <>
          <button className="edit_btn" onClick={() => editAnswerHandler(e)}>
            <i className="fa fa-edit" aria-hidden="true"></i>
          </button>
          <button className="delete_btn" onClick={() => deleteHandler(e)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </>
      )}
      {!e?.isActive && (
        <button className="revert_btn" onClick={() => deleteHandler(e)}>
          <i class="fa fa-ban" aria-hidden="true"></i>
        </button>
      )}
    </div>
  );

  const renderRightPart = (x) => (
    <div className="right">
      {renderFirstRow(x)}
      {renderSecondRow(x)}
      {isShowAction(x) && renderAction(x)}
    </div>
  );

  const setClassName = (x) =>
    Object.keys(x).includes("superAdmin")
      ? "label orange"
      : x?.user?.role === "student"
      ? "label blue"
      : x?.user?.role === "admin"
      ? "label red"
      : "label purple";

  const renderFirstRow = (x) => (
    <div className="firstRow">
      <p className={setClassName(x)}>
        {Object.keys(x).includes("superAdmin") ? "superadmin" : x?.user?.role}
      </p>

      <h1>
        Answer by:{" "}
        <span>
          {Object.keys(x).includes("superAdmin")
            ? x?.superAdmin?.email.split("@")[0]
            : x?.user?.user_name
            ? x?.user?.user_name
            : `${x?.user?.first_name} ${x?.user?.last_name}`}
        </span>
      </h1>

      <h1>
        Date:{" "}
        <span>
          {x.createdAt ? format(new Date(x.createdAt), "PPpp") : "N/A"}
        </span>
      </h1>

      {x?.isActive ? (
        x?.isPin ? (
          <FaCheckCircle className="icon check" />
        ) : (
          x?.user?.role !== "student" && (
            <button onClick={() => pinAnswerHandler(x)}>Pin this answer</button>
          )
        )
      ) : null}
    </div>
  );

  const renderSecondRow = (x) => (
    <div className="secondRow">
      <h1>Answer</h1>
      <p dir="auto">{x.answer}</p>
    </div>
  );

  const answerModal = () => (
    <ModalContainer
      isOpen={isOpenAnswerModal}
      onConfirmClick={closeAnswerModal}
      modalWidth="500"
      animateEffect="fadeInUp"
      heading={
        isEdit === "EDIT_QUESTION"
          ? "Update Question"
          : isEdit === "EDIT_ANSWER"
          ? "Update Answer"
          : "Answer"
      }
    >
      <form className="answer_form my-3" onSubmit={submitAnswer}>
        <p>{isEdit === "EDIT_QUESTION" ? "Question" : "Answer"}</p>
        <textarea
          id="answerArea"
          rows="5"
          onChange={(e) => setAnswer(e.target.value)}
          value={answer}
          style={{ width: "100%" }}
          required
          dir="auto"
        />

        <div className="text-right mt-2">
          <button type="submit" className="btn btn-secondary">
            Save
          </button>
        </div>
      </form>
    </ModalContainer>
  );

  return (
    <div className="comment-answers content">
      <Row>
        <Col md="12">
          <Card>
            <CardTitle>
              <Header title="Comment Answers" />
            </CardTitle>
            {loading ? (
              <Loader />
            ) : commentDetail ? (
              <CardBody>
                {renderContent(commentDetail)}
                {renderAnswers(commentDetail)}
              </CardBody>
            ) : (
              <h1>No answers</h1>
            )}
          </Card>
        </Col>
      </Row>
      {answerModal()}
    </div>
  );
};

export default CommentAnswers;
