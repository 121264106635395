import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalContainer from "components/Common/ModalContainer";
import Select from "react-select";
import { getGradeForDropdown } from "Utils/grades_Utils";
import { getChapterForDropdown } from "Utils/chapterPreview_Utils";
import { getSubjectForDropdown } from "Utils/subjects_Utils";
import { getTopicForDropdown } from "Utils/topic_quiz_Utils";

const FilterComments = ({
  isOpenFilterModal,
  handleClose,
  filterCommentsHandler,
}) => {
  const filterType = [
    { value: "grade", label: "Grade" },
    { value: "subject", label: "Subject" },
    { value: "chapter", label: "Chapter" },
    { value: "topic", label: "Topic" },
  ];

  const [gradesList, setGradesList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [filterBy, setFilterBy] = useState({ value: "grade", label: "Grade" });
  const [selectedFilter, setSelectedFilter] = useState({});

  const fetchInitialDataForDropdowns = async () => {
    const [grades, chapters, subjects, topics] = await Promise.all([
      getGradeForDropdown(),
      getChapterForDropdown(),
      getSubjectForDropdown(),
      getTopicForDropdown(),
    ]);

    const gradeDropdown =
      grades?.data?.data?.length &&
      grades?.data?.data?.map((e) => {
        return { value: e?._id, label: e?.grade };
      });

    const subjectDropdown =
      subjects?.data?.data?.length &&
      subjects?.data?.data?.map((e) => {
        return {
          value: e?._id,
          label: `${e?.Subject_name} - ${e?.grade?.grade}`,
        };
      });

    const topicDropdown =
      topics?.data?.data?.length &&
      topics?.data?.data?.map((e) => {
        return {
          value: e?._id,
          label: `${e?.topic_title} - ${e?.chapter?.chapter_name} - ${e?.subject?.Subject_name} - ${e?.grade?.grade}`,
        };
      });

    const chapterDropdown =
      chapters?.data?.data?.length &&
      chapters?.data?.data?.map((e) => {
        return {
          value: e?._id,
          label: `${e?.chapter_name} - ${e?.subject?.Subject_name} - ${e?.grade?.grade}`,
        };
      });

    setGradesList(gradeDropdown);
    setChapterList(chapterDropdown);
    setSubjectList(subjectDropdown);
    setTopicList(topicDropdown);
  };

  const _submit = (e) => {
    e.preventDefault();
    filterCommentsHandler({ filterBy, selectedFilter });
  };

  const arrangeDropdown = (label) => {
    switch (label) {
      case "grade":
        return gradesList?.length ? gradesList : [];

      case "chapter":
        return chapterList?.length ? chapterList : [];

      case "subject":
        return subjectList?.length ? subjectList : [];

      case "topic":
        return topicList?.length ? topicList : [];

      default:
        return [];
    }
  };

  useEffect(() => {
    fetchInitialDataForDropdowns();
  }, []);

  const renderSelect = () => (
    <>
      <p>{filterBy?.label}</p>
      <Select
        onChange={(e) => setSelectedFilter(e)}
        value={selectedFilter}
        placeholder={`Select ${filterBy?.value || "filter"}`}
        className="react-options"
        options={arrangeDropdown(filterBy?.value)}
        isClearable
      />
    </>
  );

  return (
    <div className="filter-comments">
      <ModalContainer
        isOpen={isOpenFilterModal}
        onConfirmClick={handleClose}
        modalWidth="500"
        animateEffect="fadeInUp"
        heading="Filter Comments"
      >
        <form className="filter_form my-3" onSubmit={_submit}>
          <p>Filter by</p>
          <Select
            onChange={(e) => {
              if (e?.value === filterBy?.value) return;
              setFilterBy(e);
              setSelectedFilter({});
            }}
            value={filterBy}
            placeholder="Select filter by"
            className="react-options"
            options={filterType}
            isClearable
          />
          {renderSelect()}
          <div className="text-right mt-2">
            <button type="submit" className="btn btn-secondary">
              Filter
            </button>
          </div>
        </form>
      </ModalContainer>
    </div>
  );
};

FilterComments.propTypes = {
  isOpenFilterModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  filterCommentsHandler: PropTypes.func.isRequired,
};

export default React.memo(FilterComments);
