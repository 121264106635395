import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
};

const CourseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setcourse: (state, action) => {
      state.courses = action.payload;
    },
    addcourse: (state, action) => {
      state.courses.push(action.payload);
    },
    editcourse: (state, action) => {
      let index = state.courses.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.courses];

      updated[index] = action.payload;
      state.courses = updated;
    },
    editcourseStatus: (state, action) => {
      let index = state.courses.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.courses];

      updated[index].isActive = action.payload.isActive;
      state.courses = updated;
    },
  },
});

export const { setcourse, addcourse, editcourse, editcourseStatus } =
  CourseSlice.actions;
export const selectcourses = (state) => state.course.courses;
export default CourseSlice.reducer;
