import backend from "../api";

var userJourney = [
  {
    path: "userID",
    select: "user_name first_name last_name date_created",
  },
];

var liveSession = [
  {
    path: "liveSessions",
    populate: [
      {
        path: "grade",
        select: "grade uniCode -_id",
      },
      {
        path: "subject",
        select: "Subject_name uniCode -_id",
      },
    ],
    select: "sessionDate sessionTime sessionTitle sessionStatus date_created",
  },

  {
    path: "userID",
    select: "user_name first_name last_name",
  },
];

var userData = [
  {
    path: "userID",
    select:
      "user_name first_name last_name last_visit email backup_email user_phone phone_number platform date_created",
  },
];
const pop_course = encodeURI(JSON.stringify(userJourney));
const liveSessionPopulate = encodeURI(JSON.stringify(liveSession));
const userDataPopulate = encodeURI(JSON.stringify(userData));

export const getReportForUninstalls = async (startDate, endDate) =>
  await backend({
    method: "GET",
    url: `/reports/uninstalls?startDate=${startDate}&endDate=${endDate}`,
  });

export const getReportForUserJourney = async () =>
  await backend({
    method: "GET",
    url: `/journey/?populate=${pop_course}`,
  });

export const getReportsLiveSessions = async () =>
  await backend({
    method: "GET",
    url: `/journey/live/session?populate=${liveSessionPopulate}`,
  });

export const getReportsByScreenNames = async (screenName) =>
  await backend({
    method: "GET",
    url: `/journey/filter/${screenName}`,
  });

export const getStudentUninstall = async () =>
  await backend({
    method: "GET",
    url: `/journey/uninstall?populate=${userDataPopulate}`,
  });

export const getStudentsPlatform = async ({ startDate, endDate }) =>
  await backend({
    method: "GET",
    url: startDate
      ? `/user/platform?startDate=${startDate}&endDate=${endDate}`
      : `/user/platform`,
  });

export const getStudentsJourney = async (userID) =>
  await backend({
    method: "GET",
    url: `/journey/user/${userID}?populate=${userDataPopulate}`,
  });

export const getInstallStats = async (dateObj) =>
  await backend({
    method: "GET",
    url: dateObj
      ? `/journey/install/stats?startDate=${dateObj?.startDate}&endDate=${dateObj?.endDate}`
      : "/journey/install/stats",
  });
