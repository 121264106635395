import React, { useEffect } from "react";
import Header from "components/Common/Header";
import { Card, CardBody, Row, Col } from "reactstrap";
import Select from "react-select";
import Loader from "components/Common/Loader";
import { NotificationPanelController } from "./NotificationPanelController";

function NotificationPanel() {

  const {
    sendTo,
    title,
    body,
    filter,
    grades,
    subjects,
    sessionsList,
    sessions,
    selectedGrade,
    selectedSubject,
    selectedSession,
    loading,
    topics,
    isSending,
    setSelectedGrade,
    setSelectedSubject,
    setSelectedSession,
    setTitle,
    setBody,
    onChangeHandler,
    onSelectChangeHandler,
    onSelectSendToHandler,
    resetFields,
    _submit,
    fetchInitialData,
  } = NotificationPanelController();

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <div className="notification_panel content">
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Header title="Notification" />

                <form onSubmit={_submit} className="notification_panel-form">
                  <p>Send Notification to</p>
                  <select
                    name=""
                    id=""
                    className="form-control"
                    value={sendTo}
                    onChange={onSelectSendToHandler}
                    required
                  >
                    <option value="">Select</option>
                    <option value="grades">Send to Grade</option>
                    <option value="subjects">Send to Subjects</option>
                    <option value="students">Send to Students</option>
                    <option value="sessions">Live sessions</option>
                  </select>

                  {["grades", "subjects"].includes(sendTo) && (
                    <Select
                      onChange={
                        sendTo === "grades"
                          ? onSelectChangeHandler(setSelectedGrade)
                          : onSelectChangeHandler(setSelectedSubject)
                      }
                      value={
                        sendTo === "grades" ? selectedGrade : selectedSubject
                      }
                      placeholder={
                        sendTo === "grades" ? "Select Grade" : "Select Subjects"
                      }
                      className="react-options"
                      options={sendTo === "grades" ? grades : subjects}
                      isClearable
                      isRtl
                    />
                  )}

                  {sendTo === "sessions" && (
                    <Select
                      onChange={onSelectChangeHandler(setSelectedSession)}
                      value={selectedSession}
                      placeholder="Select sessions"
                      className="react-options"
                      options={sessions}
                      isClearable
                      isRtl
                    />
                  )}

                  {/* {sendTo !== "sessions" && (
                  <>
                    <p>Filters</p>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      value={filter}
                      onChange={onChangeHandler(setFilter)}
                    >
                      <option value="">Select filter</option>
                      <option value="7days">
                        Students who didn't open app from 7 days
                      </option>
                      <option value="14days">
                        Students who didn't open app from 14 days
                      </option>
                      <option value="28days">
                        Students who didn't open app from 28 days
                      </option>
                    </select>
                  </>
                )} */}

                  <p>Title</p>
                  <input
                    id="title"
                    dir="auto"
                    type="text"
                    className="form-control"
                    onChange={onChangeHandler(setTitle)}
                    value={title}
                    required
                  />

                  <p>Message</p>
                  <textarea
                    dir="auto"
                    className="form-control"
                    onChange={onChangeHandler(setBody)}
                    value={body}
                    required
                  />

                  <div>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      disabled={isSending}
                    >
                      {isSending ? <div className="loader"></div> : "Send"}
                    </button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default NotificationPanel;
