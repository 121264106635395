import axios from '../api';
import * as Yup from 'yup';
import { setloading } from 'Redux/features/Auth/AuthSlice';
import { toast } from 'react-toastify';
import { setauth } from 'Redux/features/Auth/AuthSlice';
import backend from '../api';
import { setUserData } from 'Redux/features/Auth/AuthSlice';
const { createBrowserHistory } = require('history');
const history = createBrowserHistory();
export const sendForgotPasswordEmail = (data, setloading) => {
  setloading(true);
  axios
    .put(`/user/teacher/password/forgot`, data)
    .then((req) => {
      toast.success(req.data.data.status);
      setloading(false);
      history.push('/admin/login');
    })
    .catch((err) => {
      axios
        .put(`/superadmin/password/forgot`, data)
        .then((req) => {
          setTimeout(() => {
            setloading(false);
          }, 1000);
          toast.success(req.data.data.status);

          history.push(`/admin/login`);
        })
        .catch((err) => {
          setloading(false);
          // toast.success(`User with Email '${data.email}' Not Found`);
          toast.error(err.response.data.message);
        });
    });
};

export async function ChangePasswordBackend(
  datapass,
  decoded,
  setValues,
  dispatch,
  history
) {
  let data = {
    curr_pass: datapass.current,
    new_pass: datapass.new,
  };
  if ('role' in decoded) {
    dispatch(setloading(true));
    // console.log("Not Super Admin");
    axios
      .put(
        // `http://localhost:5001/superadmin/${decoded._id}/password/change`,
        `/user/${decoded._id}/password/change`,
        data
      )
      .then((req) => {
        sessionStorage.setItem('token', req.data.token);
        localStorage.setItem('token', req.data.token);

        toast.success('Password Changed!');

        setTimeout(() => {
          dispatch(setloading(false));
          history.push('/admin/dashboard');
        }, 1000);
      })
      .catch((err) => {
        // toast.error(err);
        // console.log(err);
        toast.error(err.response.data.message);
        dispatch(setloading(false));
        // console.log(err.message);
        setValues({ current: '', confirm: '', new: '' });
      });
  } else {
    dispatch(setloading(true));
    // console.log("super Admin");
    await axios
      .put(
        `/superadmin/${decoded._id}/password/change`,
        // `http://localhost:5001/user/${decoded._id}/password/change`,
        data
      )
      .then((req) => {
        sessionStorage.setItem('token', req.data.token);
        localStorage.setItem('token', req.data.token);

        toast.success('Password Changed!');

        setTimeout(() => {
          dispatch(setloading(false));
          history.push('/admin/dashboard');
        }, 1000);
      })
      .catch((err) => {
        // toast.error(err);
        // console.log(err);
        toast.error(err.response.data.message);
        dispatch(setloading(false));
        // console.log(err.message);
      });
  }
}
export function checkUser(cred, dispatch, history) {
  dispatch(setloading(true));
  axios
    .post(`/user/login`, { email: cred.email, password: cred.password })
    .then((request) => {
      let data = request.data.data;
      let token = request.data.token;
      sessionStorage.setItem('user', data.email);
      sessionStorage.setItem('role', data.role);
      sessionStorage.setItem('_id', data._id);
      sessionStorage.setItem('token', token);
      dispatch(setauth(true));
      dispatch(setUserData(data));
      toast.success('Login Successfully');
      setTimeout(() => {
        dispatch(setloading(false));
        history.push(`/admin/dashboard`);
      }, 1000);
    })
    .catch((err) => {
      console.log('Error I am loking for ', err.response);
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);

      if (err.response.data.message === 'User Not Registered') {
        dispatch(setloading(true));
        axios
          .post(`/superadmin/login`, {
            email: cred.email,
            password: cred.password,
          })
          .then((request) => {
            let data = request.data.data;
            let token = request.data.token;
            sessionStorage.setItem('user', data.email);
            sessionStorage.setItem('token', token);
            dispatch(setauth(true));
            dispatch(setUserData(data));
            toast.success('Login Successfully');
            setTimeout(() => {
              dispatch(setloading(false));
              history.push(`/admin/dashboard`);
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              dispatch(setloading(false));
              try {
                toast.error(err.response.data.message);
              } catch (error) {
                toast.error(err.message);
              }
              history.push(`/admin/login`);
            }, 1000);
          });
      } else {
        console.log('Got here', err.response.data.message);
        dispatch(setloading(true));
        setTimeout(() => {
          dispatch(setloading(false));

          try {
            toast.error(
              err.response.data.message
                ? err.response.data.message
                : err.response.data
            );
          } catch (error) {
            toast.error(err.response.data.message);
          }
          history.push(`/admin/login`);
        }, 1000);
      }
    });
}
export const loginFormSchema = Yup.object({
  email: Yup.string().email('Email Address is Invalid').required('Required'),
  password: Yup.string().required('Required'),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email('Email Address is Invalid').required('Required'),
});

export const changePasswordSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Password must have at least 8 Characters ')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password must match')
    .required('Required'),
});

export const updateLastVisit = async (dateObj) =>
  await backend({
    method: 'PUT',
    url: '/user/last/visit',
    data: dateObj,
  });
