import Dashboard from "views/Dashboard.js";
import Teachers from "views/teachers";
import Admins from "views/admins";
import Grades from "views/Grades";
import Students from "views/Students";
import Subjects from "views/Subjects";
import Refrel from "./components/Refrel/Refrel";
import BundleRate from "components/BundleRate/BundleRate";
import Report from "components/Report/Report";
import ReportsWrapper from "components/Report/ReportsWrapper";
import SubjectWrapper from "components/Subjects/SubjectWrapper";
import StudentWrapper from "components/Student/StudentWrapper";
import TeacherWrapper from "components/Teacher/TeacherWrapper";
import Advertisement from "components/Advertisement/Advertisement";
import TeacherProfile from "components/Teacher/TeacherProfile";
import LiveSessionWrapper from "components/LiveSessions/LiveSessionWrapper";
import TeacherPaymentWrapper from "components/TeacherPayment/TeacherPaymentWrapper";
import UniCodeWrapper from "components/UniCodeStats/UniCodeWrapper";
import CommentsWrapper from "components/Comments/CommentsWrapper";
import NotificationPanel from "components/Notification/NotificationPanel";
import TopicWrapper from "components/Topic/TopicWrapper";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/admins",
    name: "Admins",
    rtlName: "طباعة",
    icon: "tim-icons icon-key-25",
    component: Admins,
    layout: "/admin",
  },
  {
    path: "/teachers",
    name: "Teachers",
    rtlName: "طباعة",
    icon: "tim-icons icon-single-02",
    // component: Teachers,
    component: TeacherWrapper,
    layout: "/admin",
  },
  {
    path: "/students",
    name: "Students",
    // component: Students,
    component: StudentWrapper,
    icon: "tim-icons icon-single-02",
    layout: "/admin",
  },
  {
    path: "/grades",
    name: "Grades",
    rtlName: "ار تي ال",
    icon: "fas fa-book-open",
    component: Grades,
    layout: "/admin",
  },
  {
    path: "/subjects",
    name: "Subjects",
    // component: Subjects,
    component: SubjectWrapper,
    icon: "fas fa-book",
    layout: "/admin",
  },
  {
    path: "/referral",
    name: "Referral Point",
    component: Refrel,
    icon: "fas fa-star",
    layout: "/admin",
  },
  {
    path: "/bundle",
    name: "Set Bundle Time",
    component: BundleRate,
    icon: "fas fa-clock",
    layout: "/admin",
  },
  {
    path: "/report",
    name: "Report",
    component: ReportsWrapper,
    // component: Report,
    icon: "fas fa-chart-bar",
    layout: "/admin",
  },
  {
    path: "/advertisement",
    name: "Advertisement",
    component: Advertisement,
    icon: "fas fa-ad",
    layout: "/admin",
  },
  {
    path: "/teacher/payment",
    name: "Payment",
    component: TeacherPaymentWrapper,
    icon: "fas fa-money-check-alt",
    layout: "/admin",
  },
  {
    path: "/live/session",
    name: "Live Session",
    component: LiveSessionWrapper,
    icon: "fas fa-laptop",
    layout: "/admin",
  },
  {
    path: "/uniCode/stats",
    name: "UniCode Stats",
    component: UniCodeWrapper,
    icon: "fas fa-code",
    layout: "/admin",
  },
  {
    path: "/comments",
    name: "Comments",
    component: CommentsWrapper,
    icon: "fas fa-comments",
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationPanel,
    icon: "fas fa-bell",
    layout: "/admin",
  },
  // {
  //   path: "/topic/listing",
  //   name: "Topics Listing",
  //   component: TopicWrapper,
  //   icon: "fas fa-bell",
  //   layout: "/admin",
  // },
];
var routes_admin = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/teachers",
    name: "Teachers",
    rtlName: "طباعة",
    icon: "tim-icons icon-single-02",
    component: Teachers,
    layout: "/admin",
  },
  {
    path: "/students",
    name: "Students",
    component: Students,
    icon: "tim-icons icon-single-02",
    layout: "/admin",
  },
  {
    path: "/grades",
    name: "Grades",
    rtlName: "ار تي ال",
    icon: "fas fa-book-open",
    component: Grades,
    layout: "/admin",
  },
  {
    path: "/subjects",
    name: "Subjects",
    // component: Subjects,
    component: SubjectWrapper,
    icon: "fas fa-book",
    layout: "/admin",
  },
  {
    path: "/referral",
    name: "Referral Point",
    component: Refrel,
    icon: "fas fa-star",
    layout: "/admin",
  },
  {
    path: "/teacher/payment",
    name: "Payment",
    component: TeacherPaymentWrapper,
    icon: "fas fa-money-check-alt",
    layout: "/admin",
  },
  {
    path: "/live/session",
    name: "Live Session",
    component: LiveSessionWrapper,
    icon: "fas fa-laptop",
    layout: "/admin",
  },
  {
    path: "/uniCode/stats",
    name: "UniCode Stats",
    component: UniCodeWrapper,
    icon: "fas fa-code",
    layout: "/admin",
  },
  {
    path: "/comments",
    name: "Comments",
    component: CommentsWrapper,
    icon: "fas fa-comments",
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationPanel,
    icon: "fas fa-bell",
    layout: "/admin",
  },
];
var routes_teacher = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/students",
    name: "Students",
    component: Students,
    icon: "tim-icons icon-single-02",
    layout: "/admin",
  },
  // {
  //   path: '/grades',
  //   name: 'Grades',
  //   rtlName: 'ار تي ال',
  //   icon: 'fas fa-book-open',
  //   component: Grades,
  //   layout: '/admin',
  // },
  {
    path: "/subjects",
    name: "Subjects",
    component: Subjects,
    icon: "fas fa-book",
    layout: "/admin",
  },
  {
    path: "/teacher/profile",
    name: "Profile",
    component: TeacherProfile,
    icon: "fas fa-user",
    layout: "/admin",
  },
  {
    path: "/live/session",
    name: "Live Session",
    component: LiveSessionWrapper,
    icon: "fas fa-laptop",
    layout: "/admin",
  },
  {
    path: "/comments",
    name: "Comments",
    component: CommentsWrapper,
    icon: "fas fa-comments",
    layout: "/admin",
  },
];
var routes_default = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
];
export { routes, routes_admin, routes_teacher, routes_default };
