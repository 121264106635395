import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ModalContainer from "components/Common/ModalContainer";
import { toast } from "react-toastify";
import { saveSubject, updateSubject } from "Utils/subjects_Utils";
import { uploadImage } from "Utils/image_uploader";
import { getGradeForDropdown } from "Utils/grades_Utils";
import subjectImg from "../../assets/img/subject.png";
import imgLoader from "../../assets/img/loaderimg.svg";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectGradesDropdown } from "Redux/features/Subject/SubjectSlice";
import { setGradeDropdown } from "Redux/features/Subject/SubjectSlice";

const SubjectForm = ({
  isOpenModal,
  handleOnClose,
  isEdit,
  subjectObj,
  isDelete,
  saveSubjectHandler,
}) => {
  const [gradeList, setGradeList] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [grade, setGrade] = useState();
  const [subjectName, setSubjectName] = useState(
    subjectObj?.Subject_name || ""
  );
  const [subjectIcon, setSubjectIcon] = useState(
    subjectObj?.subject_icon || ""
  );
  const [subjectPrice, setSubjectPrice] = useState(
    subjectObj?.subject_price || ""
  );

  const [uniCode, setUniCode] = useState(subjectObj?.uniCode || "");
  const [loadingImage, setLoadingImage] = useState(false);

  const dispatch = useDispatch();
  const gradeDropDownList = useSelector(selectGradesDropdown);

  const onChangeHandler = (setState) => (e) => setState(e.target.value);

  const onChangeImageHandler = async (e) => {
    const imageObj = e.target.files[0];

    if (!imageObj) {
      setSubjectIcon(subjectImg);
      return;
    }

    setLoadingImage(true);
    try {
      const resp = await uploadImage(imageObj);
      setSubjectIcon(resp);
    } catch (err) {
      console.log(err);
    }
    setLoadingImage(false);
  };

  const _submit = async (e) => {
    e.preventDefault();

    if (isEdit) {
      const subjectData = {
        _id: subjectObj._id,
        Subject_name: subjectName,
        subject_icon: subjectIcon ? subjectIcon : subjectObj.subject_icon,
        grade: grade.value,
        subject_price: subjectPrice,
        isActive: subjectObj.isActive,
        uniCode: uniCode,
      };

      saveSubjectHandler(subjectObj._id, subjectData, true);
      return;
    }

    const subjectData = {
      Subject_name: subjectName,
      subject_icon:
        subjectIcon ||
        "https://corrsy-image-bucket.s3.me-south-1.amazonaws.com/MicrosoftTeams-image%20%284%29-1640072810081.png",
      grade: grade.value,
      isActive: true,
      uniCode,
    };

    if (subjectPrice) subjectData.subject_price = subjectPrice;

    saveSubjectHandler("", subjectData, false);
  };

  useEffect(() => {
    if (isDelete) {
      setIsOpen(isOpenModal);
      return;
    }

    (async () => {
      try {
        let dropDownList = null;

        if (gradeDropDownList && gradeDropDownList.length > 0) {
          dropDownList = gradeDropDownList;
        } else {
          const resp = await getGradeForDropdown();
          dispatch(setGradeDropdown(resp?.data?.data));
          dropDownList = resp?.data?.data;
        }

        if (isEdit) {
          const gradeFind = dropDownList.find(
            (e) => e._id === subjectObj.grade
          );
          const grade = {
            value: gradeFind._id,
            // label: `${gradeFind.grade} ${gradeFind.course_plan}`,
            label: `${gradeFind.grade}`,
            course_plan: gradeFind.course_plan,
            grade: gradeFind.grade,
          };
          setGrade(grade);
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
      // setIsOpen(isOpenModal)
    })();

    setTimeout(() => {
      setIsOpen(isOpenModal);
    }, 10);
  }, []);

  const renderSubjectForm = () => (
    <form onSubmit={_submit} className="subject_form-wrapper">
      <p>
        Select Grade <span style={{ color: "red" }}>*</span>{" "}
      </p>
      <Select
        onChange={(e) => {
          setGrade(e);
        }}
        value={grade}
        placeholder="Select Grade"
        className="react-options"
        options={
          gradeDropDownList &&
          gradeDropDownList.length > 0 &&
          gradeDropDownList.map((e) => {
            return {
              value: e._id,
              // label: `${e.grade} ${e.course_plan}`,
              label: `${e.grade}`,
              course_plan: e.course_plan,
              grade: e.grade,
            };
          })
        }
        isDisabled={isEdit}
      />

      <p>
        Subject Name <span style={{ color: "red" }}>*</span>{" "}
      </p>
      <input
        type="text"
        value={subjectName}
        className="form-control"
        placeholder="Enter subject name"
        onChange={onChangeHandler(setSubjectName)}
        required
      />

      <div className="img-cover">
        {loadingImage ? (
          <img src={imgLoader} alt="subject-image" />
        ) : (
          <img src={subjectIcon || subjectImg} alt="subject-image" />
        )}
      </div>

      <p>Subject Icon</p>
      <input
        type="file"
        accept="image/jpeg, image/png"
        className="form-control"
        placeholder="Enter subject name"
        onChange={onChangeImageHandler}
      />

      <p>
        Subject Price <span style={{ color: "red" }}>*</span>
      </p>
      <input
        type="number"
        value={subjectPrice}
        className="form-control"
        min={0}
        placeholder="Enter subject price"
        onChange={onChangeHandler(setSubjectPrice)}
        required
      />

      <p>UniCode</p>
      <input
        type="text"
        value={uniCode}
        className="form-control"
        placeholder="Enter subject uniCode"
        onChange={onChangeHandler(setUniCode)}
        required
      />

      <button
        type="submit"
        className="btn btn-secondary submit-btn"
        disabled={!(grade && subjectName && subjectPrice)}
      >
        {isEdit ? "Update subject" : "Add subject"}
      </button>
    </form>
  );

  const renderDeleteForm = () => (
    <div className="subject_form-wrapper">
      <h4>
        {subjectObj.isActive
          ? "Are you sure you want to restrict this subject ?"
          : "Are you sure you want to change this subject status to active ?"}
      </h4>

      <div className="delete-btn">
        <button
          className="btn btn-warning"
          onClick={() => {
            const subjectData = subjectObj;
            subjectData.isActive = subjectData.isActive ? false : true;
            saveSubjectHandler(subjectData._id, subjectData, true);
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );

  console.log("uniCode ", uniCode);
  return (
    <ModalContainer
      isOpen={isOpen}
      onConfirmClick={handleOnClose}
      modalWidth="500"
      animateEffect="fadeInUp"
      heading={
        isDelete ? "Update Status" : isEdit ? "Update Subject" : "Add Subject"
      }
    >
      <div className="subject_form">
        {isDelete ? renderDeleteForm() : renderSubjectForm()}
      </div>
    </ModalContainer>
  );
};

SubjectForm.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  subjectObj: PropTypes.object.isRequired,
  isDelete: PropTypes.bool.isRequired,
  saveSubjectHandler: PropTypes.func.isRequired,
};

SubjectForm.defaultProps = {
  // subjectID: null
};

export default SubjectForm;
