import { useEffect, useState, useContext } from "react";
import DateFilters from "components/Common/DateFilters";
import Loader from "components/Common/Loader";
import { toast } from "react-toastify";
import { getReportsByScreenNames } from "Utils/reports.Utils";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import MaterialTable from "material-table";
import { format } from "date-fns";
import { getUniCodeStats } from "Utils/uniCode_Utils";
import { useSelector } from "react-redux";
import { selectTopicUniCodes } from "Redux/features/Subject/SubjectSlice";
import { selectQuizUniCodes } from "Redux/features/Subject/SubjectSlice";

const QuizReports = () => {
  const quizUniCodes = useSelector(selectQuizUniCodes);

  const [quizJourneyList, setQuizJourneyList] = useState(null);
  const [quizJourneyDetails, setQuizJourneyDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  const submitHandler = (dateObj) => {
    if (!dateObj) {
      setQuizJourneyList(quizJourneyDetails);
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    const [...rest] = quizJourneyDetails;
    const list =
      rest?.length &&
      rest.filter(
        (x) =>
          new Date(x.date).getTime() >= new Date(dateObj.startDate).getTime() &&
          new Date(x.date).getTime() <= new Date(dateObj.endDate).getTime()
      );
    setQuizJourneyList(list);
  };

  const setStudentName = (user) =>
    user.user_name
      ? user.user_name
      : user.first_name
      ? `${user.first_name} ${user.last_name}`
      : "N/A";

  const arrangeData = (data) =>
    data
      .map((x) =>
        !x.user.length
          ? false
          : x.journeys
              .filter(
                (c) =>
                  !c.uniCode.includes("--") || !c.uniCode.includes("undefined")
              )
              .filter((c) => c.uniCode.length > 20)
              .map((e) => {
                const findTopicByUniCode = quizUniCodes?.length
                  ? quizUniCodes.find(
                      (x) => String(x?.id) === String(e?.uniCode)
                    )
                  : "-";

                return {
                  screenName: e?.screenName || "-",
                  durationInMin: e?.durationInMin || "-",
                  date: e?.date || "-",
                  status: e?.status || "-",
                  uniCode: findTopicByUniCode?.uniCode || "-",
                  quizName: findTopicByUniCode?.quizName || "-",
                  chapterName: findTopicByUniCode?.chapterName || "-",
                  subjectName: findTopicByUniCode?.subjectName || "-",
                  gradeName: findTopicByUniCode?.gradeName || "-",
                  studentName: x.user[0] ? setStudentName(x.user[0]) : "-",
                };
              })
      )
      .filter((x) => x)
      .reduce((a, b) => [...a, ...b], []);

  const fetchReportForQuiz = async () => {
    setLoading(true);
    try {
      const resp = await getReportsByScreenNames("quiz_view");
      const list = arrangeData(resp.data.data);

      setQuizJourneyList(list);
      setQuizJourneyDetails(list);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + "hr, " : "";
    var mDisplay = m > 0 ? m + "min, " : "";
    var sDisplay = s > 0 ? s + "sec" : "";
    return hDisplay + mDisplay + sDisplay;
  };

  const calculateAverageTime = () =>
    (quizJourneyList?.length &&
      quizJourneyList
        .filter((x) => x)
        .reduce((a, b) => (a += b.durationInMin), 0) / quizJourneyList.length) *
    60;

  const refreshHandler = () => fetchReportForQuiz();

  useEffect(() => {
    fetchReportForQuiz();
  }, []);

  const renderCellValue = (value) => (
    <div className="report_value">
      <h1>{value}</h1>
    </div>
  );

  const renderAverageTime = () => (
    <div className="average_time">
      <h1>
        Average time: <span>{secondsToHms(calculateAverageTime())}</span>
      </h1>
    </div>
  );

  return (
    <div>
      <DateFilters
        submitHandler={submitHandler}
        refreshHandler={refreshHandler}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="reports_list">
          {renderAverageTime()}
          {quizJourneyList?.length ? (
            <MaterialTable
              icons={tableIcons}
              title={null}
              //   key={count++}
              columns={[
                {
                  title: "Student Name",
                  field: "studentName",
                  render: (rowData) => renderCellValue(rowData?.studentName),
                },
                {
                  title: "Screen Name",
                  field: "screenName",
                  render: (rowData) => renderCellValue(rowData?.screenName),
                  filtering: false,
                },
                {
                  title: "Uni Code",
                  field: "uniCode",
                  render: (rowData) => renderCellValue(rowData?.uniCode),
                },
                {
                  title: "Grade",
                  field: "gradeName",
                  render: (rowData) => renderCellValue(rowData?.gradeName),
                },
                {
                  title: "Subject",
                  field: "subjectName",
                  render: (rowData) => renderCellValue(rowData?.subjectName),
                },
                {
                  title: "Chapter",
                  field: "chapterName",
                  render: (rowData) => renderCellValue(rowData?.chapterName),
                },
                {
                  title: "Quiz",
                  field: "quizName",
                  render: (rowData) => renderCellValue(rowData?.quizName),
                },
                {
                  title: "Duration",
                  field: "durationInMin",
                  render: (rowData) =>
                    renderCellValue(secondsToHms(rowData?.durationInMin * 60)),
                  filtering: false,
                },
                {
                  title: "Date",
                  field: "date",
                  render: (rowData) => renderCellValue(rowData?.date),
                  filtering: false,
                },
                {
                  title: "Status",
                  field: "status",
                  render: (rowData) => renderCellValue(rowData?.status),
                },
              ]}
              data={
                quizJourneyList?.length > 0 && quizJourneyList.map((e) => e)
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                pageSize: 15,
                pageSizeOptions: [
                  5,
                  15,
                  30,
                  quizJourneyList?.length > 30 && quizJourneyList?.length,
                ],
                headerStyle: {
                  backgroundColor: colorsList.find((e) => e.color === color)
                    .value,
                  color: "#FFF",
                },
                filtering: true,
                search: false,
              }}
            />
          ) : (
            <h6 className="mt-3">Data not found</h6>
          )}
        </div>
      )}
    </div>
  );
};

QuizReports.propTypes = {
  //   renderKPI: PropTypes.string.isRequired,
};

export default QuizReports;
