import { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import MaterialTable from "material-table";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { format } from "date-fns";
import { FaBan, FaCheckCircle, FaHourglassHalf } from "react-icons/fa";
import { toast } from "react-toastify";
import { getStudentDataPagination } from "Utils/students_Utils";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStudent } from "Redux/features/Student/StudentSlice";
import { getTotalActiveStudents } from "Utils/students_Utils";
import { useCallback } from "react";
import useDebounce from "CustomHooks/useDebounce";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
// import { filter } from 'domutils';
// import useDebounce from 'components/CustomHooks/useDebounce';
const StudentList = ({
  studentList,
  sendNotification,
  updateMacAddress,
  totalCount,
}) => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [studentCounts, setStudentCounts] = useState(totalCount);
  const [studentDetails, setStudentDetails] = useState(studentList);

  const param = useParams();
  const history = useHistory();
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();
  const materialTableRef = useRef(null);
  const userData = useSelector(selectUserData);

  const fetchAllStudentRecord = async (rowCount, pageNo, isRowLimitChange) => {
    setLoading(true);
    try {
      const resp = await getStudentDataPagination(rowCount, pageNo, false);
      const result = await getTotalActiveStudents();

      const { inActiveCount, activeCount } = result?.data?.data;
      const { count: inActiveStudents } = inActiveCount[0];
      const { count: activeStudents } = activeCount[0];
      const { totalStudents } = resp?.data?.data;
      setStudentCounts({ totalStudents, inActiveStudents, activeStudents });

      let newList = [];
      if (isRowLimitChange) {
        const rest = [...Array(resp.data.data.totalStudents)].map((e) => {
          return {};
        });
        const data = [...resp.data.data.results, ...rest.splice(rowCount)];
        setStudentDetails(data);
        return;
      } else {
        const [...rest] = studentDetails;
        newList = rest;
      }

      var startIndex = (Number(pageNo) - 1) * Number(rowCount);
      var lastIndex = Number(pageNo) * Number(rowCount);

      const isDataExist = newList[lastIndex - 1];
      if (isDataExist?._id) return;

      if (pageNo === 1) {
        setStudentDetails([
          ...resp.data.data.results,
          ...newList.splice(lastIndex),
        ]);
      } else if (pageNo !== 1 && lastIndex < newList.length) {
        setStudentDetails([
          ...newList.slice(0, startIndex),
          ...resp.data.data.results,
          ...newList.slice(lastIndex),
        ]);
      } else if (lastIndex > newList.length) {
        setStudentDetails([
          ...newList.slice(0, startIndex),
          ...resp.data.data.results,
          ...newList.slice(lastIndex),
        ]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getFiltratedData = async (filtratedData) => {
    try {
      const resp = await getStudentDataPagination(limit, page, filtratedData);
      if (resp.data.data.results.length > 0)
        setStudentDetails([...resp?.data?.data?.results]);
      if (filtratedData) return;
      else getStudentDataPagination(limit, page);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStudentFilterByDate = (e) => {
    const value = e.target.value;
    getFiltratedData(encodeURI(JSON.stringify({ date_created: value })));
  };

  const onChangeFilterData = (pop_course) => getFiltratedData(pop_course);
  const debounceHandler = useCallback(
    useDebounce(onChangeFilterData, 1000),
    []
  );

  const renderCellValue = (value, id) => (
    <div className="student_name">
      <h1
        onClick={() => {
          history.push(`/admin/students/${id}`);
        }}
      >
        {value}
      </h1>
    </div>
  );

  const renderRowValues = (value) => (
    <div className="value">
      <h1>{value}</h1>
    </div>
  );

  const renderStatus = (value) => (
    <div className="status">
      <h1 className={value?.toLowerCase()}>{value}</h1>
    </div>
  );

  const renderApprovedStatus = (value, id, MacReq_count) => (
    <div className="approved-status">
      {value ? (
        <FaHourglassHalf
          className={String(value)?.toLowerCase()}
          onClick={() => updateMacAddress(id, MacReq_count)}
        />
      ) : (
        <FaCheckCircle className={String(value)?.toLowerCase()} />
      )}
    </div>
  );

  const renderAction = (studentID, studentObj) => (
    <div className="actions">
      <i
        className="fa fa-bars"
        aria-hidden="true"
        title="View Refer points record"
        onClick={() => {
          dispatch(setSelectedStudent(studentObj));
          history.push(`/admin/students/refer-points/record/${studentID}`);
        }}
      />
      {!userData.role && (
        <>
          {studentObj?.reg_token && (
            <i
              className="fa fa-bell ml-3"
              aria-hidden="true"
              title="View Refer points record"
              onClick={() => sendNotification(studentObj)}
            />
          )}
        </>
      )}
    </div>
  );

  const renderStudentInfo = (label, value) => (
    <div>
      <h1>{label}</h1>
      <h1>{value}</h1>
    </div>
  );

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  const customDateFilter = () => (
    <input
      type="date"
      className="form-control"
      onChange={handleStudentFilterByDate}
    />
  );

  let count = 0;
  return (
    <div className="student-list">
      <div className="send-notification m-0 mb-2 text-right">
        {!userData.role && (
          <button
            className="btn btn-secondary m-0"
            onClick={() => sendNotification(false)}
          >
            Send Notification to All Student
          </button>
        )}
      </div>

      <div className="total_students">
        {renderStudentInfo(
          "Total students:",
          studentCounts?.totalStudents || 0
        )}
        {renderStudentInfo(
          "Active students:",
          studentCounts?.activeStudents || 0
        )}
        {renderStudentInfo(
          "In-Active students:",
          studentCounts?.inActiveStudents || 0
        )}
      </div>

      <MaterialTable
        isLoading={loading}
        icons={tableIcons}
        title={null}
        key={count++}
        totalCount="600"
        ref={materialTableRef}
        onChangePage={(e) => {
          setPage(e + 1);

          if (e + 1 === page) return;

          if (e === 0 && limit === limit) return;

          if (e === 0) fetchAllStudentRecord(limit, 1, false);
          else fetchAllStudentRecord(limit, e + 1, false);
        }}
        onChangeRowsPerPage={(e) => {
          setLimit(e);

          if (e !== limit) fetchAllStudentRecord(e, 1, true);
        }}
        onFilterChange={(e) => {
          if (e.length === 0) {
            fetchAllStudentRecord(limit, 1, true);
            return;
          }

          const filterData = {};

          e.forEach((e) => {
            // if (e.column.field === "isActive") {
            //   if ("in-active".includes(e.value.toLowerCase())) {
            //     filterData[`${e.column.field}`] = ["n", "in", "ni"].includes(
            //       e.value.toLowerCase()
            //     )
            //       ? false
            //       : true;
            //   }
            // } else filterData[`${e.column.field}`] = e.value;

            filterData[`${e.column.field}`] = e.value;
          });

          const pop_course = encodeURI(JSON.stringify(filterData));
          debounceHandler(pop_course);
        }}
        columns={[
          {
            title: "User ID",
            field: "_id",
            render: (rowData) => renderRowValues(rowData.userID),
            // filtering: false,
          },
          {
            title: "Name",
            field: "user_name",
            render: (rowData) =>
              renderCellValue(rowData?.user_name, rowData?._id),
            sorting: false,
          },
          {
            title: "Email/Facebook",
            field: "email",
            render: (rowData) => renderRowValues(rowData?.email),
            sorting: false,
          },
          {
            title: "Platform",
            field: "platform",
            render: (rowData) => renderRowValues(rowData?.platform),
            sorting: false,
          },
          {
            title: "Referral code",
            field: "referral_code",
            render: (rowData) => renderRowValues(rowData?.referral_code),
            sorting: false,
          },
          {
            title: "Status",
            field: "isActive",
            render: (rowData) => renderStatus(rowData?.isActive),
            customFilterAndSearch: (term, rowData) =>
              !rowData?.isActive
                ?.toLowerCase()
                .localeCompare(term.toLowerCase()),
            sorting: false,
          },
          {
            title: "Device change counts",
            field: "MacReq_count",
            render: (rowData) => renderRowValues(rowData.MacReq_count),
            sorting: false,
          },
          {
            title: "Last change request",
            field: "last_req",
            render: (rowData) => renderRowValues(rowData?.last_req),
            sorting: false,
            filtering: false,
          },
          {
            title: "Approved device status",
            field: "changeMac",
            render: (rowData) =>
              renderApprovedStatus(
                rowData?.changeMac,
                rowData?._id,
                rowData.MacReq_count
              ),
            filtering: false,
            sorting: false,
          },
          {
            title: "Date Created",
            field: "date_created",
            render: (rowData) => renderRowValues(rowData?.date_created),
            // filtering: false,
            sorting: false,
            filterComponent: (props) => customDateFilter(),
          },
          {
            title: "Actions",
            field: "_id",
            render: (rowData) =>
              renderAction(rowData?._id, rowData?.studentObj),
            filtering: false,
            sorting: false,
          },
        ]}
        data={
          studentDetails?.length &&
          studentDetails.map((e) => {
            return {
              index: ++count,
              studentObj: e,
              _id: e._id,
              userID: e._id,
              user_name: e.user_name
                ? e.user_name
                : `${e?.first_name}  ${e?.last_name}`,
              changeMac: e?.changeMac || false,
              last_req: e.last_req
                ? format(new Date(e.last_req), "dd MMM yyyy")
                : "-",
              isActive: e?.isActive ? "Active" : "In-Active",
              referral_code: e?.referral_code || "-",
              email: e?.email || "-",
              platform: e?.platform || "-",
              MacReq_count: e?.MacReq_count ?? "-",
              reg_token: e?.reg_token || null,
              date_created: e.date_created
                ? format(new Date(e.date_created), "dd MMM yyyy")
                : "-",
            };
          })
        }
        options={{
          actionsColumnIndex: -1,
          exportButton: false,
          pageSize: limit,
          pageSizeOptions: [5, 10, 30, 50],

          headerStyle: {
            backgroundColor: colorsList.find((e) => e.color === color).value,
            color: "#FFF",
          },
          filtering: true,
          search: false,
          // count: totalCount
        }}
        // localization={{
        //   pagination: {
        //     labelDisplayedRows: `${((page - 1) * limit) + 1}-${limit} of ${totalCount}`
        //   },
        // }}
      />
    </div>
  );
};

StudentList.propTypes = {
  studentList: PropTypes.array.isRequired,
  sendNotification: PropTypes.func.isRequired,
  updateMacAddress: PropTypes.func.isRequired,
  // getMoreStudentData: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default StudentList;
