import { toast } from "react-toastify";
import { setloading } from "Redux/features/Auth/AuthSlice";
import backend from "../api";

import {
  editsubjectStatus,
  addsubject,
  editsubject,
} from "Redux/features/Subject/SubjectSlice";
import axios from "../api";
// Status Backend
export function updateStatusBackend(id, status, setStatusModal, dispatch) {
  setStatusModal(false);
  dispatch(setloading(true));
  let data = { isActive: !status };
  axios
    .put(`/subject/status/${id}`, data)
    .then((res) => {
      dispatch(editsubjectStatus({ _id: id, isActive: data.isActive }));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Subject's Status Updated Successfully!`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

// Add Backend
export function addSubjectBackend(data, setAddModal, dispatch, setaddsubIcon) {
  setAddModal(false);
  dispatch(setloading(true));

  axios
    .post(`/subject`, data)
    .then((res) => {
      dispatch(addsubject(res.data.data));
      setaddsubIcon("");
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Subject Added Successfully!`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

// Update Backend
export function updateSubjectBackend(id, data, setEditModal, dispatch) {
  setEditModal(false);
  dispatch(setloading(true));
  axios
    .put(`/subject/${id}`, data)
    .then((res) => {
      dispatch(editsubject(res.data.data));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Subject Information Updated Successfully!`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

export const saveSubject = async (subjectObj) =>
  await backend({
    method: "POST",
    url: "/subject/save",
    data: subjectObj,
  });

export const updateSubject = async (subjectID, subjectObj) =>
  await backend({
    method: "PUT",
    url: `/subject/grade/${subjectID}`,
    data: subjectObj,
  });

export const getAllSubjects = async () =>
  await backend({
    method: "GET",
    url: "/subject/grade",
  });

export const getSubject = async (subjectID) =>
  await backend({
    method: "GET",
    url: `/subject?_id=${subjectID}`,
  });

export const getTeachersSubjects = async (teacherID) =>
  await backend({
    method: "GET",
    url: `/teacher/subjects/assign?teacher_id=${teacherID}`,
  });

export const getSubjectForDropdown = async (teacherID) =>
  await backend({
    method: "GET",
    url: "/subject/dropdown",
  });

// export const getTeachersSubjectsIDSList = async (teacherID) =>
//   await backend({
//     method: 'GET',
//     url: `/teacher/subjects/assign?teacher_id=${teacherID}`,
//   });
