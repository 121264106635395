// react imports'
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types'

// Components imports
import Loader from "../Common/Loader";
import QuizList from "./QuizList";

// dependency imports
import { toast } from 'react-toastify';

// utils or redux imports
import { getAllQuizzes, deleteQuiz } from 'Utils/quiz_Utils';
import { setAllQuizzesList, selectQuizzesList } from 'Redux/features/Quiz/QuizSlice';
import ModalContainer from 'components/Common/ModalContainer';
import { setIsQuizTab } from 'Redux/features/Quiz/QuizSlice';

// -------------------------- imports End ------------------------------

const QuizWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDelete, setIsDelete] = useState(null);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const allQuizzesList = useSelector(selectQuizzesList)

  const fetchQuizzes = async () => {
    setLoading(true);
    try {
      const resp = await getAllQuizzes(params.lessonID)
      dispatch(setAllQuizzesList(resp.data.data))
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error)
    } finally {
      setLoading(false);
    }
  }

  const handleEdit = (quizID) => {
    history.push(`/admin/subject/chapter/specificquiz/${quizID}`)
    dispatch(setIsQuizTab(true))
  };

  const handleOnClose = () => {
    setIsDelete(null);
    setIsOpenDeleteModal(false)
  }

  const handleOpenModal = (quizObj) => {
    setIsDelete([quizObj.isActive, quizObj._id])
    setIsOpenDeleteModal(true)
  }

  const deleteQuizHandler = async () => {

    console.log(isDelete);
    const [deleteStatus, _id] = isDelete;
    const data = {
      isActive: deleteStatus ? false : true
    }

    try {
      const resp = await deleteQuiz(_id, data)

      setIsDelete(null);
      toast.success(resp.data.message);

      fetchQuizzes();
    } catch (err) {
      console.log(err);
    } finally {
      setIsOpenDeleteModal(false);
    }
  }

  useEffect(() => {
    if (allQuizzesList && allQuizzesList.length > 0)
      return

    fetchQuizzes();
  }, [])

  return (
    <div className='quiz_wrapper'>
      {loading ?
        <Loader />
        : <QuizList
          quizList={allQuizzesList}
          handleEdit={handleEdit}
          handleDelete={handleOpenModal}
        />}

      <ModalContainer isOpen={isOpenDeleteModal} onConfirmClick={handleOnClose}
        modalWidth="500" animateEffect="fadeInUp"
        heading="Delete Quiz">
        <div className="delete-quiz">
          <h1>
            {isDelete && isDelete[0] ? "Are you sure you want to restrict this Quiz ?" :
              "Are you sure you want to change this Quiz status to active ?"}
          </h1>
          <div className='delete-btn'>
            <button className='btn btn-primary' onClick={deleteQuizHandler} >Confirm</button>
          </div>
        </div>
      </ModalContainer>
    </div>
  )
}

export default QuizWrapper
