import { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import MaterialTable from "material-table";
import MaterialTableIcons from "../Common/MaterialTableIcons";

import { FaPlayCircle, FaRegEdit, FaBan } from "react-icons/fa";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

const QuizList = ({ quizList, handleEdit, handleDelete }) => {
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();

  const renderQuizTitle = (quizObj) => (
    <div className="quiz_title" onClick={() => handleEdit(quizObj[1])}>
      <h1>
        {quizObj[0]} - ({quizObj[2]})
      </h1>
    </div>
  );

  const renderCellValue = (value) => (
    <div className="quiz_value">
      <h1>{value}</h1>
    </div>
  );

  const renderQuizStatus = (value, status) => (
    <div className={status ? "active" : "in-active"}>
      <h1>{value}</h1>
    </div>
  );

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  let count = 0;
  return (
    <div className="quiz_list">
      <MaterialTable
        icons={tableIcons}
        title={null}
        key={count++}
        columns={[
          {
            title: "Title",
            field: "test_title",
            render: (rowData) =>
              renderQuizTitle([
                rowData.test_title,
                rowData._id,
                rowData?.uniCode,
              ]),
          },
          {
            title: "Plan",
            field: "isPaidStatus",
            render: (rowData) => renderCellValue(rowData.isPaidStatus),
            sorting: false,
            customFilterAndSearch: (term, rowData) =>
              !rowData?.isPaidStatus
                ?.toLowerCase()
                .localeCompare(term.toLowerCase()),
          },
          {
            title: "Total Question",
            field: "no_of_questions",
            render: (rowData) => renderCellValue(rowData.no_of_questions),
          },
          {
            title: "Total mark",
            field: "total_marks",
            render: (rowData) => renderCellValue(rowData.total_marks),
          },
          {
            title: "Passing mark",
            field: "passing_marks",
            render: (rowData) => renderCellValue(rowData.passing_marks),
          },
          {
            title: "Quiz remark",
            field: "quiz_remarks",
            render: (rowData) => renderCellValue(rowData.quiz_remarks),
            sorting: false,
            filtering: false,
          },
          {
            title: "Status",
            field: "isDelete",
            render: (rowData) =>
              renderQuizStatus(rowData.isDelete, rowData.isActive),
            sorting: false,
            customFilterAndSearch: (term, rowData) =>
              !rowData?.isDelete
                ?.toLowerCase()
                .localeCompare(term.toLowerCase()),
          },
        ]}
        data={quizList?.map((quiz) => {
          return {
            index: ++count,
            _id: quiz._id,
            test_title: quiz?.test_title || "-",
            total_marks: quiz?.total_marks || 0,
            passing_marks: quiz?.passing_marks || 0,
            no_of_questions: quiz?.questions.length || 0,
            quiz_remarks: quiz?.quiz_remarks || "-",
            isDelete: quiz.isActive ? "Active" : "In-Active",
            isActive: quiz.isActive,
            isPaidStatus: quiz.isPaid ? "Paid" : "Free",
            isPaid: quiz.isPaid,
            uniCode: quiz.uniCode,
          };
        })}
        actions={[
          {
            icon: () => <FaRegEdit />,
            tooltip: "Edit Quiz",
            onClick: (event, rowData) => handleEdit(rowData._id),
          },
          {
            icon: () => <FaBan />,
            tooltip: "Change Status",
            onClick: (event, rowData) => handleDelete(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: false,
          pageSize: 10,
          pageSizeOptions: [5, 10, 30, 50],
          headerStyle: {
            backgroundColor: colorsList.find((e) => e.color === color).value,
            color: "#FFF",
          },
          filtering: true,
        }}
      />
    </div>
  );
};

QuizList.propTypes = {
  quizList: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  // handleOpenQuiz: PropTypes.func.isRequired,
};

export default QuizList;
