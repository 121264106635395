import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getStudentsPlatform } from "Utils/reports.Utils";
import { Bar, Line } from "react-chartjs-2";
import DateFilters from "components/Common/DateFilters";
import { format } from "date-fns";
import { getReportsByScreenNames } from "Utils/reports.Utils";
import { getGradeUnicode } from "Utils/grades_Utils";
import DownloadPDF from "components/Common/DownloadPDF";
import DownloadCSV from "components/Common/DownloadCSV";

const totalBarGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      width: "20px",
    },
    title: {
      display: true,
      text: "Grades Data",
    },
  },

  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Number of students",
        },
      },
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Grades",
        },
      },
    ],
  },
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      width: "20px",
    },
    title: {
      display: true,
      text: "Grades Data",
    },
  },

  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Number of students",
        },
      },
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
      },
    ],
  },
};

const data = {};

const barGraphData = {};

const GradeReport = () => {
  const [gradesDetails, setGradesDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState(false);
  const [dateObj, setDateObj] = useState(() => {
    var d = new Date();
    return {
      startDate: format(
        new Date(new Date(d.setDate(d.getDate() - 6)).toISOString()),
        "yyyy-MM-dd"
      ),
      endDate: format(new Date(), "yyyy-MM-dd"),
    };
  });

  const randomColorGenerated = () => {
    const a = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const c = Math.floor(Math.random() * 256);

    return `rgba(${a}, ${b}, ${c})`;
  };

  const afterFetchData = (dateFilter, combine, grades) => {
    setLoading(true);
    const { startDate, endDate } = dateFilter;

    const a = new Date(startDate).getTime();
    const b = new Date(endDate).getTime();

    const list =
      combine?.length &&
      combine.filter(
        (e) =>
          new Date(e?.date).getTime() >= a && new Date(e?.date).getTime() <= b
      );

    const datesList = [...new Set(list.map((x) => x?.date))].sort();
    data.labels = datesList;
    const setUniqueUniCodes = [...new Set(list.map((x) => x?.uniCode))];

    const gradesData = [];
    setUniqueUniCodes.forEach((x) => {
      const gradesList = list.filter(
        (a) => a?.uniCode.toLowerCase() === x.toLowerCase()
      );

      gradesData.push({
        grade: x,
        gradesList,
        color: randomColorGenerated(),
      });
    });

    gradesData.unshift({
      grade: "Total",
      gradesList: gradesData.reduce((a, b) => [...a, ...b?.gradesList], []),
      color: "rgba(0, 0, 0)",
    });

    const lineGraphData = [];
    gradesData.forEach((x, i) => {
      const list = x?.gradesList?.reduce(function (acc, curr) {
        return acc[curr?.date] ? ++acc[curr?.date] : (acc[curr?.date] = 1), acc;
      }, {});

      delete list["undefined"];
      const findGrade =
        (grades?.length &&
          grades.find(
            (a) =>
              x?.grade.toLowerCase() === a.uniCode.toLowerCase() ||
              x?.grade === String(a?._id)
          )?.grade) ||
        "Count";

      const entries = Object.entries(list);

      lineGraphData.push({
        label:
          i === gradesData?.length - 1
            ? x?.grade
            : `${x?.grade} - ${String(findGrade)}`,
        data: datesList.map((x) => {
          const fn = entries.find((a) => a[0] === x);
          if (fn) return fn[1];
          return 0;
        }),
        backgroundColor: x?.color,
        borderColor: x?.color,
        lineTension: 0,
        fill: false,
      });
    });

    data.datasets = lineGraphData;

    const studentPerGrade = list.reduce(function (acc, curr) {
      return (
        acc[curr?.uniCode] ? ++acc[curr?.uniCode] : (acc[curr?.uniCode] = 1),
        acc
      );
    }, {});

    const gradeKeys = Object.keys(studentPerGrade);
    const entries = Object.entries(studentPerGrade);

    const sets = gradeKeys.map((x) => {
      const fn = entries.find((a) => a[0] === x);
      if (fn) return fn[1];
      return 0;
    });

    barGraphData.labels = gradeKeys.map(
      (x) =>
        (grades?.length &&
          grades.find(
            (a) =>
              x.toLowerCase() === a.uniCode.toLowerCase() ||
              x.toLowerCase() === String(a?._id)
          )?.grade) ||
        "-"
    );
    barGraphData.datasets = [];
    barGraphData.datasets.push({
      data: sets,
      label: "Students",
      backgroundColor: "#5603ad",
      borderColor: randomColorGenerated(),
      lineTension: 0,
      fill: false,
    });

    setLoading(false);
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const grades = await getGradeUnicode();
      const resp = await getReportsByScreenNames("home");

      const makeOneArrayOfJourney = resp?.data?.data?.reduce(
        (a, b) => [...a, ...b?.journeys],
        []
      );

      afterFetchData(dateObj, makeOneArrayOfJourney, grades.data.data);
      setGradesDetails(makeOneArrayOfJourney);
      setGrades(grades.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    } finally {
      setLoading(false);
    }
  };

  const differenceBtw2Dates = () => {
    const { startDate, endDate } = dateObj;

    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    const Difference_In_Time = date2.getTime() - date1.getTime();

    return Difference_In_Time / (1000 * 3600 * 24);
  };

  const submitHandler = (dateObj) => {
    if (!dateObj?.startDate || !dateObj?.endDate) {
      toast.info("Dates Missing");
      return;
    }

    if (
      new Date(dateObj.startDate).getTime() >
      new Date(dateObj.endDate).getTime()
    ) {
      toast.error("Start date must be smaller than endDate");
      return;
    }

    setDateObj(dateObj);
    afterFetchData(dateObj, gradesDetails, grades);
  };

  const refreshHandler = () => {
    const d = new Date();
    const startDate = new Date(new Date(d.setDate(d.getDate() - 6)))
      .toISOString()
      .split("T")[0];
    const endDate = format(new Date(), "yyyy-MM-dd");

    setDateObj({ startDate, endDate });
    afterFetchData({ startDate, endDate }, gradesDetails, grades);
  };

  const createDatForExcel = (dataType) => {
    if (dataType === "headerData") {
      return [
        { label: "Total Students", key: "totalStudents" },
        { label: "Total Days", key: "totalDays" },
      ];
    } else if (dataType === "rowData") {
      return [
        {
          totalStudents:
            Object.keys(data)?.length &&
            data?.datasets[0]?.data?.reduce((a, b) => (a += b), 0),
          totalDays: differenceBtw2Dates() + 1,
        },
      ];
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <div className="user_platforms">
      <DateFilters
        submitHandler={submitHandler}
        refreshHandler={refreshHandler}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="downloadsBtn">
            <DownloadPDF downloadFileName="Grade-Report" />
            {Object.keys(barGraphData)?.length && Object.keys(data)?.length && (
              <DownloadCSV
                excelHeader={createDatForExcel("headerData")}
                excelData={createDatForExcel("rowData")}
                excelFilename="Grade-Summary"
              />
            )}
          </div>
          <div className="graph">
            <div className="downloadPDF">
              <div className="box">
                <div className="box_card">
                  <p>Total Students:</p>
                  <p>
                    {Object.keys(data)?.length &&
                      data?.datasets[0]?.data?.reduce((a, b) => (a += b), 0)}
                  </p>
                </div>
                <div className="box_card">
                  <p>Total Days:</p>
                  <p>{differenceBtw2Dates() + 1}</p>
                </div>
              </div>
              <h3 className="heading">Grades Journey Line Graph</h3>
              <Line options={options} data={data} />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">Grades Journey Bar Graph</h3>
              <Bar options={options} data={data} />
            </div>
            <hr />
            <div className="gap"></div>
            <div className="downloadPDF">
              <h3 className="heading">Total Accumlated Students Per Grade</h3>
              <p className="text-center" style={{ fontSize: "1.2rem" }}>
                From {dateObj?.startDate} to {dateObj?.endDate}
              </p>
              <Bar options={totalBarGraphOptions} data={barGraphData} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GradeReport;
