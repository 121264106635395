import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUserData } from "Redux/features/Auth/AuthSlice";
import { updateTeacherPaymentStatus } from "Utils/teacherPayment_Utils";

const TeacherPaymentStatusForm = ({ closeModal, paymentObj }) => {
  const [description, setDescription] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(paymentObj?.paymentStatus);
  // const [withDrawAmount, setWithDrawAmount] = useState("");

  const userData = useSelector(selectUserData);
  const isRole = (role) => (userData?.role === role ? true : false);

  const onChangeHandler = (setState) => (e) => setState(e.target.value);

  const _updatePaymentStatus = async (e) => {
    e.preventDefault();

    try {
      const data = {
        _id: paymentObj?._id,
        isView: false,
        paymentStatus,
        amountWithdraw: paymentStatus === "reject" ? 0 : paymentObj?.amountPaid,
        remarks: description,
      };

      // if (isRole("teacher"))
      //     data.description = description ? description : paymentObj?.description;
      // else
      //     data.remarks = description ? description : paymentObj?.remarks;

      const resp = await updateTeacherPaymentStatus(data);
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      closeModal();
    }
  };

  return (
    <div className="payment_status_form">
      <form onSubmit={_updatePaymentStatus}>
        <select
          value={paymentStatus}
          className="form-control"
          onChange={onChangeHandler(setPaymentStatus)}
          required
        >
          <option value="pending">Pending</option>
          <option value="accept">Accept</option>
          <option value="reject">Reject</option>
        </select>

        {paymentStatus === "reject" && (
          <textarea
            value={description}
            placeholder="Enter Remarks"
            style={{ width: "100%" }}
            rows="5"
            onChange={onChangeHandler(setDescription)}
            required
          ></textarea>
        )}

        <div className="btn-save">
          <button type="submit" className="btn btn-secondary">
            Update Status{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

TeacherPaymentStatusForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  paymentObj: PropTypes.string.isRequired,
};

export default TeacherPaymentStatusForm;
