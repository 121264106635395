import React, { useEffect, useState } from "react";
import { ListContainer, ListItem } from "../CreateSequence/styles";
import { DragHandle } from "../CreateSequence/partials/DragHandle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import axios from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import loader from "../../assets/img/loader.svg";
import { toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { updateBackendChapterSequence } from "Utils/topic_quiz_Utils";
import { setlesson } from "Redux/features/Subject/SubjectSlice";
const ChapterSequence = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  let subjectId = location.pathname.split("/")[5];
  // const list = List.getList();
  var pop_cus = [
    {
      path: "chapter_sequence.test chapter_sequence.topic",
    },
  ];
  const pop_course = encodeURI(JSON.stringify(pop_cus));
  const [list, setlist] = useState([]);
  const [topicList, settopicList] = useState([]);
  const loading = useSelector(selectLoading);
  const [changes, setchanges] = useState(false);
  dispatch(setloading(false));
  useEffect(() => {
    function fetchChapter() {
      axios
        .get(`/chapter?subject=${subjectId}`)
        .then((res) => {
          dispatch(setlesson(res.data.data));
          setlist(res.data.data);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    dispatch(setloading(true));
    fetchChapter();
  }, []);
  console.log(list, "List");
  const saveList = (list) => {
    localStorage.setItem("theChapterList", list);
    console.log(localStorage.getItem("theChapterList"));
  };

  function updateBackendChapterSequence() {
    dispatch(setloading(true));

    axios
      .put(`/chapter/sequence`, {
        chapter_sequence: list,
      })
      .then((res) => {
        toast.success("Chapter Sequence Updated Successfully!");
        setTimeout(() => {
          dispatch(setloading(false));
        }, 500);
      })

      .catch((err) => {
        dispatch(setloading(false));
        toast.error(err.response.data.message);
      });
  }

  if (list.length > 0) {
    return (
      <div className="content">
        {changes ? (
          <div class="alert alert-primary" role="alert">
            You made changes in sequence ,please click Update!
          </div>
        ) : null}

        <Row>
          <Col md={12}>
            <Card>
              <CardHeader>
                <CardTitle
                  tag="h4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Chapter Sequence</h4>
                  </div>
                  <button
                    disabled={!changes}
                    className="btn btn-danger"
                    onClick={() => {
                      updateBackendChapterSequence();
                    }}
                  >
                    Update
                  </button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                  Sequence will be same in application as it appears in the list
                  below.
                </p>
                <h6>User Guide : </h6>
                <p>
                  Press and Hold{" "}
                  <i
                    class="fa fa-bars"
                    aria-hidden="true"
                    style={{
                      color: "pink",
                      fontSize: "15px",
                      // marginLeft: "5px",
                    }}
                  ></i>{" "}
                  to drag chapters
                </p>
                <DragDropContext
                  onDragEnd={(param) => {
                    const srcI = param.source.index;
                    const desI = param.destination?.index;
                    console.log("src :", srcI, " dec :", desI);
                    if (desI) {
                      let newArray = [...list];
                      newArray.splice(desI, 0, newArray.splice(srcI, 1)[0]);
                      // console.log(list);
                      // saveList(list);
                      setlist(newArray);
                      console.log("Updated", list);
                      setchanges(true);
                      // console.log(localStorage.getItem("theList"));
                    }
                  }}
                >
                  <ListContainer>
                    <h1>Chapters</h1>
                    <Droppable droppableId="droppable-1">
                      {(provided, _) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {list.map((item, i) => (
                            <Draggable
                              key={item._id}
                              draggableId={"draggable-" + item._id}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <ListItem
                                    className="bg-dark"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      boxShadow: snapshot.isDragging
                                        ? "0 0 .4rem #666"
                                        : "none",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "normal",
                                    }}
                                  >
                                    <DragHandle {...provided.dragHandleProps} />
                                    <span
                                      style={{
                                        color: "white",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {item.chapter_name}{" "}
                                      {item.isActive ? (
                                        <i
                                          className="fa fa-circle"
                                          style={{
                                            color: "green",
                                          }}
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-circle"
                                          style={{ color: "red" }}
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </span>
                                  </ListItem>
                                </>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </ListContainer>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={loader} alt="Loading...." />
                </CardTitle>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
};

export default ChapterSequence;
