import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editreferral } from "Redux/features/Admin/AdminSlice";
import axios from "../../api";
import { Card, CardHeader, CardTitle, Row, Col } from "reactstrap";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { updateReferal } from "Utils/referral_Utils";
const ReferalCard = ({ referal }) => {
  console.log(referal);
  const dispatch = useDispatch();
  const [point, setpoint] = useState(referal.points);

  const [referalHolder, setreferalHolder] = useState(referal);
  const [editModal, seteditModal] = useState(false);
  useEffect(() => {
    async function GetReferal() {
      // const request = await axios.get(`/referralpoints`);

      setpoint(referal.points);
      setreferalHolder(referal);
    }
    GetReferal();
  }, []);

  const handleUpdateReq = (id) => {
    let data = { _id: id, points: parseInt(point) };
    console.log("Referal Card Prints :", data);
    dispatch(editreferral(data));
    updateReferal(id, data, seteditModal, dispatch);
  };
  if (point !== null) {
    return (
      <div>
        {/* <p>ID : {referal._id}</p> */}
        <p style={{ display: "flex", justifyContent: "space-between" }}>
          Points : {referalHolder.points}
          <button
            className="btn btn-priamry"
            onClick={() => {
              seteditModal(true);
            }}
          >
            Edit
          </button>
        </p>

        <Modal
          visible={editModal}
          width="500"
          effect="fadeInUp"
          onClickAway={() => {
            seteditModal(false);
          }}
        >
          <div className="modal-container">
            <h4
              className="mb-4"
              style={{ color: "#242b3b", marginLeft: "35%" }}
            >
              Edit Referral
            </h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                handleUpdateReq(referalHolder._id);
              }}
            >
              <div
                className="mb-2"
                style={{ marginLeft: "10%", marginRight: "10%" }}
              >
                <input
                  type="number"
                  required
                  name="point"
                  minLength="1"
                  maxLength="100"
                  value={point}
                  onChange={(e) => {
                    setpoint(e.target.value);
                  }}
                />
              </div>

              <div
                className="w-100 d-flex justify-content-end mt-4"
                style={{ padding: "2%", paddingBottom: "3%" }}
              >
                {/* <button
                  className="btn btn-danger"
                  onClick={() => {
                    seteditModal(false);
                  }}
                >
                  Cancel
                </button> */}
                <button className="btn btn-warning" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4>No Refferal Points Yet!</h4>
                </CardTitle>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
};

export default ReferalCard;
