import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subjects: null,
  gradesDropdown: null,

  lessons: [],
  topics: [],
  quizes: [],
  quiz: [],
  topicUniCodes: [],
  quizUniCodes: [],
};

const SubjectSlice = createSlice({
  name: "subject",
  initialState,
  reducers: {
    setsubject: (state, action) => {
      state.subjects = action.payload;
    },
    addsubject: (state, action) => {
      state.subjects.push(action.payload);
    },
    editsubject: (state, action) => {
      let index = state.subjects.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.subjects];

      updated[index] = action.payload;
      state.subjects = updated;
    },
    editsubjectStatus: (state, action) => {
      let index = state.subjects.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.subjects];

      updated[index].isActive = action.payload.isActive;
      state.subjects = updated;
    },
    setGradeDropdown: (state, action) => {
      state.gradesDropdown = action.payload;
    },
    //   Lessons
    setlesson: (state, action) => {
      state.lessons = action.payload;
    },
    addlesson: (state, action) => {
      state.lessons.push(action.payload);
    },
    editlesson: (state, action) => {
      let index = state.lessons.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.lessons];

      updated[index] = action.payload;
      state.lessons = updated;
    },
    editlessonStatus: (state, action) => {
      let index = state.lessons.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.lessons];

      updated[index].isActive = action.payload.isActive;
      state.lessons = updated;
    },
    //   Topics
    settopic: (state, action) => {
      state.topics = action.payload;
    },
    addtopic: (state, action) => {
      state.topics.push(action.payload);
    },
    edittopic: (state, action) => {
      let index = state.topics.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.topics];

      updated[index] = action.payload;
      state.topics = updated;
    },
    edittopicStatus: (state, action) => {
      let index = state.topics.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.topics];

      updated[index].isActive = action.payload.isActive;
      state.topics = updated;
    },
    //   Quizes
    setQuizes: (state, action) => {
      state.quizes = action.payload;
    },
    addquizes: (state, action) => {
      state.quizes.push(action.payload);
    },
    editquizes: (state, action) => {
      let index = state.quizes.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.quizes];

      updated[index] = action.payload;
      state.quizes = updated;
    },
    editquizesStatus: (state, action) => {
      let index = state.quizes.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.quizes];

      updated[index].isActive = action.payload.isActive;
      state.quizes = updated;
    },
    //   Specific Quiz
    setQuiz: (state, action) => {
      state.quiz = action.payload.data;
    },
    addquiz: (state, action) => {
      state.quiz.push(action.payload);
    },
    editquiz: (state, action) => {
      let index = state.quiz.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.quiz];

      updated[index] = action.payload;
      state.quiz = updated;
    },
    editquizStatus: (state, action) => {
      let index = state.quiz.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.quiz];

      updated[index].isActive = action.payload.isActive;
      state.quiz = updated;
    },
    setTopicUniCodes: (state, action) => {
      state.topicUniCodes = action.payload;
    },
    setQuizUniCodes: (state, action) => {
      state.quizUniCodes = action.payload;
    },
  },
});

export const {
  addsubject,
  addlesson,
  addtopic,
  addquizes,
  addquiz,
  editsubject,
  editlesson,
  edittopic,
  editquizes,
  editquiz,
  editsubjectStatus,
  editlessonStatus,
  edittopicStatus,
  editquizesStatus,
  editquizStatus,
  setsubject,
  setlesson,
  settopic,
  setQuizes,
  setQuiz,
  setGradeDropdown,
  setTopicUniCodes,
  setQuizUniCodes
} = SubjectSlice.actions;
export const selectsubjects = (state) => state.subject.subjects;
export const selectlessons = (state) => state.subject.lessons;
export const selecttopics = (state) => state.subject.topics;
export const selectquizes = (state) => state.subject.quizes;
export const selectquiz = (state) => state.subject.quiz;
export const selectGradesDropdown = (state) => state.subject.gradesDropdown;
export const selectTopicUniCodes = (state) => state.subject.topicUniCodes;
export const selectQuizUniCodes = (state) => state.subject.quizUniCodes;

export default SubjectSlice.reducer;
