import { toast } from "react-toastify";
import backend from "../api";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { addtopic } from "Redux/features/Subject/SubjectSlice";
import {
  edittopicStatus,
  edittopic,
} from "Redux/features/Subject/SubjectSlice";
import axios from "../api";
// Status Backend
export function updateStatusBackend(topic_id, status, dispatch) {
  let data = { isActive: !status };
  dispatch(setloading(true));
  axios
    .put(`/Topics/status/${topic_id}`, data)
    .then((res) => {
      dispatch(edittopicStatus({ _id: topic_id, isActive: data.isActive }));
      toast.success(`Topics's Status Updated`);
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

export function addTopicBackend(data, dispatch, lessonID, history) {
  dispatch(setloading(true));
  axios
    .post(`/topics`, data)
    .then((req) => {
      dispatch(addtopic(req.data.data));
      axios
        .post(`/chapter/chaptersequence/${lessonID}`, {
          chapter_sequence: [
            {
              sort_number: "4",
              topic: `${req.data.data._id}`,
            },
          ],
        })
        .then((res) => {
          toast.success("Topic Added Successfully!");
          setTimeout(() => {
            dispatch(setloading(false));
            history.push(`/admin/subject/chapter/chapterdetails/${lessonID}`);
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          try {
            toast.error(err.response.data.message);
          } catch (error) {
            toast.error(err.message);
          }
        });
    })
    .catch((err) => {
      dispatch(setloading(false));
      toast.error(err?.response?.data?.message || err);
    });
}

export const updateInternally = (
  index,
  description,
  setdescription,
  value,
  equation
) => {
  if (description[index].topic_type == "text") {
    setdescription(
      description.map((des, i) =>
        i === index ? { ...des, description: value } : des
      )
    );
    // description[index].description = value;
  } else {
    setdescription(
      description.map((des, i) =>
        i === index ? { ...des, description: equation } : des
      )
    );
    // description[index].description = equation;
  }
};

export function updateTopicBackend(topic_id, data, topic, dispatch, history) {
  dispatch(setloading(true));
  axios
    .put(`/Topics/${topic_id}`, data)
    .then((res) => {
      dispatch(edittopic(res.data.data));
      toast.success(`Topic Updated Successfully!`);
      setTimeout(() => {
        dispatch(setloading(false));
        history.push(`/admin/subject/chapter/chapterdetails/${topic.chapter}`);
      }, 1000);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

var pop_cus = {
  path: "chapter_sequence.test",
};
const pop_course = encodeURI(JSON.stringify(pop_cus));

var pop_cus_1 = {
  path: "subject",
  select: "+Subject_name",
};
const pop_course1 = encodeURI(JSON.stringify(pop_cus_1));

// getChapterWithQuizzes
export const getChapterWithQuizzes = async (subjectID) =>
  await backend({
    method: "GET",
    url: `/chapter/count/${subjectID}`,
  });

export const getChapterWithCountQuizzes = async (subjectID) =>
  await backend({
    method: "GET",
    url: `/chapter?subject=${subjectID}&populate=${pop_course}`,
  });

export const getLessonsOfChapter = async (lessonID) =>
  await backend({
    method: "GET",
    url: `/chapter?_id=${lessonID}&populate=${pop_course1}`,
  });

export const getTotalChaptersForSubjects = async () =>
  await backend({
    method: "GET",
    url: "/chapter/subject/count",
  });

var populateData = [
  {
    path: "chapter_sequence.test chapter_sequence.topic",
  },
];
const populate = encodeURI(JSON.stringify(populateData));
export const getChapterSequence = async (chapterID) =>
  await backend({
    method: "GET",
    url: `/chapter?_id=${chapterID}&populate=${populate}`,
  });

export const getAllChapterForSubject = async (subjectID) =>
  await backend({
    method: "GET",
    url: `/chapter?subject=${subjectID}`,
  });

export const getChapterForDropdown = async () =>
  await backend({
    method: "GET",
    url: "/chapter/dropdown",
  });
