import { useContext } from "react";
import PropTypes from "prop-types";
import MaterialTableIcons from "components/Common/MaterialTableIcons";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import MaterialTable from "material-table";
import { format } from "date-fns";

function ReportsList({ registeredCoursesList }) {
  const { color } = useContext(BackgroundColorContext);
  const { tableIcons } = MaterialTableIcons();

  const renderCellValue = (value) => (
    <div className="report_value">
      <h1>{value}</h1>
    </div>
  );

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" },
  ];

  return (
    <div className="reports_list" style={{ margin: "2rem 0 0" }}>
      {registeredCoursesList?.length ? (
        <MaterialTable
          icons={tableIcons}
          title={null}
          //   key={count++}
          columns={[
            {
              title: "Student Name",
              field: "studentName",
              render: (rowData) => renderCellValue(rowData?.studentName),
            },
            {
              title: "Platform",
              field: "platform",
              render: (rowData) => renderCellValue(rowData?.platform),
            },
            {
              title: "UniCode",
              field: "uniCode",
              render: (rowData) => renderCellValue(rowData?.uniCode),
            },
            {
              title: "Grade",
              field: "gradeName",
              render: (rowData) => renderCellValue(rowData?.gradeName),
            },
            {
              title: "Registered Courses",
              field: "subjectName",
              render: (rowData) => renderCellValue(rowData?.subjectName),
            },
            {
              title: "Purchased Via",
              field: "purchasedVia",
              render: (rowData) => renderCellValue(rowData?.purchasedVia),
            },
            {
              title: "Purchased Date",
              field: "purchasedDate",
              render: (rowData) => renderCellValue(rowData?.purchasedDate),
            },
            {
              title: "Expiry Date",
              field: "expireDate",
              render: (rowData) => renderCellValue(rowData?.expireDate),
            },
            {
              title: "Amount Paid",
              field: "amountPaid",
              render: (rowData) => renderCellValue(rowData?.amountPaid),
            },
            {
              title: "Payment Gateway",
              field: "paymentGateway",
              render: (rowData) => renderCellValue(rowData?.paymentGateway),
            },
          ]}
          data={
            registeredCoursesList?.length > 0 &&
            registeredCoursesList.map((e) => {
              return {
                // index: ++count,
                studentName:
                  e?.student?.user_name || e?.student?.first_name
                    ? `${e?.student?.first_name} ${e?.student?.last_name}`
                    : "N/A",
                amountPaid: e?.amountPaid,
                platform: e?.student?.platform || "N/A",
                paymentGateway: e?.paymentGateway,
                subjectName: e?.subject?.Subject_name,
                uniCode: `${e?.grade?.uniCode || "-"}-${
                  e?.subject?.uniCode || "-"
                }`,
                gradeName: e?.grade?.grade,
                expireDate: e?.expireDate
                  ? format(new Date(e.expireDate), "dd MMM yyyy")
                  : "N/A",
                purchasedDate: e?.purchaseDate
                  ? format(new Date(e.purchaseDate), "dd MMM yyyy")
                  : "N/A",
                purchasedVia:
                  e?.isPurchase && e?.isBundle ? "with bundle" : "with subject",
              };
            })
          }
          options={{
            actionsColumnIndex: -1,
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 30, 50],
            headerStyle: {
              backgroundColor: colorsList.find((e) => e.color === color).value,
              color: "#FFF",
            },
            filtering: true,
          }}
        />
      ) : (
        <h1>Data not found</h1>
      )}
    </div>
  );
}

ReportsList.propTypes = {
  registeredCoursesList: PropTypes.array.isRequired,
};

export default ReportsList;
