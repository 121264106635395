import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function BreadCrumbs({ breadCrumbList }) {
  return (
    <div className="bread_crumbs">
      <div className="bread_crumbs-list">
        {breadCrumbList?.length &&
          breadCrumbList.map((e, i) => (
            <>
              <Link className="links" to={e?.pathName}>
                {e?.title}
              </Link>
              {i !== breadCrumbList.length - 1 && (
                <>
                  <i className="fas fa-angle-right"></i>
                </>
              )}
            </>
          ))}
      </div>
    </div>
  );
}

BreadCrumbs.propTypes = {
  breadCrumbList: PropTypes.array.isRequired,
};

export default BreadCrumbs;
