import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import Loader from "components/Common/Loader";
import { useHistory } from "react-router-dom";
import userAvatar from "../../assets/img/anime3.png";

function CommentsList({
  commentsList,
  replyForCommentHandler,
  loading,
  deleteCommentHandler,
}) {
  const history = useHistory();

  const setStudentName = (e) =>
    e?.user?.user_name
      ? e?.user?.user_name
      : `${e?.user?.first_name} ${e?.user?.last_name}`;

  const renderSmallDetails = (label, value) => (
    <div className="asked_by">
      <p>
        {label}
        <span>{value}</span>
      </p>
    </div>
  );

  const renderCommentInfo = (e) => (
    <div className="details">
      <div className="user_profile">
        <img src={e?.user?.profile_image || userAvatar} alt="user_profile" />
      </div>

      <div>
        <button
          className="delete_btn"
          onClick={() => {
            deleteCommentHandler(e?._id, e?.isActive);
          }}
        >
          {e?.isActive ? <i className="fa fa-trash" aria-hidden="true"></i> : <i class="fa fa-ban" aria-hidden="true"></i>}
        </button>
        {renderSmallDetails("Asked by: ", setStudentName(e))}
        {renderSmallDetails("Grade: ", e?.grade?.grade)}
        {renderSmallDetails("Subject: ", e?.subject?.Subject_name)}
        {renderSmallDetails("Chapter: ", e?.chapter?.chapter_name)}
        {renderSmallDetails("Topic Name: ", e?.topic?.topic_title)}
      </div>
    </div>
  );

  const renderReplyContent = (e) => (
    <div className="reply">
      <div
        className="answers"
        onClick={() => history.push(`/admin/comment/${e?._id}`)}
      >
        <i className="fa fa-comment-alt"></i>
        <h1>{e?.answers?.length} Answers</h1>
      </div>
      <button
        className="reply_btn"
        onClick={() => {
          replyForCommentHandler(e?._id);
        }}
      >
        Answer
      </button>
    </div>
  );

  const renderQuestionAndDate = (e) => (
    <>
      <p className="date">
        {e.createdAt ? format(new Date(e.createdAt), "PPpp") : "N/A"}
      </p>
      <h1 dir="auto" className="question">
        {e?.question}
      </h1>
    </>
  );

  return loading ? (
    <Loader />
  ) : (
    <div className="comments-list">
      {commentsList?.length ? (
        commentsList.map((e, i) => (
          <div
            key={"list" + i}
            className={
              e?.isActive ? "comments-list_card" : "comments-list_card disabled"
            }
          >
            {renderCommentInfo(e)}
            {renderQuestionAndDate(e)}
            {renderReplyContent(e)}
          </div>
        ))
      ) : (
        <h1 className="not_found">Record not found</h1>
      )}
    </div>
  );
}

CommentsList.propTypes = {
  commentsList: PropTypes.array.isRequired,
  replyForCommentHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  deleteCommentHandler: PropTypes.func.isRequired,
};

export default React.memo(CommentsList);
