import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import AdminLayout from 'layouts/Admin/Admin.js';
import RTLLayout from 'layouts/RTL/RTL.js';

import 'assets/scss/black-dashboard-react.scss';
import 'assets/demo/demo.css';
import 'assets/css/nucleo-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import ThemeContextWrapper from './components/ThemeWrapper/ThemeWrapper';
import BackgroundColorWrapper from './components/BackgroundColorWrapper/BackgroundColorWrapper';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from './app/store';
import { persistStore } from 'redux-persist';
import App from 'App';
import 'app.scss';
let persistor = persistStore(store);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeContextWrapper>
          <BackgroundColorWrapper>
            <Router>
              <App />
            </Router>
          </BackgroundColorWrapper>
        </ThemeContextWrapper>
      </PersistGate>
    </Provider>
  </React.StrictMode>,

  document.getElementById('root')
);
