import { toast } from "react-toastify";
import { setloading } from "Redux/features/Auth/AuthSlice";
import axios from "../api";
import backend from "../api";
export function updateReferal(id, data, seteditModal, dispatch) {
  seteditModal(false);
  dispatch(setloading(true));
  axios
    .put(`/referralpoints/${id}`, data)
    .then((res) => {
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Referral Points Updated Successfully!`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}

const populateData = [
  {
    path: "availedBy",
    select: "+user_name +email +platform",
  },
];
const pop_course = encodeURI(JSON.stringify(populateData));

export const getStudentReferPointsRecord = async (studentID) =>
  await backend({
    method: "GET",
    url: `/studentpoints?user=${studentID}&populate=${pop_course}`,
  });

export const getStudentDetails = async (studentID) =>
  await backend({
    method: "GET",
    url: `/user?_id=${studentID}`,
  });
