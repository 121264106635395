import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  arrangeGoogleAnalyticsData,
  generateAccessToken,
  getGoogleAnalyticsData,
} from "../Utils/dashboard_Utils";
import { format } from "date-fns";
import { Pie } from "react-chartjs-2";
import Loader from "components/Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "Redux/features/Auth/AuthSlice";
import ModalContainer from "../components/Common/ModalContainer";

const GoogleAnalyticsStats = ({
  postData,
  heading,
  value,
  filterDownloads,
}) => {
  // states
  const [screenNames, setScreenNames] = useState([]);
  const [eventCount, setEventCount] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [isScreenVisitOpen, setIsScreenVisitOpen] = useState(isOpen);
  const [screenVisitColorsList, setScreenVisitColorsList] = useState([]);
  const [statDays, setStatDays] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpenFilterDownloadModal, setIsOpenFilterDownloadModal] =
    useState(false);

  // redux
  const accessToken = useSelector(selectAccessToken);

  const openModal = () => {
    setStartDate("");
    setEndDate("");
    setIsOpenFilterDownloadModal(true);
  };

  const closeModal = () => {
    setStartDate("");
    setEndDate("");
    setIsOpenFilterDownloadModal(false);
  };

  const getNumberOfDays = () => {
    const totalDays = Math.round(
      (new Date(endDate).getTime() - new Date(startDate)?.getTime()) /
        (1000 * 3600 * 24)
    );

    setStatDays(totalDays);
  };

  const randomColorGenerated = () => {
    const a = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const c = Math.floor(Math.random() * 256);

    return `rgba(${a}, ${b}, ${c}, 0.5)`;
  };

  const data = {
    labels: screenNames,
    datasets: [
      {
        label: "# of Votes",
        data: eventCount,
        backgroundColor: screenVisitColorsList?.length
          ? screenVisitColorsList
          : [],
        borderColor: screenVisitColorsList?.length ? screenVisitColorsList : [],
        borderWidth: 1,
      },
    ],
  };

  const fetchGoogleAnalytics = async (start, end) => {
    setLoading(true);
    try {
      const apikey = "AIzaSyAA7S86_Od8P35NQAQYrZSeDEYq8QhzXmM";
      let theUrl = "";
      if (postData === "activeUserIn30Minutes")
        theUrl = `https://analyticsdata.googleapis.com/v1beta/properties/239598182:runRealtimeReport?access_token=${accessToken}&key=${apikey}`;
      else
        theUrl = `https://analyticsdata.googleapis.com/v1beta/properties/239598182:runReport?access_token=${accessToken}&key=${apikey}`;

      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open("POST", theUrl, false);

      xmlHttp.send(getGoogleAnalyticsData(postData, start, end));
      const { rows } = JSON.parse(xmlHttp.responseText);

      const resp = await arrangeGoogleAnalyticsData(postData, rows);
      const { screenNames, eventCount } = resp;

      const colorList =
        screenNames?.length && screenNames.map((e) => randomColorGenerated());

      setScreenVisitColorsList(colorList);
      setScreenNames(screenNames);
      setEventCount(eventCount);
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const _submit = (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      toast.error("Date are missing");
      return;
    }
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      toast.error("Start date must be smaller than end date");
      return;
    }

    const a = new Date();
    const hrs = a.getHours();
    const min = a.getMinutes();
    const sec = a.getSeconds();

    getNumberOfDays();

    filterDownloads(
      new Date(startDate),
      new Date(new Date(endDate).setHours(hrs, min, sec))
    );
    closeModal();
  };

  useEffect(() => {
    const current_Date = new Date();
    const start_date = new Date().setMonth(new Date().getMonth() - 1);
    setStartDate(start_date);
    setEndDate(current_Date);

    setLoading(true);
    fetchGoogleAnalytics(
      format(new Date(start_date), "yyyy-MM-dd"),
      format(new Date(current_Date), "yyyy-MM-dd")
    );
    setLoading(false);
  }, []);

  const renderTableLayout = () => {
    const arrangeDate = (count) => {
      let hrs = null;
      let min = null;
      let sec = null;
      min = Number(count) / 60;
      sec = Number(count) % 60;
      hrs = min / 24;

      return `${Math.round(hrs)}h - ${Math.round(min)}m - ${Math.round(sec)}s`;
    };

    return (
      <div className="google_analytics-card">
        <h1>{heading}</h1>
        <div className="table_layout">
          <table>
            <thead>
              <tr>
                <th>Screen Names</th>
                <th>
                  {postData == "screenVisits"
                    ? "Clicks"
                    : postData == "studentSource"
                    ? "Students"
                    : "Time"}
                </th>
              </tr>
            </thead>
            <tbody>
              {screenNames.map((e, i) => (
                <tr>
                  {" "}
                  <td> {e}</td>{" "}
                  <td>
                    {" "}
                    {postData === "screenSessionTime"
                      ? arrangeDate(eventCount[i])
                      : Math.round(eventCount[i])}{" "}
                  </td>{" "}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  // format(new Date(startDate), 'yyyy-MM-dd')

  const renderPieChartLayout = () => (
    <div className="google_analytics-card">
      <h1>{heading}</h1>
      <div className="pie_chart">
        <Pie data={data} />
      </div>
    </div>
  );

  const renderPieChartBigLayout = () => (
    <div className="google_analytics-card">
      <h1>{heading}</h1>
      <div className="big_pie_chart">
        <Pie data={data} />
      </div>
    </div>
  );

  const renderTotalValueLayout = () => {
    let hrs = null;
    let min = null;
    let sec = null;
    if (postData === "timeInTheApp") {
      min = Number(eventCount[0]) / 60;
      sec = Number(eventCount[0]) % 60;
      hrs = min / 24;
    }

    return (
      <div className="total_value_layout">
        {postData === "activeUserIn30Minutes" ? (
          <p>{heading}</p>
        ) : (
          <p>{statDays ? `DOWNLOADS IN ${statDays} Days` : heading}</p>
        )}
        {postData === "downloads" && (
          <div className="downloads">
            <h1>{value}</h1>
            <button className="btn btn-secondary" onClick={openModal}>
              Filter downloads
            </button>
          </div>
        )}
        {postData === "activeUserIn30Minutes" && (
          <h1>{Math.round(eventCount[0])}</h1>
        )}
        {postData === "timeInTheApp" && (
          <h1>
            {Math.round(hrs)}h - {Math.round(min)}m - {Math.round(sec)}s
          </h1>
        )}
      </div>
    );
  };

  const renderFilterDateModalBody = () => (
    <div className="date_filter_modal">
      <form onSubmit={_submit}>
        <p>Start date</p>
        <input
          type="date"
          value={startDate ? format(new Date(startDate), "yyyy-MM-dd") : ""}
          className="form-control"
          required
          onChange={(e) => {
            e.preventDefault();
            setStartDate(e.target.value);
          }}
        />
        <p>End date</p>
        <input
          type="date"
          value={endDate ? format(new Date(endDate), "yyyy-MM-dd") : ""}
          className="form-control"
          required
          onChange={(e) => {
            e.preventDefault();
            setEndDate(e.target.value);
          }}
        />
        <div className="filter-btn">
          <button type="submit" className="btn btn-secondary">
            {" "}
            Filter{" "}
          </button>
        </div>
      </form>
    </div>
  );

  const renderAnalyticsStats = () => (
    <>
      {postData === "activeUserIn30Minutes" && renderTotalValueLayout()}

      {postData === "downloads" && (
        <>
          {renderTotalValueLayout()}

          <ModalContainer
            isOpen={isOpenFilterDownloadModal}
            onConfirmClick={closeModal}
            modalWidth="600"
            animateEffect="fadeInUp"
            heading="Filter Dates"
          >
            {renderFilterDateModalBody()}
          </ModalContainer>
        </>
      )}

      {postData === "timeInTheApp" && renderTotalValueLayout()}

      {postData === "screenSessionTime" && renderTableLayout()}

      {postData === "noOfStudents" && renderPieChartLayout()}

      {postData === "screenVisits" && renderTableLayout()}

      {postData === "studentSource" && renderTableLayout()}

      {postData === "studentsPerCountry" && renderPieChartLayout()}

      {postData === "studentsPerRegion" && renderPieChartBigLayout()}
    </>
  );

  const renderGoogleAnalyticsCard = () => (
    <>{loading ? <Loader /> : renderAnalyticsStats()}</>
  );

  return <div className="google_analytics">{renderGoogleAnalyticsCard()}</div>;
};

GoogleAnalyticsStats.propTypes = {
  postData: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  value: PropTypes.number,
  filterDownloads: PropTypes.func,
};

GoogleAnalyticsStats.defaultProps = {
  value: 0,
  filterDownloads: () => {},
};

export default GoogleAnalyticsStats;
