import { toast } from "react-toastify";
import { setloading } from "Redux/features/Auth/AuthSlice";
import axios, { request } from "../api";
import backend from "../api";

export function updateBackendChapterSequence(
  dispatch,
  lessonID,
  list,
  history
) {
  dispatch(setloading(true));

  axios
    .put(`/chapter/chaptersequence/${lessonID}`, {
      chapter_sequence: list,
    })
    .then((res) => {
      toast.success("Quiz/Topic Sequence Updated Successfully!");
      setTimeout(() => {
        dispatch(setloading(false));
        history.goBack();
      }, 500);
    })

    .catch((err) => {
      dispatch(setloading(false));
      toast.error(err.response.data.message);
    });
}

export const updateChapterSequence = async (lessonID, chapter_sequence) =>
  await backend({
    method: "PUT",
    url: `/chapter/chaptersequence/${lessonID}`,
    data: chapter_sequence,
  });

export const getAllTopicsForChapter = async (chapterID) =>
  await backend({
    method: "GET",
    url: `/topics?chapter=${chapterID}`,
  });

export const getTopicForDropdown = async (teacherID) =>
  await backend({
    method: "GET",
    url: "/topics/dropdown",
  });

export const saveNewTopic = async (topicObj) =>
  await request({
    method: "POST",
    url: "/topic",
    data: topicObj,
  });

export const getNewTopics = async () =>
  await request({
    method: "GET",
    url: "/topic",
  });
