import { toast } from "react-toastify";
import { setloading } from "Redux/features/Auth/AuthSlice";
import {
  editlessonStatus,
  addlesson,
  editlesson,
} from "Redux/features/Subject/SubjectSlice";
import axios from "../api";
// Status Backend
export function updateStatusBackend(id, status, setStatusModal, dispatch) {
  setStatusModal(false);
  dispatch(setloading(true));
  let data = { isActive: !status };
  axios
    .put(`/chapter/status/${id}`, data)
    .then((res) => {
      dispatch(editlessonStatus({ _id: id, isActive: data.isActive }));
      toast.success(`Chpater's Status Updated Successfully!`);
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}
// Add Backend
export function addChapterBackend(
  data,
  setAddModal,
  setchapterName,
  setaddchapIcon,
  setchapterIcon,
  dispatch
) {
  setAddModal(false);
  setchapterName("");
  setaddchapIcon("");
  setchapterIcon([]);
  dispatch(setloading(true));

  axios
    .post(`/chapter`, data)
    .then((res) => {
      dispatch(addlesson(res.data.data));
      toast.success(`Chapter Added Successfully!`);
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}
// Update Backend
export function updateChapterBackend(id, data, setEditModal, dispatch) {
  setEditModal(false);
  dispatch(setloading(true));
  axios
    .put(`/chapter/${id}`, data)
    .then((res) => {
      dispatch(editlesson(res.data.data));
      setTimeout(() => {
        dispatch(setloading(false));
      }, 1000);
      toast.success(`Chapter's Infomation Updated Successfully`);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}
