import backend from "../api";

export const getAdvertisement = async () =>
  await backend({
    method: "GET",
    url: "/advertisement",
  });

export const updateAdvertisement = async (advertisementID, advertisementObj) =>
  await backend({
    method: "PUT",
    url: `/advertisement/${advertisementID}`,
    data: advertisementObj,
  });

export const runPaymentApi = async () => {
  const postData = {
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbW91bnQiOjI1MCwib3JkZXJJZCI6IjEyMjMyIiwic2VydmljZVR5cGUiOiJHcm9jZXJ5IFN0b3JlIiwicmVkaXJlY3RVcmwiOiJodHRwOi8vbG9jYWxob3N0OjUwMDEvemFpbmNhc2gvcmVkaXJlY3QiLCJwcm9kdWN0aW9uIjpmYWxzZSwibXNpc2RuIjoiOTY0NzgzNTA3Nzg5MyIsImlhdCI6MTY1MzQ1NzExMjExOSwiZXhwIjoxNjUzNDU3MTI2NTE5fQ.Ak3O6tXsVL84H46GFCkDr28hy4SV4q28c3YPDB4g-n8",
    merchantId: "5ffacf6612b5777c6d44266f",
    lang: "en",
  };

  // return await backend({
  //   method: "POST",
  //   url: `https://test.zaincash.iq/transaction/init`,
  //   data: JSON.stringify(postData),
  //   headers: {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   },
  //   withCredentials: true
  // });

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Access-Control-Allow-Credentials" : true 
    },
    body: JSON.stringify(postData),
    mode: "no-cors",
  };

  fetch("https://test.zaincash.iq/transaction/init", requestOptions)
    .then((res) => {
      console.log("///////////");
      console.log(res.json());
    })
    .then((json) => {
      console.log(json);
    })
    .catch((err) => console.log(err.message));
};
