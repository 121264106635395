import React, { useState } from "react";

import EquationEditor from "equation-editor-react";

const EquationEditorHolder = ({ setdescription, description }) => {
  const [equation, setEquation] = useState("");
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          width: "700px",
          height: "200px",
          border: "2px solid black",
        }}
      >
        <EquationEditor
          value={equation}
          onChange={setEquation}
          autoCommands="pi theta sqrt sum prod alpha beta gamma rho"
          autoOperatorNames="sin cos tan"
        />
      </div>
      <button
        className="btn btn-priamry mt-3"
        onClick={() => {
          setdescription((prevItems) => [
            ...prevItems,
            {
              topic_type: "math",
              description: equation,
            },
          ]);
          setEquation("");
        }}
      >
        Add to Description
      </button>
    </>
  );
};

export default EquationEditorHolder;
