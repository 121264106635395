import { useState } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragHandle } from 'components/CreateSequence/partials/DragHandle';

const Sequence = ({ chapterTopicsAndQuizzesSequence, updateChapterSequenceHandler }) => {

  const [list, setList] = useState(chapterTopicsAndQuizzesSequence);

  const updateListHandler = (param) => {
    const srcI = param?.source?.index;
    const desI = param?.destination?.index;

    if (desI === undefined || (srcI === desI))
      return;

    const [...rest] = list;
    const item = rest[srcI];
    const temp = rest.filter((e, i) => i != srcI);
    const updateList = [...temp.slice(0, desI), item, ...temp.slice(desI)];

    setList(updateList);
  }

  const updateSequence = () => updateChapterSequenceHandler(list);


  const isListUpdate = list?.length > 0 && list.filter((e, i) => e._id === chapterTopicsAndQuizzesSequence[i]._id);

  return (

    list && list.length > 0 ?
      <div className='sequence'>

        <div className="update_btn">
          <button className='btn btn-secondary'
            disabled={isListUpdate?.length === chapterTopicsAndQuizzesSequence?.length}
            onClick={updateSequence} >
            Update
          </button>
          <button className='btn btn-secondary'
            disabled={isListUpdate?.length === chapterTopicsAndQuizzesSequence?.length}
            onClick={() => setList(chapterTopicsAndQuizzesSequence)} >
            Reset Sequence
          </button>
        </div>

        <div className="sequence-list">


          <DragDropContext
            onDragEnd={updateListHandler}
          >

            <Droppable droppableId="topic_quiz" >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >

                  {list?.map((item, index) =>
                    <Draggable key={item?._id} draggableId={item?._id} index={index}>
                      {(provided, snapshot) => (

                        <div ref={provided.innerRef}
                          {...provided.draggableProps}
                          // {...provided.dragHandleProps}
                          className='topic_quiz_list'
                        >
                          <div>
                            <DragHandle {...provided.dragHandleProps} />
                            {item?.test?.test_title ?? item?.topic?.topic_title ?? null}
                          </div>

                          {item?.test && <>Quiz</>}
                          {item?.topic && <p>Topic</p>}

                        </div>
                      )}
                    </Draggable>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        </div>
      </div>
      : <h3 className='pl-4' >Topic or Quiz not entered yet</h3>

  )
}

Sequence.propTypes = {
  chapterTopicsAndQuizzesSequence: PropTypes.array.isRequired,
  updateChapterSequenceHandler: PropTypes.func.isRequired,
}

export default Sequence;