import React from "react";
import { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import { connect, useDispatch, useSelector } from "react-redux";
import { FaRegEdit, FaBan, FaCheckCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  updateGradeBackend,
  addGradeBackend,
  updateStatusBackend,
} from "Utils/grades_Utils";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import axios from "../api";
import { useHistory } from "react-router-dom";
import { selectgrades } from "Redux/features/Grade/GradeSlice";
import { setGrade, editgrade, addgrade } from "Redux/features/Grade/GradeSlice";
import { editgradeStatus } from "Redux/features/Grade/GradeSlice";
import loader from "../assets/img/loader.svg";
import loaderimg from "../assets/img/loaderimg.svg";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { setGradeDropdown } from "Redux/features/Subject/SubjectSlice";
import BreadCrumbs from "components/Common/BreadCrumbs";
import {
  setIsNotificationModalShow,
  setSendNotificationID,
  setSendNotificationType,
} from "Redux/features/Auth/AuthSlice";
import { backendURL } from "../api";

const Grades = () => {
  const history = useHistory();
  const grades = useSelector(selectgrades);
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const [loadingImage, setloadingImage] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const fetchGrade = async () => {
    dispatch(setloading(true));
    axios
      .get(`/grades`)
      .then((res) => {
        dispatch(setGrade(res.data.data));
        setTimeout(() => {
          dispatch(setloading(false));
        }, 1000);
      })
      .catch((err) => {
        dispatch(setloading(false));
        toast.error(err);
      });
  };

  useEffect(() => {
    fetchGrade();
  }, []);

  const [gradeName, setgradeName] = useState("");
  const [coursePlan, setcoursePlan] = useState("");
  const [grade_id, setgrade_id] = useState("");
  const [gradeIcon, setgradeIcon] = useState([]);
  const [addgradeIcon, setaddgradeIcon] = useState("");
  const [gradeDisplayIcon, setgradeDisplayIcon] = useState("");
  const [gradeStatus, setgradeStatus] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [modalGrade, setmodalGrade] = useState({});
  const [activity, setactivity] = useState("");
  const [price, setPrice] = useState("");
  const [uniCode, setUniCode] = useState("");
  const [country, setCountry] = useState("IRAQ");

  const changeStatus = (gd) => {
    setmodalGrade(gd);
    setStatusModal(true);
  };
  const resetStates = () => {
    setgradeName("");
    // setcoursePlan('');
    setaddgradeIcon("");
    setgradeStatus("");
    setPrice("");
    const allInput = document.getElementsByClassName("gradeIconInput");
    for (var j = 0; j < allInput.length; j++) allInput[j].value = "";
  };
  const editGradeInternally = () => {
    let grad = {
      _id: grade_id,
      grade: gradeName,
      grade_icon: addgradeIcon,
      isActive: gradeStatus,
      // course_plan: coursePlan,
      grade_price: price,
      uniCode: uniCode,
      country
    };

    updateGradeBackend(grade_id, grad, dispatch);
    dispatch(setGradeDropdown(null));
    fetchGrade();
  };

  const Add_Grade = () => {
    // course_plan: coursePlan,
    let grad = {
      grade: gradeName,
      grade_icon: addgradeIcon,
      grade_price: price,
      isActive: true,
      uniCode: uniCode,
      country,
    };

    addGradeBackend(grad, setAddModal, dispatch);
    dispatch(setGradeDropdown(null));
    fetchGrade();
  };

  function uploadImage() {
    setloadingImage(true);
    const formData = new FormData();

    formData.append("files", gradeIcon[0]);

    axios
      .post(
        `${backendURL}/filesupload/file-upload`,
        formData,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      )
      .then((res) => {
        setaddgradeIcon(res.data.locationArray[0].fileLocation);
      })
      .catch((err) => {
        setloadingImage(false);
        toast.error(err.response.data.message);
      });

    setTimeout(() => {
      setloadingImage(false);
    }, 1000);
    setgradeIcon([]);
  }
  const changeHandler = (event) => {
    if (!event.target.files[0]) {
      setgradeDisplayIcon("");
      setgradeIcon([]);
      setaddgradeIcon("");
      return;
    }

    setgradeDisplayIcon(event.target.files[0].name);
    // setgradeIcon(event.target.files[0]);
    gradeIcon.push(event.target.files[0]);
    uploadImage();
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="grades content">
            <BreadCrumbs
              breadCrumbList={[{ title: "Grades", pathName: "/admin/grades" }]}
            />
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <CardTitle
                      tag="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: "36px", cursor: "pointer" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{" "}
                      <h4 style={{ marginLeft: "10px" }}>Grades</h4>
                    </CardTitle>
                    <div>
                      <button
                        className="btn btn-priamry"
                        onClick={() => {
                          setAddModal(true);
                          resetStates();
                        }}
                        style={{
                          marginBottom: "3%",
                          marginLeft: "2%",
                          fontSize: "14px",
                          border: "0",
                          width: "250px",
                        }}
                      >
                        Add Grade
                      </button>
                      <div className="dropdown">
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          role="button"
                          id="dropdownMenuLinkGrade"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ marginLeft: "2%", width: "250px" }}
                        >
                          {activity === ""
                            ? "Select Active/Inactive"
                            : activity}
                        </a>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLinkGrade"
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            onClick={() => {
                              setactivity("Active");
                              // setcoursePlan("premium");
                            }}
                          >
                            Active
                          </a>
                          <a
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            onClick={() => {
                              setactivity("InActive");
                              // setcoursePlan("freemium");
                            }}
                          >
                            InActive
                          </a>
                          <a
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            onClick={() => {
                              setactivity("");
                              // setcoursePlan("freemium");
                            }}
                          >
                            None
                          </a>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            {activity === "" ? (
              <Row>
                {grades.map((grad) => (
                  <Col md="4">
                    <Card>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "30%",
                            height: "auto",
                            background:
                              "linear-gradient(to right, #649173, #dbd5a4)",
                          }}
                        >
                          {grad.grade_icon ? (
                            <img src={grad.grade_icon} alt="logo" />
                          ) : (
                            "Logo"
                          )}
                        </div>
                        <div>
                          <CardHeader>
                            <CardTitle tag="p" style={{ fontSize: "18px" }}>
                              {grad.grade}
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            {/* <p style={{ fontSize: '12px' }}>
                              {' '}
                              Course Plan: {grad.course_plan}
                            </p> */}
                            {/* {grad.course_plan === 'premium' && ( */}
                            <p style={{ fontSize: "12px" }}>
                              Grade Price: {grad?.grade_price}
                            </p>
                            <p style={{ fontSize: "12px" }}>
                              uniCode: {grad?.uniCode}
                            </p>
                            <p style={{ fontSize: "12px" }}>
                              Country: {grad?.country || "IRAQ"}
                            </p>
                            {/* )} */}
                            <button
                              style={{
                                marginTop: "4%",
                                marginBottom: "2%",
                                backgroundColor: "transparent",
                                border: "0px",
                              }}
                              onClick={() => {
                                setgradeName(grad.grade);
                                // setcoursePlan(grad.course_plan);
                                setgrade_id(grad._id);
                                setaddgradeIcon(grad.grade_icon);
                                setgradeStatus(grad.isActive);
                                setPrice(grad.grade_price);
                                setUniCode(grad.uniCode);
                                setEditModal(true);
                              }}
                            >
                              <i
                                className="far fa-edit"
                                style={{ fontSize: "15px", color: "grey" }}
                              ></i>
                            </button>
                            <button
                              style={{
                                marginTop: "4%",
                                marginBottom: "2%",
                                backgroundColor: "transparent",
                                border: "0px",
                              }}
                              onClick={() => {
                                setgradeName(grad.grade);
                                setcoursePlan(grad.course_plan);
                                setgrade_id(grad._id);
                              }}
                            >
                              {!grad.isActive ? (
                                <FaBan
                                  style={{ fontSize: "18px", color: "red" }}
                                  onClick={() => {
                                    setmodalGrade(grad);
                                    changeStatus(grad);
                                  }}
                                />
                              ) : (
                                <FaCheckCircle
                                  onClick={() => {
                                    setmodalGrade(grad);
                                    changeStatus(grad);
                                  }}
                                  style={{
                                    fontSize: "18px",
                                    color: "green",
                                  }}
                                />
                              )}
                            </button>

                            {/* <div>
                              <button
                                onClick={() => {
                                  dispatch(setIsNotificationModalShow(true));
                                  dispatch(setSendNotificationID(grad?._id));
                                  dispatch(setSendNotificationType("grade"));
                                }}
                              >
                                Send Notification
                              </button>
                            </div> */}
                          </CardBody>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : activity === "Active" ? (
              <Row>
                {grades.map((grad) =>
                  grad.isActive === true ? (
                    <Col md="4">
                      <Card>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "30%",
                              height: "auto",
                              background:
                                "linear-gradient(to right, #649173, #dbd5a4)",
                            }}
                          >
                            {grad.grade_icon ? (
                              <img src={grad.grade_icon} alt="logo" />
                            ) : (
                              "Logo"
                            )}
                          </div>
                          <div>
                            <CardHeader>
                              <CardTitle tag="p" style={{ fontSize: "18px" }}>
                                {grad.grade}
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <p style={{ fontSize: "12px" }}>
                                {" "}
                                Course Plan: {grad.course_plan}
                              </p>
                              <button
                                style={{
                                  marginTop: "4%",
                                  marginBottom: "2%",
                                  backgroundColor: "transparent",
                                  border: "0px",
                                }}
                                onClick={() => {
                                  setgradeName(grad.grade);
                                  setcoursePlan(grad.course_plan);
                                  setgrade_id(grad._id);
                                  setaddgradeIcon(grad.grade_icon);
                                  setgradeStatus(grad.isActive);
                                  setEditModal(true);
                                }}
                              >
                                <i
                                  className="far fa-edit"
                                  style={{ fontSize: "15px", color: "grey" }}
                                ></i>
                              </button>
                              <button
                                style={{
                                  marginTop: "4%",
                                  marginBottom: "2%",
                                  backgroundColor: "transparent",
                                  border: "0px",
                                }}
                                onClick={() => {
                                  setgradeName(grad.grade);
                                  setcoursePlan(grad.course_plan);
                                  setgrade_id(grad._id);
                                }}
                              >
                                {!grad.isActive ? (
                                  <FaBan
                                    style={{ fontSize: "18px", color: "red" }}
                                    onClick={() => {
                                      setmodalGrade(grad);
                                      changeStatus(grad);
                                    }}
                                  />
                                ) : (
                                  <FaCheckCircle
                                    onClick={() => {
                                      setmodalGrade(grad);
                                      changeStatus(grad);
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      color: "green",
                                    }}
                                  />
                                )}
                              </button>
                            </CardBody>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ) : null
                )}
              </Row>
            ) : (
              <Row>
                {grades.map((grad) =>
                  grad.isActive === false ? (
                    <Col md="4">
                      <Card>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "30%",
                              height: "auto",
                              background:
                                "linear-gradient(to right, #649173, #dbd5a4)",
                            }}
                          >
                            {grad.grade_icon ? (
                              <img src={grad.grade_icon} alt="logo" />
                            ) : (
                              "Logo"
                            )}
                          </div>
                          <div>
                            <CardHeader>
                              <CardTitle tag="p" style={{ fontSize: "18px" }}>
                                {grad.grade}
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <p style={{ fontSize: "12px" }}>
                                {" "}
                                Course Plan: {grad.course_plan}
                              </p>
                              <button
                                style={{
                                  marginTop: "4%",
                                  marginBottom: "2%",
                                  backgroundColor: "transparent",
                                  border: "0px",
                                }}
                                onClick={() => {
                                  setgradeName(grad.grade);
                                  setcoursePlan(grad.course_plan);
                                  setgrade_id(grad._id);
                                  setaddgradeIcon(grad.grade_icon);
                                  setgradeStatus(grad.isActive);
                                  setEditModal(true);
                                }}
                              >
                                <i
                                  className="far fa-edit"
                                  style={{ fontSize: "15px", color: "grey" }}
                                ></i>
                              </button>
                              <button
                                style={{
                                  marginTop: "4%",
                                  marginBottom: "2%",
                                  backgroundColor: "transparent",
                                  border: "0px",
                                }}
                                onClick={() => {
                                  setgradeName(grad.grade);
                                  setcoursePlan(grad.course_plan);
                                  setgrade_id(grad._id);
                                }}
                              >
                                {!grad.isActive ? (
                                  <FaBan
                                    style={{ fontSize: "18px", color: "red" }}
                                    onClick={() => {
                                      setmodalGrade(grad);
                                      changeStatus(grad);
                                    }}
                                  />
                                ) : (
                                  <FaCheckCircle
                                    onClick={() => {
                                      setmodalGrade(grad);
                                      changeStatus(grad);
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      color: "green",
                                    }}
                                  />
                                )}
                              </button>
                            </CardBody>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ) : null
                )}
              </Row>
            )}
          </div>
          <Modal
            visible={statusModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setStatusModal(false);
            }}
          >
            <div
              className="modal-container"
              style={{ padding: "3%", textAlign: "center" }}
            >
              <h4 className="mb-4" style={{ color: "#242b3b" }}>
                Change Status
              </h4>

              {modalGrade.isActive ? (
                <p style={{ fontSize: "20px" }}>
                  Are you sure you want to restrict Grade{" "}
                  {modalGrade.subject_name}?
                </p>
              ) : (
                <p style={{ fontSize: "20px" }}>
                  Are you sure you want to change this Grade's status to active?
                </p>
              )}
              <div
                className="w-100 d-flex justify-content-between mt-4"
                style={{ padding: "2%", paddingBottom: "3%" }}
              >
                <button
                  className="btn btn-danger"
                  onClick={() => setStatusModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-warning"
                  onClick={() => {
                    setStatusModal(false);
                    updateStatusBackend(
                      modalGrade._id,
                      modalGrade.isActive,
                      setStatusModal,
                      dispatch
                    );
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            visible={editModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setEditModal(false);
              setaddgradeIcon("");
              document.getElementById("editgradeIconInput").value = "";
            }}
          >
            <div className="modal-container">
              <h4
                className="mb-4"
                style={{ color: "#242b3b", marginLeft: "35%" }}
              >
                Edit Grade
              </h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setEditModal(false);
                  editGradeInternally();
                }}
              >
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Name</p>
                  <input
                    type="text"
                    required
                    name="firstName"
                    value={gradeName}
                    onChange={(e) => {
                      setgradeName(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Grade Icon</p>
                  <div style={{ display: "flex" }}>
                    <input
                      id="editgradeIconInput"
                      type="file"
                      name="gradeIcon"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler}
                    />
                    {loadingImage ? (
                      <img src={loaderimg} alt="loading..." />
                    ) : null}
                  </div>
                  <p style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    Current Image : {addgradeIcon?.split("/")[3] || null}
                  </p>
                  {addgradeIcon && (
                    <div className="grade_img">
                      <img src={addgradeIcon} alt="gradeImage" />
                    </div>
                  )}
                </div>
                {/* <div
                  className="mb-2"
                  style={{
                    marginLeft: '10%',
                    marginRight: '10%',
                    marginTop: '3%',
                  }}
                >
                  <p>Grade</p>
                  <div className="dropdown">
                    <a
                      className="btn btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLinkGrade"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {coursePlan}
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLinkGrade"
                    >
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setcoursePlan('premium');
                        }}
                      >
                        premium
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setcoursePlan('freemium');
                        }}
                      >
                        freemium
                      </a>
                    </div>
                  </div>
                </div> */}

                {/* {coursePlan === 'premium' && ( */}
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Grade Price</p>
                  <input
                    type="number"
                    required
                    name="gradePrice"
                    placeholder="Enter grade price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                  />

                  <p>Uni Code</p>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      required
                      name="uniCode"
                      value={uniCode}
                      onChange={(e) => {
                        setUniCode(e.target.value);
                      }}
                    />
                  </div>

                  <p>Country</p>
                  <div style={{ display: "flex" }}>
                    <select
                      required
                      name="country"
                      value={country}
                      placeholder="Select country"
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      className="form-control"
                    >
                      {["IRAQ", "SAUDI_ARABIA"].map((e) => (
                        <option key={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* )} */}

                <div
                  className="w-100 d-flex justify-content-end mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => {
                      closeEventFunction();
                      setEditModal(false);
                    }}
                  >
                    Cancel
                  </button> */}
                  <button
                    className="btn btn-warning"
                    type="submit"
                    disabled={
                      !(gradeName && (price || price === 0) && addgradeIcon)
                    }
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            visible={addModal}
            width="500"
            effect="fadeInUp"
            onClickAway={() => {
              setAddModal(false);
            }}
          >
            <div className="modal-container">
              <h4
                className="mb-4"
                style={{ color: "#242b3b", marginLeft: "35%" }}
              >
                Add Grade
              </h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  Add_Grade();
                  setAddModal(false);
                }}
              >
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Name</p>
                  <input
                    type="text"
                    required
                    name="firstName"
                    value={gradeName}
                    onChange={(e) => {
                      setgradeName(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Grade Icon</p>
                  <div style={{ display: "flex" }}>
                    <input
                      type="file"
                      name="gradeIcon"
                      accept="image/jpeg, image/png"
                      onChange={changeHandler}
                      min={0}
                      required
                    />
                    {loadingImage ? (
                      <img src={loaderimg} alt="loading..." />
                    ) : null}
                  </div>
                  <p>Current Image : {addgradeIcon?.split("/")[3] || null}</p>
                  {addgradeIcon && (
                    <div className="grade_img">
                      <img src={addgradeIcon} alt="gradeImage" />
                    </div>
                  )}
                </div>
                {/* <div
                  className="mb-2"
                  style={{
                    marginLeft: '10%',
                    marginRight: '10%',
                    marginTop: '3%',
                  }}
                >
                  <p>Grade</p>
                  <div className="dropdown">
                    <a
                      className="btn btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLinkGrade"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {coursePlan}
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLinkGrade"
                    >
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setcoursePlan('premium');
                        }}
                      >
                        premium
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setcoursePlan('freemium');
                        }}
                      >
                        freemium
                      </a>
                    </div>
                  </div>
                </div> */}

                {/* {coursePlan === 'premium' && ( */}
                <div
                  className="mb-2"
                  style={{ marginLeft: "10%", marginRight: "10%" }}
                >
                  <p>Grade Price</p>
                  <input
                    type="number"
                    required
                    name="gradePrice"
                    placeholder="Enter grade price"
                    min={0}
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                  />
                  {/* )} */}

                  <p>Uni Code</p>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      required
                      name="uniCode"
                      value={uniCode}
                      placeholder="Enter uniCode"
                      onChange={(e) => {
                        setUniCode(e.target.value);
                      }}
                    />
                  </div>

                  <p>Country</p>
                  <div style={{ display: "flex" }}>
                    <select
                      required
                      name="country"
                      value={country}
                      placeholder="Select country"
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      className="form-control"
                    >
                      {["IRAQ", "SAUDI_ARABIA"].map((e) => (
                        <option key={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  className="w-100 d-flex justify-content-end mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => {
                      setAddModal(false);
                    }}
                  >
                    Cancel
                  </button> */}
                  {/* {gradeName !== '' &&
                  coursePlan !== '' &&
                  addgradeIcon !== '' ? (
                    <button className="btn btn-warning" type="submit">
                      Add
                    </button>
                  ) : ( */}
                  <button
                    className="btn btn-warning"
                    type="submit"
                    disabled={
                      !(gradeName && (price || price === 0) && addgradeIcon)
                    }
                  >
                    Add
                  </button>
                  {/* )} */}
                </div>
              </form>
            </div>
          </Modal>
        </>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={loader} alt="Loading...." />
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Grades;
