import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getStudentReferPointsRecord } from "Utils/referral_Utils";

import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import Header from "components/Common/Header";
import Loader from "components/Common/Loader";
import ReferPointsRecordList from "./ReferPointsRecordList";
import BreadCrumbs from "components/Common/BreadCrumbs";
import { getStudentDetails } from "Utils/referral_Utils";

const ReferPointsRecordWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState(null);
  const [studentObj, setStudentObj] = useState(null);

  const param = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const resp = await getStudentReferPointsRecord(param.studentID);
        const studentDetails = await getStudentDetails(param.studentID);
        setStudentObj(studentDetails?.data.data[0]);
        const filterAvailedBy = resp.data.data.filter((x) =>
          Boolean(x.availedBy)
        );
        setRecordList(filterAvailedBy);
      } catch (error) {
        toast.error(error?.response?.data?.message || error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const displayStudentName = () =>
    studentObj
      ? studentObj?.user_name ||
        `${studentObj?.first_name} ${studentObj?.last_name}`
      : "";

  return (
    <div className="refer_points_record content">
      <BreadCrumbs
        breadCrumbList={[
          { title: displayStudentName(), pathName: "/admin/students" },
          {
            title: "Student Referrer Point Record",
            pathName: `/admin/students/refer-points/record/${param?.studentID}`,
          },
        ]}
      />
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Header title="Refer Point Record" />
              {loading ? (
                <Loader />
              ) : (
                <ReferPointsRecordList recordList={recordList} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReferPointsRecordWrapper;
