
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom';
import MaterialTableIcons from 'components/Common/MaterialTableIcons';
import MaterialTable from 'material-table'
import { BackgroundColorContext } from 'contexts/BackgroundColorContext';
import { format } from 'date-fns';
import { useContext } from 'react';
import { FaBan, FaCheckCircle, FaRegEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';

const TeachersList = ({ teachersList, editTeacherHandler, changeStatus, assignSubjectHandler, openTeacherPaymentScreen }) => {

  const param = useParams();
  const history = useHistory();
  const { color } = useContext(BackgroundColorContext)
  const { tableIcons } = MaterialTableIcons();


  const renderTeacherName = (value, teacherObj) => (
    <div className="teacher_name">
      <h1 onClick={() => editTeacherHandler(teacherObj)} >{value}</h1>
    </div>
  )

  const renderCellValue = (value) => (
    <div className="value">
      <h1>{value}</h1>
    </div>
  )

  const renderStatus = (value) => (
    <div className="status">
      <h1 className={value?.toLowerCase()} >{value}</h1>
    </div>
  )

  const renderAction = (teacherID, teacherObj, index) => (
    <div className="actions">
      <FaRegEdit id={`edit_${index}`} className='icon' title='Edit teacher' onClick={() => editTeacherHandler(teacherObj)} />

      {teacherObj?.isActive ? <FaCheckCircle id={`status_${index}`} className='icon' title='Change Status' onClick={() => changeStatus(teacherObj)} />
        : <FaBan id={`status_${index}`} className='icon red' title='Change Status' onClick={() => changeStatus(teacherObj)} />}

      <i id={`assign_${index}`} className="fas fa-tasks icon" title='Assign subject' aria-hidden="true"
        onClick={() => assignSubjectHandler(teacherObj)} ></i>

      <i id={`payment${index}`} className="fas fa-money-check-alt icon" title='View Payment' aria-hidden="true"
        onClick={() => openTeacherPaymentScreen(teacherID)} ></i>
    </div>
  )

  const colorsList = [
    { color: "blue", value: "#44a3ff" },
    { color: "primary", value: "#ba54f5" },
    { color: "green", value: "#38e3c2" }
  ];

  let count = 0;
  let rowID = 0;
  return (
    <div className='teacher-list'>

      <MaterialTable
        icons={tableIcons}
        title={null}
        key={count++}
        columns={
          [
            // {
            //   title: "#", field: 'index',
            //   render: rowData => renderReferRowData(rowData.index),
            //   filtering: false
            // },
            {
              title: "Name", field: 'name',
              render: rowData => renderTeacherName(rowData?.name, rowData?.teacherObj),
            },
            {
              title: "Email/Facebook", field: 'userEmail',
              render: rowData => renderCellValue(rowData?.userEmail),
            },
            {
              title: "Phone Number", field: 'phone_number',
              render: rowData => renderCellValue(rowData?.phone_number),
            },
            {
              title: "City", field: 'city',
              render: rowData => renderCellValue(rowData?.city),
            },
            {
              title: "Last Visit", field: 'lastVisit',
              render: rowData => renderCellValue(rowData?.lastVisit),
            },
            {
              title: "Status", field: 'isActive',
              render: rowData => renderStatus(rowData?.isActive),
              customFilterAndSearch: (term, rowData) => !(rowData?.isActive?.toLowerCase().localeCompare(term.toLowerCase()))
            },
            {
              title: "Actions", field: '_id',
              render: rowData => renderAction(rowData?._id, rowData?.teacherObj, rowData?.index),
              filtering: false
            },
          ]
        }

        data={
          teachersList?.length ? teachersList.map((e) => {
            if (e?.role === "teacher")
              return {
                index: ++count,
                teacherObj: e,
                _id: e._id,
                name: `${e?.first_name}  ${e?.last_name}` || "-",
                isActive: e?.isActive ? "Active" : "In-Active",
                userEmail: e?.email || "-",
                phone_number: e?.phone_number || "-",
                city: e?.city || "-",
                // lastVisit: e?.last_visit || "-",
                lastVisit: e.last_visit ? format(new Date(e.last_visit), "PPpp") : "-",
              }
          }) : []}

        options={{
          actionsColumnIndex: -1,
          exportButton: false,
          pageSize: 10,
          pageSizeOptions: [5, 10, 30, 50],
          headerStyle: {
            backgroundColor: colorsList.find(e => e.color === color).value,
            color: '#FFF'
          },
          filtering: true,
        }}
      // components={{
      //   Row: (props) => <MTableBodyRow id={rowID++} {...props} />
      // }}
      />
    </div>
  )
}

TeachersList.propTypes = {
  teachersList: PropTypes.array.isRequired,
  editTeacherHandler: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  assignSubjectHandler: PropTypes.func.isRequired,
  openTeacherPaymentScreen: PropTypes.func.isRequired,
}

export default TeachersList
