import "../../Templates/Login/css/style.css";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { RiLockPasswordFill, RiMailOpenFill } from "react-icons/ri";
import { loginFormSchema } from "./validations";
import axios from "../../api";
import { useHistory } from "react-router";
import { setauth } from "Redux/features/Auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import loader from "assets/img/loader.svg";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { checkUser } from "Utils";
const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);

  return (
    <>
      <div className="main">
        <div className="signup">
          <div className="container" style={{ width: "70%" }}>
            <div className="signup-content">
              <div className="signup-form">
                <h2
                  className="form-title"
                  style={{
                    textAlign: "left",
                    marginBottom: "1%",
                    marginTop: "-1%",
                    textDecoration: "underline",
                    textDecorationColor: " #93cf35",
                    color: "#222",
                    fontSize: "40px",
                  }}
                >
                  {" "}
                  Corrsy.{" "}
                </h2>
                <p
                  style={{
                    marginLeft: "2%",
                    marginBottom: "5%",
                    fontSize: "17px",
                    color: "grey",
                  }}
                >
                  # Learn Something New Everyday
                </p>
                <h4
                  style={{
                    marginBottom: "5%",
                    color: "darkgrey",
                    fontSize: "35px",
                  }}
                >
                  Sign In{" "}
                </h4>
                {!loading ? (
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={loginFormSchema}
                    onSubmit={(values) => {
                      checkUser(values, dispatch, history);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="form-group">
                            {/* <label for="name"><i className="zmdi zmdi-account material-icons-name"></i></label> */}
                            <RiMailOpenFill style={{ fontSize: "18px" }} />
                            <Field
                              style={{ fontSize: "16px" }}
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Your Email"
                            />
                            <ErrorMessage name="email">
                              {(error) => (
                                <div
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  {error}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="form-group">
                            <RiLockPasswordFill style={{ fontSize: "18px" }} />
                            <Field
                              style={{ fontSize: "16px" }}
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Your Password"
                            />
                            <ErrorMessage name="password">
                              {(error) => (
                                <div
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  {error}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="form-group form-button">
                            <input
                              style={{
                                fontSize: "18px",
                                padding: "1%",
                                height: "30%",
                                width: "28%",
                              }}
                              type="submit"
                              name="signup"
                              id="signup"
                              className="form-submit"
                              value="Login"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                ) : (
                  <img src={loader} alt="Loading...." />
                )}
              </div>
              <div className="signup-image">
                <div>
                  <img
                    src="https://image.freepik.com/free-vector/sign-page-abstract-concept-illustration_335657-3875.jpg"
                    alt="sing up image"
                  />{" "}
                </div>
                <a
                  className="signup-image-link"
                  style={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => {
                    history.push("/admin/forgot-password");
                  }}
                >
                  Forgot Password?{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
