import { FaRegEdit, FaBan, FaCheckCircle } from "react-icons/fa";
import Modal from "react-awesome-modal";
import React from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-dt/";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../api";
import {
  updateStatusBackend,
  addTeacherBackend,
  updateTeacherBackend,
} from "Utils/teacher_Utils";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  setteacher,
  addteacher,
  editteacher,
  editteacherStatus,
} from "Redux/features/Teacher/TeacherSlice";
import { selectteachers } from "Redux/features/Teacher/TeacherSlice";
import loader from "../assets/img/loader.svg";
import { ToastContainer, toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";
const Teachers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const teachers = useSelector(selectteachers);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [editUser, setEditUser] = useState({});
  const loading = useSelector(selectLoading);
  const [editValues, setEditValues] = useState({
    _id: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [addValues, setAddValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [selectedTeacher, setselectedTeacher] = useState("");
  const [activity, setactivity] = useState("");
  const handleEditChange = (props) => (event) => {
    setEditValues({
      ...editValues,
      [props]: event.target.value,
    });
  };

  const handleAddChange = (props) => (event) => {
    setAddValues({
      ...addValues,
      [props]: event.target.value,
    });
  };
  useEffect(() => {
    async function fetchTeachers() {
      dispatch(setloading(true));
      axios
        .get(`/user?role=teacher`)
        .then((res) => {
          dispatch(setteacher(res.data.data));
          console.log("Teacher Data : ", res.data.data);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    fetchTeachers();
  }, []);

  const handleAddTeacher = () => {
    let teacher = {
      first_name: addValues.first_name,
      last_name: addValues.last_name,
      email: addValues.email,
      role: "teacher",
      isActive: true,
    };
    // console.log(teacher);
    addTeacherBackend(teacher, setAddModal, dispatch);
    // props.add_teacher(teacher);
  };

  const handleEditTeacher = () => {
    let teacher = {
      first_name: editValues.first_name,
      last_name: editValues.last_name,
      email: editValues.email,
    };

    updateTeacherBackend(editValues._id, teacher, setEditModal, dispatch);
    setEditModal(false);
  };

  if (activity == "") {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: "36px", cursor: "pointer" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{" "}
                      <h4 style={{ marginLeft: "10px" }}>Teachers</h4>
                    </CardTitle>
                    <div style={{ display: "flex" }}>
                      <p className="category">
                        {" "}
                        Total {teachers.length} Teachers Registered
                      </p>
                      <div style={{ marginLeft: "66%" }}>
                        <button
                          className="btn btn-priamry"
                          onClick={() => {
                            setAddModal(true);
                            setAddValues({
                              first_name: "",
                              last_name: "",
                              email: "",
                            });
                          }}
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          Add Teacher
                        </button>
                        <div className="dropdown">
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: "220px" }}
                          >
                            {activity === ""
                              ? "Select Active/Inactive"
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("Active");
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("InActive");
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("");
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col>
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email</th>

                              <th className="th-sm">Status</th>

                              <th className="th-sm">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {teachers.map((user) => (
                              <tr style={{ backgroundColor: "transparent" }}>
                                <td>
                                  {user.first_name} {user.last_name}
                                </td>
                                <td>{user.email}</td>

                                <td>
                                  {user.isActive ? (
                                    <p style={{ color: "green" }}>Active</p>
                                  ) : (
                                    <p style={{ color: "red" }}>Inactive</p>
                                  )}
                                </td>

                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "30%",
                                    }}
                                  >
                                    <a
                                      onClick={() => {
                                        setEditValues({
                                          ...editValues,
                                          ["_id"]: user._id,
                                          ["first_name"]: user.first_name,
                                          ["last_name"]: user.last_name,
                                          ["email"]: user.email,
                                        });
                                        setEditUser(user);
                                        setEditModal(true);
                                      }}
                                    >
                                      {" "}
                                      <FaRegEdit
                                        style={{
                                          fontSize: "18px",
                                          color: "#008B8B",
                                        }}
                                      ></FaRegEdit>{" "}
                                    </a>
                                    <p style={{ flexGrow: "1" }}></p>
                                    {user.isActive ? (
                                      <a style={{ marginRight: "50%" }}>
                                        {" "}
                                        <FaCheckCircle
                                          onClick={() => {
                                            setEditUser(user);
                                            setselectedTeacher(user.isActive);
                                            setStatusModal(true);
                                          }}
                                          style={{
                                            fontSize: "18px",
                                            color: "green",
                                          }}
                                        ></FaCheckCircle>{" "}
                                      </a>
                                    ) : (
                                      <a style={{ marginRight: "50%" }}>
                                        {" "}
                                        <FaBan
                                          onClick={() => {
                                            setEditUser(user);
                                            setselectedTeacher(user.isActive);
                                            setStatusModal(true);
                                          }}
                                          style={{
                                            fontSize: "18px",
                                            color: "red",
                                          }}
                                        ></FaBan>{" "}
                                      </a>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={editModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setEditModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "35%" }}
                >
                  Edit Teacher
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEditTeacher();
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="first_name"
                      value={editValues.first_name}
                      onChange={handleEditChange("first_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="last_name"
                      value={editValues.last_name}
                      onChange={handleEditChange("last_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={editValues.email}
                      onChange={handleEditChange("email")}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={addModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setAddModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "26%" }}
                >
                  Create New Teacher
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddTeacher();
                    setAddModal(false);
                    setAddValues({
                      first_name: "",
                      last_name: "",
                      email: "",
                    });
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={addValues.first_name}
                      onChange={handleAddChange("first_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={addValues.last_name}
                      onChange={handleAddChange("last_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={addValues.email}
                      onChange={handleAddChange("email")}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={statusModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setStatusModal(false);
              }}
            >
              <div
                className="modal-container"
                style={{ padding: "3%", textAlign: "center" }}
              >
                <h4 className="mb-4" style={{ color: "#242b3b" }}>
                  Change Status
                </h4>

                {editUser.isActive ? (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to restrict this user?
                  </p>
                ) : (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to change this user's status to
                    active?
                  </p>
                )}
                <div
                  className="w-100 d-flex justify-content-between mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setStatusModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      setStatusModal(false);
                      updateStatusBackend(
                        editUser._id,
                        selectedTeacher,
                        dispatch
                      );
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else if (activity == "Active") {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: "36px", cursor: "pointer" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{" "}
                      <h4 style={{ marginLeft: "10px" }}>Teachers</h4>
                    </CardTitle>
                    <div style={{ display: "flex" }}>
                      <p className="category">
                        {" "}
                        Total {teachers.length} Teachers Registered
                      </p>
                      <div style={{ marginLeft: "66%" }}>
                        <button
                          className="btn btn-priamry"
                          onClick={() => setAddModal(true)}
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          Add Teacher
                        </button>
                        <div className="dropdown">
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: "220px" }}
                          >
                            {activity === ""
                              ? "Select Active/Inactive"
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("Active");
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("InActive");
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("");
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col style={{ width: "100%" }}>
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email</th>

                              <th className="th-sm">Status</th>

                              <th className="th-sm">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {teachers.map((user) =>
                              user.isActive ? (
                                <tr style={{ backgroundColor: "transparent" }}>
                                  <td>
                                    {user.first_name} {user.last_name}
                                  </td>
                                  <td>{user.email}</td>

                                  <td>
                                    {user.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          setEditValues({
                                            ...editValues,
                                            ["_id"]: user._id,
                                            ["first_name"]: user.first_name,
                                            ["last_name"]: user.last_name,
                                            ["email"]: user.email,
                                          });
                                          setEditUser(user);
                                          setEditModal(true);
                                        }}
                                      >
                                        {" "}
                                        <FaRegEdit
                                          style={{
                                            fontSize: "18px",
                                            color: "#008B8B",
                                          }}
                                        ></FaRegEdit>{" "}
                                      </a>
                                      <p style={{ flexGrow: "1" }}></p>
                                      {user.isActive ? (
                                        <a style={{ marginRight: "50%" }}>
                                          {" "}
                                          <FaBan
                                            onClick={() => {
                                              setEditUser(user);
                                              setselectedTeacher(user.isActive);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                          ></FaBan>{" "}
                                        </a>
                                      ) : (
                                        <a style={{ marginRight: "50%" }}>
                                          {" "}
                                          <FaCheckCircle
                                            onClick={() => {
                                              setEditUser(user);
                                              setselectedTeacher(user.isActive);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                          ></FaCheckCircle>{" "}
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={editModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setEditModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "35%" }}
                >
                  Edit Teacher
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEditTeacher();
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="first_name"
                      value={editValues.first_name}
                      onChange={handleEditChange("first_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="last_name"
                      value={editValues.last_name}
                      onChange={handleEditChange("last_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={editValues.email}
                      onChange={handleEditChange("email")}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={addModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setAddModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "26%" }}
                >
                  Create New Teacher
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddTeacher();
                    setAddModal(false);
                    setAddValues({
                      first_name: "",
                      last_name: "",
                      email: "",
                    });
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={addValues.first_name}
                      onChange={handleAddChange("first_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={addValues.last_name}
                      onChange={handleAddChange("last_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={addValues.email}
                      onChange={handleAddChange("email")}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={statusModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setStatusModal(false);
              }}
            >
              <div
                className="modal-container"
                style={{ padding: "3%", textAlign: "center" }}
              >
                <h4 className="mb-4" style={{ color: "#242b3b" }}>
                  Change Status
                </h4>

                {editUser.isActive ? (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to restrict this user?
                  </p>
                ) : (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to change this user's status to
                    active?
                  </p>
                )}
                <div
                  className="w-100 d-flex justify-content-between mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setStatusModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      setStatusModal(false);
                      updateStatusBackend(
                        editUser._id,
                        selectedTeacher,
                        dispatch
                      );
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else if (activity == "InActive") {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: "36px", cursor: "pointer" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{" "}
                      <h4 style={{ marginLeft: "10px" }}>Teachers</h4>
                    </CardTitle>
                    <div style={{ display: "flex" }}>
                      <p className="category">
                        {" "}
                        Total {teachers.length} Teachers Registered
                      </p>
                      <div style={{ marginLeft: "66%" }}>
                        <button
                          className="btn btn-priamry"
                          onClick={() => setAddModal(true)}
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          Add Teacher
                        </button>
                        <div className="dropdown">
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: "220px" }}
                          >
                            {activity === ""
                              ? "Select Active/Inactive"
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("Active");
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("InActive");
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("");
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col style={{ width: "100%" }}>
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email</th>

                              <th className="th-sm">Status</th>

                              <th className="th-sm">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {teachers.map((user) =>
                              !user.isActive ? (
                                <tr style={{ backgroundColor: "transparent" }}>
                                  <td>
                                    {user.first_name} {user.last_name}
                                  </td>
                                  <td>{user.email}</td>

                                  <td>
                                    {user.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          setEditValues({
                                            ...editValues,
                                            ["_id"]: user._id,
                                            ["first_name"]: user.first_name,
                                            ["last_name"]: user.last_name,
                                            ["email"]: user.email,
                                          });
                                          setEditUser(user);
                                          setEditModal(true);
                                        }}
                                      >
                                        {" "}
                                        <FaRegEdit
                                          style={{
                                            fontSize: "18px",
                                            color: "#008B8B",
                                          }}
                                        ></FaRegEdit>{" "}
                                      </a>
                                      <p style={{ flexGrow: "1" }}></p>
                                      {user.isActive ? (
                                        <a style={{ marginRight: "50%" }}>
                                          {" "}
                                          <FaBan
                                            onClick={() => {
                                              setEditUser(user);
                                              setselectedTeacher(user.isActive);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                          ></FaBan>{" "}
                                        </a>
                                      ) : (
                                        <a style={{ marginRight: "50%" }}>
                                          {" "}
                                          <FaCheckCircle
                                            onClick={() => {
                                              setEditUser(user);
                                              setselectedTeacher(user.isActive);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                          ></FaCheckCircle>{" "}
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={editModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setEditModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "35%" }}
                >
                  Edit Teacher
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEditTeacher();
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="first_name"
                      value={editValues.first_name}
                      onChange={handleEditChange("first_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="last_name"
                      value={editValues.last_name}
                      onChange={handleEditChange("last_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={editValues.email}
                      onChange={handleEditChange("email")}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={addModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setAddModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "26%" }}
                >
                  Create New Teacher
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddTeacher();
                    setAddModal(false);
                    setAddValues({
                      first_name: "",
                      last_name: "",
                      email: "",
                    });
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={addValues.first_name}
                      onChange={handleAddChange("first_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={addValues.last_name}
                      onChange={handleAddChange("last_name")}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={addValues.email}
                      onChange={handleAddChange("email")}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={statusModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setStatusModal(false);
              }}
            >
              <div
                className="modal-container"
                style={{ padding: "3%", textAlign: "center" }}
              >
                <h4 className="mb-4" style={{ color: "#242b3b" }}>
                  Change Status
                </h4>

                {editUser.isActive ? (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to restrict this user?
                  </p>
                ) : (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to change this user's status to
                    active?
                  </p>
                )}
                <div
                  className="w-100 d-flex justify-content-between mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setStatusModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      setStatusModal(false);
                      updateStatusBackend(
                        editUser._id,
                        selectedTeacher,
                        dispatch
                      );
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
};

export default Teachers;
