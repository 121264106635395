import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import { setloading } from "Redux/features/Auth/AuthSlice";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { selectstudent } from "Redux/features/Student/StudentSlice";
import axios from "../../api";
import loader from "../../assets/img/loader.svg";
const QuizPanel = () => {
  const loading = useSelector(selectLoading);
  const location = useLocation();
  let lessonID = location.pathname.split("/")[5];
  let quizID = location.pathname.split("/")[6];
  const studentList = useSelector(selectstudent);
  var pop_cus = [
    {
      path: "student",
    },
  ];
  const pop_course = encodeURI(JSON.stringify(pop_cus));
  let studentIdList = {};
  function prepopulate() {
    studentList.forEach(function (word, i) {
      studentIdList[word._id] = word;
    });
  }
  prepopulate();
  const history = useHistory();
  const dispatch = useDispatch();
  const [studentID, setstudentID] = useState(0);
  const [editQuiz, seteditQuiz] = useState(false);
  const [quizHistory, setquizHistory] = useState([]);
  const [curentQuiz, setcurentQuiz] = useState({});
  const [specificQuiz, setspecificQuiz] = useState({});
  const [message, setmessage] = useState("Fetching data for you...");
  useEffect(() => {
    function fetchRegisteredCourses() {
      axios
        .get(`/courseregistration/coursehistory?chapter=${lessonID}`)
        .then((res) => {
          let data = [];
          console.log("Data", res.data.data);
          // setquizHistory(res.data.data);
          res.data.data.forEach((quiz) => {
            if (quiz.history.test == quizID) {
              let curQuiz = quiz;
              if (curQuiz.student in studentIdList) {
                data.push(quiz);
              }
              // && quiz.student in studentIdList
            }
          });
          if (data.length === 0) {
            setmessage("No Student Attempted This Quiz Yet!");
          }
          setquizHistory(data);

          // console.log("Quiz History", data);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1200);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
      dispatch(setloading(true));
      axios
        .get(`/test/question?_id=${quizID}`)
        .then((res) => {
          setcurentQuiz(res.data.data[0]);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err);
        });
    }
    dispatch(setloading(true));
    fetchRegisteredCourses();
  }, []);

  if (quizHistory.length > 0 && editQuiz === false) {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader
                    tag="h4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Quiz Panel</h4>
                  </CardHeader>

                  <CardBody>
                    <div style={{ width: "90%", margin: "3%" }}>
                      <div style={{ width: "100%" }}>
                        <table
                          id="dtBasicExample"
                          class="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th class="th-sm">Student Name</th>

                              {/* <th class="th-sm">Chapter</th> */}
                              {/* <th class="th-sm">Topic</th> */}
                              <th class="th-sm">Quiz Name</th>
                              <th class="th-sm">Status</th>
                              <th class="th-sm">Total Marks</th>
                              <th class="th-sm">Obtained Marks</th>
                              <th class="th-sm">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quizHistory.map((item) => {
                              return (
                                <tr>
                                  <td>
                                    {studentList.map((stu) => {
                                      if (stu._id == item.student) {
                                        return (
                                          <>
                                            {stu.first_name} {stu.last_name}
                                          </>
                                        );
                                      }
                                    })}
                                  </td>

                                  {/* <td>{item.history.chapter}</td> */}
                                  <td>{curentQuiz.test_title}</td>
                                  <td>{item.history.status}</td>
                                  <td>{item.history.total_marks}</td>
                                  <td>{item.history.obtained_marks}</td>

                                  <td>
                                    {" "}
                                    <i
                                      class="fa fa-eye"
                                      style={{
                                        fontSize: "15px",
                                        color: "#008B8B",

                                        cursor: "pointer",
                                      }}
                                      aria-hidden="true"
                                      onClick={() => {
                                        setspecificQuiz(item);
                                        setstudentID(item.student);
                                        seteditQuiz(!editQuiz);
                                        dispatch(setloading(true));
                                        setTimeout(() => {
                                          dispatch(setloading(false));
                                        }, 1000);
                                      }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else if (editQuiz) {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader
                    tag="h4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        seteditQuiz(false);
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Quiz Detail</h4>
                  </CardHeader>
                  <CardBody>
                    <div style={{ width: "90%", margin: "3%" }}>
                      <div style={{ width: "100%" }}>
                        <table
                          id="dtBasicExample"
                          class="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th class="th-sm">Question</th>
                              <th class="th-sm">Student Answer</th>
                              <th class="th-sm">Correct Answer</th>
                              <th class="th-sm">Marks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {specificQuiz.history.attempted_test.map((quiz) => {
                              return (
                                <tr>
                                  <td>{quiz.question_title}</td>
                                  <td>{quiz.student_answer}</td>
                                  <td>{quiz.correct_answer}</td>
                                  <td>{quiz.marks}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else if (quizHistory.length === 0 && editQuiz === false) {
    return (
      <>
        {!loading ? (
          <div className="content">
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader
                    tag="h4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <i
                      class="fa fa-arrow-circle-left"
                      style={{ fontSize: "36px", cursor: "pointer" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>{" "}
                    <h4 style={{ marginLeft: "10px" }}>Quiz Panel</h4>
                  </CardHeader>
                  <CardTitle tag="h4" id="loadingText">
                    <h4 style={{ marginLeft: "10px" }}>{message}</h4>
                  </CardTitle>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
};

export default QuizPanel;
