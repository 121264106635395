import { toast } from 'react-toastify';
import { setloading } from 'Redux/features/Auth/AuthSlice';
import { editstudent } from 'Redux/features/Student/StudentSlice';
import { editstudentStatus } from 'Redux/features/Student/StudentSlice';
import backend from '../api';
import axios from '../api';
export function updateStatusBackend(
  id,
  setStatusModal,
  studentStatus,
  dispatch
) {
  let data = { isActive: !studentStatus };
  setStatusModal(false);
  dispatch(setloading(true));
  axios
    .put(`/user/status/${id}`, data)
    .then((res) => {
      dispatch(editstudentStatus({ _id: id, isActive: data.isActive }));
      setTimeout(() => {
        toast.success(`Student's Status Updated Successfully!`);
        dispatch(setloading(false));
      }, 1000);
    })
    .catch((err) => {
      dispatch(setloading(false));
      try {
        toast.error(err.response.data.message);
      } catch (error) {
        toast.error(err.message);
      }
    });
}
export function updateMacToDefault(id, dispatch) {
  dispatch(setloading(true));
  axios.put(`user/changemacdefault/${id}`).then((res) => {
    dispatch(editstudent(res.data.data));
    setTimeout(() => {
      toast.success(`Student's Mac Address Set to default!`);
      dispatch(setloading(false));
    }, 1000);
  });
}

export const getStudentData = async () =>
  await backend({
    method: 'GET',
    url: '/user?role=student',
  });

export const getStudentDataPagination = async (limit, page, search) =>
  await backend({
    method: 'GET',
    url: search ? `/user/pagination?limit=${limit}&page=${page}&search=${search}` : `/user/pagination?limit=${limit}&page=${page}`,
  });

export const setMacToDefault = async (id, MacReq_count) =>
  await backend({
    method: 'PUT',
    url: `user/changemacdefault/${id}`,
    data: { MacReq_count },
  });

export const getTotalActiveStudents = async () =>
  await backend({
    method: 'GET',
    url: "/user/count/active",
  });

export const getStudentAllPoints = async (studentID) =>
  await backend({
    method: 'GET',
    url: `/studentpoints?user=${studentID}`,
  });
