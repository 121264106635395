import { DragIconWrapper } from "../styles";
import { ReactComponent as DragHandleIcon } from "../drag_handle-black-18dp.svg";
import React from "react";

export function DragHandle(props) {
  return (
    // <DragIconWrapper {...props}>
    //   <DragHandleIcon style={{ color: "pink" }} />
    <i
      {...props}
      class="fa fa-bars"
      aria-hidden="true"
      style={{
        color: "pink",
        fontSize: "15px",
        marginLeft: "5px",
      }}
    ></i>
    // {/* </DragIconWrapper> */}
  );
}
