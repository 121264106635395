import { FaRegEdit, FaBan, FaCheckCircle } from "react-icons/fa";
import Modal from "react-awesome-modal";
import React from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-dt/";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import axios from "../api";
import { useHistory } from "react-router-dom";
import { selectadmins } from "Redux/features/Admin/AdminSlice";
import {
  setAdmin,
  addAdmin,
  editAdmin,
  editAdminStatue,
} from "Redux/features/Admin/AdminSlice";
import loader from "../assets/img/loader.svg";
import { ToastContainer, toast } from "react-toastify";
import { selectLoading } from "Redux/features/Auth/AuthSlice";
import { setloading } from "Redux/features/Auth/AuthSlice";

import {
  addadminbackend,
  updateAdminBackend,
  updateAdminStatus,
} from "Utils/admin_Utils";
import BreadCrumbs from "components/Common/BreadCrumbs";
const Admins = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const admins = useSelector(selectadmins);
  const loading = useSelector(selectLoading);
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [user_name, setuser_name] = useState("");
  const [email, setemail] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [activity, setactivity] = useState("");
  const [editValues, setEditValues] = useState({
    first_name: "",
    last_name: "",

    // mobile: "",
    email: "",
  });
  const [addValues, setAddValues] = useState({
    first_name: "",
    last_name: "",

    // mobile: "",
    email: "",
  });
  useEffect(() => {
    function fetchAdmins() {
      dispatch(setloading(true));
      axios
        .get(`/user?role=admin`)
        .then((res) => {
          dispatch(setAdmin(res.data.data));
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        })
        .catch((err) => {
          dispatch(setloading(false));
          toast.error(err.response.data.message);
        });
    }
    fetchAdmins();
  }, []);

  const handleAddAdmin = () => {
    let adminData = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      role: "admin",
      isActive: true,
    };

    addadminbackend(adminData, setAddModal, dispatch);
  };

  const handleEditAdmin = () => {
    let editAdmin = {
      first_name,
      last_name,
      email,
    };
    let data = { _id: editUser._id, editAdmin };
    updateAdminBackend(data, setEditModal, dispatch);
  };

  if (activity == "") {
    return (
      <>
        {!loading ? (
          <>
            <div className="content">
              <BreadCrumbs
                breadCrumbList={[
                  { title: "Admins", pathName: "/admin/admins" },
                ]}
              />
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <CardTitle
                        tag="h4"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                        <i
                          className="fa fa-arrow-circle-left"
                          style={{ fontSize: "36px", cursor: "pointer" }}
                          onClick={() => {
                            history.goBack();
                          }}
                        ></i>{" "}
                        <h4 style={{ marginLeft: "10px" }}>Admins</h4>
                      </CardTitle>
                      <div style={{ display: "flex" }}>
                        <p className="category">
                          {" "}
                          Total {admins.length} Admins Registered
                        </p>
                        <div style={{ marginLeft: "66%" }}>
                          <button
                            id="add_admin"
                            onClick={() => {
                              setfirst_name("");
                              setlast_name("");
                              setuser_name("");
                              setemail("");
                              setAddModal(true);
                            }}
                            className="btn btn-priamry"
                            style={{ border: "0px", fontSize: "14px" }}
                          >
                            {" "}
                            Add Admin
                          </button>
                          <div className="dropdown">
                            <a
                              className="btn btn-secondary dropdown-toggle"
                              role="button"
                              id="dropdownMenuLinkGrade"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style={{ width: "220px" }}
                            >
                              {activity === ""
                                ? "Select Active/Inactive"
                                : activity}
                            </a>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLinkGrade"
                            >
                              <a
                                style={{ cursor: "pointer" }}
                                className="dropdown-item"
                                onClick={() => {
                                  setactivity("Active");
                                  // setcoursePlan("premium");
                                }}
                              >
                                Active
                              </a>
                              <a
                                style={{ cursor: "pointer" }}
                                className="dropdown-item"
                                onClick={() => {
                                  setactivity("InActive");
                                  // setcoursePlan("freemium");
                                }}
                              >
                                InActive
                              </a>
                              <a
                                style={{ cursor: "pointer" }}
                                className="dropdown-item"
                                onClick={() => {
                                  setactivity("");
                                  // setcoursePlan("freemium");
                                }}
                              >
                                None
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {/* style={{ width: "90%", margin: "3%" }} */}
                        <Col>
                          {/* style={{ width: "100%" }} */}
                          <table
                            id="dtBasicExample"
                            className="table table-striped table-bordered table-sm"
                            cellspacing="0"
                            width="100%"
                          >
                            <thead>
                              <tr>
                                <th className="th-sm">Name</th>
                                <th className="th-sm">Email</th>
                                {/* <th className="th-sm">Member Since</th> */}
                                {/* <th className="th-sm">Referral Code</th> */}
                                <th className="th-sm">Status</th>

                                <th className="th-sm">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {admins.map((user, i) => (
                                <tr
                                  id={`row_${i}`}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <td>
                                    {user.first_name} {user.last_name}
                                  </td>
                                  <td>{user.email}</td>
                                  {/* <td>
                         {user.date_created
                           ? dateFormat(user.date_created, "mmmm dS, yyyy")
                           : "-"}
                       </td> */}
                                  {/* <td>{user.referral_code}</td> */}
                                  <td>
                                    {user.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          setfirst_name(user.first_name);
                                          setlast_name(user.last_name);
                                          setuser_name(user.user_name);
                                          setemail(user.email);
                                          setEditUser(user);
                                          setEditModal(true);
                                        }}
                                        id={`edit_${i}`}
                                      >
                                        {" "}
                                        <FaRegEdit
                                          style={{
                                            fontSize: "18px",
                                            color: "#008B8B",
                                          }}
                                        ></FaRegEdit>{" "}
                                      </a>
                                      <p style={{ flexGrow: "1" }}></p>
                                      {user.isActive ? (
                                        <a
                                          id={`circle_${i}`}
                                          style={{ marginRight: "50%" }}
                                        >
                                          {" "}
                                          <FaCheckCircle
                                            onClick={() => {
                                              setEditUser(user);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                          ></FaCheckCircle>{" "}
                                        </a>
                                      ) : (
                                        <a
                                          id={`ban_${i}`}
                                          style={{ marginRight: "50%" }}
                                        >
                                          {" "}
                                          <FaBan
                                            onClick={() => {
                                              setEditUser(user);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                          ></FaBan>{" "}
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Modal
                visible={editModal}
                width="500"
                effect="fadeInUp"
                onClickAway={() => {
                  setEditModal(false);
                }}
              >
                <div className="modal-container">
                  <h4
                    className="mb-4"
                    style={{ color: "#242b3b", marginLeft: "35%" }}
                  >
                    Edit Admin
                  </h4>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleEditAdmin();
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    >
                      <p>First Name</p>
                      <input
                        type="text"
                        required
                        name="firstName"
                        value={first_name}
                        onChange={(e) => setfirst_name(e.target.value)}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    >
                      <p>Last Name</p>
                      <input
                        type="text"
                        required
                        name="lastName"
                        value={last_name}
                        onChange={(e) => setlast_name(e.target.value)}
                      />
                    </div>
                    {/* <div
                className="mb-2"
                style={{ marginLeft: "10%", marginRight: "10%" }}
              >
                <p>User Name</p>
                <input
                  type="text"
                  required
                  name="userName"
                  value={user_name}
                  onChange={(e) => setuser_name(e.target.value)}
                />
              </div> */}
                    {/* <div
                className="mb-2"
                style={{ marginLeft: "10%", marginRight: "10%" }}
              >
                <p>Mobile</p>
                <input
                  type="text"
                  required
                  name="mobile"
                  value={editValues.mobile}
                  onChange={handleEditChange("name")}
                />
              </div> */}
                    <div
                      className="mb-2"
                      style={{
                        marginLeft: "10%",
                        marginRight: "10%",
                        marginTop: "3%",
                      }}
                    >
                      <p>Email</p>
                      <input
                        type="email"
                        required
                        name="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>

                    <div
                      className="w-100 d-flex justify-content-end mt-4"
                      style={{ padding: "2%", paddingBottom: "3%" }}
                    >
                      {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                      <button className="btn btn-warning" type="submit">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>
              <Modal
                visible={addModal}
                width="500"
                effect="fadeInUp"
                onClickAway={() => {
                  setAddModal(false);
                }}
              >
                <div className="modal-container">
                  <h4
                    className="mb-4"
                    style={{ color: "#242b3b", marginLeft: "26%" }}
                  >
                    Create New Admin
                  </h4>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleAddAdmin();
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    >
                      <p>First Name</p>
                      <input
                        type="text"
                        required
                        name="firstName"
                        value={first_name}
                        onChange={(e) => setfirst_name(e.target.value)}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    >
                      <p>Last Name</p>
                      <input
                        type="text"
                        required
                        name="lastName"
                        value={last_name}
                        onChange={(e) => setlast_name(e.target.value)}
                      />
                    </div>
                    {/* <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>User Name</p>
                    <input
                      type="text"
                      required
                      name="userName"
                      value={user_name}
                      onChange={(e) => setuser_name(e.target.value)}
                    />
                  </div> */}
                    {/* <div
                className="mb-2"
                style={{ marginLeft: "10%", marginRight: "10%" }}
              >
                <p>Mobile</p>
                <input
                  type="text"
                  required
                  name="mobile"
                  value={editValues.mobile}
                  onChange={handleEditChange("name")}
                />
              </div> */}
                    <div
                      className="mb-2"
                      style={{
                        marginLeft: "10%",
                        marginRight: "10%",
                        marginTop: "3%",
                      }}
                    >
                      <p>Email</p>
                      <input
                        type="email"
                        required
                        name="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    <div
                      className="w-100 d-flex justify-content-end mt-4"
                      style={{ padding: "2%", paddingBottom: "3%" }}
                    >
                      {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                      <button className="btn btn-warning" type="submit">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>
              <Modal
                visible={statusModal}
                width="500"
                effect="fadeInUp"
                onClickAway={() => {
                  setStatusModal(false);
                }}
              >
                <div
                  className="modal-container"
                  style={{ padding: "3%", textAlign: "center" }}
                >
                  <h4 className="mb-4" style={{ color: "#242b3b" }}>
                    Change Status
                  </h4>

                  {editUser.isActive ? (
                    <p style={{ fontSize: "20px" }}>
                      Are you sure you want to restrict this user?
                    </p>
                  ) : (
                    <p style={{ fontSize: "20px" }}>
                      Are you sure you want to change this user's status to
                      active?
                    </p>
                  )}
                  <div
                    className="w-100 d-flex justify-content-between mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        setStatusModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-warning"
                      onClick={() => {
                        let data = {
                          _id: editUser._id,
                          isActive: editUser.isActive,
                        };
                        updateAdminStatus(data, setStatusModal, dispatch);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  } else if (activity == "Active") {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: "36px", cursor: "pointer" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{" "}
                      <h4 style={{ marginLeft: "10px" }}>Admins</h4>
                    </CardTitle>
                    <div style={{ display: "flex" }}>
                      <p className="category">
                        {" "}
                        Total {admins.length} Admins Registered
                      </p>
                      <div style={{ marginLeft: "66%" }}>
                        <button
                          id="add_admin"
                          onClick={() => {
                            setfirst_name("");
                            setlast_name("");
                            setuser_name("");
                            setemail("");
                            setAddModal(true);
                          }}
                          className="btn btn-priamry"
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          Add Admin
                        </button>
                        <div className="dropdown">
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: "220px" }}
                          >
                            {activity === ""
                              ? "Select Active/Inactive"
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("Active");
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("InActive");
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("");
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {/* style={{ width: "90%", margin: "3%" }} */}
                      <Col>
                        {/* style={{ width: "100%" }} */}
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email</th>
                              {/* <th className="th-sm">Member Since</th> */}
                              {/* <th className="th-sm">Referral Code</th> */}
                              <th className="th-sm">Status</th>

                              <th className="th-sm">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {admins.map((user, i) =>
                              user.isActive ? (
                                <tr
                                  id={`row_${i}`}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <td>
                                    {user.first_name} {user.last_name}
                                  </td>
                                  <td>{user.email}</td>
                                  {/* <td>
                         {user.date_created
                           ? dateFormat(user.date_created, "mmmm dS, yyyy")
                           : "-"}
                       </td> */}
                                  {/* <td>{user.referral_code}</td> */}
                                  <td>
                                    {user.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          setfirst_name(user.first_name);
                                          setlast_name(user.last_name);
                                          setuser_name(user.user_name);
                                          setemail(user.email);
                                          setEditUser(user);
                                          setEditModal(true);
                                        }}
                                        id={`edit_${i}`}
                                      >
                                        {" "}
                                        <FaRegEdit
                                          id={`edit_${i}`}
                                          style={{
                                            fontSize: "18px",
                                            color: "#008B8B",
                                          }}
                                        ></FaRegEdit>{" "}
                                      </a>
                                      <p style={{ flexGrow: "1" }}></p>
                                      {user.isActive ? (
                                        <a
                                          id={`circle_${i}`}
                                          style={{ marginRight: "50%" }}
                                        >
                                          {" "}
                                          <FaCheckCircle
                                            onClick={() => {
                                              setEditUser(user);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                          ></FaCheckCircle>{" "}
                                        </a>
                                      ) : (
                                        <a
                                          id={`ban_${i}`}
                                          style={{ marginRight: "50%" }}
                                        >
                                          {" "}
                                          <FaBan
                                            onClick={() => {
                                              setEditUser(user);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                          ></FaBan>{" "}
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={editModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setEditModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "35%" }}
                >
                  Edit Admin
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEditAdmin();
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={first_name}
                      onChange={(e) => setfirst_name(e.target.value)}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="lastName"
                      value={last_name}
                      onChange={(e) => setlast_name(e.target.value)}
                    />
                  </div>
                  {/* <div
                className="mb-2"
                style={{ marginLeft: "10%", marginRight: "10%" }}
              >
                <p>User Name</p>
                <input
                  type="text"
                  required
                  name="userName"
                  value={user_name}
                  onChange={(e) => setuser_name(e.target.value)}
                />
              </div> */}
                  {/* <div
                className="mb-2"
                style={{ marginLeft: "10%", marginRight: "10%" }}
              >
                <p>Mobile</p>
                <input
                  type="text"
                  required
                  name="mobile"
                  value={editValues.mobile}
                  onChange={handleEditChange("name")}
                />
              </div> */}
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={addModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setAddModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "26%" }}
                >
                  Create New Admin
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddAdmin();
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={first_name}
                      onChange={(e) => setfirst_name(e.target.value)}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="lastName"
                      value={last_name}
                      onChange={(e) => setlast_name(e.target.value)}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>User Name</p>
                    <input
                      type="text"
                      required
                      name="userName"
                      value={user_name}
                      onChange={(e) => setuser_name(e.target.value)}
                    />
                  </div>
                  {/* <div
                className="mb-2"
                style={{ marginLeft: "10%", marginRight: "10%" }}
              >
                <p>Mobile</p>
                <input
                  type="text"
                  required
                  name="mobile"
                  value={editValues.mobile}
                  onChange={handleEditChange("name")}
                />
              </div> */}
                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={statusModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setStatusModal(false);
              }}
            >
              <div
                className="modal-container"
                style={{ padding: "3%", textAlign: "center" }}
              >
                <h4 className="mb-4" style={{ color: "#242b3b" }}>
                  Change Status
                </h4>

                {editUser.isActive ? (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to restrict this user?
                  </p>
                ) : (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to change this user's status to
                    active?
                  </p>
                )}
                <div
                  className="w-100 d-flex justify-content-between mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setStatusModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      let data = {
                        _id: editUser._id,
                        isActive: editUser.isActive,
                      };
                      updateAdminStatus(data, setStatusModal, dispatch);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}{" "}
      </>
    );
  } else if (activity == "InActive") {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {!loading ? (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      tag="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <i
                        className="fa fa-arrow-circle-left"
                        style={{ fontSize: "36px", cursor: "pointer" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      ></i>{" "}
                      <h4 style={{ marginLeft: "10px" }}>Admins</h4>
                    </CardTitle>
                    <div style={{ display: "flex" }}>
                      <p className="category">
                        {" "}
                        Total {admins.length} Admins Registered
                      </p>
                      <div style={{ marginLeft: "66%" }}>
                        <button
                          id="add_admin"
                          onClick={() => {
                            setfirst_name("");
                            setlast_name("");
                            setuser_name("");
                            setemail("");
                            setAddModal(true);
                          }}
                          className="btn btn-priamry"
                          style={{ border: "0px", fontSize: "14px" }}
                        >
                          {" "}
                          Add Admin
                        </button>
                        <div className="dropdown">
                          <a
                            className="btn btn-secondary dropdown-toggle"
                            role="button"
                            id="dropdownMenuLinkGrade"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ width: "220px" }}
                          >
                            {activity === ""
                              ? "Select Active/Inactive"
                              : activity}
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLinkGrade"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("Active");
                                // setcoursePlan("premium");
                              }}
                            >
                              Active
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("InActive");
                                // setcoursePlan("freemium");
                              }}
                            >
                              InActive
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={() => {
                                setactivity("");
                                // setcoursePlan("freemium");
                              }}
                            >
                              None
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {/* style={{ width: "90%", margin: "3%" }} */}
                      <Col>
                        {/* style={{ width: "100%" }} */}
                        <table
                          id="dtBasicExample"
                          className="table table-striped table-bordered table-sm"
                          cellspacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th className="th-sm">Name</th>
                              <th className="th-sm">Email</th>
                              {/* <th className="th-sm">Member Since</th> */}
                              {/* <th className="th-sm">Referral Code</th> */}
                              <th className="th-sm">Status</th>

                              <th className="th-sm">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {admins.map((user, i) =>
                              !user.isActive ? (
                                <tr
                                  id={`row_${i}`}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <td>
                                    {user.first_name} {user.last_name}
                                  </td>
                                  <td>{user.email}</td>
                                  {/* <td>
                       {user.date_created
                         ? dateFormat(user.date_created, "mmmm dS, yyyy")
                         : "-"}
                     </td> */}
                                  {/* <td>{user.referral_code}</td> */}
                                  <td>
                                    {user.isActive ? (
                                      <p style={{ color: "green" }}>Active</p>
                                    ) : (
                                      <p style={{ color: "red" }}>Inactive</p>
                                    )}
                                  </td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginLeft: "30%",
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          setfirst_name(user.first_name);
                                          setlast_name(user.last_name);
                                          setuser_name(user.user_name);
                                          setemail(user.email);
                                          setEditUser(user);
                                          setEditModal(true);
                                        }}
                                        id={`edit_${i}`}
                                      >
                                        {" "}
                                        <FaRegEdit
                                          id={`edit_${i}`}
                                          style={{
                                            fontSize: "18px",
                                            color: "#008B8B",
                                          }}
                                        ></FaRegEdit>{" "}
                                      </a>
                                      <p style={{ flexGrow: "1" }}></p>
                                      {user.isActive ? (
                                        <a
                                          id={`circle_${i}`}
                                          style={{ marginRight: "50%" }}
                                        >
                                          {" "}
                                          <FaCheckCircle
                                            onClick={() => {
                                              setEditUser(user);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                          ></FaCheckCircle>{" "}
                                        </a>
                                      ) : (
                                        <a
                                          id={`ban_${i}`}
                                          style={{ marginRight: "50%" }}
                                        >
                                          {" "}
                                          <FaBan
                                            onClick={() => {
                                              setEditUser(user);
                                              setStatusModal(true);
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                          ></FaBan>{" "}
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={editModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setEditModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "35%" }}
                >
                  Edit Admin
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEditAdmin();
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={first_name}
                      onChange={(e) => setfirst_name(e.target.value)}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="lastName"
                      value={last_name}
                      onChange={(e) => setlast_name(e.target.value)}
                    />
                  </div>

                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>

                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={addModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setAddModal(false);
              }}
            >
              <div className="modal-container">
                <h4
                  className="mb-4"
                  style={{ color: "#242b3b", marginLeft: "26%" }}
                >
                  Create New Admin
                </h4>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddAdmin();
                  }}
                >
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>First Name</p>
                    <input
                      type="text"
                      required
                      name="firstName"
                      value={first_name}
                      onChange={(e) => setfirst_name(e.target.value)}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>Last Name</p>
                    <input
                      type="text"
                      required
                      name="lastName"
                      value={last_name}
                      onChange={(e) => setlast_name(e.target.value)}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    <p>User Name</p>
                    <input
                      type="text"
                      required
                      name="userName"
                      value={user_name}
                      onChange={(e) => setuser_name(e.target.value)}
                    />
                  </div>

                  <div
                    className="mb-2"
                    style={{
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginTop: "3%",
                    }}
                  >
                    <p>Email</p>
                    <input
                      type="email"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div
                    className="w-100 d-flex justify-content-end mt-4"
                    style={{ padding: "2%", paddingBottom: "3%" }}
                  >
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-warning" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              visible={statusModal}
              width="500"
              effect="fadeInUp"
              onClickAway={() => {
                setStatusModal(false);
              }}
            >
              <div
                className="modal-container"
                style={{ padding: "3%", textAlign: "center" }}
              >
                <h4 className="mb-4" style={{ color: "#242b3b" }}>
                  Change Status
                </h4>

                {editUser.isActive ? (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to restrict this user?
                  </p>
                ) : (
                  <p style={{ fontSize: "20px" }}>
                    Are you sure you want to change this user's status to
                    active?
                  </p>
                )}
                <div
                  className="w-100 d-flex justify-content-between mt-4"
                  style={{ padding: "2%", paddingBottom: "3%" }}
                >
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setStatusModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      let data = {
                        _id: editUser._id,
                        isActive: editUser.isActive,
                      };
                      updateAdminStatus(data, setStatusModal, dispatch);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={loader} alt="Loading...." />
                    </CardTitle>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
};

export default Admins;
