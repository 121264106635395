import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
  registeredCourses: [],
  selectedStudent: null
};

const StudentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setstudent: (state, action) => {
      state.students = action.payload.sort(function(x, y) {
        // true values first
        // return (x === y)? 0 : x? -1 : 1;
        // false values first
        return (x === y)? 0 : x? 1 : -1;
    });
    },
    setregisteredCourses: (state, action) => {
      state.registeredCourses = action.payload;
    },
    addstudent: (state, action) => {
      state.students.push(action.payload);
    },
    editstudent: (state, action) => {
      let index = state.students.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.students];
      
      updated[index] = action.payload;
      state.students = updated;
    },
    editstudentStatus: (state, action) => {
      let index = state.students.findIndex((u) => u._id == action.payload._id);
      let updated = [...state.students];
      
      updated[index].isActive = action.payload.isActive;
      state.students = updated;
    },
    setSelectedStudent: (state, action) => {
      state.selectedStudent = action.payload;
    },
  },
});

export const {
  setstudent,
  setregisteredCourses,
  addstudent,
  editstudent,
  editstudentStatus,
  setSelectedStudent
} = StudentSlice.actions;
export const selectstudent = (state) => state.student.students;
export const studentSelected = (state) => state.student.selectedStudent;
export const selectRegisteredCourses = (state) =>
  state.student.registeredCourses;
export default StudentSlice.reducer;
